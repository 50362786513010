export const forgotpassword_style = {
  rootSx: {
    bgcolor: '#F5F5F5',
  },

  inputGroupSx: { display: 'grid', gap: 0.5 },

  totalSx: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    overflow: 'hidden',
  },
  leftSideSx: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },

  eyeSx: {
    width: '20px',
    height: '20px',
  },

  lastText: {
    color: 'text.secondary',
    fontSize: 14,
    opacity: 1,
    textAlign: 'center',
    mt: 9,
    display: 'flex',
    gap: 0.5,
    justifyContent: 'center',
  },

  signSx: {
    fontWeight: 600,
    fontSize: '14px',
    color: 'primary.main',
    opacity: 1,
    cursor: 'pointer',
    textDecoration: 'none',
  },

  googleStyle: {
    color: '#ACACAC',
    border: '1px solid',
    borderColor: 'grey.400',
    textTransform: 'none',
  },

  buttonsStyle: {
    textTransform: 'none',
  },

  titleSx: {
    color: 'text.primary',
    fontSize: '20px',
    fontWeight: 600,
  },

  forgotpasswordSx: {
    float: 'right',
    marginTop: '10px',
    fontWeight: 600,
    fontSize: '14px',
    cursor: 'pointer',
    color: 'primary.main',
    opacity: 1,
    textAlign: 'end',
    textDecoration: 'none',
  },

  rightImgSx: {
    height: '696px',
  },
  rightImgMobileSx: {
    height: '696px',
    marginBottom: '241px',
    opacity: '0.6',
  },
  rightSideSx: {
    px: 2.5,
    pt: 4,
    pb: 5,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '24px',
  },
  CardcontentSx: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 8px 69px #0000001A',
    borderRadius: '12px',
    px: 4,
    py: 5,
    mt: 2.5,
    width: '435px',
  },
  CardcontentMobileSx: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 8px 69px #0000001A',
    borderRadius: '24px 24px 0px 0px',
    px: 2.5,
    py: 2.5,
    width: '386px',
    position: 'fixed',
    zIndex: 1,
    bottom: 0,
  },
  imgSx: {
    width: '100%',
    maxWidth: '150px',
  },
  buttonBox: {
    display: 'grid',
    gap: 3,
    pt: 1.5,
  },
  labelStyle: {
    color: '#262C33',
    lineHeight: '1.4375em',
    fontWeight: 500,
  },
  subtitleSx: {
    color: '#808080',
    pt: 1,
    pb: 0.5,
    fontWeight: 500,
  },
  inputFieldSx: {
    '& .MuiOutlinedInput-input': {
      width: '100%',
      fontWeight: 600,
      backgroundColor: '#fff',
    },
  },
};
