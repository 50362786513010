export const prescriptionHeader_style = {
  containerSx: {
    backgroundColor: '#ECECEC',
    borderRadius: '8px',
    p: 1,
  },
  typoSx: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#0E1824',
  },
};
