import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CustomTextArea, Dropdown, Input } from '../../../atoms';
import { addMedicineModal_style } from './style';

function AddMedicineModal(props) {
  const { className = '', rootStyle = {}, ...rest } = props;
  const selectMedicine = [
    {
      label: 'Dolo',
      value: 'Dolo',
    },
    {
      label: 'Dolo 500',
      value: 'Dolo 500',
    },
    { label: 'Dolo 600', value: 'Dolo 600' },
  ];

  const selectDose = [
    {
      label: '1-1-1',
      value: '1-1-1',
    },
    {
      value: '1-0-1',
      label: '1-0-1',
    },
    { value: '1-0-0', label: '1-0-0' },

    { value: '0-0-1', label: '0-0-1' },
  ];

  const selectTime = [
    {
      label: '5 mints',
      value: '5 mints',
    },
    {
      value: '10 mints',
      label: '10 mints',
    },
  ];

  const selectWhen = [
    {
      label: 'before food',
      value: 'before food',
    },
    {
      value: 'after food',
      label: 'after food',
    },
  ];

  const selectFrequency = [
    {
      label: 'Daily',
      value: 'Daily',
    },
    {
      value: 'Alternate Day',
      label: 'Alternate Day',
    },
  ];

  const selectDurationMode = [
    {
      label: 'Day(s)',
      value: 'Day(s)',
    },
    {
      value: 'Week(s)',
      label: 'Week(s)',
    },
  ];

  return (
    <Box
      sx={{
        ...addMedicineModal_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography gutterBottom fontWeight={600} mb={0.5}>
                  Medicine
                </Typography>
                <Dropdown selectOption={selectMedicine} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={2}>
              <Grid item xs={4}>
                <Typography gutterBottom fontWeight={600} mb={0.5}>
                  Dose
                </Typography>
                <Dropdown selectOption={selectDose} />
              </Grid>
              <Grid item xs={4}>
                <Typography gutterBottom fontWeight={600} mb={0.5}>
                  Time
                </Typography>
                <Dropdown selectOption={selectTime} />
              </Grid>
              <Grid item xs={4}>
                <Typography gutterBottom fontWeight={600} mb={0.5}>
                  When
                </Typography>
                <Dropdown selectOption={selectWhen} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={2}>
              <Grid item xs={4}>
                <Typography gutterBottom fontWeight={600} mb={0.5}>
                  Frequency
                </Typography>
                <Dropdown selectOption={selectFrequency} />
              </Grid>
              <Grid item xs={2}>
                <Typography gutterBottom fontWeight={600} mb={0.5}>
                  Duration
                </Typography>
                <Input placeholder="E.g 1" />
              </Grid>
              <Grid item xs={3.5}>
                <Typography gutterBottom fontWeight={600} mb={0.5}>
                  Duration Mode
                </Typography>
                <Dropdown selectOption={selectDurationMode} />
              </Grid>
              <Grid item xs={2.5}>
                <Typography gutterBottom fontWeight={600} mb={0.5}>
                  Qty
                </Typography>
                <Input placeholder="E.g 1" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography gutterBottom fontWeight={600} mb={0.5}>
                  Notes / Instructions
                </Typography>
                {/* <TextField multiline fullWidth rows={2} maxRows={4} /> */}
                <CustomTextArea
                  fullWidth
                  rows={1.8}
                  rowsMax={5}
                  sx={{
                    width: '100%',
                  }}
                  // onChange={(e) => {
                  //   handleChange(e.target.value);
                  // }}
                  // value={text}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

AddMedicineModal.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { AddMedicineModal };
