export const doctorProfile_style = {
  backIconSx: {
    cursor: 'pointer',
    color: '#000',
  },
  titleSx: {
    color: '#000',
  },
  leftSx: { borderRight: '0px solid ' },
  rightSx: {
    overflowY: 'scroll',
  },
  totalLeftBox: {
    position: 'fixed',
    height: '100%',
    // width: { xs: 'auto', md: '320px' },
  },
  totalTopBox: { height: '100%', px: 2 },
  buttonSx: {
    border: '2px solid #007965',
    '&:hover': {
      border: '2px solid #007965',
    },
  },
  totalBotomSx: {
    px: 2,
    pb: 16,
  },
};
