export const patientCard_style = {
  avatar: {
    background: '#F5F5F5',
  },
  subText: {
    textTransform: 'capitalize',
    color: '#0F0B117A',
    fontSize: '14px',
    fontWeight: 500,
  },

  totalCardLeftSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  overAllSx: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: '1px dashed',
    borderColor: '#808080',
    pt: 1,
    pb: 3,
  },
  referCardSx: {
    fontSize: '14px',
    backgroundColor: 'transparent',
    color: 'primary.main',
    textTransform: 'capitalize',
    border: '1px solid ',
    borderColor: 'primary.main',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'primary.main',
      boxShadow: 'none',
    },
  },
  totalCardRightSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  totalPatientCardSx: {
    // py: 3,
  },
  viewSx: {
    width: '100px',
  },
  documentSx: {
    color: 'orangeTints.400',
    width: '22px',
    height: '22px',
  },
  viewSummarySx: {
    '&: hover': {
      backgroundColor: '#fff5ef',
    },
  },
  nameSx: {
    width: '280px',
  },
  gridcenter: {
    justifyContent: 'center',
  },
  gridcenterTotal: {
    // border: '1px solid #000',
    mt: 2,
    pb: 3,
  },
  totalBoxSx: {
    mt: 2,
    mb: 3,
  },
};
