export const rootLayout_style = {
  rootSx: {
    bgcolor: 'white',
    maxWidth: {
      lg: 1024,
    },
    mx: {
      lg: 'auto',
    },
  },

  mobileRootSx: {
    bgcolor: 'white',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },

  backgroundSx: {
    bgcolor: '#F1F5F9',
    boxShadow: {
      lg: '0px 0px 10px #efefef',
    },
    minHeight: '100vh',
    width: '100%',
    maxHeight: 'auto',
  },
};
