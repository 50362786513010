/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
import { Button, ShareReport } from '@atoms';
import { Box, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { confirmationPopup_style } from './style';

export function ConfirmationPopup(props) {
  const {
    notifyBtnStyle = {},
    notifyBtnsStyle = {},
    icons,
    setOpen,
    loading,
    subTitleStyle = {},
    totalBoxStyle = {},
    notifyBtnStyletwo = {},
    // handleClose = () => false,
    subTitle = '',
    open = false,
    icon = false,
    className = '',
    acceptButtonName = '',
    declineButtonName = '',
    onAccpet,
    onDecline,
    field = '',
    ...rest
  } = props;

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={confirmationPopup_style.totalModalSx}>
        <Box className={`${className}`} {...rest}>
          <Box sx={{ ...confirmationPopup_style.totalBoxSx, ...totalBoxStyle }}>
            <Box pt={1}>{icons}</Box>
            {icon && (
              <ShareReport rootStyle={confirmationPopup_style.notifyIconSx} />
            )}
            <Typography
              sx={{ ...confirmationPopup_style.subTitleSx, ...subTitleStyle }}
            >
              {subTitle}
            </Typography>
            <div
              style={{
                marginBottom: '30px',
                marginRight: '10px',
                marginLeft: '10px',
              }}
            >
              {field}{' '}
            </div>

            <Box sx={confirmationPopup_style.totalBtnSx}>
              <Button
                buttonStyle={{
                  ...confirmationPopup_style.notifyBtnSx,
                  backgroundColor: 'error.main',
                  ...notifyBtnStyletwo,
                }}
                onClick={onDecline}
              >
                {/* Decline */}
                {declineButtonName}
              </Button>
              <Button
                buttonStyle={{
                  ...confirmationPopup_style.notifyBtnSx,
                  backgroundColor: 'primary.main',
                  ...notifyBtnsStyle,
                }}
                onClick={onAccpet}
                // loading={loading}
              >
                {/* Accept */}
                {acceptButtonName}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
ConfirmationPopup.propTypes = {
  notifyBtnStyle: PropTypes.object,
  open: PropTypes.bool,
  totalBoxStyle: PropTypes.object,
  onDecline: PropTypes.func,
  onAccpet: PropTypes.func,
  subTitle: PropTypes.string,
  field: PropTypes.string,
  className: PropTypes.string,
  acceptButtonName: PropTypes.string,
  declineButtonName: PropTypes.string,
  icon: PropTypes.bool,
  notifyBtnsStyle: PropTypes.object,
  icons: PropTypes.any,
  setOpen: PropTypes.any,
  loading: PropTypes.any,
  subTitleStyle: PropTypes.object,
  notifyBtnStyletwo: PropTypes.object,
};
