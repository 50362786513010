import { Box, Typography, Grid, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { EditIcon, TrashIcon } from '@atoms';
import { drugsListMapping_style } from './style';

function DrugsListItem(props) {
  const {
    className = '',
    medicineName = '',
    dose = '',
    timeToTakeDose = '',
    course = '',
    quantity = '',
    onDelete = () => {},
    onEdit = () => {},
    rootStyle = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...drugsListMapping_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box mb={1.2}>
        <Typography gutterBottom fontSize="14px" fontWeight={500}>
          • {medicineName}
        </Typography>
        <Grid container display="flex" justifyContent="space-between">
          <Grid item xs={11} sm={10.5} md={11} lg={11}>
            <Grid container columnSpacing={2}>
              <Grid item>
                <Typography fontSize="14px" color="#888888">
                  {dose}
                </Typography>
              </Grid>
              <Grid item>
                <Divider
                  sx={drugsListMapping_style.dividerSx}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
              </Grid>
              <Grid item>
                <Typography fontSize="14px" color="#888888">
                  {timeToTakeDose}
                </Typography>
              </Grid>
              <Grid item>
                <Divider
                  sx={drugsListMapping_style.dividerSx}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
              </Grid>
              <Grid item>
                <Typography fontSize="14px" color="#888888">
                  {course}
                </Typography>
              </Grid>
              <Grid item>
                <Divider
                  sx={drugsListMapping_style.dividerSx}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
              </Grid>
              <Grid item>
                <Typography fontSize="14px" color="#888888">
                  {quantity} Qty
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

DrugsListItem.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  medicineName: PropTypes.string,
  dose: PropTypes.string,
  timeToTakeDose: PropTypes.string,
  course: PropTypes.string,
  quantity: PropTypes.string,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export { DrugsListItem };
