export const summaryPrescription_style = {
  addSummaryTitleSx: {
    color: '#808080',
    fontSize: '12px',
    pb: 0.5,
  },
  rootSx: {
    mt: 2,
  },
  importRxGroupTextSx: {
    fontSize: '13px',
    color: '#007965',
    fontWeight: 500,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    pb: 1,
    pr: 0.5,
  },
  medicineHeadingTextSx: {
    display: 'flex',
    alignItems: 'center',
    color: '#4F4F4F',
    fontSize: '14px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  medicineTextSx: {
    color: 'grey.600',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
  rxGroupSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pb: 2,
    mt: 3,
  },
  dividerSx: {
    mx: 1.5,
    height: '25px',
    border: '0.5px solid',
    backgroundColor: 'grey.400',
    borderColor: 'grey.400',
  },
  dividerSxx: {
    borderStyle: 'dashed',
    borderColor: 'divider',
    margin: '0px 8px',
  },
  avatarSx: {
    backgroundColor: 'text.primary',
    width: '24px',
    height: '24px',
    fontSize: '12px',
    ml: 1,
  },
  commonTextColorSx: {
    color: '#007965',
    fontSize: '13px',
  },
  inputTextShowSx: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    bgcolor: '#F5F5F5',
    pt: 1,
    pb: 1.2,
    pl: 1,
    borderRadius: '8px',
  },
  summaryTitlesx: {
    color: 'text.primary',
    fontSize: '14px',
    fontWeight: 500,
  },
  boxCardSx: {
    bgcolor: '#F5F5F5',
    borderRadius: '8px',
    p: 2,
    mb: 1.5,
  },
  valTextSx: {
    color: 'text.primary',
    fontSize: '14px',
    fontWeight: 600,
  },
};
