import { useSummary, useViewPort } from '@hc/store';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import {
  AddSummaryIcon,
  SummaryEditIcon,
  SummaryTrashIcon,
} from '../../../atoms';
import { summaryPrescription_style } from './style';
import { PrescriptionDeleteModal } from '../prescription/prescriptionDeleteModal';
import { useState } from 'react';
import { useRxGroup } from '@hc/store/doctor/rxGroup';

function SummaryPrescription(props) {
  const {
    summaryView = false,
    summaryModelView = false,
    addTitle = '',
    summaryTitle = '',
    className = '',
    addPrescription,
    isRxAudit,
    isRxAuditData,
    isMobile,
    rootStyle = {},
    ...rest
  } = props;

  const [deleteData, setDeleteData] = useState({
    drugName: '',
    index: null,
  });
  const [DeleteModal, setDeleteModal] = useState(false);

  // const { deleteDrugData } = useRxGroup((state) => ({
  //   deleteDrugData: state.deleteDrugData,
  // }));

  const { summaryState, deleteDrugData } = useSummary((state) => ({
    summaryState: state.summaryState,
    deleteDrugData: state.deleteDrugData,
  }));

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const { pastSummaryData, prescriptionData, prescriptionNotesInstruction } =
    summaryState;

  // const { prescription } = pastSummaryData;

  const prescription = isRxAudit
    ? isRxAuditData?.prescriptions
    : pastSummaryData?.prescription;

  const DeleteModalOpenFun = (drugName, i) => {
    setDeleteData({
      drugName,
      index: i,
    });
    setDeleteModal(true);
  };
  // DELETE MODEL CLOSE FUNCTION
  const DeleteModalCloseFun = () => {
    setDeleteModal(false);
    setDeleteData({
      drugName: '',
      index: null,
    });
  };
  // DELETE DOSE
  const handleDelete = async () => {
    deleteDrugData(deleteData?.index);
    DeleteModalCloseFun();
  };

  return (
    <Box
      sx={{
        ...summaryPrescription_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {/* Summary Title with Import Rx Group Text */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography sx={summaryPrescription_style.addSummaryTitleSx}>
          {summaryTitle}
        </Typography>
      </Box>

      {!summaryModelView && !summaryView && (
        <>
          <Box>
            {Array.isArray(prescriptionData) && prescriptionData?.length > 0
              ? prescriptionData
                  .filter((val) => val.isDeleted === false)
                  ?.map((val, i) => {
                    return (
                      <>
                        <Box key={i}>
                          <Box
                            sx={{
                              bgcolor: '#F5F5F5',
                              borderRadius: '8px',
                              p: 2,
                            }}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography
                                sx={
                                  summaryPrescription_style.medicineHeadingTextSx
                                }
                              >
                                <RiCheckboxBlankCircleFill
                                  style={{ fontSize: '6px', ml: 1, mr: 0.5 }}
                                />
                                &nbsp; {val?.drugName ?? ''}
                              </Typography>
                              <Box>
                                <IconButton
                                  disableRipple
                                  onClick={() =>
                                    DeleteModalOpenFun(val?.drugName, i)
                                  }
                                >
                                  <SummaryTrashIcon />
                                </IconButton>
                                <IconButton
                                  disableRipple
                                  onClick={addPrescription}
                                >
                                  <SummaryEditIcon />
                                </IconButton>
                              </Box>
                            </Box>
                            <Box display="flex" alignItems="center">
                              <Typography
                                sx={summaryPrescription_style.medicineTextSx}
                              >
                                {`${val?.morningDose} - ${val?.afterNoonDose} - ${val?.nightDose}`}
                              </Typography>
                              <Divider
                                orientation="vertical"
                                sx={summaryPrescription_style.dividerSx}
                              />
                              <Typography
                                sx={summaryPrescription_style.medicineTextSx}
                              >
                                {`${val?.time?.label} - ${val?.when?.label}`}
                              </Typography>
                              <Divider
                                orientation="vertical"
                                sx={summaryPrescription_style.dividerSx}
                              />
                              <Typography
                                sx={summaryPrescription_style.medicineTextSx}
                              >
                                {`${val?.frequency?.label} for ${val?.duration} ${val?.durationMode?.label}`}
                              </Typography>
                              <Divider
                                orientation="vertical"
                                sx={summaryPrescription_style.dividerSx}
                              />
                              <Typography
                                sx={summaryPrescription_style.medicineTextSx}
                              >
                                {`${val?.quantity} Qty`}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        {/* <Divider sx={summaryPrescription_style.dividerSxx} /> */}
                      </>
                    );
                  })
              : null}

            {prescriptionNotesInstruction?.length > 0 && (
              <>
                <Divider sx={summaryPrescription_style.dividerSxx} />
                <Box sx={summaryPrescription_style.boxCardSx}>
                  <Typography sx={summaryPrescription_style.valTextSx}>
                    {prescriptionNotesInstruction ?? ''}
                  </Typography>
                </Box>
              </>
            )}
          </Box>

          {/* Add Summary Icon With Text */}
          <Box
            sx={summaryPrescription_style.inputTextShowSx}
            onClick={() => addPrescription()}
          >
            <IconButton disableRipple>
              <AddSummaryIcon />
            </IconButton>
            <Typography sx={summaryPrescription_style.summaryTitlesx}>
              {addTitle}
            </Typography>
          </Box>
        </>
      )}

      {summaryView && (
        <Box>
          {Array.isArray(prescription) && prescription?.length > 0 ? (
            prescription?.map((val, i) => {
              return (
                <Box key={i}>
                  <Box
                    sx={{
                      bgcolor: '#F5F5F5',
                      borderRadius: '8px',
                      p: 2,
                    }}
                  >
                    <Typography
                      sx={summaryPrescription_style.medicineHeadingTextSx}
                    >
                      <RiCheckboxBlankCircleFill
                        style={{ fontSize: '6px', ml: 1, mr: 0.5 }}
                      />
                      &nbsp; {val?.drug ?? ''}
                    </Typography>

                    <Box
                      display={isMobilePort ? '' : 'flex'}
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center">
                        <Typography
                          sx={summaryPrescription_style.medicineTextSx}
                        >
                          {' '}
                          {val?.dose ?? ''}
                        </Typography>
                        <Divider
                          orientation="vertical"
                          sx={summaryPrescription_style.dividerSx}
                        />
                        <Typography
                          sx={summaryPrescription_style.medicineTextSx}
                        >
                          {' '}
                          {`${val?.time} - ${val?.when}`}
                        </Typography>
                        <Divider
                          orientation="vertical"
                          sx={summaryPrescription_style.dividerSx}
                        />
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography
                          sx={summaryPrescription_style.medicineTextSx}
                        >
                          {' '}
                          {`${val?.frequency} for ${val?.duration} ${val?.duration_mode}`}
                        </Typography>
                        <Divider
                          orientation="vertical"
                          sx={summaryPrescription_style.dividerSx}
                        />
                        <Typography
                          sx={summaryPrescription_style.medicineTextSx}
                        >
                          {`${val?.quantity} Qty`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box sx={summaryPrescription_style.boxCardSx}>
              <Typography sx={summaryPrescription_style.valTextSx}>
                No Prescription found!
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {summaryModelView && (
        <Box>
          {Array.isArray(prescriptionData) && prescriptionData?.length > 0 ? (
            prescriptionData?.map((val, i) => {
              return (
                <>
                  <Box key={i}>
                    <Box
                      sx={{
                        bgcolor: '#F5F5F5',
                        borderRadius: '8px',
                        py: 1.5,
                        px: 2,
                      }}
                    >
                      <Typography
                        sx={summaryPrescription_style.medicineHeadingTextSx}
                      >
                        <RiCheckboxBlankCircleFill
                          style={{ fontSize: '6px', ml: 1, mr: 0.5 }}
                        />
                        &nbsp; {val?.drugName ?? ''}
                      </Typography>

                      <Box sx={{ display: 'flex' }}>
                        <Typography
                          sx={summaryPrescription_style.medicineTextSx}
                        >
                          {`${val?.morningDose} - ${val?.afterNoonDose} - ${val?.nightDose}`}
                        </Typography>
                        {!isMobile && (
                          <Divider
                            orientation="vertical"
                            sx={summaryPrescription_style.dividerSx}
                          />
                        )}

                        <Typography
                          sx={summaryPrescription_style.medicineTextSx}
                        >
                          {`${val?.time?.label} - ${val?.when?.label}`}
                        </Typography>
                        {!isMobile && (
                          <Divider
                            orientation="vertical"
                            sx={summaryPrescription_style.dividerSx}
                          />
                        )}
                        <Typography
                          sx={summaryPrescription_style.medicineTextSx}
                        >
                          {`${val?.frequency?.label} for ${val?.duration} ${val?.durationMode?.label}`}
                        </Typography>
                        {!isMobile && (
                          <Divider
                            orientation="vertical"
                            sx={summaryPrescription_style.dividerSx}
                          />
                        )}
                        <Typography
                          sx={summaryPrescription_style.medicineTextSx}
                        >
                          {`${val?.quantity} Qty`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={summaryPrescription_style.dividerSxx} />
                </>
              );
            })
          ) : (
            <Box sx={summaryPrescription_style.boxCardSx}>
              <Typography sx={summaryPrescription_style.valTextSx}>
                No Prescription found!
              </Typography>
            </Box>
          )}
        </Box>
      )}

      <PrescriptionDeleteModal
        isMobileResponsive={isMobilePort}
        isModalOpen={DeleteModal}
        handleClose={DeleteModalCloseFun}
        handleDelete={handleDelete}
        drugName={deleteData?.drugName}
      />
    </Box>
  );
}

SummaryPrescription.propTypes = {
  className: PropTypes.string,
  addTitle: PropTypes.string,
  summaryTitle: PropTypes.string,
  viewSummaryTitle: PropTypes.string,
  summaryView: PropTypes.bool,
  rootStyle: PropTypes.object,
  summaryModelView: PropTypes.bool,
  addPrescription: PropTypes.any,
};

export { SummaryPrescription };
