import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@styles/global.css';

import { lightTheme, theme } from '@hc/theme';
// import { queryClient } from '@lib/config/queryClient';
import { queryClient } from '@hc/utils';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import RouterApp from '@router';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';

const themeGenerator = createTheme({
  ...theme,
  ...lightTheme,
});

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;

function App() {
  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={themeGenerator}>
          <CssBaseline />
          <RouterApp />
        </ThemeProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
