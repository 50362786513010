export const refferalNotesCard_style = {
  cardParentSx: {
    bgcolor: '#F5F5F5',
    borderRadius: '8px',
    py: 2,
    px: 2,
  },
  notesStyle: {
    color: '#0E1824',
    fontSize: '14px',
    fontWeight: 500,
    mb: 2,
  },
};
