export const drugsCard_style = {
  rootSx: {
    bgcolor: '#F5F5F5',
    borderRadius: '8px',
    py: 2,
  },
  dividerSx: {
    height: '20px',
    border: '1px solid',
    borderColor: '#E2E2E2',
    backgroundColor: '#E2E2E2',
    mt: '1px',
  },
  addDrugSx: {
    display: 'flex',
    borderTop: '2px dashed #DBDBDB',
    px: 2,
    pt: 2,
    cursor: 'pointer',
  },
};
