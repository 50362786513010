/* eslint-disable react/no-children-prop */

import { Layout } from '@components/doctor';
import { doctorRoutes } from '@hc/routes';
import { useSummary } from '@hc/store';
import { ReferralSuccessPage } from '@hc/ui/components/doctor';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { homepage_style } from './style';

export default function ReferralSuccess() {
  const navigate = useNavigate();

  const { summaryState } = useSummary((state) => ({
    summaryState: state.summaryState,
  }));

  const { referToDoctorDetails } = summaryState;

  const { doctorSpeciality, doctorName } = referToDoctorDetails;

  const setProfile = () => {
    navigate(doctorRoutes?.profile);
  };

  const setAvailability = () => {
    navigate(doctorRoutes.availability);
  };

  const rxGroup = () => {
    navigate(doctorRoutes.rxgroup);
  };

  const LogoutFun = () => {
    toast.success('Signed Out Successfully');
    localStorage.clear();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const menuItems = [
    {
      name: 'View Profile',
      onclick: setProfile,
    },
    {
      name: 'Set Availability',
      onclick: setAvailability,
    },
    {
      name: 'Rx Group',
      onclick: rxGroup,
    },
    {
      name: 'Logout',
      onclick: LogoutFun,
    },
  ];

  const backOnClick = () => {
    navigate(doctorRoutes?.summary);
  };

  const initialData = async () => {};

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Layout
      children={
        <Box sx={{ px: 1, pt: 3, background: 'white', height: '100vh' }}>
          <ReferralSuccessPage
            message="Referral Invite Sent Successfully"
            doctorName={doctorName ?? ''}
            speciality={doctorSpeciality ?? ''}
            backOnClick={backOnClick}
          />
        </Box>
      }
      // menuItems={data?.is_verified ? verifyDoctorMenuItems : menuItems}
      menuItems={menuItems ?? []}
    />
  );
}
