export const referralSuccesPage_style = {
  rootSx: {
    display: 'grid',
    gap: 2,
    // minHeight: '100vh',
  },
  bannerTextSx: {
    color: 'text.primary',
    fontSize: '20px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
  cardSx: {
    boxShadow: 'none',
    height: '88px',
    backgroundColor: '#FFD8BE',
    overflow: 'inherit',
    position: 'relative',
    // marginTop: '16px',
    borderRadius: '8px',
  },
  imgcontentSx: {
    position: 'absolute',
    top: '-23px',
    zIndex: '1000',
  },
  bannerSubTextSx: {
    fontSize: '14px',
    textAlign: 'left',
  },
  avatarSx: {
    background: '#F5F5F5',
    height: '30px',
    width: '30px',
    display:'flex',
    justifyContent:"center",
    borderRadius:'50%'
  },
  subTextSx: {
    textTransform: 'capitalize',
    color: '#808080',
    fontSize: '14px',
  },
  doctorSx: {
    display: 'flex',
    alignItems: 'center',
    pt: 0.5,

    textTransform: 'capitalize',
    color: '#808080',
    fontSize: '14px',
  },
  text: {
    fontWeight: 500,
    color: 'primary.text',
    mb: 1,
    mt: 2,
  },
  box: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    mb: 1,
    mt: 6,
  },
  cardBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  labelSxx: {
    // backgroundColor: 'PrimaryTints.A100',
    p: 0.4,
    borderRadius: '4px',
    color: '#868484',
    fontSize: '12px',
    ml: 0.8,
    fontWeight: 600,
  },
  assitantSx: {
    color: 'text.hint',
    fontSize: '12px !important',
    fontWeight: 'medium',
    ml: 1,
    // width: '100px',
  },
};
