export const prescriptionTimeFrequencyModal_style = {
  titleSx: {
    fontSize: 16,
    fontWeight: 600,
  },
  dialogSx: {
    width: { xs: '80vw', md: '60vw' },
  },
  buttonSx: {
    mr: 0.5,
    bgcolor: 'white',
    color: 'primary.main',
    borderRadius: '8px',
    '&:hover': {
      color: 'white',
    },
    border: '1px solid #007965',
    width: '100px',
  },
  ToggleButtonActiveSx: {
    border: '1px solid',
    borderRadius: '10px',
    padding: '8px',
  },
  ToggleButtonInActiveSx: {
    borderRadius: '10px',
    border: '1px solid ',
    borderColor: '#E3E3E3',
    padding: '8px ',
  },
  activeToggleTextSx: {
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: '14px',
  },
  inActiveToggleTextSx: {
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: '14px',
    color: '#0E1824',
  },
  activeToggleTimeSx: {
    textTransform: 'lowercase',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: '14px',
  },
  inActiveToggleTimeSx: {
    textTransform: 'lowercase',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: '14px',
    color: '#0E1824',
  },
  drawerbuttonSx: {
    border: '2px solid #007965',
    '&:hover': {
      border: '2px solid #007965',
    },
  },
  cancelBtnSx: {
    border: '2px solid #007965',
    textTransform: 'none',
    backgroundColor: '#fff',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: '#Fff',
    },
  },
  applyBtnSx: {
    textTransform: 'none',
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2.5,
  },
};
