export const summaryObservation_style = {
  addSummaryTitleSx: {
    color: '#808080',
    fontSize: '12px',
    pb: 0.5,
  },
  rootSx: {
    mt: 2,
  },
  boxCardSx: {
    bgcolor: '#F5F5F5',
    borderRadius: '8px',
    p: 2,
    mb: 1.5,
  },
  valTextSx: {
    color: 'text.primary',
    fontSize: '14px',
    fontWeight: 600,
  },
  summaryTitlesx: {
    color: 'text.primary',
    fontSize: '14px',
    fontWeight: 500,
  },
  dividerSx: {
    // my: 1.2,
    borderStyle: 'dashed',
    borderColor: 'divider',
  },
  inputValueSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    bgcolor: '#F5F5F5',
    pt: 1,
    pb: 1.2,
    borderRadius: 1,
  },
  inputTextSx: {
    fontSize: '16px',
    color: 'text.primary',
    fontWeight: '500',
    pl: 1,
  },
  inputValueIconSx: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  inputTextShowSx: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    bgcolor: '#F5F5F5',
    pt: 1,
    pb: 1.2,
    pl: 1,
    borderRadius: '8px',
  },
  inputBoxSx: {
    display: 'flex',
    alignItems: 'center',
    bgcolor: '#F5F5F5',
    pt: 1,
    pb: 1,
    pl: 1,
    borderRadius: '8px',
  },
};
