export const instructionModal_style = {
  rootSx: { position: 'relative' },
  totalModalSx: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    border: '0px solid',
    boxShadow: 24,
    px: 2,
    outline: '0px',
  },
  totalBoxSx: {
    borderRadius: '8px',
    mt: { xs: 3, lg: -9.5 },
  },
  typoSx: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#4D4D4D',
  },
  instructionTab: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '8px',
    p: 1.5,
    maxWidth: 'fit-content',
  },
};
