import { Box, Grid, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { pdfSkeleton_style } from './style';

function PdfSkeleton(props) {
  const { color } = props;

  return (
    <Grid
      container
      p={2}
      sx={{
        height: '100vh',
        border: '1px solid #DCF5F1',
        borderRadius: '10px',
        '&.MuiGrid-root>.MuiGrid-item': { paddingTop: '0px' },
      }}
    >
      <Grid
        item
        height="10%"
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Skeleton
            width={200}
            height={20}
            animation="wave"
            sx={{
              ...pdfSkeleton_style.skeletonRoot,
              ...(color && { background: color }),
            }}
          />
          <Skeleton
            width={100}
            height={20}
            animation="wave"
            sx={{
              ...pdfSkeleton_style.skeletonRoot,
              ...(color && { background: color }),
            }}
          />
        </Box>
        <Box>
          <Skeleton
            width={100}
            height={20}
            animation="wave"
            sx={{
              ...pdfSkeleton_style.skeletonRoot,
              ...(color && { background: color }),
            }}
          />
          <Skeleton
            width={100}
            height={20}
            animation="wave"
            sx={{
              ...pdfSkeleton_style.skeletonRoot,
              ...(color && { background: color }),
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          width="70%"
          height={20}
          animation="wave"
          sx={{
            ...pdfSkeleton_style.skeletonRoot,
            ...(color && { background: color }),
          }}
        />
        <Skeleton
          width={100}
          height={20}
          animation="wave"
          sx={{
            ...pdfSkeleton_style.skeletonRoot,
            ...(color && { background: color }),
          }}
        />
      </Grid>
      <Grid item xs={12} pt={0}>
        {Array(6)
          .fill(0)
          .map((item, i) => {
            return (
              <Box mt={4} key={i}>
                <Skeleton
                  width="100%"
                  height={20}
                  animation="wave"
                  sx={{
                    ...pdfSkeleton_style.skeletonRoot,
                    ...(color && { background: color }),
                  }}
                />
                <Skeleton
                  width="50%"
                  height={20}
                  animation="wave"
                  sx={{
                    ...pdfSkeleton_style.skeletonRoot,
                    ...(color && { background: color }),
                  }}
                />
              </Box>
            );
          })}
      </Grid>
    </Grid>
  );
}

PdfSkeleton.propTypes = {
  color: PropTypes.string,
};

export { PdfSkeleton };
