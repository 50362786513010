import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { refferalNotesCard_style } from './style';

function RefferalNotesCard(props) {
  const { className = '', rootStyle = {}, data, ...rest } = props;

  return (
    <Box
      sx={{
        ...refferalNotesCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={refferalNotesCard_style.cardParentSx}>
        {/* {data.map((val, i) => ( */}
        <Box>
          <Typography gutterBottom sx={refferalNotesCard_style.notesStyle}>
            {' '}
            • {data}
          </Typography>
        </Box>
        {/* ))} */}
      </Box>
    </Box>
  );
}

RefferalNotesCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  data: PropTypes.string,
};

export { RefferalNotesCard };
