/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-unreachable-loop */
/* eslint-disable no-restricted-syntax */
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ForwardIcon, PreviousIcon } from '../../../atoms';
import { doctorAppointmentCalender_style } from './style';

function DoctorAppointmentCalender(props) {
  const {
    className = '',
    rootStyle = {},
    value,
    setSelectedIndex,
    onDateSelect = () => {},
    todayClick = () => {},
    selectedDate = 'Jul, 2023',
    ...rest
  } = props;
  const [currentIndex, setCurrentIndex] = useState();
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(selectedDate);
  const [data, setData] = useState([]);

  const getDaysOfWeek = (date) => {
    const today = new Date(date);

    // Get the first day of the current week
    const firstDayOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - (today.getDay() - 1)
    );

    // Get the last day of the current week
    const lastDayOfWeek = new Date(
      firstDayOfWeek.getFullYear(),
      firstDayOfWeek.getMonth(),
      firstDayOfWeek.getDate() + 6
    );
    const dayOfmonth = new Date(firstDayOfWeek);
    const month = new Date(dayOfmonth).toLocaleString('default', {
      month: 'long',
    });

    const day = `${month.substring(0, 3)} ${new Date(
      dayOfmonth
    ).getFullYear()}`;

    setSelectedMonth(day);

    const daysOfYear = [];
    for (
      const d = new Date(firstDayOfWeek);
      d <= lastDayOfWeek;
      d.setDate(d.getDate() + 1)
    ) {
      daysOfYear.push(new Date(d));
    }

    // Get the days of the current week
    const days = [];
    const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    for (const object of daysOfYear) {
      let val;
      const date = object.getDate();
      if (date.toString().length === 1) {
        val = `0${date.toString()}`;
        days.push(val);
      } else {
        days.push(date.toString());
      }
    }

    const data = [];
    days.forEach((day, i) => {
      weekDays.forEach((date, index) => {
        daysOfYear.forEach((curDate, indexVal) => {
          if (i === index) {
            if (index === indexVal) {
              const object = {
                day,
                date,
                value: curDate,
              };
              data.push(object);
            }
          }
        });
      });
    });

    setData(data);

    return data;
  };


  const getCurrentIndex = (date) => {
    const daysOfWeek = getDaysOfWeek(date);

    let curDate = new Date().getDate().toString();
    let curMonth = new Date().getDate();
    if (curDate.toString().length === 1) {
      curDate = `0${curDate}`;
    }

    console.log(curMonth, 'curMonth');

    daysOfWeek.forEach((val, i) => {
      if (curDate === val?.day) {
        // if (curMonth === val?.value?.getMonth()) {
        setCurrentIndex(i);
        // }
      }
    });
  };

  const handlePreWeekClick = () => {
    setCurrentIndex(undefined);
    setSelectedIndex(undefined);
    const today = new Date(selectedDay);
    today.setDate(today.getDate() - 7);
    setSelectedDay(today);
    getCurrentIndex(today);
    // getDaysOfWeek(today);
  };
  const handleNextWeekClick = () => {
    setCurrentIndex(undefined);
    setSelectedIndex(undefined);
    getCurrentIndex();
    const today = new Date(selectedDay);
    today.setDate(today.getDate() + 7);
    setSelectedDay(today);
    getCurrentIndex(today);
  };

  useEffect(() => {
    getCurrentIndex(new Date());
  }, []);

  return (
    <Box
      sx={{
        ...doctorAppointmentCalender_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid container rowSpacing={2}>
        <Grid item xs={12} sm={12}>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item>
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  todayClick();
                  getCurrentIndex(new Date());
                }}
              >
                <Typography color="#888888" fontSize={14} fontWeight={500}>
                  Today
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography fontSize={14} fontWeight={500}>
                {selectedMonth}
                {/* {selectedDate} */}
              </Typography>
            </Grid>
            <Grid item display="flex">
              <Box
                pr={1}
                sx={{ cursor: 'pointer' }}
                onClick={() => handlePreWeekClick()}
              >
                <PreviousIcon />
              </Box>
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => handleNextWeekClick()}
              >
                <ForwardIcon />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          display="flex"
          justifyContent="space-between"
        >
          {data?.map((obj, i) => (
            <Box
              key={i}
              mx={0.8}
              onClick={() => onDateSelect(i, obj)}
              sx={
                value !== undefined
                  ? value === i
                    ? doctorAppointmentCalender_style.activeDateRootSx
                    : doctorAppointmentCalender_style.dateRootSx
                  : currentIndex === i
                  ? doctorAppointmentCalender_style.activeDateRootSx
                  : doctorAppointmentCalender_style.dateRootSx
              }
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography
                    fontSize={14}
                    color={
                      value !== undefined
                        ? value === i
                          ? '#EB6D13'
                          : '#000'
                        : currentIndex === i
                        ? '#EB6D13'
                        : '#000'
                    }
                    fontWeight={500}
                    alignSelf="end"
                  >
                    {obj?.day}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography
                    fontSize={10}
                    fontWeight={500}
                    color={
                      value !== undefined
                        ? value === i
                          ? '#EB6D13'
                          : '#000'
                        : currentIndex === i
                        ? '#EB6D13'
                        : '#000'
                    }
                  >
                    {obj?.date}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

DoctorAppointmentCalender.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  value: PropTypes.number,
  onDateSelect: PropTypes.func,
  handleMonthClick: PropTypes.func,
  todayClick: PropTypes.func,
  selectedDate: PropTypes.string,
};

export { DoctorAppointmentCalender };
