/* eslint-disable no-unused-vars */
import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import create from 'zustand';

export const useLabReport = create((set, get) => ({
  labReportData: [],
  loading: null,
  error: null,
  getLabReportData: async () => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status, data, error, isFetching } = await queryClient.fetchQuery([
        '/master/get-lab-test',
        'get',
      ]);
      if (status?.code === 200) {
        const labReportDataCopy = [];
        if (Array.isArray(data) && data?.length > 0) {
          data.map((val) => {
            labReportDataCopy.push({
              value: val?.id ?? '',
              label: val?.lab_test ?? '',
            });
          });
        }
        return set((state) => ({
          loading: false,
          labReportData: labReportDataCopy,
        }));
      }
      return set((state) => ({
        loading: false,
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
