import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useViewPort } from '@hc/store';
import { DayAvailabilityCard } from '../dayAvailabilityCard';
import { dayAvailabilityList_style } from './style';

function DayAvailabilityList(props) {
  const { className = '', rootStyle = {}, data = [], ...rest } = props;
  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  return (
    <Box
      sx={{
        ...dayAvailabilityList_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {!isMobilePort && (
        <Typography fontWeight={600} mb={2}>
          Working Hours
        </Typography>
      )}
      <Box>
        {data?.map((val, parentIndex) => (
          <Box key={parentIndex}>
            <DayAvailabilityCard parentIndex={parentIndex} data={val} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

DayAvailabilityList.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  data: PropTypes.array,
};

export { DayAvailabilityList };
