export const viewSummaryModel_style = {
  avatarSx: {
    mt: 1,
    ml:1,
    background: '#F5F5F5',
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '50%',
  },
};
