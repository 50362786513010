export const dateRangePicker_style = {
  selectedDateSx: {
    width: '30px',
    height: '30px',
    bgcolor: '#007965',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
  },

  unSelectedDateSx: {
    width: '30px',
    height: '30px',
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  isNotCurrentMonthSx: {
    color: '#C5C5C5',
  },
  isCurrentMonthSx: {
    color: '#000',
  },
  isSelectSx: {
    color: '#fff',
  },
  currentDateSx: {
    width: '30px',
    height: '30px',
    border: '2px solid #007965',
    color: '#007965',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
  },
};
