export const reset_password_style = {
  CardcontentSx: {
    backgroundColor: '#FDFFFF',
    boxShadow: '0px 0px 10px #0000001A',
    borderRadius: '12px',
    px: 4,
    py: 5,
    mt: 2.5,
    width: '435px',
  },

  createPasswordSx: {
    fontSize: '20px',
    color: 'text.primary',
    textAlign: 'left',
    fontWeight: 600,
    // pt: 1.5,
    pb: 1,
  },
  subTextSx: {
    fontSize: '14px',
    color: 'text.hint',
    width: '335px',
    fontWeight: 'normal',
  },
  hcLogoSx: {
    ml: -1.15,
  },
  passwordTextSx: {
    fontSize: '14px',
    color: 'text.hint',
    textAlign: 'center',
    fontWeight: 'normal',
    pt: 3,
    pb: 1.5,
    cursor: 'pointer',
  },
  inputGroupSx: {
    pt: 1.5,
  },
  buttonStyleSx: {
    textTransform: 'capitalize',
  },

  rootSx: {
    bgcolor: '#F5F5F5',
  },
  textFieldSX: {
    '& .MuiOutlinedInput-input': {
      width: '100%',
      fontWeight: 600,
      backgroundColor: '#fff',
    },
  },

  inputGroupSxx: { display: 'grid', gap: 0.5 },
  totalSx: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    overflow: 'hidden',
  },
  leftSideSx: {
    height: '100vh',
    display: { xs: 'none', sm: 'flex' },
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  rightImgSx: {
    height: '700px',
  },

  eyeSx: {
    width: '20px',
    height: '20px',
  },

  lastText: {
    color: 'text.secondary',
    fontSize: 14,
    opacity: 1,
    textAlign: 'center',
    mt: 9,
    display: 'flex',
    gap: 0.5,
    justifyContent: 'center',
  },

  signSx: {
    fontWeight: 600,
    fontSize: '14px',
    color: 'primary.main',
    opacity: 1,
    cursor: 'pointer',
    textDecoration: 'none',
  },

  googleStyle: {
    color: '#ACACAC',
    border: '1px solid',
    borderColor: 'grey.400',
    textTransform: 'none',
  },

  buttonsStyle: {
    textTransform: 'none',
  },

  titleSx: {
    color: 'text.primary',
    fontSize: '20px',
    fontWeight: 600,
  },

  forgotpasswordSx: {
    float: 'right',
    marginTop: '10px',
    fontWeight: 600,
    fontSize: '14px',
    cursor: 'pointer',
    color: 'primary.main',
    opacity: 1,
    textAlign: 'end',
    textDecoration: 'none',
  },

  rightSideSx: {
    px: 2.5,
    pt: 4,
    pb: 5,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '24px',
  },
  imgSx: {
    width: '100%',
    maxWidth: '150px',
  },
  buttonBox: {
    display: 'grid',
    gap: 3,
    mt: 1,
  },
  labelStyle: {
    color: '#262C33',
    lineHeight: '1.4375em',
    fontWeight: 500,
  },
  subtitleSx: {
    color: '#808080',
    pt: 1,
    pb: 0.5,
    fontWeight: 500,
  },
};
