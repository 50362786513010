import { getDateFormat } from '@hc/dayjs';
import { useSummary } from '@hc/store';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDateRangePicker from '../doctorAvailability/availabiltyDatePicker';
import { addFollowup_style } from './style';

function AddFollowup(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  const { summaryState, updateSummaryState } = useSummary((state) => ({
    summaryState: state.summaryState,
    updateSummaryState: state.updateSummaryState,
  }));

  const { followUp } = summaryState;

  const onDateChange = (value) => {
    updateSummaryState('followUp', value);
  };
  return (
    <Box
      sx={{
        ...addFollowup_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box mt={1}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: 'text.primary',
            }}
          >
            Follow-up date
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: 'primary.main',
              mb: 1,
            }}
          >
            {followUp ? getDateFormat(followUp, 'YYYY-MM-DD') : ''}
          </Typography>
        </Box>
        <Grid container columnSpacing={2}>
          <CustomDateRangePicker
            selectedDate={followUp}
            setSelectedDate={onDateChange}
          />
        </Grid>
      </Box>
    </Box>
  );
}

AddFollowup.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { AddFollowup };
