/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-const */
/* eslint-disable brace-style */
/* eslint-disable react/jsx-filename-extension */
import { doctorRoutes } from '@hc/routes';
import { calculateDoseQuantity, queryClient, routeTo } from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';
import { useRouting } from '../common/routing';

export const useRxGroup = create((set, get) => ({
  drug: {
    category: 0,
    error: {
      category: '',
    },
  },
  rxGroupState: {
    rxGroupName: '',
    rxGroupId: '',
    rxGroupStaus: null,
    isAddDrug: null,
    error: {
      rxGroupName: '',
    },
    rxGroupData: [],
    drugRxGroupDropDownData: [],
    drugRxGroupData: [],
  },
  loading: null,
  error: null,

  handleDrugFormChange: (key, value) => {
    const { drug } = get();
    set({
      drug: {
        ...drug,
        [key]: value,
      },
    });
  },

  handleDrugClear: () => {
    set({
      drug: {
        category: 0,
        error: {
          category: '',
        },
      },
    });
  },

  clearRxGroupData: () => {
    const { rxGroupState } = get();
    set({
      rxGroupState: {
        ...rxGroupState,
        rxGroupName: '',
        rxGroupId: '',
        rxGroupStaus: null,
        isAddDrug: null,
        error: {
          rxGroupName: '',
        },
        rxGroupData: [],
        drugRxGroupDropDownData: [],
        drugRxGroupData: [],
      },
    });
  },

  constructDrugData: (data) => {
    const arr = [];
    if (Array.isArray(data) && data?.length > 0) {
      data.map((val) => {
        const obj = {
          value: val,
          label: `Drugs - ${val?.drug_name}`,
          type: 'drug',
          name: val?.drug_name,
        };
        arr.push(obj);
      });
    }
    return arr;
  },
  constructRxGropData: (data, rxGroupName) => {
    const arr = [];
    if (Array.isArray(data) && data?.length > 0) {
      data.map((val) => {
        if (
          val?.rx_group?.drug_data?.length > 0 &&
          val?.rx_group?.rx_group_name !== rxGroupName
        ) {
          const obj = {
            value: val,
            label: `Rx Group - ${val?.rx_group?.rx_group_name} (${val?.rx_group?.drug_data?.length} drugs)`,
            type: 'rxGroup',
            name: val?.rx_group?.rx_group_name,
          };
          arr.push(obj);
        }
      });
    }
    return arr;
  },
  constructRxGroupDrugData: (data) => {
    const arr = [];
    if (Array.isArray(data) && data?.length > 0) {
      data.map((val) => {
        if (val?.isDeleted === false) {
          const obj = {
            drug_id: val?.drugId ?? '',
            medicine_id: val?.drugMasterId ?? 0,
            time_id: val?.time?.value ?? 0,
            when_type_id: val?.when?.value ?? 0,
            frequency_id: val?.frequency?.value ?? 0,
            duration: val?.duration ? val?.duration?.toString() : '',
            duration_mode_id: val?.durationMode?.value ?? 0,
            dose_morning: val?.morningDose ? val?.morningDose?.toString() : '',
            dose_after_noon: val?.afterNoonDose
              ? val?.afterNoonDose?.toString()
              : '',
            dose_night: val?.nightDose ? val?.nightDose?.toString() : '',
            quantity: val?.quantity ? val?.quantity?.toString() : '',
            is_deleted: val?.isDeleted ?? false,
          };
          arr.push(obj);
        }
      });
    }
    return arr;
  },

  constructRxGroupWithDrugData: (arrData) => {
    const arr = [];
    if (Array.isArray(arrData) && arrData?.length > 0) {
      arrData.map((data) => {
        const obj = {
          drugId: data?.id ?? '',
          drugName: data?.master_drug?.drug_name ?? '',
          drugMasterId: data?.master_drug?.id ?? 0,
          // eslint-disable-next-line radix
          morningDose: data?.dose_morning ? parseInt(data?.dose_morning) : 0,
          afterNoonDose: data?.dose_after_noon
            ? // eslint-disable-next-line radix
              parseInt(data?.dose_after_noon)
            : 0,
          // eslint-disable-next-line radix
          nightDose: data?.dose_night ? parseInt(data?.dose_night) : 0,
          time: {
            value: data?.master_time?.id ?? 0,
            label: data?.master_time?.time ?? '',
          },
          frequency: {
            value: data?.master_frequency?.id ?? 0,
            label: data?.master_frequency?.frequency ?? '',
          },
          when: {
            value: data?.master_when_type?.id ?? 0,
            label: data?.master_when_type?.when ?? '',
          },
          // eslint-disable-next-line radix
          duration: data?.duration ? parseInt(data?.duration) : 0,
          durationMode: {
            value: data?.master_duration_mode?.id ?? 0,
            label: data?.master_duration_mode?.duration_mode ?? '',
          },
          // eslint-disable-next-line radix
          quantity: data?.quantity ? parseInt(data?.quantity) : 0,
          isDeleted: data?.is_deleted ?? false,
        };
        arr.push(obj);
      });
    }
    return arr;
  },

  getRXGroup: async (doctor_id, searchValue) => {
    try {
      const { rxGroupState, getRxGroupDrugDataFnc } = get();
      const { rxGroupId, rxGroupName } = rxGroupState;
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery([
        searchValue?.length > 0
          ? `/doctor/rx-groups/get/all?doctor_id=${doctor_id}&search=${searchValue}`
          : `/doctor/rx-groups/get/all?doctor_id=${doctor_id}`,
        'get',
        {},
      ]);
      if (status?.code === 200) {
        set(() => ({
          loading: false,
          rxGroupState: {
            ...rxGroupState,
            rxGroupData: Array.isArray(data) && data?.length > 0 ? data : [],
          },
        }));
        if (rxGroupId?.length > 0) {
          return await getRxGroupDrugDataFnc(rxGroupId, rxGroupName);
        }
        if (data?.[0]?.rx_data?.id) {
          return await getRxGroupDrugDataFnc(
            data?.[0]?.rx_data?.id,
            data?.[0]?.rx_data?.rx_group_name,
          );
        }
      }
      return set(() => ({
        loading: false,
      }));
    } catch (error) {
      set({ loading: false });
      if (error.message === 'Request failed with status code 401') {
        // toast('Redirecting to the login page, please continue', { icon: '⚠️' });
        localStorage.clear();
        return routeTo(useRouting, doctorRoutes.signin);
      }
      return toast.error(
        error?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  getRxGroupDrugDataFnc: async (rx_group_id, name) => {
    try {
      const { rxGroupState, constructRxGroupWithDrugData } = get();
      // set({ loading: true });
      const { data, status } = await queryClient.fetchQuery([
        `/doctor/rx-groups/get?rx_group_id=${rx_group_id}`,
        'get',
        {},
      ]);
      if (status?.code === 200) {
        const constructData =
          Array.isArray(data) && data?.length > 0
            ? constructRxGroupWithDrugData(data)
            : [];
        return set(() => ({
          loading: false,
          rxGroupState: {
            ...rxGroupState,
            rxGroupId: rx_group_id,
            rxGroupName: name,
            drugRxGroupData: constructData,
          },
        }));
      }
      return set(() => ({
        loading: false,
      }));
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getDrugRxGroupList: async (doctor_id, search, search_from) => {
    try {
      const { rxGroupState, constructDrugData, constructRxGropData } = get();
      const { rxGroupName } = rxGroupState;
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery([
        `/doctor/rx-group-drugs/get?doctor_id=${doctor_id}&search_from=${search_from}&search=${search}&offset=0&limit=100`,
        'get',
        {},
      ]);

      let drugArr = [];
      let rxGroupArr = [];
      if (status?.code === 200) {
        if (Array.isArray(data?.drug_data) && data?.drug_data?.length > 0) {
          drugArr = constructDrugData(data?.drug_data ?? []);
        }
        if (Array.isArray(data?.rx_data) && data?.rx_data?.length > 0) {
          rxGroupArr = constructRxGropData(data?.rx_data, rxGroupName);
        }
        return set(() => ({
          loading: false,
          rxGroupState: {
            ...rxGroupState,
            drugRxGroupDropDownData: [...rxGroupArr, ...drugArr],
          },
        }));
      }
      return set(() => ({
        loading: false,
      }));
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  addRxGroup: async (payload, isAdd) => {
    try {
      const { rxGroupState, getRxGroupDrugDataFnc } = get();
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery([
        isAdd ? '/doctor/rx-groups/add' : '/doctor/rx-groups/update',
        'post',
        {
          ...payload,
        },
      ]);
      if (status?.code === 200) {
        toast.success(
          isAdd ? 'Rx group is created' : 'Rx group name is updated',
        );
        set({
          loading: false,
          rxGroupState: {
            ...rxGroupState,
            rxGroupId: isAdd ? data?.rx_group_id : payload?.rx_group_id,
            rxGroupName: payload?.rx_group_name ?? '',
          },
        });
        if (!isAdd) {
          await getRxGroupDrugDataFnc(
            payload?.rx_group_id,
            payload?.rx_group_name,
          );
        }
        return status?.code;
      }

      set({ loading: false });
      return status?.code;
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  updateRxGroup: async (payload) => {
    try {
      set({ loading: true });
      const { status } = await queryClient.fetchQuery([
        '/doctor/rx-groups/update',
        'post',
        {
          ...payload,
        },
      ]);
      if (status?.code === 200) {
        toast.success(
          `${payload?.rx_group_name} is ${
            payload?.is_active === true ? 'enabled' : 'disabled'
          }`,
        );
        set({ loading: false });
        return status?.code;
      }

      set({ loading: false });
      return status?.code;
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  // UPDATE RX-GROUP ERROR
  updateRxGroupError: (error) => {
    const { rxGroupState } = get();
    set({
      rxGroupState: {
        ...rxGroupState,
        error,
      },
    });
  },
  // HANDLE RX-GROUP DATA CAHNGE
  handleRxGroupDataChange: (key, value) => {
    const { rxGroupState } = get();
    return set({
      rxGroupState: {
        ...rxGroupState,
        [key]: value,
      },
    });
  },
  // HANDLE RX-GROUP EDIT DATA CAHNGE
  handleRxGroupEditData: (rxGroupName, rxGroupId, rxGroupStaus) => {
    const { rxGroupState } = get();
    return set({
      rxGroupState: {
        ...rxGroupState,
        rxGroupName,
        rxGroupId,
        rxGroupStaus,
      },
    });
  },
  // drugRxGroupData
  addNewDrugInStore: (data, drugName, drugMasterId) => {
    const { rxGroupState } = get();
    const { drugRxGroupData } = rxGroupState;
    let drugRxGroupDataCopy = JSON.parse(JSON.stringify(drugRxGroupData));

    let obj = {
      drugName: drugName ?? '',
      drugMasterId: drugMasterId ?? 0,
      morningDose: 0,
      afterNoonDose: 0,
      nightDose: 0,
      isDeleted: false,
      time: {
        value: data?.master_time?.id ?? 0,
        label: data?.master_time?.time ?? '',
      },
      frequency: {
        value: data?.master_frequency?.id ?? 0,
        label: data?.master_frequency?.frequency ?? '',
      },
      when: {
        value: data?.master_when_type?.id ?? 0,
        label: data?.master_when_type?.when ?? '',
      },
      category: {
        value: data?.master_drug_category?.id ?? 0,
        label: data?.master_drug_category?.drug_category_name ?? '',
      },
      duration: 0,
      durationMode: {
        value: 0,
        label: '',
      },
      // quantity: 0,
      quantity: data?.master_drug_category?.id !== 1 ? 1 : 0,
    };
    drugRxGroupDataCopy.push(obj);
    set({
      rxGroupState: {
        ...rxGroupState,
        drugRxGroupData: drugRxGroupDataCopy,
      },
    });
  },
  // drugRxGroupData
  addDrugInStore: (data) => {
    const { rxGroupState } = get();
    const { drugRxGroupData } = rxGroupState;
    let drugRxGroupDataCopy = JSON.parse(JSON.stringify(drugRxGroupData));
    if (data?.type === 'drug') {
      let obj = {
        drugName: data?.value?.drug_name ?? '',
        drugMasterId: data?.value?.id ?? 0,
        morningDose: data?.value?.morningDose ?? 0,
        afterNoonDose: data?.value?.afterNoonDose ?? 0,
        nightDose: data?.value?.nightDose ?? 0,
        isDeleted: false,
        time: {
          value: data?.value?.master_time?.id ?? 0,
          label: data?.value?.master_time?.time ?? '',
        },
        frequency: {
          value: data?.value?.master_frequency?.id ?? 0,
          label: data?.value?.master_frequency?.frequency ?? '',
        },
        when: {
          value: data?.value?.master_when_type?.id ?? 0,
          label: data?.value?.master_when_type?.when ?? '',
        },
        category: {
          value: data?.value?.master_drug_category?.id ?? 0,
          label: data?.value?.master_drug_category?.drug_category_name ?? '',
        },
        duration: 0,
        durationMode: {
          value: 0,
          label: '',
        },
        quantity: data?.value?.master_drug_category?.id !== 1 ? 1 : 0,
      };
      drugRxGroupDataCopy.push(obj);
      set({
        rxGroupState: {
          ...rxGroupState,
          drugRxGroupData: drugRxGroupDataCopy,
        },
      });
    } else if (data?.type === 'rxGroup') {
      if (
        Array.isArray(data?.value?.rx_group?.drug_data) &&
        data?.value?.rx_group?.drug_data?.length > 0
      ) {
        data?.value?.rx_group?.drug_data.map((val) => {
          let obj = {
            drugName: val?.master_drug?.drug_name ?? '',
            drugMasterId: val?.master_drug?.id ?? 0,
            // eslint-disable-next-line radix
            morningDose: val?.dose_morning ? parseInt(val?.dose_morning) : 0,
            afterNoonDose: val?.dose_after_noon
              ? // eslint-disable-next-line radix
                parseInt(val?.dose_after_noon)
              : 0,
            // eslint-disable-next-line radix
            nightDose: val?.dose_night ? parseInt(val?.dose_night) : 0,
            isDeleted: false,
            time: {
              value: val?.master_time?.id ?? 0,
              label: val?.master_time?.time ?? '',
            },
            frequency: {
              value: val?.master_frequency?.id ?? 0,
              label: val?.master_frequency?.frequency ?? '',
            },
            when: {
              value: val?.master_when_type?.id ?? 0,
              label: val?.master_when_type?.when ?? '',
            },
            // eslint-disable-next-line radix
            duration: val?.duration ? parseInt(val?.duration) : 0,
            durationMode: {
              value: val?.master_duration_mode?.id ?? 0,
              label: val?.master_duration_mode?.duration_mode ?? '',
            },
            // eslint-disable-next-line radix
            quantity: val?.quantity ? parseInt(val?.quantity) : 0,
          };
          drugRxGroupDataCopy.push(obj);
        });

        set({
          rxGroupState: {
            ...rxGroupState,
            drugRxGroupData: drugRxGroupDataCopy,
          },
        });
      }
    }
  },

  // drugRxGroupData
  addSummaryDrugInStore: (data) => {
    const { rxGroupState } = get();
    if (Array.isArray(data) && data?.length > 0) {
      let drugRxGroupDataCopy = [];
      // eslint-disable-next-line array-callback-return
      data.map((val) => {
        let obj = {
          drugName: val?.drugName ?? '',
          drugMasterId: val?.drugMasterId ?? 0,
          morningDose: val?.morningDose ?? 0,
          afterNoonDose: val?.afterNoonDose ?? 0,
          nightDose: val?.nightDose ?? 0,
          isDeleted: val?.isDeleted ?? false,
          time: {
            value: val?.time?.value ?? 0,
            label: val?.time?.label ?? '',
          },
          frequency: {
            value: val?.frequency?.value ?? 0,
            label: val?.frequency?.label ?? '',
          },
          when: {
            value: val?.when?.value ?? 0,
            label: val?.when?.label ?? '',
          },
          category: {
            value: val?.category?.value ?? 0,
            label: val?.category?.label ?? '',
          },
          duration: val?.duration ?? 0,
          durationMode: {
            value: val?.durationMode?.value ?? 0,
            label: val?.durationMode?.label ?? '',
          },
          quantity: val?.quantity ?? 0,
        };
        drugRxGroupDataCopy.push(obj);
      });

      set({
        rxGroupState: {
          ...rxGroupState,
          drugRxGroupData: drugRxGroupDataCopy,
        },
      });
    } else {
      set({
        rxGroupState: {
          ...rxGroupState,
          drugRxGroupData: [],
        },
      });
    }
  },
  // DELETE DRUG DATA
  deleteDrugData: (indexVal) => {
    const { rxGroupState } = get();
    const { drugRxGroupData } = rxGroupState;
    let drugRxGroupDataCopy = JSON.parse(JSON.stringify(drugRxGroupData));

    drugRxGroupDataCopy.forEach((data, index, array) => {
      if (index === indexVal) {
        array[indexVal] = {
          ...data,
          isDeleted: true,
        };
      }
    });

    set({
      rxGroupState: {
        ...rxGroupState,
        drugRxGroupData: drugRxGroupDataCopy,
      },
    });
  },
  // DOSE DATA CHANGE FUNCTION
  changeDoseDataFnc: (data) => {
    const { rxGroupState } = get();
    const { drugRxGroupData } = rxGroupState;
    let drugRxGroupDataCopy = JSON.parse(JSON.stringify(drugRxGroupData));
    if (drugRxGroupDataCopy[data?.index]?.category?.value === 1) {
      const quantityH = calculateDoseQuantity(
        drugRxGroupDataCopy[data?.index]?.duration,
        drugRxGroupDataCopy[data?.index]?.durationMode?.value,
        drugRxGroupDataCopy[data?.index]?.frequency?.value,
        data?.morningDose + data?.afterNoonDose + data?.nightDose,
      );
      drugRxGroupDataCopy[data?.index] = {
        ...drugRxGroupDataCopy[data?.index],
        quantity: quantityH,
        morningDose: data?.morningDose ?? 0,
        afterNoonDose: data?.afterNoonDose ?? 0,
        nightDose: data?.nightDose ?? 0,
      };
      set({
        rxGroupState: {
          ...rxGroupState,
          drugRxGroupData: drugRxGroupDataCopy,
        },
      });
    } else {
      drugRxGroupDataCopy[data?.index] = {
        ...drugRxGroupDataCopy[data?.index],
        morningDose: data?.morningDose ?? 0,
        afterNoonDose: data?.afterNoonDose ?? 0,
        nightDose: data?.nightDose ?? 0,
      };
      set({
        rxGroupState: {
          ...rxGroupState,
          drugRxGroupData: drugRxGroupDataCopy,
        },
      });
    }
  },

  changeTimeFrequencyWhenDataFnc: (data) => {
    const { rxGroupState } = get();
    const { drugRxGroupData } = rxGroupState;
    let drugRxGroupDataCopy = JSON.parse(JSON.stringify(drugRxGroupData));
    if (drugRxGroupDataCopy[data?.index]?.category?.value === 1) {
      const quantityH = calculateDoseQuantity(
        drugRxGroupDataCopy[data?.index]?.duration,
        drugRxGroupDataCopy[data?.index]?.durationMode?.value,
        data?.frequency?.value,
        drugRxGroupDataCopy[data?.index]?.morningDose +
          drugRxGroupDataCopy[data?.index]?.afterNoonDose +
          drugRxGroupDataCopy[data?.index]?.nightDose,
      );

      drugRxGroupDataCopy[data?.index] = {
        ...drugRxGroupDataCopy[data?.index],
        quantity: quantityH,
        time: data?.time ?? { value: 0, label: '' },
        frequency: data?.frequency ?? { value: 0, label: '' },
        when: data?.when ?? { value: 0, label: '' },
      };
      set({
        rxGroupState: {
          ...rxGroupState,
          drugRxGroupData: drugRxGroupDataCopy,
        },
      });
    } else {
      drugRxGroupDataCopy[data?.index] = {
        ...drugRxGroupDataCopy[data?.index],
        time: data?.time ?? { value: 0, label: '' },
        frequency: data?.frequency ?? { value: 0, label: '' },
        when: data?.when ?? { value: 0, label: '' },
      };
      set({
        rxGroupState: {
          ...rxGroupState,
          drugRxGroupData: drugRxGroupDataCopy,
        },
      });
    }
  },

  changeDurationQuantityDataFnc: (data) => {
    const { rxGroupState } = get();
    const { drugRxGroupData } = rxGroupState;
    let drugRxGroupDataCopy = JSON.parse(JSON.stringify(drugRxGroupData));
    drugRxGroupDataCopy[data?.index] = {
      ...drugRxGroupDataCopy[data?.index],
      duration:
        data?.duration > 0
          ? data?.duration
          : data?.customDuration > 0
          ? data?.customDuration
          : 0,
      durationMode: data?.durationMode ?? {
        value: 0,
        label: '',
      },
      quantity: data?.quantity ?? 0,
    };
    set({
      rxGroupState: {
        ...rxGroupState,
        drugRxGroupData: drugRxGroupDataCopy,
      },
    });
  },
  saveRxGroupDrug: async (doctor_id) => {
    try {
      const { rxGroupState, constructRxGroupDrugData } = get();
      const { rxGroupId, drugRxGroupData } = rxGroupState;
      set({ loading: true });
      const paylaodData = {
        doctor_id: doctor_id ?? '',
        rx_group_id: rxGroupId ?? '',
        drugs:
          Array.isArray(drugRxGroupData) && drugRxGroupData?.length > 0
            ? constructRxGroupDrugData(drugRxGroupData)
            : [],
      };
      const { status } = await queryClient.fetchQuery([
        '/doctor/rx-groups/drugs/update',
        'post',
        {
          ...paylaodData,
        },
      ]);
      if (status?.code === 200) {
        toast.success('Drug detail is updated');
        set({
          loading: false,
          rxGroupState: {
            ...rxGroupState,
            isAddDrug: null,
            drugRxGroupData: [],
          },
        });
        return status?.code;
      }

      set({ loading: false });
      return status?.code;
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
}));
