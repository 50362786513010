import { Button } from '@atoms';
import { Box, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { disconnectCalendarModal_style } from './style';

function DisconnectCalendarModal(props) {
  const {
    isModalOpen,
    handleClose,
    handleRemove,
    rootStyle = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...disconnectCalendarModal_style.rootSx,
        ...rootStyle,
      }}
      {...rest}
    >
      <Modal open={isModalOpen} onClose={handleClose}>
        <Box sx={disconnectCalendarModal_style.totalModalSx}>
          <Box sx={disconnectCalendarModal_style.totalBoxSx}>
            <Box mt={2}>
              <Typography sx={disconnectCalendarModal_style.description}>
                {' '}
                Are you sure you want to disconnect google calendar?{' '}
              </Typography>

              <Box
                mt={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'end',
                }}
              >
                <Button
                  sx={disconnectCalendarModal_style.closebuttonSx}
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  sx={disconnectCalendarModal_style.DeletebuttonSx}
                  onClick={() => handleRemove()}
                >
                  DISCONNECT
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

DisconnectCalendarModal.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isModalOpen: PropTypes.any,
  handleClose: PropTypes.any,
  handleRemove: PropTypes.any,
};

export { DisconnectCalendarModal };
