import { Button, ProgressBar } from '@atoms';
import { BackIcon } from '@atoms/icons';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { doctorScreenLayout_style } from './style';

function DoctorScreenLayout(props) {
  const {
    profilePage = false,
    disableUpdateButton = false,
    progressValue,
    className = '',
    rootStyle = {},
    title = '',
    titleChildren,
    isChatTittleRequired,
    backIconStyle = {},
    appBarStyle = {},
    backRequired,
    backRoute = '',
    stateValue,
    rightbtn,
    disableButton,
    titleRequired,
    appbarRightButtonRequired,
    appbarRightButtonName = '',
    leftChildren = '',
    rightChildren = '',
    leftSideContainer = '',
    rightSideContainer = '',
    onClick = () => {},
    customLeftBorder = {},
    leftSideContainerRootStyle = {},
    rightSideContainerRootStyle = {},
    titleStyle = {},
    rightSideContainerChildStyle = {},
    ...rest
  } = props;
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        ...doctorScreenLayout_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={doctorScreenLayout_style.doctorAppbarContainerSx}>
        <Box
          sx={{ ...doctorScreenLayout_style.doctorAppbarSx, ...appBarStyle }}
        >
          <Box sx={{ ...doctorScreenLayout_style.totalScreenSx }}>
            {backRequired && (
              <BackIcon
                fill="#E2E2E2"
                rootStyle={{
                  ...doctorScreenLayout_style.backIconSx,
                  ...backIconStyle,
                }}
                onClick={() => {
                  backRoute.length > 0
                    ? navigate(backRoute, { state: { stateValue } })
                    : navigate(-1);
                }}
              />
            )}

            {/* Title */}
            <Box sx={doctorScreenLayout_style.headerSx}>
              {titleRequired && (
                <Typography
                  sx={{ ...doctorScreenLayout_style.titleSx, ...titleStyle }}
                  variant="body1"
                >
                  {title}
                </Typography>
              )}
              {isChatTittleRequired && <Box>{titleChildren}</Box>}
            </Box>
            {/* Profile Percentage completed */}
            {profilePage && progressValue !== 100 && progressValue !== 0 && (
              <Box sx={doctorScreenLayout_style.headerSx}>
                {progressValue && (
                  <Typography
                    sx={{ ...doctorScreenLayout_style.completedSx }}
                    variant="body1"
                  >
                    {`${progressValue ?? 0}% Completed!`}
                  </Typography>
                )}
              </Box>
            )}
            <Box sx={doctorScreenLayout_style.appbarRightChildSx}>
              {appbarRightButtonRequired && (
                <Button
                  buttonStyle={{
                    ...doctorScreenLayout_style.buttonSx,
                    ...rightbtn,
                  }}
                  variant="outlined"
                  onClick={onClick}
                  disabled={profilePage ? disableUpdateButton : disableButton}
                >
                  {appbarRightButtonName}
                </Button>
              )}
            </Box>
          </Box>
        </Box>

        {profilePage && progressValue !== 100 && progressValue !== 0 && (
          <ProgressBar value={progressValue ?? 0} />
        )}
      </Box>

      <Box mt="-16px">
        <Grid container>
          <Grid
            item
            sm={leftSideContainer}
            mt={2}
            sx={{ borderRight: '1px solid #E3E3E3', ...customLeftBorder }}
          >
            <Box
              sx={{
                ...doctorScreenLayout_style.leftSideContainerSx,
                ...leftSideContainerRootStyle,
              }}
            >
              {leftChildren}
            </Box>
          </Grid>

          <Grid
            item
            sx={{
              ...doctorScreenLayout_style.rightSideContainerSx,
              ...rightSideContainerRootStyle,
            }}
            sm={rightSideContainer}
            mt={2}
          >
            <Box
              sx={{
                overflowY: 'scroll',
                // height: '100vh',
                pb: 3.5,
                ...rightSideContainerChildStyle,
              }}
            >
              {rightChildren}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

DoctorScreenLayout.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  title: PropTypes.string,
  backIconStyle: PropTypes.object,
  progressValue: PropTypes.number,
  appBarStyle: PropTypes.object,
  rightbtn: PropTypes.object,
  backRequired: PropTypes.bool,
  backRoute: PropTypes.string,
  stateValue: PropTypes.object,
  titleRequired: PropTypes.bool,
  appbarRightButtonRequired: PropTypes.bool,
  appbarRightButtonName: PropTypes.string,
  titleStyle: PropTypes.object,
  leftSideContainerRootStyle: PropTypes.object,
  rightSideContainerRootStyle: PropTypes.object,
  rightChildren: PropTypes.string,
  leftChildren: PropTypes.string,
  leftSideContainer: PropTypes.string,
  rightSideContainer: PropTypes.string,
  onClick: PropTypes.func,
  profilePage: PropTypes.bool,
  disableUpdateButton: PropTypes.bool,
  disableButton: PropTypes.bool,
  customLeftBorder: PropTypes.object,
  rightSideContainerChildStyle: PropTypes.object,
};

export { DoctorScreenLayout };
