export const ViewDrug_style = {
  boxRootSx: {
    px: 2.5,
  },
  buttonSx: {
    bgcolor: 'primaryTints.200',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  dividerSx: {
    height: '20px',
    border: '1px solid',
    borderColor: '#E2E2E2',
    backgroundColor: '#E2E2E2',
    mt: '-1px',
  },
};
