export const doctorVerification_style = {
  doctorSx: {
    color: '#0E1824',
    fontSize: '20px',
    fontWeight: 600,
    mt: 1,
  },
  descriptionSx: {
    color: '#808080',
    fontSize: '16px',
    mt: 1,
  },
  illustartionSx: {
    mt: 5,
    mb: 1,
    width: '419px',
    height: '324px',
  },
  illustartionMobileSx: {
    mt: 5,
    mb: 1,
    width: '100%',
    height: '180px',
  },
  footerSx: {
    color: '#007965',
    fontSize: '16px',
    textAlign: 'right',
    mt: 5,
    cursor: 'pointer',
  },
};
