import { AddIcon, CheckBox } from '@atoms';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDateRangePicker from '../availabiltyDatePicker';
import { AvailableTimeSelectionInput } from '../availableTimeSelectionInput';
import { addDateOverrideDialog_style } from './style';

function AddDateOverrideDialog(props) {
  const {
    className = '',
    rootStyle = {},
    onAdd = () => {},
    isAvailable,
    selectedDate,
    setSelectedDate,
    istimeValid,
    isValid,
    getToTime = () => {},
    getFromTime = () => {},
    onCheckClick = () => {},
    overRideData = [],
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...addDateOverrideDialog_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ borderBottom: '1px solid #E3E3E3' }}>
        <CustomDateRangePicker
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </Box>
      <Box p={1.8}>
        <Typography fontWeight={600}>Add your specific availability</Typography>

        <Grid container>
          <Grid item sm={12} display="flex">
            <Box display="flex" alignSelf="end" width={110} py={1.5} pr={5}>
              <Box pr={1}>
                <CheckBox
                  id="checkbox"
                  checked={isAvailable}
                  onChange={onCheckClick}
                  checkStyle={{ borderRadius: '3px' }}
                  checkSecondStyle={{ borderRadius: '3px' }}
                />
              </Box>
              <Typography>Unavailable</Typography>
            </Box>
          </Grid>
          <Grid item sm={11}>
            {!isAvailable && (
              <Box>
                {overRideData?.map((val, index, array) => (
                  <Box py={0.5} key={index}>
                    <AvailableTimeSelectionInput
                      getToTime={getToTime}
                      getFromTime={getFromTime}
                      childIndex={index}
                      istimeValid={istimeValid}
                      isValid={isValid}
                      data={val}
                      masterData={array}
                      isOverRide
                    />
                  </Box>
                ))}
              </Box>
            )}
          </Grid>
          <Grid item sm={1} alignSelf="end" pb={2}>
            {!isAvailable && (
              <Box sx={{ cursor: 'pointer' }} onClick={onAdd} id="add">
                <AddIcon
                  rootStyle={{ color: '#007965', height: '19', width: '20' }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

AddDateOverrideDialog.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  onAdd: PropTypes.func,
  istimeValid: PropTypes.bool,
  isValid: PropTypes.bool,
  isAvailable: PropTypes.bool,
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func,
  getToTime: PropTypes.func,
  getFromTime: PropTypes.func,
  onCheckClick: PropTypes.func,
  overRideData: PropTypes.array,
};

export { AddDateOverrideDialog };
