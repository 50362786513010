import { AddIcon } from '@atoms';
import { useRxGroup } from '@hc/store/doctor/rxGroup';
import { RxGroupEmptyState } from '@hc/ui/components';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { DrugsListItem } from '../drugsListItem';
import { drugsCard_style } from './style';

function DrugsCard(props) {
  const {
    className = '',   
    onAdd = () => {},
    rootStyle = {},
    ...rest
  } = props;

  const { rxGroupState } = useRxGroup((state) => ({
    rxGroupState: state.rxGroupState,
    loading: state.loading,
  }));
  const { drugRxGroupData } = rxGroupState;

  return Array.isArray(drugRxGroupData) && drugRxGroupData?.length > 0 ? (
    <Box
      sx={{
        ...drugsCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box px={2}>
        {drugRxGroupData.map((val, i) => (
          <Box key={i}>
            <DrugsListItem
              medicineName={val?.drugName ?? ''}
              dose={`${val?.morningDose} - ${val?.afterNoonDose} - ${val?.nightDose}`}
              timeToTakeDose={`${val?.time?.label} - ${val?.when?.label}`}
              course={`${val?.frequency?.label} for ${val?.duration} ${val?.durationMode?.label}`}
              quantity={val?.quantity}
            />
          </Box>
        ))}
      </Box>
      <Box
        id="addDrug"
        onClick={() => onAdd('update')}
        sx={drugsCard_style.addDrugSx}
      >
        <AddIcon rootStyle={{ color: '#007965' }} />
        <Typography ml={1.2} fontWeight={500}>
          Add Drugs
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box px={2}>
      <RxGroupEmptyState
        content="Add drug to proceed"
        buttonName="Add Drug"
        onAdd={() => onAdd('add')}
      />
    </Box>
  );
}

DrugsCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object, 
  onAdd: PropTypes.func,
};

export { DrugsCard };
