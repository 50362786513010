import { useRxGroup } from '@hc/store/doctor/rxGroup';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { DrugsCard } from '../drugscard';
import { drugsMainContent_style } from './style';

function DrugsMainContent(props) {
  const { className = '', rootStyle = {}, onAdd = () => {}, ...rest } = props;

  // eslint-disable-next-line no-unused-vars
  const { rxGroupState, loading } = useRxGroup((state) => ({
    rxGroupState: state.rxGroupState,
    loading: state.loading,
  }));
  const { rxGroupName, drugRxGroupData } = rxGroupState;

  return (
    <Box
      sx={{
        ...drugsMainContent_style.rootSx,
        ...rootStyle,
      }}
      // px={2}
      className={`${className}`}
      {...rest}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item>
              <Typography fontSize={20} fontWeight={600}>
                {rxGroupName ?? ''}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {drugRxGroupData?.length > 0 && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container display="flex" justifyContent="space-between">
              <Grid item>
                <Typography fontSize={14} color="#808080">
                  ADDED DRUGS
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Box mt={1}>
        <DrugsCard onAdd={onAdd} />
      </Box>
    </Box>
  );
}

DrugsMainContent.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  onAdd: PropTypes.func,
};

export { DrugsMainContent };
