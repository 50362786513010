import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { Button, DialogModal, SearchIcon, SearchSelect } from '@atoms';
import { QusAnsSelectionLisiting } from '../../../qusAnsSelectionListing';
import { drugsImportDialog_style } from './style';

function DrugsImportDialog(props) {
  const {
    className = '',
    isModalOpen,
    handleClose = () => {},
    rootStyle = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...drugsImportDialog_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <DialogModal
        title="IMPORT RX GROUP"
        titleStyle={drugsImportDialog_style.titleSx}
        maxModalWidth="xl"
        topDivider
        bottomDivider
        dialogRootStyle={drugsImportDialog_style.dialogSx}
        content={
          <QusAnsSelectionLisiting
            QusAnsSelectionHeading="RX Group"
            QusAnsListingHeading="Medications"
            QusAnsListingAdded="Added"
            // OnClearAll={handleClear}
            // displaySelectionValues={drugs}
            // OnDelete={handleDelete}
            // sugesstionClick={handleAdd}
            qsOptionViewKey="label"
            QusAnsSelectionListing={
              <SearchSelect
                startAdornment={<SearchIcon />}
                placeholder="Select"
                // onChange={handleAdd}
                // onChangeText={onChangeText}
                optionViewKey="label"
                value={null}
                // options={medicationData}
              />
            }
          />
        }
        actions={
          <Grid p={1} container display="flex" justifyContent="end">
            <Grid item md={1.5} lg={1.5} sm={1.5} xl={1.5}>
              <Button>save</Button>
            </Grid>
          </Grid>
        }
        isDialogOpened={isModalOpen}
        handleCloseDialog={handleClose}
      />
    </Box>
  );
}

DrugsImportDialog.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export { DrugsImportDialog };
