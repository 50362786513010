import { Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { DateRangePicker } from '../../../atoms';
import { dateChanger_style } from './style';

function DateChanger(props) {
  const {
    startDate = '',
    leftIcon = true,
    iconStyle = {},
    readOnly = false,
    rightIcon = true,
    onDayChange,
    className = '',
    rootStyle,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...dateChanger_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {leftIcon && (
        <IconButton disableRipple>
          <BiChevronLeft
            id="left"
            onClick={() => {
              onDayChange('minus', 1, startDate);
            }}
            style={{ ...dateChanger_style.iconSx, ...iconStyle }}
          />
        </IconButton>
      )}
      {rightIcon && (
        <IconButton
          id="right"
          disableRipple
          disabled={
            new Date().toDateString() === new Date(startDate).toDateString()
          }
          sx={{ p: 0 }}
          onClick={() => {
            onDayChange('add', 1, startDate);
          }}
        >
          <BiChevronRight
            style={{ ...dateChanger_style.iconSx, ...iconStyle }}
          />
        </IconButton>
      )}
      <Box sx={dateChanger_style.dateRangeSx}>
        <DateRangePicker
          readOnly={readOnly}
          startDate={new Date(startDate)}
          unStyled="true"
        />
      </Box>
    </Box>
  );
}

DateChanger.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  startDate: PropTypes.any,
  leftIcon: PropTypes.any,
  iconStyle: PropTypes.any,
  readOnly: PropTypes.any,
  rightIcon: PropTypes.any,
  onDayChange: PropTypes.any,
};

export { DateChanger };
