import { Button } from '@atoms';
import { useViewPort } from '@hc/store';
import { Box, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { cancelAppointmentDialog_style } from './style';

function CancelAppointmentDialog(props) {
  const {
    className = '',
    isModalOpen,
    handleClose,
    handleRemove,
    rootStyle = {},
    ...rest
  } = props;
  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  return (
    <Box
      sx={{
        ...cancelAppointmentDialog_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Modal open={isModalOpen} onClose={handleClose}>
        <Box sx={cancelAppointmentDialog_style.totalModalSx}>
          <Box
            minWidth={isMobilePort ? '' : '426px'}
            sx={cancelAppointmentDialog_style.totalBoxSx}
          >
            <Box mt={2}>
              <Typography sx={cancelAppointmentDialog_style.description}>
                {' '}
                Do you want to cancel the appointment?{' '}
              </Typography>

              <Box
                mt={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'end',
                }}
              >
                <Button
                  sx={cancelAppointmentDialog_style.closebuttonSx}
                  onClick={handleClose}
                >
                  No
                </Button>
                <Button
                  sx={cancelAppointmentDialog_style.DeletebuttonSx}
                  onClick={handleRemove}
                >
                  Yes, Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

CancelAppointmentDialog.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isModalOpen: PropTypes.any,
  handleClose: PropTypes.any,
  handleRemove: PropTypes.any,
};

export { CancelAppointmentDialog };
