export const googleConnect_style = {
  rootSx: {},
  inputGroupSx: { display: 'grid', gap: 0.5 },
  titleSx: {
    color: ' #808080',
    lineHeight: '1.7',
    fontSize: '14px',
    fontWeight: 400,
  },

  connectSx: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    borderRadius: '8px',
    pr: 6,
  },
  googleconnectTextSx: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#0E1824',
    flexGrow: 1,
  },
  GoogleConnectSx: {
    cursor: 'pointer',
    border: '1.5px solid #DBEAE8',
    borderRadius: '8px',
    p: 1.6,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 2,
    // '&:hover': { backgroundColor: '#E8FCF0' },
  },
  GoogleConnectActiveSx: {
    cursor: 'pointer',
    border: '1.5px solid #25C460',
    alignItems: 'center',
    backgroundColor: '#E8FCF0',
    borderRadius: '8px',
    p: 1.6,
    display: 'flex',
    width: '100%',
    gap: 2,
  },
};
