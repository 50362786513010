export const profileMobilePage_style = {
  rootSx: {
    bgcolor: '#FDFFFF',
    height: '100vh',
  },
  cardRootSx: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 2,
    border: '1px solid #EEEEEE',
    borderRadius: '8px',
    mb: 1.5,
    cursor: 'pointer',
  },
  buttonSx: {
    bgcolor: '#DFF7F3',
    color: 'primary.main',
    '&:hover': {
      bgcolor: '#DFF7F3',
    },
  },
  logoutConfirmSx: {
    textAlign: 'Center',
    py: 4.5,
  },
  callButtonSx: {
    borderRadius: 2,
    bgcolor: 'error.main',
    '&:hover': {
      bgcolor: 'error.main',
    },
  },
};
