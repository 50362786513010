/* eslint-disable no-restricted-syntax */
import { doctorRoutes } from '@hc/routes';
import { useViewPort } from '@hc/store';
import { useDoctorChatConsultation } from '@hc/store/doctor/chatConsultation';
import { queryClient } from '@hc/utils';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { NoRefferals } from '../../../atoms';
import { ClinicalEmptyState } from '../../clinical';
import { ChatListCard } from '../chatListCard';
import { chatViewCard_style } from './style';

function ChatViewCard(props) {
  const { rootStyle = {}, IntersectionRef } = props;

  const navigate = useNavigate();

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const {
    doctorChatConsultationState,
    getChatHistoryDataById,
    getChatCounsultationById,
    updateDoctorChatConsultationState,
    loading,
  } = useDoctorChatConsultation((state) => ({
    getChatCounsultationById: state.getChatCounsultationById,
    updateDoctorChatConsultationState: state.updateDoctorChatConsultationState,
    getChatHistoryDataById: state.getChatHistoryDataById,
    doctorChatConsultationState: state.doctorChatConsultationState,
    loading: state.loading,
  }));

  const { doctorChatListingData, doctorChatData, chatCounsultationData } =
    doctorChatConsultationState;

  const chatCout = () => {
    const resArr = [];
    for (const obj of doctorChatData) {
      for (const object of obj?.messageData) {
        if (object?.is_seen === false) {
          resArr?.push(object);
        }
      }
    }
    return resArr?.length;
  };

  const onChatClick = async (object) => {
    updateDoctorChatConsultationState(
      'chatStatus',
      object?.consultation_status_name,
    );
    queryClient?.invalidateQueries({
      queryKey: ['chat_consultation_history_by_id'],
    });
    const historyRess = await getChatHistoryDataById(
      object?.chat_consltation_id,
      object?.consultation_status_name,
    );
    const counsultationResponse = await getChatCounsultationById(
      object?.chat_consltation_id,
    );
    if (
      historyRess?.status?.code === 200 &&
      counsultationResponse?.status?.code === 200
    ) {
      if (
        historyRess?.data[0]?.messageData?.length > 0 ||
        object?.consultation_status_name === 'OnGoing'
      ) {
        navigate(doctorRoutes?.doctorChatPage);
      } else {
        toast.error('There is no chat in this user');
      }
    }
  };

  return (
    <Box
      sx={{
        ...chatViewCard_style.rootSx,
        ...rootStyle,
      }}
    >
      <Box mt={isMobilePort ? 18 : 0}>
        {doctorChatListingData?.length > 0 ? (
          doctorChatListingData?.map((object, i) => (
            <Box key={i}>
              <ChatListCard
                PatientName={object?.first_name ?? ''}
                subText={object?.gender ?? ''}
                age={object?.age ?? ''}
                mobile={object?.mobile_no ?? ''}
                countyCode={object?.mobile_no_country_code ?? ''}
                userPic={object?.profile_pic ?? ''}
                onChatClick={() => onChatClick(object)}
                data={object}
              />
            </Box>
          ))
        ) : (
          <Box sx={loading ? { display: 'none' } : ''}>
            <ClinicalEmptyState
              IconRequired={<NoRefferals />}
              emptyStateStyle={{ mt: 8, height: '50vh' }}
              subHead="No Chat Found"
              subHeadStyle={chatViewCard_style.subHeadSx}
            />
          </Box>
        )}
        <div ref={IntersectionRef} style={{ marginTop: '-20px', opacity: 0 }}>
          intersection
        </div>
      </Box>
      {/* <Box sx={{ display: 'flex', justifyContent: 'end', p: 2.5 }}>
        <Box
          sx={{
            position: 'fixed',
            bottom: 110,
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Box
            id="add"
            sx={{ ...chatViewCard_style.speedDialRootSx }}
            // onClick={() => onAddExistingClick()}
          >
            <Box alignSelf="center">
              <Badge
                badgeContent={
                  chatCounsultationData?.consultation_status_name?.length > 0
                    ? chatCounsultationData?.consultation_status_name ===
                      'OnGoing'
                      ? chatCout()
                      : 0
                    : 0
                }
                color="secondary"
              >
                <WhatsAppChatIcon
                  color="#fff"
                  style={{
                    marginTop: '5px',
                    // height: '35px',
                    // width: '35px',
                  }}
                />
              </Badge>
            </Box>
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
}

ChatViewCard.propTypes = {
  rootStyle: PropTypes.object,
};

export { ChatViewCard };

