export const prescriptionDoseModal_style = {
  titleSx: {
    fontSize: 16,
    fontWeight: 600,
  },
  dialogSx: {
    width: 450,
  },
  buttonSx: {
    mr: 0.5,
    bgcolor: 'white',
    color: 'primary.main',
    borderRadius: '8px',
    '&:hover': {
      color: 'white',
    },
    border: '1px solid #007965',
    width: '100px',
  },

  toggleButtonActiveSx: {
    border: '1px solid',
    minWidth: '36px',
    minHeight: '40px',
    borderRadius: '10px',
  },
  toggleButtonInActiveSx: {
    border: '1px solid ',
    borderColor: '#E3E3E3',
    borderRadius: '10px',
    minWidth: '36px',
    minHeight: '40px',
  },

  drawerbuttonSx: {
    border: '2px solid #007965',
    '&:hover': {
      border: '2px solid #007965',
    },
  },
  cancelBtnSx: {
    border: '2px solid #007965',
    textTransform: 'none',
    backgroundColor: '#fff',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: '#Fff',
    },
  },
  applyBtnSx: {
    textTransform: 'none',
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2.5,
  },
};
