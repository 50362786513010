/* eslint-disable prefer-destructuring */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
import { doctorRoutes } from '@hc/routes';
import { useViewPort } from '@hc/store';
import { useDoctorChatConsultation } from '@hc/store/doctor/chatConsultation';
import {
  AwrImgIcon,
  Button,
  DialogModal,
  DocumentIcon,
  Drawer,
  DropDown,
  RegisterIcon,
  UploadCloseIcon,
} from '@hc/ui/atoms';
import {
  ChartListingInputBox,
  ChatListingBox,
  ConfigurationModal,
  DoctorScreenLayout,
  MedicalCard,
  PatientReportUploadCard,
  Screenlayout,
} from '@hc/ui/components';
import {
  ChatConstructData,
  formatBytes,
  handleUploadFile,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import { useSocket } from '@hc/utils/socket';
import { Box, Grid, Modal, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { doctor_chat_style } from './style';

export default function DoctorChatPage() {
  const socket = useSocket();
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  const [isEndChatModalOpen, setEndChatModalOpen] = useState(false);

  const [isModalOpen, setisModalOpen] = useState(false);
  const [isTimeOutModalOpen, setTimeOutModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isAttachFileOpen, setIsAttachFileOpen] = useState(false);
  const [isSelected, setIsSelected] = useState('');
  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));
  const [timer, setTimer] = useState();

  const uploadUtills = [
    {
      value: 'image',
      name: 'Image',
      icon: (
        <AwrImgIcon
          fill="#666"
          rootStyle={{ height: '50px', width: '50px', color: '#000' }}
        />
      ),
      accept: 'image/*',
    },
    {
      value: 'pdf',
      name: 'PDF',
      icon: (
        <DocumentIcon
          rootStyle={{ height: '50px', width: '50px', color: '#666' }}
        />
      ),
      accept: 'application/pdf',
    },
  ];

  const {
    doctorChatConsultationState,
    getChatCounsultationById,
    getChatHistoryDataById,
    updateDoctorChatConsultationState,
  } = useDoctorChatConsultation(
    (state) => ({
      getChatHistoryDataById: state.getChatHistoryDataById,
      getChatCounsultationById: state.getChatCounsultationById,
      doctorChatConsultationState: state.doctorChatConsultationState,
      updateDoctorChatConsultationState:
        state.updateDoctorChatConsultationState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const {
    chatStatusData,
    onlineTyping,
    typingMessage,
    doctorChatData,
    chatCounsultationData,
    messageType,
    messageJSON,
    isChatExtendRequest,
    isScroll,
  } = doctorChatConsultationState;

  const {
    doctorProfileId = '',
    doctorUserId = '',
    doctorProfilePicture = '',
    doctorName = '',
    doctorMobileNo = '',
    doctorChatConsultationAmount = 0,
    chatRequestedDate = '',
    chatConsultationDuration = '',
    patientUserId = '',
    patientProfileId = '',
    patientProfilePicture = '',
    patientName = '',
    patientMobileNo = '',
    // type = '',
    chatConsutationMessageId = '',
    chatConsultationRequestsId = '',
  } = chatStatusData;

  const commonSocketData = {
    doctorProfileId,
    doctorUserId,
    doctorProfilePicture,
    doctorName,
    doctorMobileNo,
    doctorChatConsultationAmount,
    chatRequestedDate,
    chatConsultationDuration,
    patientUserId,
    patientProfileId,
    patientProfilePicture,
    patientName,
    patientMobileNo,
    chatConsultationRequestsId,
    type: 'typingByDoctor',
    chatConsultationId: localStorage.getItem('chatConsultationId'),
  };

  const handleCloseDialog = () => setisModalOpen(!isModalOpen);
  const navigate = useNavigate();
  const md = useMediaQuery('(max-width:864px)');
  const handleEndChat = () => setEndChatModalOpen(true);
  const handleEndTask = () => setEndChatModalOpen(true);
  const handleChatClose = () => setEndChatModalOpen(false);

  const onMessageTypeFnc = async (val) => {
    updateDoctorChatConsultationState('typingMessage', val);
    updateDoctorChatConsultationState('messageType', 1);
    // call socket for chat_live_typing_status
    await socket.emit('chat_live_typing_status', {
      ...commonSocketData,
      chatStatus: 'typing...',
    });
  };

  const handleTimeOutModal = () => {
    setTimeOutModalOpen(false);
  };

  const handleTimeOutTask = () => {
    setTimeOutModalOpen(true);
  };

  // Attach PDF or IMG file scenario
  const onAttachFileFnc = () => {
    setIsAttachFileOpen(true);
  };

  const onCloseAttachFileDrawer = () => {
    setIsAttachFileOpen(false);
    setIsSelected('');
  };

  const onSendMessageFnc = async () => {
    if (isAttachFileOpen) {
      updateDoctorChatConsultationState('messageJSON', []);
      updateDoctorChatConsultationState('messageType', 1);
      onCloseAttachFileDrawer();
    }
    updateDoctorChatConsultationState('isScroll', true);
    // Send message scenario
    const eventData = {
      chatMessage: {
        type: messageType ?? 1,
        message: typingMessage,
        message_json: messageJSON ?? null,
        created_at: new Date(),
        is_seen: false,
        chatData: doctorChatData,
      },
      messageSender: 'doctor',
    };

    ChatConstructData(
      eventData,
      tokenData,
      doctorChatData,
      'doctorChatData',
      updateDoctorChatConsultationState,
      true,
    );

    // call socket for chat_consultation_message
    await socket.emit('chat_consultation_message', {
      ...commonSocketData,
      messageSender: 'doctor',
      chatMessage: eventData?.chatMessage,
      chatConsutationMessageId,
    });

    updateDoctorChatConsultationState('typingMessage', '');
    updateDoctorChatConsultationState('isScroll', false);
    // call socket for chat_live_typing_status
    await socket.emit('chat_live_typing_status', {
      ...commonSocketData,
      chatStatus: 'online',
    });
  };

  const handleTimeoutCloseChat = async () => {
    // call socket for chat_live_typing_status
    await socket.emit('chat_consultation_completion', {
      ...commonSocketData,
      chatEndedBy: patientProfileId,
      endedByWhom: 'Time Out',
    });

    setTimeOutModalOpen(false);
    // Online or Typing store update
    updateDoctorChatConsultationState('onlineTyping', '');

    // Socket call for availability status change
    await socket.emit('doctor_availability_status_change', {
      doctorProfileId,
      type: 'online',
    });
    navigate(doctorRoutes?.home);
  };

  const handleCloseChat = async () => {
    await socket.emit('chat_consultation_completion', {
      ...commonSocketData,
      chatEndedBy: doctorProfileId,
      endedByWhom: 'doctor',
    });
    localStorage.removeItem('chatTimerMins');
    localStorage.removeItem('chatTimerMins');
    localStorage.removeItem('isChatTimer');
    setEndChatModalOpen(false);
    // Online or Typing store update
    updateDoctorChatConsultationState('onlineTyping', '');
    // Socket call for availability status change
    await socket.emit('doctor_availability_status_change', {
      doctorProfileId,
      type: 'online',
    });
    navigate(doctorRoutes?.home);
  };

  const onSelectUploadType = (i) => {
    if (isSelected === '') {
      setIsSelected(i);
    }
  };

  const handleFileUpload = async (e, value) => {
    let fileList = e?.target?.files;

    let url = '';
    let arrayData = [];
    if (value === 'image') {
      updateDoctorChatConsultationState('messageType', 2);
      // setIsLoading(true);
      for (const file of fileList) {
        const fileFormData = file;
        const getData = await handleUploadFile([file]);
        const data = getData.map((val) => val.downloadUrl);
        url = data[0];
        const size = formatBytes(file?.size ?? 0, 0);
        const filename = file?.name;
        const type = file?.type;
        const sizeNumber = file?.size ?? 0;
        arrayData.push({
          fileFormData: { 0: fileFormData, length: 1 },
          downloadUrl: [url],
          size,
          filename,
          type,
          sizeNumber,
        });
      }
      updateDoctorChatConsultationState('messageJSON', arrayData);
    } else if (value === 'pdf') {
      let reArr = [];
      updateDoctorChatConsultationState('messageType', 3);
      for (const file of fileList) {
        const getData = await handleUploadFile([file]);
        const data = getData.map((val) => val.downloadUrl);
        url = data[0];
        const fileFormData = file;
        const size = formatBytes(file?.size ?? 0, 0);
        const filename = file?.name;
        const type = file?.type;
        const sizeNumber = file?.size ?? 0;
        reArr.push({
          fileFormData: { 0: fileFormData, length: 1 },
          downloadUrl: [url],
          size,
          filename,
          type,
          sizeNumber,
        });
      }
      updateDoctorChatConsultationState('messageJSON', reArr);
    }
  };

  const handleDelete = (value) => {
    let reArr = [];
    for (const obj of doctorChatConsultationState?.messageJSON) {
      if (obj?.filename !== value) {
        reArr?.push(obj);
      }
    }
    updateDoctorChatConsultationState('messageJSON', reArr);
  };

  const handleModelClose = () => setOpen(false);

  const onBack = () => {
    updateDoctorChatConsultationState('messageJSON', []);
    updateDoctorChatConsultationState('messageType', 1);
    onCloseAttachFileDrawer();
  };

  const handleModelOpen = async () => {
    setOpen(true);
  };

  const onDelineRequest = async () => {
    // Send message scenario
    const eventData = {
      chatMessage: {
        type: messageType ?? 1,
        message: 'Doctor reject the request',
        message_json: messageJSON ?? null,
        created_at: new Date(),
        is_seen: false,
        chatData: doctorChatData,
      },
      messageSender: 'doctor',
    };

    ChatConstructData(
      eventData,
      tokenData,
      doctorChatData,
      'doctorChatData',
      updateDoctorChatConsultationState,
      true,
    );

    // call socket for chat_consultation_message
    await socket.emit('chat_consultation_message', {
      ...commonSocketData,
      messageSender: 'doctor',
      chatMessage: eventData?.chatMessage,
      chatConsutationMessageId,
      chatConsultationRequestPaymentId: '',
      responsePaymentData: {},
    });
    updateDoctorChatConsultationState('isChatExtendRequest', false);
  };

  const onAcceptRequest = async () => {
    const eventData = {
      chatMessage: {
        type: messageType ?? 1,
        message: 'Doctor accept the request',
        message_json: messageJSON ?? null,
        created_at: new Date(),
        is_seen: false,
        chatData: doctorChatData,
      },
      messageSender: 'doctor',
    };

    ChatConstructData(
      eventData,
      tokenData,
      doctorChatData,
      'doctorChatData',
      updateDoctorChatConsultationState,
      true,
    );

    // call socket for chat_consultation_message
    await socket.emit('chat_consultation_message', {
      ...commonSocketData,
      messageSender: 'doctor',
      chatMessage: eventData?.chatMessage,
      chatConsutationMessageId,
      chatConsultationRequestPaymentId: '',
      responsePaymentData: {},
    });
    updateDoctorChatConsultationState('isChatExtendRequest', false);
  };

  let myIntervalll;

  const stopInterval = () => {
    clearInterval(myIntervalll);
  };

  const timerFuntion = (futureTime, data) => {
    // debugger;
    // if (myIntervalll) {
    //   clearInterval(myIntervalll);
    // }
    clearInterval(myIntervalll);
    myIntervalll = setInterval(() => {
      const countDownDate = new Date(futureTime).getTime();
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (minutes >= 0 && seconds >= 0) {
        setTimer(`${minutes}mins ${seconds}secs`);
      }

      if (
        minutes <= 0 &&
        seconds <= 0 &&
        data?.consultation_status_name === 'OnGoing' &&
        data?.chat_starts_at?.length > 0
      ) {
        updateDoctorChatConsultationState('chatTimer', false);
        clearInterval(myIntervalll);
        localStorage.setItem('isChatTimer', false);
        handleTimeOutTask();
      }
    }, 1000);
    return () => {
      clearInterval(myIntervalll);
    };
  };

  const getChatDetails = async (consultation_id) => {
    queryClient?.invalidateQueries({
      queryKey: ['chat_consultation_history_by_id'],
    });

    queryClient.invalidateQueries({
      queryKey: ['chat_consultation_data_by_id'],
    });

    const response = await getChatCounsultationById(consultation_id);
    if (response?.data?.consultation_status_name === 'OnGoing') {
      await getChatHistoryDataById(
        consultation_id,
        response?.data?.consultation_status_name,
      );
    }

    updateDoctorChatConsultationState('chatCounsultationData', response?.data);
    const paymentCount = response
      ? response?.data?.chat_payment_count
      : chatCounsultationData?.chat_payment_count;

    const chatTime = paymentCount * 15;
    let futureTime = new Date(
      new Date(
        response
          ? response?.data?.chat_starts_at
          : chatCounsultationData?.chat_starts_at,
      ).getTime() +
        chatTime * 60 * 1000,
    );
    stopInterval();
    // debugger
    // myIntervalll = undefined;
    setTimer('');
    timerFuntion(futureTime, response?.data);
  };

  useEffect(() => {
    const chatConsultationId = localStorage.getItem('chatConsultationId');
    getChatDetails(chatConsultationId);
    const jsonData = localStorage.getItem('doctorChatStatusData');
    const chatStatusData = JSON.parse(jsonData);
    updateDoctorChatConsultationState('chatStatusData', chatStatusData);
  }, []);

  useEffect(() => {
    if (socket) {
      const chatConsultationId = localStorage.getItem('chatConsultationId');
      // Chat start by doctor
      socket.on('chat_consultation_message', (eventData) => {
        if (tokenData?.user_profile_id === eventData?.doctorProfileId) {
          // const data = {
          //   ...commonSocketData(eventData),
          //   chatConsultationId: eventData?.chatConsultationId,
          // };

          if (Object.keys(eventData?.responsePaymentData).length > 0) {
            getChatDetails(chatConsultationId);
            window.location.reload();
          }
        }
      });
      socket.on('send_medical_card_to_doctor', (eventData) => {
        // debugger;
        if (tokenData?.user_profile_id === eventData?.doctorProfileId) {
          // const data = {
          //   ...commonSocketData(eventData),
          //   messageSender: eventData?.messageSender,
          //   chatMessage: eventData?.chatMessage,
          //   chatConsutationMessageId: eventData?.chatConsutationMessageId,
          //   medicalCard: eventData?.medicalCard,
          // };
          queryClient.invalidateQueries({
            queryKey: ['chat_consultation_data_by_id'],
          });
          getChatDetails(chatConsultationId);
        }
      });
    }
    // eslint-disable-next-line
  }, [socket]);
  console.log(chatCounsultationData?.medical_card_data);
  return (
    <>
      {!isMobilePort && (
        <DoctorScreenLayout
          // titleRequired
          isChatTittleRequired
          // title="Chat"
          titleChildren={
            <Box display="-webkit-box">
              <Grid container>
                <Grid item xs={12}>
                  <Typography color="#000" fontWeight={600}>
                    {chatCounsultationData?.patient_first_name}
                  </Typography>
                </Grid>
                {chatCounsultationData?.consultation_status_name ===
                  'OnGoing' && (
                  <Grid item xs={12}>
                    <Box display="flex">
                      <Typography fontSize={12} mr={1} color="#000">
                        {timer}
                      </Typography>
                      <Typography fontSize={12} color="#000">
                        {onlineTyping ?? ''}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          }
          backRequired
          appbarRightButtonRequired={
            chatCounsultationData?.consultation_status_name === 'OnGoing'
          }
          rightbtn={doctor_chat_style.buttonSx}
          appbarRightButtonName="End Chat"
          onClick={handleEndChat}
          titleStyle={doctor_chat_style.titleSx}
          backIconStyle={doctor_chat_style.backIconSx}
          rightSideContainer={md ? 12 : 8}
          leftSideContainer={md ? 12 : 4}
          leftSideContainerRootStyle={{ p: 0, m: 0 }}
          leftChildren={
            <Box>
              <MedicalCard
                isShow
                patientName={chatCounsultationData?.patient_first_name ?? ''}
                subText={`${chatCounsultationData?.patient_gender ?? ''},${
                  chatCounsultationData?.patient_age ?? ''
                }Yrs`}
                age={chatCounsultationData?.patient_age ?? ''}
                mobile={`${chatCounsultationData?.patient_mobile_no_country_code} ${chatCounsultationData?.patient_mobile_no}`}
                userPic={chatCounsultationData?.patient_profile_pic ?? ''}
                height={
                  chatCounsultationData?.medical_card_data?.vital_json?.height
                }
                weight={
                  chatCounsultationData?.medical_card_data?.vital_json?.weight
                }
                temp={
                  chatCounsultationData?.medical_card_data?.vital_json
                    ?.temp_in_f
                }
                Pulse={
                  chatCounsultationData?.medical_card_data?.vital_json?.puls_bpm
                }
                bp={
                  chatCounsultationData?.medical_card_data?.vital_json
                    ?.blood_pressure
                }
                o2={
                  chatCounsultationData?.medical_card_data?.vital_json?.o2_level
                }
                allergies={
                  chatCounsultationData?.medical_card_data
                    ?.medical_condition_json?.medical_allergies
                }
                medications={
                  chatCounsultationData?.medical_card_data
                    ?.medical_condition_json?.medical_active_medications
                }
                health_problems={
                  chatCounsultationData?.medical_card_data
                    ?.medical_condition_json?.medical_health_problems
                }
                isMedicalCardSent={
                  chatCounsultationData?.medical_card_count > 0
                }
                data={chatCounsultationData}
                isVitalShow={
                  chatCounsultationData?.medical_card_data?.is_vital_sent
                }
                isMedicalConditionShow={
                  chatCounsultationData?.medical_card_data
                    ?.is_medical_condition_sent
                }
              />
            </Box>
          }
          rightChildren={
            <Box pl={1.5}>
              {' '}
              <Box sx={{ px: 2.5 }}>
                <ChartListingInputBox
                  isScroll={isScroll}
                  // isMobilePort={isMobilePort}
                  chatStatus={chatCounsultationData?.consultation_status_name}
                  onMessageType={onMessageTypeFnc}
                  message={typingMessage}
                  isChatExtendRequest={isChatExtendRequest}
                  onAcceptRequest={onAcceptRequest}
                  onDelineRequest={onDelineRequest}
                  onSendMessage={onSendMessageFnc}
                  onAttachFile={onAttachFileFnc}
                  isDoctor
                >
                  <ChatListingBox data={doctorChatData} isDoctor />
                </ChartListingInputBox>
              </Box>
              <ConfigurationModal
                isModalOpen={isEndChatModalOpen}
                handleClose
                handleDelete={handleCloseChat}
                title="End Chat?"
                message="Are you sure you want to end the chat session with "
                cancleLable="Cancel"
                acceptLable="End Now"
                name={patientName}
              />
              <ConfigurationModal
                isModalOpen={isTimeOutModalOpen}
                handleClose={handleTimeOutModal}
                handleDelete={handleTimeoutCloseChat}
                title="Chat Time Ended"
                message="Thanks doctor, for your precious time with patient!"
                cancleLable="Cancel"
                acceptLable="Ok"
                name={patientName}
              />
            </Box>
          }
        />
      )}
      {isMobilePort && (
        <Box>
          <Screenlayout
            isChatTittleRequired
            isChatEndButton={
              chatCounsultationData?.consultation_status_name === 'OnGoing'
            }
            titleRequired={
              chatCounsultationData?.consultation_status_name === 'OnGoing' &&
              false
            }
            title={chatCounsultationData?.patient_first_name ?? ''}
            titleChildren={
              <Box display="-webkit-box">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography color="#fff" fontWeight={600}>
                      {chatCounsultationData?.patient_first_name}
                    </Typography>
                  </Grid>
                  {chatCounsultationData?.consultation_status_name ===
                    'OnGoing' && (
                    <Grid item xs={12}>
                      <Box display="flex">
                        <Typography fontSize={12} mr={1} color="#fff">
                          {timer}
                        </Typography>
                        <Typography fontSize={12} color="#fff">
                          {onlineTyping ?? ''}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            }
            backRequired
            notshowFooter
            handleEndTask={handleEndTask}
          >
            <Box
              sx={doctor_chat_style?.medicalCardSx}
              onClick={handleModelOpen}
            >
              <Grid container display="flex" justifyContent="space-between">
                <Grid item>
                  <Box display="flex">
                    <RegisterIcon rootStyle={{ color: '#007965' }} />
                    <Typography
                      ml={0.8}
                      fontSize={14}
                      color="#007965"
                      fontWeight={500}
                    >
                      Medical card
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <DropDown rootStyle={{ color: '#007965' }} />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ px: 2.5 }}>
              <ChartListingInputBox
                isScroll={isScroll}
                chatStatus={chatCounsultationData?.consultation_status_name}
                onMessageType={onMessageTypeFnc}
                message={typingMessage}
                isChatExtendRequest={isChatExtendRequest}
                onAcceptRequest={onAcceptRequest}
                onDelineRequest={onDelineRequest}
                onSendMessage={onSendMessageFnc}
                onAttachFile={onAttachFileFnc}
                isDoctor
              >
                <ChatListingBox data={doctorChatData} isDoctor />
              </ChartListingInputBox>
            </Box>
            <Modal
              open={open}
              onClose={handleModelClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={doctor_chat_style?.modalSx}>
                <MedicalCard
                  isShow
                  patientName={chatCounsultationData?.patient_first_name ?? ''}
                  subText={`${chatCounsultationData?.patient_gender ?? ''},${
                    chatCounsultationData?.patient_age ?? ''
                  }Yrs`}
                  age={chatCounsultationData?.patient_age ?? ''}
                  mobile={`${chatCounsultationData?.patient_mobile_no_country_code} ${chatCounsultationData?.patient_mobile_no}`}
                  userPic={chatCounsultationData?.patient_profile_pic ?? ''}
                  height={
                    chatCounsultationData?.medical_card_data?.vital_json?.height
                  }
                  weight={
                    chatCounsultationData?.medical_card_data?.vital_json?.weight
                  }
                  temp={
                    chatCounsultationData?.medical_card_data?.vital_json
                      ?.temp_in_f
                  }
                  Pulse={
                    chatCounsultationData?.medical_card_data?.vital_json
                      ?.puls_bpm
                  }
                  bp={
                    chatCounsultationData?.medical_card_data?.vital_json
                      ?.blood_pressure
                  }
                  o2={
                    chatCounsultationData?.medical_card_data?.vital_json
                      ?.o2_level
                  }
                  allergies={
                    chatCounsultationData?.medical_card_data
                      ?.medical_condition_json?.medical_allergies
                  }
                  medications={
                    chatCounsultationData?.medical_card_data
                      ?.medical_condition_json?.medical_active_medications
                  }
                  health_problems={
                    chatCounsultationData?.medical_card_data
                      ?.medical_condition_json?.medical_health_problems
                  }
                  isMedicalCardSent={
                    chatCounsultationData?.medical_card_count > 1
                  }
                  data={chatCounsultationData}
                  isVitalShow={
                    chatCounsultationData?.medical_card_data?.is_vital_sent
                  }
                  isMedicalConditionShow={
                    chatCounsultationData?.medical_card_data
                      ?.is_medical_condition_sent
                  }
                />
              </Box>
            </Modal>
          </Screenlayout>
        </Box>
      )}
      <DialogModal
        isBackRequired={isMobilePort ? true : false}
        titleStyle={doctor_chat_style.titleSx}
        maxModalWidth="md"
        isDialogOpened={isModalOpen}
        handleCloseDialog={handleCloseDialog}
        contentRootStyle={doctor_chat_style.contentSx}
        bottomDivider
        content={
          <Box>
            <MedicalCard />
          </Box>
        }
        dialogRootStyle={doctor_chat_style.dialogSx}
        closeStyle={{
          width: '16px',
          height: '16px',
          display: isMobilePort ? 'none' : 'block',
        }}
        DialogActionsboxShadow={{ boxShadow: '0px 0px 10px #efefef' }}
      />
      <ConfigurationModal
        isModalOpen={isEndChatModalOpen}
        handleClose={handleChatClose}
        handleDelete={handleCloseChat}
        title="End Chat?"
        message="Are you sure you want to end the chat session with "
        cancleLable="Cancel"
        acceptLable="End Now"
        name={patientName}
      />
      <ConfigurationModal
        isModalOpen={isTimeOutModalOpen}
        handleClose={handleTimeOutModal}
        handleDelete={handleTimeoutCloseChat}
        title="Chat Time Ended"
        message="Thanks doctor, for your precious time with patient!"
        cancleLable="Cancel"
        acceptLable="Ok"
        name={patientName}
      />
      <Drawer
        show={isAttachFileOpen}
        onCloseDrawer={() => onCloseAttachFileDrawer()}
        height="30vh"
        header="Select Upload Type"
        headerStyle={{ fontWeight: 600 }}
        footer={
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <Button
                // loading={doctorsLoading}
                buttonStyle={doctor_chat_style.backButtonSx}
                onClick={onBack}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                // loading={doctorsLoading}
                buttonStyle={doctor_chat_style.applyButtonSx}
                onClick={onSendMessageFnc}
              >
                Upload
              </Button>
            </Grid>
          </Grid>
        }
      >
        {doctorChatConsultationState?.messageJSON?.length === 0 && (
          <Grid container columnSpacing={2}>
            {uploadUtills?.map((obj, i) => (
              <Grid item xs={6} key={i}>
                <label htmlFor={`uplodebtnv${obj.value}`}>
                  <Box
                    sx={
                      isSelected === ''
                        ? doctor_chat_style?.uploadTypeBoxSx
                        : isSelected === i
                        ? doctor_chat_style?.uploadTypeBoxSx
                        : doctor_chat_style?.uploadTypeBoxDisableSx
                    }
                    onClick={() => onSelectUploadType(i)}
                  >
                    <Grid container pt={2}>
                      <Grid
                        item
                        display="flex"
                        pt={2}
                        justifyContent="center"
                        xs={12}
                      >
                        <Box alignSelf="center">{obj?.icon}</Box>
                      </Grid>
                      <Grid item display="flex" justifyContent="center" xs={12}>
                        <Typography fontSize={14} fontWeight={500}>
                          {obj.name}
                        </Typography>
                      </Grid>
                      {i === 0 ? (
                        <input
                          hidden
                          name="img"
                          accept="image/*"
                          multiple="multiple"
                          type="file"
                          id={`uplodebtnv${obj.value}`}
                          onChange={(e) => handleFileUpload(e, obj.value)}
                        />
                      ) : (
                        <input
                          hidden
                          name={obj.value}
                          accept={obj.accept}
                          multiple="multiple"
                          type="file"
                          id={`uplodebtnv${obj.value}`}
                          onChange={(e) => handleFileUpload(e, obj.value)}
                        />
                      )}
                    </Grid>
                  </Box>
                </label>
              </Grid>
            ))}
          </Grid>
        )}

        <Grid container display="flex" justifyContent="center">
          {doctorChatConsultationState?.messageJSON?.length > 0 &&
            doctorChatConsultationState?.messageJSON?.map((obj, i) => (
              <Grid item xs={12} key={i} mt={1.5}>
                <PatientReportUploadCard
                  showReportName
                  icon={<UploadCloseIcon />}
                  isUploadCard
                  reportName={obj?.filename ?? ''}
                  uploadedFor={obj?.size ?? ''}
                  uploadedData={[
                    {
                      file: `${obj?.downloadUrl ?? ''}`,
                    },
                  ]}
                  IconOnClick={() => handleDelete(obj?.filename)}
                  // isLoading={loading}
                />
              </Grid>
            ))}
        </Grid>
      </Drawer>
    </>
  );
}
