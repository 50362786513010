export const rxAuditRequsetCard_style = {
  cardSx: {
    boxShadow: 'none',
    '& .MuiCardHeader-root': {
      padding: '0px',
    },
    borderBottom: `1px dashed #E3E3E3`,
    padding: '24px 0px',
  },
  buttonSx: {
    boxShadow: 'none',
    border: '2px solid #007965',
    borderRadius: '8px',
    padding: '6px',
    fontSize: '14px',
    textTransform: 'capitalize',
    width: '138px',
    '&:hover': {
      border: '2px solid #007965',
    },
  },
  gridcenterSx: {
    alignItems: 'center',
    display: 'flex',
  },
  referchangingSx: {
    borderRadius: '4px',
    fontWeight: 600,
    color: 'primary.main',
    backgroundColor: 'primary.lighter',
    padding: '2px 6px',
  },
  referchangingTESx: {
    borderRadius: '4px',
    fontWeight: 600,
    color: 'red.800',
    backgroundColor: 'red.50',
    padding: '2px 6px',
  },
  morelistSx: {
    '& .MuiPopover-paper': {
      boxShadow: ' 0px 25px 50px #0000001F',
      border: '1px solid #F5F5F5',
      borderRadius: '6px',
    },
    '& .MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list': {
      padding: '0px',
    },
  },
  borderSx: {
    fontSize: '12px',
    fontWeight: 600,
    margin: 0,
    color: 'error.main',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'common.white',
    },
  },
  cancelAppointment: {
    ml: 0.9,
  },
};
