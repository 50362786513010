/* eslint-disable prefer-const */
import { HealthCirclesLogo, } from '@hc/ui/atoms';
import { StepperFormOthers } from '@hc/ui/components/doctor';
import { Box, } from '@mui/material';
// import PropTypes from 'prop-types';
import React from 'react';
import { onboarding_style } from './style';

export default function OnboardingOthers(props) {
  const { className = '', ...rest } = props;

  return (
    <Box sx={onboarding_style.rootSx} className={`${className}`} {...rest}>
      <StepperFormOthers vertical logo={<HealthCirclesLogo />} others />
    </Box>
  );
}
