import {
  CustomTextArea,
  DateRangePicker,
  Input,
  Label,
  SearchSelect,
} from '@atoms';
import { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react';
// import SearchIcon from '@mui/icons-material/Search';
import {
  useDoctorProfile,
  useLanguage,
  useSpeciality,
  useViewPort,
} from '@hc/store';
import { getAuthData } from '@hc/utils';
import { Box, Typography } from '@mui/material';
import { useKeyPress } from 'ahooks';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { SearchIcon } from '../../../atoms';
import { QusAnsSelectionLisiting } from '../../qusAnsSelectionListing';
import { basicInfo_style } from './style';

export function BasicInfo(props) {
  const { className = '', others=false,...rest } = props;
  const filter = createFilterOptions();
  // Store Data
  const {
    profile,
    handleProfileDataChange,
    deleteData,
    addSpeciality,
    addLanguage,
    addWorkingAt,
    clearBasicInfo,
    setPersistedProfileData,
  } = useDoctorProfile(
    (state) => ({
      profile: state.profile,
      handleProfileDataChange: state.handleProfileDataChange,
      addSpeciality: state.addSpeciality,
      deleteData: state.deleteData,
      addLanguage: state.addLanguage,
      addWorkingAt: state.addWorkingAt,
      clearBasicInfo: state.clearBasicInfo,
      setPersistedProfileData: state.setPersistedProfileData,
      setAuthValue: state.setAuthValue,
    }),
    (prev, curr) => {
      isEqual(prev, curr);
      return false;
    },
  );

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const { getSpecialityData, specialityData } = useSpeciality(
    (state) => ({
      getSpecialityData: state.getSpecialityData,
      specialityData: state.specialityData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { getLanguageData, languageData } = useLanguage(
    (state) => ({
      getLanguageData: state.getLanguageData,
      languageData: state.languageData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const changeState = (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    // HANDLE Basic DATA CAHNGE
    handleProfileDataChange(key, value);
  };

  const onDateChange = (val) => {
    handleProfileDataChange('serving_from', val);
  };

  // General Hooks
  const [text, setText] = useState(null);
  const [work, setWork] = useState('');
  const [addDoctorSpeciality, setAddDoctorSpeciality] = useState('');
  const [addDoctorKnownLanguage, setAddDoctorKnownLanguage] = useState('');

  const handleAddWork = () => {
    if (work && work?.length > 0) {
      // HANDLE Basic DATA CAHNGE
      addWorkingAt(work);
      // CLEAR TEXT STATE DATA
      setWork('');
    }
  };

  // Add Data
  const handleAdd = (e, option) => {
    addSpeciality(e, option);
    setText({ value: '', label: '', labelShow: '' });
    setAddDoctorSpeciality('');
  };

  const getFilteredadata = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.title);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        value: inputValue,
        labelShow: inputValue,
        label: `ADD ${inputValue}`,
      });
    }
    return filtered;
  };

  const handleAddLanguage = (e, option) => {
    addLanguage(e, option);
    setText({ value: '', label: '' });
    setAddDoctorKnownLanguage('');
  };

  // clear data
  const onClearSpeciality = () => {
    clearBasicInfo('speciality');
  };

  const onClearLanguage = () => {
    clearBasicInfo('language');
  };

  const onClearWorkAt = () => {
    clearBasicInfo('working_at');
  };

  // delete data
  const onDeleteSpeciality = (i) => {
    deleteData('speciality', i);
  };

  const onDeleteLanguage = (i) => {
    deleteData('language', i);
  };

  const onDeleteWorkAt = (i) => {
    deleteData('working_at', i);
  };

  const handleChange = (e) => {
    setWork(e.target.value);
  };

  // While onEnterKeyPress Text  will Added
  useKeyPress(['Enter'], (event) => {
    event.preventDefault();
    handleAddWork();
  });

  const getInitialData = async () => {
    // GET SPECIALITY FUNCTION
    if(!others){
      await getSpecialityData();
    }
    // GET LANGUAGE FUNCTION
    await getLanguageData();
  };

  function getUnselectedSpecialties() {
    const unselectedSpecialties = specialityData.filter(
      (specialty) =>
        !profile.speciality.some(
          (selected) => selected?.label === specialty?.label,
        ),
    );

    return unselectedSpecialties;
  }
  function getUnselectedLanguages() {
    const unselectedLanguages =
      Array.isArray(profile?.language) && profile?.language?.length > 0
        ? languageData.filter((language) => {
            return !profile?.language?.some(
              (selected) => selected.value === language.value,
            );
          })
        : languageData;
    return unselectedLanguages;
  }

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    const profileData = localStorage.getItem('profile');
    if (profileData !== null) {
      setPersistedProfileData(JSON.parse(profileData || '{}'));
    } 
    
    else if (profile?.name?.length === 0) {
      const authData = getAuthData();

      setPersistedProfileData({
        email_id: authData?.email_id ?? '',
        name: authData?.name ?? '',
        speciality: [],
        country_code: authData?.country_code ?? '',
        mobile_no: authData?.mobile_no ?? '',
        register_no: authData?.register_no ?? '',
        state_medical_council_name: authData?.state_medical_council_name ?? '',
        year_of_registration: authData?.year_of_registration ?? '',
      });
    }
  }, []);

  



  useEffect(() => {
    if (profile?.name?.length > 0) {
      localStorage.setItem('profile', JSON.stringify(profile));
    }
  }, [profile]);


  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={!isMobilePort ? basicInfo_style.rootSx : ''}>
        <Box sx={{ display: 'grid', gap: 3 }}>
          {/* Name */}
          <Box sx={basicInfo_style.inputGroupSx}>
            <Label rootStyle={basicInfo_style.labelStyle} isRequired>
              Name
            </Label>
            <Input
              id="name"
              errorMessage={profile?.error?.name}
              isError={profile?.error?.name?.length > 0}
              value={profile?.name}
              onChange={(e) => changeState('name', e.target.value)}
              textFieldStyle={basicInfo_style.customtextSx}
              isReadOnly
              startAdornment={
                <Typography sx={basicInfo_style.startadorentDrSx}>
                  {' '}
                  Dr.
                </Typography>
              }
            />
          </Box>
          {/* Qualification */}
          <Box sx={basicInfo_style.inputGroupSx}>
            <Label rootStyle={basicInfo_style.labelStyle} isRequired>
              Qualification
            </Label>
            <Input
              id="qualification"
              errorMessage={profile?.error?.qualification}
              isError={profile?.error?.qualification?.length > 0}
              value={profile?.qualification}
              onChange={(e) => changeState('qualification', e.target.value)}
              textFieldStyle={basicInfo_style.customtextSx}
            />
          </Box>

          {/* Working At */}
          <Box sx={basicInfo_style.inputGroupSx}>
            <Label rootStyle={basicInfo_style.labelStyle} isRequired>
              Working At
            </Label>
            <div style={{ marginTop: '-26px' }}>
              <QusAnsSelectionLisiting
                id="workingAdded"
                qusAnsListingCardStyle={basicInfo_style.listingCardSx}
                ListCardTypoSX={{
                  ...basicInfo_style.listingCardEllipseSx,
                  width: isMobilePort ? 'auto' : '400px',
                }}
                QusAnsSelectionHeading=""
                showDivider={false}
                QusAnsListingHeading="Places"
                QusAnsListingAdded="Added"
                OnClearAll={onClearWorkAt}
                displaySelectionValues={profile?.working_at}
                OnDelete={onDeleteWorkAt}
                QusAnsSelectionListing={
                  <CustomTextArea
                    data_testId="working"
                    textFieldStyle={basicInfo_style.worktypoSx}
                    rows={1}
                    rowsMax={1}
                    add={handleAddWork}
                    onChange={handleChange}
                    value={work}
                    placeholder="Eg. Apollo Clinic"
                    enterKeyPress={useKeyPress}
                    plusButton
                  />
                }
              />
              {profile?.error?.working_at?.length > 0 && (
                <Typography sx={{ mt: 0.5 }} variant="caption" color="error">
                  {profile?.error?.working_at}
                </Typography>
              )}
            </div>
          </Box>
          {/* Speciality */}
      {   !others && <Box sx={basicInfo_style.inputGroupSx}>
            <Box sx={basicInfo_style.boxRootSx}>
              {/* Qus and Ans Selection Lisiting */}
              <Label rootStyle={basicInfo_style.labelStyle} isRequired>
                Speciality
              </Label>
              <QusAnsSelectionLisiting
                id="specialityAdded"
                qusAnsListingCardStyle={basicInfo_style.listingCardSx}
                QusAnsListingHeading="Specialty"
                showDivider={false}
                QusAnsListingAdded="Added"
                rootStyle={basicInfo_style.qnsAnsTotalSx}
                OnClearAll={onClearSpeciality}
                displaySelectionValues={profile?.speciality}
                OnDelete={onDeleteSpeciality}
                qsOptionViewKey="label"
                qusAnsSelectionHeadingStyle={
                  basicInfo_style.qnsAnsSelectionHeadSx
                }
                QusAnsSelectionListing={
                  <SearchSelect
                    TestId="speciality"
                    startAdornment={
                      <SearchIcon
                        sx={{ color: 'text.hint', fontSize: '16px' }}
                      />
                    }
                    renderOption={(props, option) => {
                      return (
                        <Box
                          {...props}
                          id="add"
                          sx={{ cursor: 'pointer' }}
                          height="40px"
                          px={2}
                          key={option.label}
                          display="flex"
                        >
                          {option?.label?.includes('ADD') ? (
                            <Box display="flex" alignSelf="center" id="addSpec">
                              <Typography
                                sx={{
                                  color: '#EB6D13',
                                  fontSize: '14px',
                                  fontWeight: '600',
                                }}
                              >
                                +ADD &nbsp;
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                }}
                              >
                                {addDoctorSpeciality}
                              </Typography>
                            </Box>
                          ) : (
                            <Box display="flex" alignSelf="center">
                              <Typography>{option.label}</Typography>
                            </Box>
                          )}
                        </Box>
                      );
                    }}
                    onChangeText={(e) =>
                      setAddDoctorSpeciality(e?.target?.value ?? '')
                    }
                    placeholder="Gynecologist, Laparoscopic Surgeon"
                    onChange={handleAdd}
                    filterOptions={(options, params) =>
                      getFilteredadata(options, params)
                    }
                    optionViewKey="label"
                    value={text}
                    options={getUnselectedSpecialties()}
                    autocompeleteStyle={basicInfo_style.autocompeleteSx}
                  />
                }
              />

              {profile?.error?.speciality?.length > 0 && (
                <Typography sx={{ mt: 0.5 }} variant="caption" color="error">
                  {profile?.error?.speciality}
                </Typography>
              )}
            </Box>
          </Box>}
          {/* Serving From */}
          <Box sx={basicInfo_style.inputGroupSx}>
            <Label rootStyle={basicInfo_style.labelStyle}>Serving From</Label>
            <DateRangePicker
              customSx={{ '& p': { fontWeight: 500 } }}
              showYearDropdown
              startDate={
                profile?.serving_from ? new Date(profile?.serving_from) : null
              }
              onChange={onDateChange}
              isError={profile?.error?.serving_from}
              errorMessage={profile?.error?.serving_from?.length > 0}
            />
          </Box>
          {/* Address */}
          <Box sx={basicInfo_style.inputGroupSx}>
            <Label rootStyle={basicInfo_style.labelStyle}>Address</Label>
            <CustomTextArea
              data_testId="address"
              placeholder="123 Main St, Anytown, USA 12345"
              textFieldStyle={basicInfo_style.customtextSx}
              value={profile?.address}
              onChange={(e) => changeState('address', e.target.value)}
            />
          </Box>
          {/* Languages Known */}
          <Box sx={basicInfo_style.inputGroupSx}>
            <Box sx={basicInfo_style.boxRootSx}>
              {/* Qus and Ans Selection Lisiting */}
              <Label rootStyle={basicInfo_style.labelStyle} isRequired>
                Languages Known
              </Label>
              <QusAnsSelectionLisiting
                id="languagesAdded"
                qusAnsListingCardStyle={basicInfo_style.listingCardSx}
                QusAnsListingHeading="Languages"
                QusAnsListingAdded="Added"
                showDivider={false}
                OnClearAll={onClearLanguage}
                rootStyle={basicInfo_style.qnsAnsTotalSx}
                displaySelectionValues={profile?.language}
                OnDelete={onDeleteLanguage}
                qsOptionViewKey="label"
                qusAnsSelectionHeadingStyle={
                  basicInfo_style.qnsAnsSelectionHeadSx
                }
                QusAnsSelectionListing={
                  <SearchSelect
                    TestId="languagesKnown"
                    startAdornment={
                      <SearchIcon
                        sx={{ color: 'text.hint', fontSize: '16px' }}
                      />
                    }
                    renderOption={(props, option) => {
                      return (
                        <Box
                          sx={{ cursor: 'pointer' }}
                          height="40px"
                          onClick={(e) => handleAdd(e, option)}
                          px={2}
                          key={option.label}
                          display="flex"
                          {...props}
                          id="languageAdd"
                        >
                          {option?.label?.includes('ADD') ? (
                            <Box display="flex" alignSelf="center">
                              <Typography
                                sx={{
                                  color: '#EB6D13',
                                  fontSize: '14px',
                                  fontWeight: '600',
                                }}
                              >
                                +ADD &nbsp;
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                }}
                              >
                                {addDoctorKnownLanguage}
                              </Typography>
                            </Box>
                          ) : (
                            <Box display="flex" alignSelf="center">
                              <Typography>{option.label}</Typography>
                            </Box>
                          )}
                        </Box>
                      );
                    }}
                    placeholder="English,Tamil"
                    onChange={handleAddLanguage}
                    filterOptions={(options, params) =>
                      getFilteredadata(options, params)
                    }
                    onChangeText={(e) =>
                      setAddDoctorKnownLanguage(e?.target?.value ?? '')
                    }
                    optionViewKey="label"
                    value={text}
                    options={getUnselectedLanguages()}
                    autocompeleteStyle={basicInfo_style.autocompeleteSx}
                  />
                }
              />
            </Box>
          </Box>
          {/* About */}
          <Box sx={basicInfo_style.inputGroupSx}>
            <Label rootStyle={basicInfo_style.labelStyle}>About</Label>
            <CustomTextArea
              data_testId="about"
              placeholder="Tell us a little bit about yourself"
              textFieldStyle={basicInfo_style.customAboutSx}
              value={profile?.about}
              onChange={(e) => changeState('about', e.target.value)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

BasicInfo.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  className: PropTypes.node,
  sx: PropTypes.object,
};
