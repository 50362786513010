import { useDoctorOnboarding, useDoctorProfile } from '@hc/store';
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';

import { GoogleCalender, GreenTickIcon } from '../../../../atoms';
import { googleConnect_style } from './style';

export function GoogleConnect(props) {
  const { className = '' } = props;

  const { doctor, getOauthURL, loadingUpdate } = useDoctorOnboarding(
    (state) => ({
      doctor: state.doctor,
      getOauthURL: state.getOauthURL,
      loadingUpdate: state.loadingUpdate,
    }),
  );

  const { profile, handleProfileDataChange } = useDoctorProfile((state) => ({
    profile: state.profile,
    handleProfileDataChange: state.handleProfileDataChange,
  }));
  const { googleAuthToken } = profile;

  const { oauth_url } = doctor;
  let windowObjectReference = null;
  let previousUrl = null;

  const receiveMessage = (event) => {
    // Do we trust the sender of this message? (might be
    // different from what we originally opened, for example).
    if (event.origin !== window.location.origin) {
      toast.error('Something went wrong please try again!');
      return;
    }
    const { data } = event;
    // if we trust the sender and the source is our popup
    if (data?.code) {
      handleProfileDataChange('googleAuthToken', data?.code);
      // get the URL params and redirect to our server to use Passport to auth/login
      // changeState('googleAuthCode', data.code);
    }
  };

  const onGoogleIntegerationClicked = () => {
    loadingUpdate(true);
    const url = oauth_url;
    const name = 'Google Calendar Integeration';

    // remove any existing event listeners
    window.removeEventListener('message', receiveMessage);

    // window features
    const strWindowFeatures =
      'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

    if (windowObjectReference === null || windowObjectReference.closed) {
      /* if the pointer to the window object in memory does not exist
         or if such pointer exists but the window was closed */
      windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
      /* if the resource to load is different,
         then we load it in the already opened secondary window and then
         we bring such window back on top/in front of its parent window. */
      windowObjectReference = window.open(url, name, strWindowFeatures);
      windowObjectReference.focus();
    } else {
      /* else the window reference must exist and the window
         is not closed; therefore, we can bring it back on top of any other
         window with the focus() method. There would be no need to re-create
         the window or to reload the referenced resource. */
      windowObjectReference.focus();
    }
    // add the listener for receiving a message from the popup
    window.addEventListener('message', (event) => receiveMessage(event), false);
    // assign the previous URL
    previousUrl = url;
  };

  const onClickMethod = () => {
    onGoogleIntegerationClicked();
  };

  useEffect(() => {
    getOauthURL();
  }, []);
  return (
    <Box className={`${className}`} sx={googleConnect_style.rootSx}>
      <Box mt={2}>
        <Typography variant="body1" sx={googleConnect_style.titleSx}>
          To enable the reminder option in the calendar, connect your google
          account here. You will receive in-app notifications anyhow.
        </Typography>
        <Box mt={2}>
          <Box sx={googleConnect_style.connectSx}>
            <Box
              sx={{
                ...(googleAuthToken && googleAuthToken?.length > 0
                  ? googleConnect_style.GoogleConnectActiveSx
                  : googleConnect_style.GoogleConnectSx),
              }}
              onClick={onClickMethod}
            >
              <GoogleCalender />
              <Typography sx={googleConnect_style.googleconnectTextSx}>
                {googleAuthToken && googleAuthToken?.length > 0
                  ? 'Synced with calendar!'
                  : 'Connect My Google Account'}
              </Typography>
              {googleAuthToken && googleAuthToken?.length > 0 ? (
                <GreenTickIcon />
              ) : (
                ''
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

GoogleConnect.propTypes = {
  className: PropTypes.string,
};
