import { getReferralDateFormat } from '@hc/dayjs';
import { useSummary } from '@hc/store';
import { Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { viewReferralNote_style } from './style';

function ViewReferralNote(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  const { summaryState } = useSummary((state) => ({
    summaryState: state.summaryState,
  }));

  const { referralNoteData } = summaryState;
  const {
    doctorName,
    speciality,
    doctorMobileNumber,
    doctorCountryCode,
    regNo,
    hospitalName,
    referralNoteDate,
    patientName,
    patientAge,
    patientGender,
    referralNotes,
  } = referralNoteData;

  return (
    <Box
      sx={{
        ...viewReferralNote_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box>
        <Box>
          <Typography
            sx={{
              color: 'text.hint',
              fontSize: '12px',
              fontWeight: 500,
            }}
          >
            {getReferralDateFormat(referralNoteDate, 'HH:MM A, Do MMM, YYYY')}
          </Typography>
        </Box>
        <Typography
          sx={{
            color: 'text.primary',
            fontSize: '12px',
            fontWeight: 600,
          }}
        >
          {`Dr. ${doctorName}`}
        </Typography>
        {/* <Typography
          sx={{
            color: 'text.primary',
            fontSize: '12px',
            fontWeight: 600,
          }}
        >
          Referral Note
        </Typography> */}
      </Box>

      <Box display="flex" justifyContent="space-between" mt={1}>
        <Box  alignItems="center" gap={1}>
          <Typography
            sx={{
              color: 'text.hint',
              fontSize: '12px',
              // display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
            }}
          >
            {Array.isArray(speciality) &&
              speciality?.length > 0 &&
              speciality.map((val, index) => (
                <>
                  {index > 0 ? (
                    <span
                    key={index}
                      style={{
                        // backgroundColor: 'PrimaryTints.A100',
                        padding: '4px 0px',
                        borderRadius: '4px',
                        color: '#868484',
                        fontSize: '12px',
                        // marginLeft: '6px',
                        fontWeight: 600,
                      }}
                    >
                     {/*  */}
                    </span>
                  ) : null}
                  <Typography
                    key={index}
                    sx={{
                      color: 'text.hint',
                      fontSize: '12px!important',
                      fontWeight: 'medium',
                    }}
                  >
                    &nbsp;{val?.master_speciality?.speciality ?? ''}&nbsp;
                  </Typography>
                </>
              ))}
            {/* &nbsp;Cardiologist &nbsp; */}
            <RiCheckboxBlankCircleFill
              style={{ fontSize: '4px', marginLeft: '6px' }}
            />
          </Typography>
          <Typography
            sx={{
              color: 'text.hint',
              fontSize: '12px',
              display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
            }}
          >
            {`${doctorCountryCode} ${doctorMobileNumber}`} &nbsp;
            <RiCheckboxBlankCircleFill
              style={{
                fontSize: '4px',
                marginLeft: '6px',
                // marginRight: '4px',
              }}
            />
          </Typography>
          <Typography
            sx={{
              color: 'text.hint',
              fontSize: '12px',
              fontWeight: 500,
            }}
          >
            {regNo}
          </Typography>
        </Box>
        {/* <Box>
          <Typography
            sx={{
              color: 'text.hint',
              fontSize: '12px',
              fontWeight: 500,
            }}
          >
            {getReferralDateFormat(referralNoteDate, 'HH:MM A, Do MMM, YYYY')}
            
          </Typography>
        </Box> */}
      </Box>
      <Typography
        sx={{
          color: 'text.hint',
          fontSize: '12px',
          display: 'flex',
          alignItems: 'center',
          fontWeight: 500,
        }}
      >
        {Array.isArray(hospitalName) &&
          hospitalName?.length > 0 &&
          hospitalName.map((val, index) => (
            <>
              {index > 0 ? (
                <span
                key={index}
                  style={{
                    // backgroundColor: 'PrimaryTints.A100',
                    padding: '4px 0px',
                    borderRadius: '4px',
                    color: '#868484',
                    fontSize: '12px',
                    // marginLeft: '6px',
                    fontWeight: 600,
                  }}
                >
                  {', '}{' '}
                </span>
              ) : null}
              <Typography
                key={index}
                sx={{
                  color: 'text.hint',
                  fontSize: '12px!important',
                  fontWeight: 'medium',
                }}
              >
                &nbsp;{val ?? ''}&nbsp;
              </Typography>
            </>
          ))}
        {/* Hospital Name */}
      </Typography>

      <Box mt={3}>
        <Divider />
        <Typography
          sx={{
            color: 'text.primary',
            fontSize: '12px',
            fontWeight: 600,
            my: 2,
          }}
        >
          {`Patient: ${patientName}, ${patientAge} ${patientGender}`}
          {/* Patient: Sivashankar Raman, 25 Male */}
        </Typography>
        <Divider />
      </Box>

      <Box mt={3}>
        <Typography
          sx={{
            color: 'text.primary',
            fontSize: '12px',
            fontWeight: 600,
          }}
        >
          Referral Notes
        </Typography>
        <Box
          sx={{
            bgcolor: '#F5F5F5',
            p: 1.5,
            mt: 1,
            borderRadius: 1,
          }}
        >
          <Typography
            sx={{
              color: 'text.primary',
              fontSize: '12px',
              fontWeight: 600,
            }}
          >
            {referralNotes}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

ViewReferralNote.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ViewReferralNote };
