import {
  Button,
  PreviousIcon,
  // DateRangePicker,
  Input,
  DialogModal,
  ClockIcon,
  CalenderColorIcon,
  RightArrowIcon,
  LeftArrowIcon,
  ForwardIcon,
  // SearchSelect,
} from '@atoms';
import { useDoctorAppointment, usePatientDetails } from '@hc/store';
import {
  Avatar,
  Box,
  Grid,
  Typography,
  useMediaQuery,
  IconButton,
  Divider,
} from '@mui/material';
import {
  addDays,
  addMinutes,
  addMonths,
  differenceInCalendarMonths,
  eachDayOfInterval,
  endOfWeek,
  format,
  isAfter,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
} from 'date-fns';
import PropTypes from 'prop-types';
import { getDateFormat, getEndTimeFromStartTime } from '@hc/dayjs';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { doctorAvailablePayload } from '@hc/utils/helperFunctions';
import {
  localStorageKeys,
  parseJwt,
  // formatDate,
  // commonSocketData,
} from '@hc/utils';
import { toast } from 'react-hot-toast';
import { Dropdown, DataTabs } from '../../../atoms';
import { bookappoinmentDialog_style } from './style';

// import { useNavigate } from 'react-router-dom';

function BookappoinmentOthersDialog(props) {
  const {
    className = '',
    handleClose = () => false,
    isModalOpen,
    rootStyle = {},
    patientData = {},
    setTabIndex = () => {},
    isMobileResponsive,
    ...rest
  } = props;

  const {
    // getDoctorSlot,
    // updatePatientState,
    setBookAppoinment,
    // doctorSlots,
    patientState,
    // clearDoctorSlots,
    getDoctorData,
    setStartEndBetween,
    updateBAState,
    selectSlotUpdate,
    constructSlotDataState,
    getReasonData,
    reasonData,
    getVerifiedDoctors,
    verifiedDoctors,
    slotLoading,
    claerDoctorDetail,
  } = usePatientDetails(
    (state) => ({
      patientState: state.patientState,
      setBookAppoinment: state.setBookAppoinment,
      doctorSlots: state.doctorSlots,
      updatePatientState: state.updatePatientState,
      getDoctorSlot: state.getDoctorSlot,
      clearDoctorSlots: state.clearDoctorSlots,
      claerDoctorDetail: state.claerDoctorDetail,
      getDoctorData: state.getDoctorData,
      // getDoctorsList: state.getDoctorsList,
      setStartEndBetween: state.setStartEndBetween,
      constructSlotDataState: state.constructSlotDataState,
      updateBAState: state.updateBAState,
      selectSlotUpdate: state.selectSlotUpdate,
      getReasonData: state.getReasonData,
      reasonData: state.reasonData,
      getVerifiedDoctors: state.getVerifiedDoctors,
      verifiedDoctors: state.verifiedDoctors,
      slotLoading: state.slotLoading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const {
    morningSlotData,
    afternoonSlotData,
    eveningSlotData,
    nightSlotData,
    selectedSlot,
    selectedDate,
    calenderData,
    // doctorDetail,
    doctorSearch,
    languages,
    selectedSlotStartTime,
    selectedSlotEndTime,
    speciality,
  } = patientState;

  const { getDoctorAppointmentData, getDashBoardData } = useDoctorAppointment(
    (state) => ({
      getDashBoardData: state.getDashBoardData,
      getDoctorAppointmentData: state.getDoctorAppointmentData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const buttonData = [
    {
      label: 'Virtual',
    },
    {
      label: 'In-Person',
    },
  ];

  const breaks = [
    {
      value: '1',
      label: 'morning',
    },
    {
      value: '2',
      label: 'afternoon',
    },
    {
      value: '3',
      label: 'evening',
    },
    {
      value: '4',
      label: 'night',
    },
  ];

  const reasonCondition = [
    ...reasonData,

    {
      value: 'others',
      label: 'others',
    },
  ];

  const media = useMediaQuery('(max-width:360px)');
  // const { endDate } = patientState;
  // const [dateVal, setDateVal] = useState();
  // const [selectedDData, setSelectedData] = useState('');
  const [reasonSelect, setReasonSelect] = useState('');
  const [doctorSelect, setDoctorSelect] = useState('');
  const [othersValue, setOthersValue] = useState('');
  // const [slotData, setSlotData] = useState([]);
  const [sendInvites, setSendInvite] = useState(1);
  const [active, setActive] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tabIndex, setTabIndexs] = useState(
    new Date().getHours() < 12
      ? '1'
      : new Date().getHours() < 16
      ? '2'
      : new Date().getHours() < 19
      ? '3'
      : '4',
  );

  const returnTimeSlot = (type) => {
    switch (type) {
      case '1':
        return morningSlotData;
      case '2':
        return afternoonSlotData;
      case '3':
        return eveningSlotData;
      case '4':
        return nightSlotData;
      default:
        return morningSlotData;
    }
  };

  const returnSlotType = (type) => {
    switch (type) {
      case '1':
        return 'morning';
      case '2':
        return 'afternoon';
      case '3':
        return 'evening';
      case '4':
        return 'night';
      default:
        return 'morning';
    }
  };

  // SELECT SLOT FUNCTION
  const onSelectSlot = (value) => {
    const selectedSlotStartTime = value?.client_date_time;
    const selectedSlotEndTime = getEndTimeFromStartTime(
      value?.client_date_time,
      15,
    );
    // SELECT SLOT UPDATE
    selectSlotUpdate(selectedSlotStartTime, selectedSlotEndTime, value?.slot);
  };

  const onConfirm = async () => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const keydata = parseJwt(authToken);
    if (keydata?.role_id !== 2 && !doctorSelect) {
      return toast.error('Select the doctor !!!');
    }
    if (reasonSelect === 'others' ? othersValue : reasonSelect) {
      if (selectedSlotStartTime && selectedSlotStartTime?.length > 0) {
        const reason = reasonData?.find((val) => val?.value === reasonSelect);
        const doctorName = verifiedDoctors?.find(
          (val) => val?.value === doctorSelect,
        );

        const obj = {
          user_profile_id: patientData?.user_profile_id ?? '',
          patient_family_member_id: patientData?.family_member_id ?? '',
          other_member_id: patientData?.other_member_id ?? '',
          appointed_doctor_details: {
            id: doctorSelect ? doctorSelect : keydata?.profile?.id ?? '',
            doctor_name:
              doctorName?.label?.length > 0 ? doctorName?.label : keydata?.name,
            appointment_date: getDateFormat(
              selectedSlotStartTime,
              'YYYY-MM-DD',
            ),
            appointment_time_slot: getDateFormat(
              selectedSlotStartTime,
              'HH:mm:ss',
            ),
            sendInvite: active === true ? true : false,
            appointment_client_start_time: selectedSlotStartTime,
            appointment_client_end_time: selectedSlotEndTime,
            // reason_id:reasonSelect,
            ...(reasonSelect !== 'others' && { reason_id: reasonSelect }),
            reason: reason?.label || othersValue,
          },
        };
        handleClose();
        claerDoctorDetail();
        await setBookAppoinment(obj);
        if (keydata?.role_id === 2) {
          await getDoctorAppointmentData('DoctorAppoinment');
        } else {
          await getDoctorAppointmentData('DoctorAppoinment','', 'fromFrontdesk');
        }
        await getDashBoardData('doctorDashBoard');
        setTabIndex('1');
        setIsError();
        setReasonSelect('');
        setDoctorSelect('');
        setOthersValue('');
        setSendInvite(1);
        // setDateVal();
        // setSelectedData('');
        setActive();
      } else {
        // setIsError(true);
        toast.error('Please select the slot...!!!!');
      }
    } else {
      // setIsError(true);
      toast.error(
        reasonSelect === 'others'
          ? 'Please enter reason...!!!!'
          : 'Please select the reason...!!!!',
      );
    }
  };

  // const onDateChange = async (val) => {
  //   debugger
  //   setDateVal(val);
  //   updatePatientState('endDate', val);
  //   updatePatientState('startDate', val);
  //   await getDoctorSlot(val);
  // };

  // const handleAdd = (option) => {
  //   setSelectedData(option);
  // };

  const onReasonBooking = (option) => {
    setReasonSelect(option);
  };

  const getInitialSlotData = async (val) => {
    const start = startOfWeek(new Date());

    const payload = {
      ...doctorAvailablePayload(start),
      id: val,
    };

    const slotData = await getDoctorData(payload);
    const selectedDate = getDateFormat(new Date(), 'YYYY-MM-DD');

    // UPDATE STATE
    updateBAState('selectedDate', selectedDate);

    // CONSTRUCT SLOT DATA
    const constructSlotData = (seDate, value) => {
      constructSlotDataState(seDate, value);
    };

    constructSlotData(selectedDate, slotData);
  };

  const onDoctorSelect = async (option) => {
    setDoctorSelect(option);
    await getInitialSlotData(option);
  };

  // const handleOnchange = (event) => {
  //   const slotArrayCopy = JSON.parse(JSON.stringify(doctorSlots));
  //   const arr = [];
  //   if (slotArrayCopy?.length > 0) {
  //     slotArrayCopy.forEach((val) => {
  //       if (val?.label?.includes(event?.target?.value)) {
  //         arr.push(val);
  //       }
  //     });
  //   }

  //   setSlotData(arr);
  // };

  const handleInvite = (index) => {
    setSendInvite(index);
    if (index === 0) {
      setActive(true);
    } else if (index === 1) {
      setActive(false);
    }
  };

  const handleCloseFnc = () => {
    setSendInvite(1);
    handleClose();
    setReasonSelect('');
    setDoctorSelect('');
    setOthersValue('');
    claerDoctorDetail();
  };

  // GET DOCTOR LIST FUNCTION
  const getDoctorsListFunction = async (start, doctorSearchFilter) => {
    const payload = {
      ...doctorAvailablePayload(start),
      search:
        doctorSearchFilter === undefined ? doctorSearch : doctorSearchFilter,
      languages: languages ?? [],
      speciality: speciality ? speciality?.toString() : '',
      id: data?.user_profile_id,
    };
    await getDoctorData(payload);
  };

  // GET PREVIOUS WEEK DATA
  const previousWeek = () => {
    const bookAppointmentStateCopy = JSON.parse(JSON.stringify(patientState));

    const start = format(
      subDays(
        new Date(bookAppointmentStateCopy?.calenderData?.betweenDates[0]),
        7,
      ),
      'yyyy,L,dd',
    );
    const end = format(
      subDays(
        new Date(
          bookAppointmentStateCopy?.calenderData?.betweenDates[
            bookAppointmentStateCopy?.calenderData?.betweenDates?.length - 1
          ],
        ),
        7,
      ),
      'yyyy,L,dd',
    );
    const betweenDates = eachDayOfInterval({
      start: new Date(start),
      end: new Date(end),
    });
    setStartEndBetween(new Date(start), new Date(end), betweenDates);
    // GET DOCTOR LIST FUNCTION
    getDoctorsListFunction(start);
    // SET START_OF_WEEK, END_OF_WEEK & BETWEEN_DATES IN STORE
  };

  // GET NEXT WEEK DATA
  const nextWeek = () => {
    const bookAppointmentStateCopy = JSON.parse(JSON.stringify(patientState));
    const start = format(
      addDays(
        new Date(bookAppointmentStateCopy?.calenderData?.betweenDates[0]),
        7,
      ),
      'yyyy,L,dd',
    );
    const end = format(
      addDays(
        new Date(
          bookAppointmentStateCopy?.calenderData?.betweenDates[
            bookAppointmentStateCopy?.calenderData?.betweenDates?.length - 1
          ],
        ),
        7,
      ),
      'yyyy,L,dd',
    );
    const betweenDates = eachDayOfInterval({
      start: new Date(start),
      end: new Date(end),
    });

    // SET START_OF_WEEK, END_OF_WEEK & BETWEEN_DATES IN STORE
    setStartEndBetween(new Date(start), new Date(end), betweenDates);
    // GET DOCTOR LIST FUNCTION
    getDoctorsListFunction(new Date(start));
  };

  // GET PREVIOUS MONTH DATA
  const previousMonth = () => {
    const bookAppointmentStateCopy = JSON.parse(JSON.stringify(patientState));
    const current_month = subMonths(
      new Date(
        bookAppointmentStateCopy?.calenderData?.betweenDates[
          bookAppointmentStateCopy?.calenderData?.betweenDates?.length - 1
        ],
      ),
      1,
    );
    const start_of_month = startOfMonth(new Date(current_month));
    const start_week = startOfWeek(new Date(start_of_month));
    const end_week = endOfWeek(new Date(start_of_month));
    const betweenDates = eachDayOfInterval({
      start: new Date(format(start_week, 'yyyy,L,dd')),
      end: new Date(format(end_week, 'yyyy,L,dd')),
    });
    // SET START_OF_WEEK, END_OF_WEEK & BETWEEN_DATES IN STORE
    setStartEndBetween(
      new Date(start_week, 'yyyy,L,dd'),
      new Date(end_week, 'yyyy,L,dd'),
      betweenDates,
    );
    // GET DOCTOR LIST FUNCTION
    getDoctorsListFunction(start_week);
  };

  // GET NEXT MONTH 7 DATA
  const nextMonth = () => {
    const bookAppointmentStateCopy = JSON.parse(JSON.stringify(patientState));
    const current_month = addMonths(
      new Date(
        bookAppointmentStateCopy?.calenderData?.betweenDates[
          bookAppointmentStateCopy?.calenderData?.betweenDates?.length - 1
        ],
      ),
      1,
    );
    const start_of_month = startOfMonth(new Date(current_month));
    const start_week = startOfWeek(new Date(start_of_month));
    const end_week = endOfWeek(new Date(start_of_month));
    const betweenDates = eachDayOfInterval({
      start: new Date(format(start_week, 'yyyy,L,dd')),
      end: new Date(format(end_week, 'yyyy,L,dd')),
    });
    // SET START_OF_WEEK, END_OF_WEEK & BETWEEN_DATES IN STORE
    setStartEndBetween(new Date(start_week), new Date(end_week), betweenDates);
    // GET DOCTOR LIST FUNCTION
    getDoctorsListFunction(start_week);
  };

  // SELECT DATE FUNCTION
  const selectDate = async (val) => {
    const selectedDat = getDateFormat(val, 'YYYY-MM-DD');
    // UPDATE STATE
    updateBAState('selectedDate', selectedDat);

    const bookAppointmentStateCopy = JSON.parse(JSON.stringify(patientState));

    // CONSTRUCT SLOT DATA
    const constructSlotData = (seDate, value) => {
      constructSlotDataState(seDate, value);
    };

    const doctorDetail_ =
      await bookAppointmentStateCopy?.doctorAvailableDetailsCopy.filter(
        (val) => val?.id === bookAppointmentStateCopy?.doctorDetail?.id,
      )?.[0];

    constructSlotData(selectedDat, doctorDetail_);
  };

  const initialData = async () => {
    const start = startOfWeek(new Date());
    const end = endOfWeek(new Date());
    const days = eachDayOfInterval({
      start,
      end,
    });
    await setStartEndBetween(start, end, days);
    await getReasonData();
    if (data?.role_id !== 2) {
      await getVerifiedDoctors();
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box
      sx={{
        ...bookappoinmentDialog_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <DialogModal
        title="BOOK APPOINTMENT"
        titleStyle={bookappoinmentDialog_style.titleSx}
        isBackRequired={isMobileResponsive ? true : false}
        isCloseOut={isMobileResponsive ? false : true}
        isMobileResponsive={isMobileResponsive}
        maxModalWidth="xl"
        topDivider
        bottomDivider
        dialogRootStyle={
          isMobileResponsive ? '' : bookappoinmentDialog_style.dialogSx
        }
        content={
          <Box>
            <Box sx={{ ...bookappoinmentDialog_style.profileSx, pb: 1 }}>
              <Box sx={bookappoinmentDialog_style.proPicSX}>
                {' '}
                <Avatar />{' '}
              </Box>
              <Box sx={bookappoinmentDialog_style.rightSx}>
                <Typography sx={bookappoinmentDialog_style.firstSx}>
                  {patientData?.patient_profile?.name}
                </Typography>
                <Typography sx={bookappoinmentDialog_style.subSx}>
                  {patientData?.patient_profile?.gender}{' '}
                  {patientData?.patient_profile?.age} Years
                </Typography>
              </Box>
            </Box>

            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{ ...bookappoinmentDialog_style.titleSx, py: 1 }}
                >
                  Reason for booking appointment
                </Typography>
                <Dropdown
                  placeholder="select"
                  selectOption={reasonCondition}
                  optionListStyle={{ height: '350px' }}
                  optionViewKey="label"
                  onChange={(event, value) =>
                    onReasonBooking(event.target.value, value)
                  }
                  isError={isError ? true : false}
                  helperText={isError ? 'Please select the slot' : ''}
                  // onChangeText={handleOnchange}
                />
              </Grid>

              {reasonSelect === 'others' && (
                <Grid item xs={12}>
                  <Typography
                    sx={{ ...bookappoinmentDialog_style.titleSx, py: 1 }}
                  >
                    Enter a reason
                  </Typography>
                  <Input
                    textFieldStyle={{
                      '& .MuiOutlinedInput-input': { height: '28px' },
                    }}
                    id="othersValue"
                    onChange={(e) => {
                      setOthersValue(e.target.value);
                    }}
                    value={othersValue}
                    isError={isError === 'othersValue' ? true : false}
                    helperText={
                      isError === 'othersValue'
                        ? 'Please enter the reason...'
                        : ''
                    }
                  />
                </Grid>
              )}
              {data?.role_id !== 2 ? (
                <Grid item xs={12}>
                  <Typography
                    sx={{ ...bookappoinmentDialog_style.titleSx, py: 1 }}
                  >
                    Select doctor
                  </Typography>
                  <Dropdown
                    placeholder="select"
                    selectOption={verifiedDoctors}
                    optionListStyle={{ height: '350px' }}
                    optionViewKey="label"
                    onChange={(event) => onDoctorSelect(event.target.value)}
                    isError={isError ? true : false}
                    helperText={isError ? 'Please select the slot' : ''}
                    // onChangeText={handleOnchange}
                  />
                </Grid>
              ) : null}

              {(data?.role_id === 2 || doctorSelect) && (
                <Grid item xs={12}>
                  <Typography sx={bookappoinmentDialog_style.titleSx}>
                    When is the appointment?
                  </Typography>
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex" alignItems="center" marginLeft={2}>
                      <IconButton disableRipple>
                        <CalenderColorIcon />
                      </IconButton>
                      <Typography sx={bookappoinmentDialog_style.CalendarSx}>
                        {getDateFormat(
                          calenderData?.betweenDates?.[
                            calenderData?.betweenDates?.length - 1
                          ],
                          'MMM, YYYY',
                        )}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" marginRight={2}>
                      <IconButton disableRipple onClick={() => previousMonth()}>
                        <PreviousIcon />
                      </IconButton>
                      <IconButton
                        disableRipple
                        disabled={
                          differenceInCalendarMonths(
                            startOfMonth(
                              new Date(
                                addMonths(
                                  new Date(
                                    patientState?.calenderData?.betweenDates[
                                      patientState?.calenderData?.betweenDates
                                        ?.length - 1
                                    ],
                                  ),
                                  1,
                                ),
                              ),
                            ),
                            startOfMonth(new Date()),
                          ) > 2
                        }
                      >
                        <ForwardIcon
                          opacity={
                            differenceInCalendarMonths(
                              startOfMonth(
                                new Date(
                                  addMonths(
                                    new Date(
                                      patientState?.calenderData?.betweenDates[
                                        patientState?.calenderData?.betweenDates
                                          ?.length - 1
                                      ],
                                    ),
                                    1,
                                  ),
                                ),
                              ),
                              startOfMonth(new Date()),
                            ) > 2
                              ? '0.4'
                              : '1'
                          }
                          onClick={() => nextMonth()}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={bookappoinmentDialog_style.rootDateSx}>
                    <IconButton disableRipple onClick={() => previousWeek()}>
                      <LeftArrowIcon />
                    </IconButton>
                    <Box sx={bookappoinmentDialog_style.dateSelectionWrapperSx}>
                      {Array.isArray(calenderData?.betweenDates) &&
                      calenderData?.betweenDates?.length > 0
                        ? calenderData?.betweenDates?.map((val, i) => (
                            <Box
                              key={i}
                              sx={{
                                ...bookappoinmentDialog_style.dateSelectionSx,
                                ...(getDateFormat(
                                  new Date(val),
                                  'YYYY-MM-DD',
                                ) === getDateFormat(new Date(), 'YYYY-MM-DD') &&
                                getDateFormat(new Date(val), 'YYYY-MM-DD') ===
                                  selectedDate
                                  ? bookappoinmentDialog_style.todayButton
                                  : getDateFormat(
                                      new Date(val),
                                      'YYYY-MM-DD',
                                    ) ===
                                    getDateFormat(new Date(), 'YYYY-MM-DD')
                                  ? bookappoinmentDialog_style.unSelectButton
                                  : getDateFormat(new Date(val), 'YYYY-MM-DD') <
                                    getDateFormat(new Date(), 'YYYY-MM-DD')
                                  ? bookappoinmentDialog_style.pastButton
                                  : getDateFormat(
                                      new Date(val),
                                      'YYYY-MM-DD',
                                    ) === selectedDate
                                  ? bookappoinmentDialog_style.todayButton
                                  : bookappoinmentDialog_style.unSelectButton),
                              }}
                              onClick={() =>
                                !(
                                  getDateFormat(new Date(val), 'YYYY-MM-DD') <
                                  getDateFormat(new Date(), 'YYYY-MM-DD')
                                ) && selectDate(val)
                              }
                            >
                              <Box
                                sx={{
                                  display: 'grid',
                                  placeItems: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: '14px', fontWeight: 500 }}
                                >
                                  {/* {val?.date} */}
                                  {getDateFormat(val, 'DD')}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: '10px', fontWeight: 500 }}
                                >
                                  {/* {val?.day} */}
                                  {getDateFormat(val, 'ddd')}
                                </Typography>
                              </Box>
                            </Box>
                          ))
                        : null}
                    </Box>
                    <IconButton
                      disableRipple
                      disabled={
                        differenceInCalendarMonths(
                          startOfMonth(
                            new Date(
                              addDays(
                                new Date(
                                  patientState?.calenderData?.betweenDates[
                                    patientState?.calenderData?.betweenDates
                                      ?.length - 1
                                  ],
                                ),
                                7,
                              ),
                            ),
                          ),
                          startOfMonth(new Date()),
                        ) > 2
                      }
                    >
                      <RightArrowIcon
                        opacity={
                          differenceInCalendarMonths(
                            startOfMonth(
                              new Date(
                                addDays(
                                  new Date(
                                    patientState?.calenderData?.betweenDates[
                                      patientState?.calenderData?.betweenDates
                                        ?.length - 1
                                    ],
                                  ),
                                  7,
                                ),
                              ),
                            ),
                            startOfMonth(new Date()),
                          ) > 2
                            ? '0.4'
                            : '1'
                        }
                        onClick={() => nextWeek()}
                      />
                    </IconButton>
                  </Box>
                  <Divider
                    sx={{
                      mt: 1.3,
                      border: '.5px solid',
                      borderColor: 'grey.400',
                    }}
                  />
                  <Box sx={bookappoinmentDialog_style.stickySx}>
                    <Box>
                      <Typography
                        sx={bookappoinmentDialog_style.availableTextSx}
                      >
                        {/* Slots available on 9th Aug, 2022 */}
                        {morningSlotData?.length > 0 ||
                        afternoonSlotData?.length > 0 ||
                        eveningSlotData?.length > 0 ||
                        nightSlotData?.length > 0
                          ? `Slots available on ${getDateFormat(
                              selectedDate,
                              'Do MMM,YYYY',
                            )}`
                          : `No slots available on ${getDateFormat(
                              selectedDate,
                              'Do MMM,YYYY',
                            )}`}
                      </Typography>
                    </Box>
                    {/* {(morningSlotData?.length > 0 ||
                  afternoonSlotData?.length > 0 ||
                  eveningSlotData?.length > 0 ||
                  nightSlotData?.length > 0) && ( */}
                    <Box my={2}>
                      <DataTabs
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndexs}
                        totalStyle={bookappoinmentDialog_style.totalSx}
                        tabStyle={bookappoinmentDialog_style.tabSx}
                        underTabSx={
                          bookappoinmentDialog_style.underTabCalenderStyle
                        }
                        tabs={breaks}
                      />
                    </Box>
                    {/* )} */}
                  </Box>

                  <Box
                    sx={{
                      width: '100%',
                      display: 'grid',
                    }}
                  >
                    <Box>
                      {/* Morning Slot */}
                      {/* {state?.data?.length > 0 && ( */}

                      <Grid container spacing={2}>
                        {returnTimeSlot(tabIndex).map((val, i) => (
                          <Grid key={i} item xs={media ? 6 : 4}>
                            <Box
                              id={val?.slot.replace(/[.:\s]/g, '')}
                              sx={{
                                ...bookappoinmentDialog_style.timeSelectionSx,
                                ...(val?.slot === selectedSlot
                                  ? bookappoinmentDialog_style.selectTimeButton
                                  : // : isAfter(new Date(), new Date(val?.client_date_time))
                                  // User can book after 15 mins slots only. (If time is 9.46 pm user only book after 10.01 slot only)
                                  isAfter(
                                      addMinutes(new Date(), 15),
                                      new Date(val?.client_date_time),
                                    )
                                  ? bookappoinmentDialog_style.pastTimeButton
                                  : bookappoinmentDialog_style.unSelectTimeButton),
                              }}
                              onClick={() =>
                                // !isAfter(new Date(), new Date(val?.client_date_time)) &&
                                // User can book after 15 mins slots only. (If time is 9.46 pm user only book after 10.01 slot only)
                                !isAfter(
                                  addMinutes(new Date(), 15),
                                  new Date(val?.client_date_time),
                                ) && onSelectSlot(val)
                              }
                            >
                              <Box
                                sx={
                                  bookappoinmentDialog_style.iconWithTimeSelectionSx
                                }
                              >
                                {val?.slot === selectedSlot ? (
                                  <IconButton disableRipple>
                                    <ClockIcon
                                      rootStyle={
                                        bookappoinmentDialog_style.SelectIconChange
                                      }
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton disableRipple>
                                    <ClockIcon
                                      rootStyle={{
                                        ...bookappoinmentDialog_style.unSelectIconChange,
                                        color: isAfter(
                                          addMinutes(new Date(), 15),
                                          new Date(val?.client_date_time),
                                        )
                                          ? 'divider'
                                          : '#007965',
                                      }}
                                    />
                                  </IconButton>
                                )}

                                <Typography
                                  variant="body1"
                                  sx={{
                                    ...bookappoinmentDialog_style.timeTextSx,
                                    // color: val?.slot === selectedSlot ? '' : '#007965',
                                    color: isAfter(
                                      addMinutes(new Date(), 15),
                                      new Date(val?.client_date_time),
                                    )
                                      ? ''
                                      : val?.slot === selectedSlot
                                      ? ''
                                      : '#007965',
                                  }}
                                >
                                  {val?.slot}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>

                      {/* // )} */}
                    </Box>
                  </Box>
                  {(morningSlotData?.length > 0 ||
                    afternoonSlotData?.length > 0 ||
                    eveningSlotData?.length > 0 ||
                    nightSlotData?.length > 0) &&
                    returnTimeSlot(tabIndex)?.length === 0 && (
                      <Typography
                        variant="body1"
                        sx={{
                          ...bookappoinmentDialog_style.timeTextSx,
                          ml: 1.6,
                        }}
                      >
                        {`No slots available at ${returnSlotType(tabIndex)}`}
                      </Typography>
                    )}
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography sx={bookappoinmentDialog_style.titleSx}>
                  Mode of consultation
                </Typography>
                <Box sx={bookappoinmentDialog_style.continueBtnSx}>
                  {buttonData?.map((val, index) => (
                    <Box key={index} mr={1.5}>
                      <Button
                        onClick={() => handleInvite(index)}
                        buttonStyle={
                          sendInvites === index
                            ? bookappoinmentDialog_style.saveBtnSx
                            : bookappoinmentDialog_style.noBtnSx
                        }
                      >
                        {val?.label}
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        }
        actions={
          <Grid
            p={1}
            container
            spacing={2}
            justifyContent={isMobileResponsive ? 'space-around' : 'end'}
          >
            {isMobileResponsive && (
              <Grid item xs={6}>
                <Button
                  onClick={() => handleCloseFnc()}
                  sx={bookappoinmentDialog_style.buttonSx}
                >
                  CANCEL
                </Button>
              </Grid>
            )}

            <Grid item xs={isMobileResponsive ? 6 : 4}>
              <Button onClick={() => onConfirm()}>CONFIRM</Button>
            </Grid>
          </Grid>
        }
        isDialogOpened={isModalOpen}
        handleCloseDialog={() => handleCloseFnc()}
      />
    </Box>
  );
}

BookappoinmentOthersDialog.propTypes = {
  className: PropTypes.string,
  handleClose: PropTypes.func,
  isModalOpen: PropTypes.func,
  patientData: PropTypes.object,
  rootStyle: PropTypes.object,
  setTabIndex: PropTypes.func,
  isMobileResponsive: PropTypes.bool,
};

export { BookappoinmentOthersDialog };
