/* eslint-disable no-restricted-globals */

import {
  getAllAccess,queryClient
  } from '@hc/utils';
  import { envConfig } from '@hc/config';
import toast from 'react-hot-toast';
import create from 'zustand';



export const useRolePermission = create((set, get) => ({
  useRolePermissionState: {
    getRepositoryPermissionData: {},
  },
  getLoading: true,
  loading: false,
  error: null,


  getRolePermission: async (roleId) => {
    try {
      const { useRolePermissionState } = get();
      set({ loading: true });
      const { data } = await queryClient.fetchQuery([
        `/backofficeMaster/permission/get?roleIds=${roleId}`,
        'get',
        `${envConfig.api_url}`,
      ]);
     console.log(data,'data')
     
      if (data?.status?.code === 200) {
        if (
          Array.isArray(
            data?.data?.data?.rows?.[0]?.role_permission_mappings?.[0]
              ?.permission?.data?.data,
          ) &&
          data?.data?.data?.rows?.[0]?.role_permission_mappings?.[0]
            ?.permission?.data?.data?.length > 0
        ) {
          const resss = await getAllAccess(
            data?.data?.data?.rows?.[0]?.role_permission_mappings?.[0]
              ?.permission?.data?.data,
          );
          set({
            getLoading: false,
            useRolePermissionState: {
              ...useRolePermissionState,
              getRepositoryPermissionData: resss ?? {},
            },
          });
          return {
            status,
            resss,
          };
        } 
          set({
            getLoading: false,
            useRolePermissionState: {
              ...useRolePermissionState,
              getRepositoryPermissionData: {},
            },
          });
        
      }
      set({ getLoading: false });
      return data?.data?.data?.rows?.[0]?.role_permission_mappings?.[0]
        ?.permission?.data?.data;
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },
}));
