import React from 'react';

export default function GoogleIntegeration() {
  React.useEffect(() => {
    // get the URL parameters which will include the auth token
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const code = params.get('code');

    if (window.opener) {
      // send them to the opening window
      window.opener.postMessage({ code });
      // close the popup
      window.close();
    }
  });

  return <div>Redirecting....</div>;
}
