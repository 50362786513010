export const summaryReferNote_style = {
  divideDottedSx: {
    my: 2,
    borderStyle: 'dashed',
    borderColor: 'divider',
  },
  dividerSx: {
    mx: 1.5,
    height: '25px',
    border: '0.5px solid',
    backgroundColor: 'grey.400',
    borderColor: 'grey.400',
  },
  summaryTitleSx: {
    color: 'text.hint',
    fontSize: '14px',
    pb: 1,
    pl: 1,
  },
  pastReferRootSx: {
    bgcolor: '#F5F5F5',
    borderRadius: '8px',
    p: 1.5,
  },
  cardSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardSxx: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  commonTextSx: {
    color: 'text.primary',
    fontSize: '14px',
    fontWeight: 600,
  },
  issuesTextSx: {
    color: 'text.hint',
    fontSize: '12px',
    fontWeight: 500,
    mt: 1,
  },
  subCommonTextSx: {
    color: 'text.hint',
    fontSize: '12px',
  },
  boxCardSx: {
    bgcolor: '#F5F5F5',
    borderRadius: '8px',
    p: 2,
    mb: 1.5,
  },
};
