import { Box, Grid, IconButton, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  CloseIcon,
  DropIcon,
  DropUpIcon,
  HeartPulse,
  Input,
} from '../../../atoms';
import { summaryChips_style } from './style';

function SummaryChips(props) {
  const {
    className = '',
    rootStyle = {},
    title = 'asdfasdf',
    placeholder = 'Type here..',
    value = '',
    summaryView,
    icon = <HeartPulse />,
    data = [],
    onAdd = () => {},
    onDelete = () => {},
    onChange = () => {},
    noDataFound = '',
    ...rest
  } = props;

  const res = useMediaQuery('(max-width:945px)');
  const [expand, setExpand] = useState(true);
  const IntialData = res ? data.slice(0, 1) : data.slice(0, 2);

  return (
    <Box
      sx={{
        ...summaryChips_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid container>
        <Grid item py={1} sm={12} md={12} lg={12} xl={12}>
          <Box sx={summaryChips_style.titleSx}>
            {icon}
            <Typography sx={summaryChips_style.headerSx}>{title}</Typography>
          </Box>
        </Grid>
        {!summaryView && (
          <Grid item md={12} lg={12} xl={12}>
            <Input
              placeholder={placeholder}
              value={value}
              onChange={(event) => onChange(event?.target?.value)}
              endAdornment={
                <Box sx={summaryChips_style.endAdornmentSx} onClick={onAdd}>
                  <Typography fontSize={12} color="#fff" alignSelf="center">
                    Add
                  </Typography>
                </Box>
              }
            />
          </Grid>
        )}
      </Grid>

      <Grid container>
        <Grid item sm={10} md={10} lg={10} xl={10}>
          <Box>
            {expand ? (
              <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                {IntialData && IntialData.length ? (
                  IntialData.map((val, index) => (
                    <Box key={index} as="span" sx={summaryChips_style.labelSx}>
                      <Box> {val}</Box>
                      {!summaryView && (
                        <Box
                          mt="2px"
                          ml="5px"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => onDelete(val)}
                        >
                          <CloseIcon rootStyle={{ color: '#F44F5A' }} />
                        </Box>
                      )}
                    </Box>
                  ))
                ) : (
                  <Typography sx={summaryChips_style.noDataFound}>
                    {noDataFound}
                  </Typography>
                )}
                {data.length - IntialData.length > 0 && (
                  <Box sx={summaryChips_style.pointsSx} as="span">
                    {`+${data.length - IntialData.length}`}
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  mt: 1,
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {data && data.length ? (
                  data.map((val, index) => (
                    <>
                      {' '}
                      <Box
                        key={index}
                        as="span"
                        sx={summaryChips_style.labelSx}
                      >
                        <Box> {val}</Box>
                        {!summaryView && (
                          <Box
                            mt="2px"
                            ml="5px"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => onDelete(val)}
                          >
                            <CloseIcon rootStyle={{ color: '#F44F5A' }} />
                          </Box>
                        )}
                      </Box>
                    </>
                  ))
                ) : (
                  <Typography sx={summaryChips_style.noDataFound}>
                    {noDataFound}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item sm={2} md={2} lg={2} xl={2}>
          <Box display="flex" justifyContent="end">
            <IconButton>
              {data.length > 0 ? (
                expand ? (
                  <DropIcon
                    onClick={() => setExpand(false)}
                    rootStyle={{ cursor: 'pointer' }}
                  />
                ) : (
                  <DropUpIcon
                    onClick={() => setExpand(true)}
                    rootStyle={{ cursor: 'pointer' }}
                  />
                )
              ) : null}
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

SummaryChips.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  summaryView: PropTypes.any,
  icon: PropTypes.any,
  data: PropTypes.array,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  noDataFound: PropTypes.string,
};

export { SummaryChips };
