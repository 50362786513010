export const reportViewSummary_style = {
  titleSx: {
    color: 'common.black',
  },
  leftSx: {
    px: 0.75,
  },
  viewReportSx: {
    px: 1.5,
  },
  buttonSx: {
    border: '2px solid #007965',
    '&:hover': {
      border: '2px solid #007965',
    },
  },
  boxRootSx: { p: 1 },
};
