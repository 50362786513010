export const prescriptionListCard_style = {
  containerSx: {
    alignItems: 'center',
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    backGroundColor: '#fff',
    py: 1.4,
    px: 0.8,
  },
  typoSx: {
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 500,
    color: '#4D4D4D',
  },

  DrugnameSx: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#0E1824',
    pl: 0.8,
  },

  listDataAlign: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    borderRadius: '8px',
  },

  customclickSx: {
    cursor: 'pointer',
    color: '#007965',
    fontSize: '12px',
    fontWeight: 500,
  },
};
