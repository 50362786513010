export const doctor_chat_style = {
  buttonSx: {
    color: '#fff',
    border: '.5px solid #F44F5A',
    backgroundColor: '#F44F5A',
    '&:hover': {
      border: '.5px solid #F44F5A',
      backgroundColor: '#F44F5A',
    },
  },
  medicalCardSx: {
    p: 2,
    m: 1.5,
    border: '1px solid #007965',
    borderRadius: '12px',
    bgcolor: '#DBEAE8',
    position: 'fixed',
    top: 0,
    zIndex: '1000',
    left: 0,
    right: 0,
    // width: '100%',
    maxWidth: {
      sm: 400,
    },
    mx: {
      sm: 'auto',
    },
    mt: 10,
    cursor: 'pointer',
  },
  modalSx: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 375,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
  },
  boxRootSx: { p: 1 },
  backIconSx: {
    cursor: 'pointer',
    color: '#000',
  },

  uploadTypeBoxSx: {
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid #E4E4E4',
    borderRadius: '8px',
    height: '150px',
    cursor: 'pointer',
  },
  uploadTypeBoxDisableSx: {
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid #E4E4E4',
    borderRadius: '8px',
    height: '150px',
    cursor: 'no-drop',
    opacity: 0.5,
  },

  applyButtonSx: {
    bgcolor: 'primary.main',
    textTransform: 'capitalize',
    color: '#fff',

    borderRadius: '8px',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },
  backButtonSx: {
    border: '1px solid',

    borderColor: 'primary.main',
    color: 'primary.main',
    textTransform: 'capitalize',
    borderRadius: '8px',
    bgcolor: '#fff',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },

  filterTabRootSx: {
    fontSize: '14px',
    fontWeight: 500,
    height: '20px',
    position: 'relative',
    zIndex: 1,
    color: '#3D3D3D',
    width: '49%',
    opacity: 1,
    borderRadius: '8px',
    '&.Mui-selected': {
      color: '#fff',
    },
  },
  contentSx: {
    pt: 2,
    pb: 0,
    pl: 0,
    pr: 0,
  },
  titleSx: {
    fontSize: 16,
    fontWeight: 600,
    padding: '0px',
  },
  dialogSx: {
    width: '469px',
  },
};
