import { useViewPort } from '@hc/store';
import { Button, PhoneIcon, UserProfileIcon } from '@hc/ui/atoms';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { chatListCard_style } from './style';

function ChatListCard(props) {
  const {
    rootStyle = {},
    PatientName = 'Arunachalam R',
    subText = 'male',
    age = '24',
    mobile = ' +91 9992299999',
    countyCode,
    userPic = false,
    border = true,
    data = {},
    onChatClick = () => {},
    index,
  } = props;

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  return (
    <Box
      sx={{
        ...chatListCard_style.rootSx,
        ...rootStyle,
      }}
    >
      <Box sx={chatListCard_style.totalBoxSx} id={`patientCard${index + 1}`}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{
            ...chatListCard_style.gridcenterTotal,
            borderBottom:
              !isMobilePort && border ? '0.5px dashed #DBDBDB ' : '',
            border: isMobilePort && '1px solid #E2E2E2',
            borderRadius: isMobilePort && '6px',
          }}
          p={isMobilePort ? 1 : null}
        >
          <Grid
            item
            xs={8}
            sm={8}
            md={8}
            pt={1}
            sx={chatListCard_style.gridcenter}
          >
            <Box sx={chatListCard_style.totalCardLeftSx}>
              <Grid container>
                <Grid
                  item
                  xs={3}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Avatar aria-label="recipe" sx={chatListCard_style.avatar}>
                    {userPic ? (
                      <img src={userPic} alt="" />
                    ) : (
                      <UserProfileIcon rootStyle={{ color: 'common.black' }} />
                    )}
                  </Avatar>
                </Grid>
                <Grid item xs={9} display="flex">
                  <Grid container alignSelf="center">
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        sx={{ fontWeight: 500 }}
                      >
                        {PatientName}
                      </Typography>
                    </Grid>
                    {subText && (
                      <Grid item xs={12} display="flex" justifyContent="start">
                        <Box
                          component="span"
                          style={chatListCard_style.subText}
                        >
                          {subText}
                          {age && (
                            <Box
                              component="span"
                              style={chatListCard_style.subText}
                            >
                              ,{age} Yrs
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {!isMobilePort && (
                <Grid container>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Avatar aria-label="recipe" sx={chatListCard_style.avatar}>
                      <PhoneIcon rootStyle={{ color: '#000' }} />
                    </Avatar>
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      alignSelf="center"
                      sx={{ fontWeight: 500 }}
                    >
                      {countyCode} {mobile}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box marginLeft="auto" marginRight="16px">
                <Button
                  id={`refer${index + 1}`}
                  buttonStyle={chatListCard_style.referCardSx}
                  onClick={onChatClick}
                >
                  {data?.consultation_status_name === 'OnGoing'
                    ? 'Chat'
                    : 'View'}
                </Button>
              </Box>
              {/* <Box>
                <IconButton
                  id={`more${index + 1}`}
                  sx={{ p: 0 }}
                  disableRipple
                  onClick={(e) => handleClick(e)}
                >
                  <RiMore2Fill
                    style={{
                      fontSize: '20px',
                      color: 'grey.800',
                      height: '18px',
                      '&:hover': {
                        backgroundColor: 'common.white',
                      },
                    }}
                  />
                </IconButton>
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

ChatListCard.propTypes = {
  PatientName: PropTypes.string,
  age: PropTypes.string,
  subText: PropTypes.string,
  mobile: PropTypes.string,
  userPic: PropTypes.string,
  border: PropTypes.bool,
  onChatClick: PropTypes.func,
  rootStyle: PropTypes.object,
  countyCode: PropTypes.object,
  index: PropTypes.any,
  data: PropTypes.object,
};

export { ChatListCard };
