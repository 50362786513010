export const summary_style = {
  backIconSx: {
    cursor: 'pointer',
    color: '#000',
    // backgroundColor:
  },
  titleSx: {
    color: '#000',
  },
  divideDottedSx: {
    my: 2,
    borderStyle: 'dashed',
    borderColor: 'divider',
  },
  dividerSx: {
    mx: 1.5,
    height: '25px',
    border: '0.5px solid',
    backgroundColor: 'grey.400',
    borderColor: 'grey.400',
  },
  summaryHeaderSx: {
    display: 'flex',
    justifyContent: 'space-between',
    mt: 2.5,
  },
  summaryRootSx: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    bgcolor: '#F5F5F5',
    pt: 1,
    px: 1,
    pb: 1.2,
    borderRadius: 1,
  },
  filterTabRootSx:{
    fontSize: '14px',
    fontWeight: 500,
    height: '20px',
    position: 'relative',
    zIndex: 1,
    color: '#3D3D3D',
    width: '49%',
    opacity: 1,
    borderRadius: '8px',
    '&.Mui-selected': {
      color: '#fff',
    },
  },
  viewSummaryTextSx: {
    cursor: 'pointer',
    color: 'primary.main',
    fontSize: '12px',
    textDecoration: 'underline',
    textAlign: 'left',
    font: ' normal normal 600 16px/25px ',
    textTransform: 'uppercase',
  },
  dialogtitleSx: {
    fontSize: 16,
    fontWeight: 600,
    padding: '0px',
  },
  dialogSx: {
    width: '600px',
  },
  contentSx: {
    pt: 0,
    pb: 0,
    pl: 0,
    pr: 0,
  },
  buttonSx: {
    border: '2px solid #007965',
    '&:hover': {
      border: '2px solid #007965',
    },
  },
  jitsiMeetMiniSx: {
    zIndex: 1,
    width: '350px',
    height: '250px',
    bottom: 20,
    right: 20,
    position: 'fixed',
  },
  jitsiMeetMaxSx: {
    zIndex: 1,
    width: '100%',
    position: 'absolute',
  },
  jitsiGridContainerMiniSx: {
    ml: 0,
    width: '100% ',
    pb: 1.5,
    borderRadius: '16px 16px 16px 16px',
    bgcolor: '#000',
    pt: 1.5,
  },
  jitsiGridContainerMaxSx: {
    ml: 0,
    width: '100% ',
    borderRadius: '0',
    bgcolor: '#000',
    pt: 1.5,
  },
  jitsiButtonMiniSx: {
    bgcolor: '#000',
    textTransform: 'lowercase',
    '&:hover': {
      bgcolor: '#000',
    },
  },
  jitsiButtonMaxSx: {
    bgcolor: '#424242',
    textTransform: 'lowercase',
    '&:hover': {
      bgcolor: '#424242',
    },
  },
};
