import { Button, DialogModal, Input } from '@atoms';
// import { useRxGroup } from '@hc/store/doctor/rxGroup';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { drugsGroupAddDialog_style } from './style';
import { Drawer } from '../../../../atoms';

function DrugsGroupAddDialog(props) {
  const {
    className = '',
    isModalOpen,
    handleClose,
    isEdit,
    rootStyle = {},
    handleChange,
    rxGroupSaveFnc,
    rxGroupUpdateFnc,
    openDrawer,
    drawerCloseFun,
    dialogueTrue,
    ...rest
  } = props;

  const [state, setState] = useState({ name: '', error: '' });

  // const { rxGroupState } = useRxGroup((state) => ({
  //   rxGroupState: state.rxGroupState,
  // }));
  // const { rxGroupName, error } = rxGroupState;

  const handleState = (value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    // HANDLE PROFILE DATA CAHNGE
    setState({ ...state, name: value });
  };

  const isIamValidToSave = () => {
    let isValid = true;

    // Checking name
    if (!state?.name) {
      isValid = false;
      setState({ ...state, error: 'Please enter RX group name' });
    } else {
      setState({ ...state, error: '' });
    }

    return isValid;
  };

  const rxSaveFnc = (Edit) => {
    const result = isIamValidToSave();
    if (result) {
      if (Edit) {
        rxGroupUpdateFnc(state?.name);
      } else {
        rxGroupSaveFnc(state?.name);
      }
    }
  };

  const handleCloseFnc = () => {
    handleClose();
    setState({ name: '', error: '' });
  };
  useEffect(() => {
    setState({ ...state, name: isEdit });
  }, [isEdit]);

  return (
    <Box
      sx={{
        ...drugsGroupAddDialog_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {dialogueTrue && (
        <DialogModal
          title={isEdit ? 'RENAME RX GROUP' : 'CREATE RX GROUP'}
          titleStyle={drugsGroupAddDialog_style.titleSx}
          maxModalWidth="xl"
          topDivider
          bottomDivider
          dialogRootStyle={drugsGroupAddDialog_style.dialogSx}
          content={
            <>
              <Typography mb={1.5} fontWeight={600}>
                RX Group Name
              </Typography>
              <Input
                placeholder="Enter RX Group name"
                id="rxGroupName"
                onChange={(e) => {
                  handleState(e.target.value, 20);
                }}
                isError={state?.error}
                errorMessage={state?.error}
                value={state?.name}
              />
            </>
          }
          actions={
            <Grid p={1} container display="flex" justifyContent="end">
              <Grid item md={1.5} lg={1.5} sm={1.5} xl={1.5}>
                <Button
                  // onClick={() => (isEdit ? rxGroupUpdateFnc() : rxGroupSaveFnc())}
                  onClick={() => rxSaveFnc(isEdit)}
                >
                  {isEdit ? 'Update' : 'save'}
                </Button>
              </Grid>
            </Grid>
          }
          isDialogOpened={isModalOpen}
          handleCloseDialog={() => handleCloseFnc()}
        />
      )}

      {!dialogueTrue && (
        <Drawer
          show={openDrawer}
          header="Add New Rx Group"
          drawerRightClose
          onCloseDrawer={drawerCloseFun}
          headerStyle={{
            fontSize: '16px',
            fontWeight: 600,
            color: '#101010',
            textTransform: 'capitalize',
          }}
          rootStyle={drugsGroupAddDialog_style.drawerSx}
          closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={drawerCloseFun}
                buttonStyle={drugsGroupAddDialog_style.cancelBtnSx}
              >
                CANCEL
              </Button>
              <Button
                onClick={() => rxSaveFnc(isEdit)}
                buttonStyle={drugsGroupAddDialog_style.applyBtnSx}
              >
                {isEdit ? 'Update' : 'Save'}
              </Button>
            </Box>
          }
        >
          <Box sx={{ height: '15vh' }}>
            <Typography mb={1.5} fontWeight={600}>
              RX Group Name
            </Typography>
            <Input
              placeholder="Enter the Group name.....!"
              id="rxGroupName"
              onChange={(e) => {
                handleState(e.target.value, 20);
              }}
              isError={state?.error}
              errorMessage={state?.error}
              value={state?.name}
            />
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

DrugsGroupAddDialog.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  isEdit: PropTypes.string,
  handleChange: PropTypes.any,
  rxGroupSaveFnc: PropTypes.any,
  rxGroupUpdateFnc: PropTypes.any,
  openDrawer: PropTypes.any,
  drawerCloseFun: PropTypes.any,
  dialogueTrue: PropTypes.any,
};

export { DrugsGroupAddDialog };
