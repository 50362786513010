export const homepage_style = {
  tabStyleSx: {
    backgroundColor: 'transparent',
    width: ' 100%',
    margin: '0px auto',
    display: 'flex',
    alignItems: 'center',
    mr: 2,
    borderBottom: '2px solid #EBFAF7',
    '& .MuiTabs-root': {
      minHeight: 0,
    },
  },

  bannerRootSx: {
    pl: 2,
    pr: 2.5,
    boxSizing: 'border-box',
    pt: '70px',
    pb: 2,
    backgroundColor: '#fff',
    position: 'fixed',
    top: 0,
    zIndex: '10',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },
  filterBoxSx: {
    height: 40,
    width: 40,
    borderRadius: 1,
    border: '1px solid #E3E3E3',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  underTabStyleSx: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#007965',
      textAlign: 'left',
    },
    '& .MuiTabs-flexContainer': {
      display: 'grid',
      gridTemplateColumns: '155px 155px 155px 120px',
      boxSizing: 'border-box',
      mx: 0.5,
      padding: 1,

      textAlign: 'left',
    },
  },

  numberCountSx: {
    width: '24px',
    height: '24px',
    fontSize: '14px',
  },
  tabSx: {
    textTransform: 'capitalize',
    minHeight: 0,
    textAlign: 'left',
    color: '#808080',
    fontWeight: 500,
    '&.Mui-selected': {
      textAlign: 'left',
      fontSize: '14px',
      fontWeight: 600,
    },
  },
  searchFiledSx: {
    '& .MuiOutlinedInput-root': {
      py: 0.3,
      color: '#C5C5C5',
      borderRadius: '8px',
      borderColor: '#DBEAE8',
      '&.Mui-focused fieldset': {
        borderColor: '#DBEAE8',
        color: '#0E1824',
        borderRadius: '8px',
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      color: '#0E1824',
    },
  },
  subHeadSx: {
    fontSize: '16px',
    color: '#888888',
    // p: 5.5,
    fontWeight: '25px',
    textAlign: 'center',
  },

  switchSx: {
    '& .MuiSwitch-track': {
      backgroundColor: '#fff',
      opacity: 1,
      border: 0,
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#fff',
      opacity: 1,
    },
  },
  switchFont: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#FFFFFF',
    mr: 1,
  },
};
