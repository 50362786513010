import { TrashIcon } from '@atoms';
import { getTimeFormat } from '@hc/dayjs';
import { useAvailability } from '@hc/store';
import { NotificationPopup } from '@hc/ui/components/clinical';
import { tweleveHourTimeformetter } from '@hc/utils';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import isEqual from 'react-fast-compare';
import { dateOverrideCard_style } from './style';

function DateOverrideCard(props) {
  const { className = '', data = {}, rootStyle = {}, index, ...rest } = props;

  const [alertOpen, setAlertOpen] = useState();

  const { deleteAvailability } = useAvailability(
    (state) => ({
      deleteAvailability: state.deleteAvailability,
    }),
    (prev, curr) => {
      isEqual(prev, curr);
      return false;
    },
  );

  const openPopUp = () => {
    setAlertOpen(true);
  };

  const onClose = () => {
    setAlertOpen(false);
  };

  const onDelete = () => {
    deleteAvailability(true, false, index);
    setAlertOpen(false);
  };

  return (
    <Box
      sx={{
        ...dateOverrideCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid container justifyContent="space-between">
        <Grid item display="flex">
          <Typography fontWeight={500}>
            {getTimeFormat(data?.startDate, 'MMM DD, yyyy')}
          </Typography>
        </Grid>
        <Grid item>
          <Box sx={{ cursor: 'pointer' }} onClick={openPopUp}>
            <TrashIcon
              rootStyle={{ width: '18px', height: '19px' }}
              id="deleteOverride"
            />
          </Box>
          <NotificationPopup
            open={alertOpen}
            subTitleStyle={{
              fontWeight: 500,
            }}
            subTitle="Are you sure, You want delete this override slot ?"
            acceptButtonName="Yes"
            declineButtonName="Cancel"
            notifyBtnStyletwo={{
              bgcolor: 'primaryTints.200',
              color: 'primary.main',
              '&:hover': {
                bgcolor: 'primaryTints.200',
              },
            }}
            notifyBtnStyle={{
              bgcolor: 'primary.main',
              color: 'common.white',
            }}
            onDecline={onClose}
            onAccpet={() => {
              onDelete();
            }}
          />
        </Grid>
      </Grid>

      {data?.timeSlots.length > 0 &&
        data?.timeSlots?.map((val, i) => (
          <Box key={i}>
            {val?.fromTime && val?.toTime && data?.isUnavailable === false && (
              <Typography fontWeight={500} color="#808080" mt={1}>
                {`${tweleveHourTimeformetter(
                  val?.fromTime,
                )} - ${tweleveHourTimeformetter(val?.toTime)}`}
              </Typography>
            )}
          </Box>
        ))}

      {data?.isUnavailable === true && (
        <Typography fontWeight={500} color="#808080" mt={1}>
          Unavailable
        </Typography>
      )}
    </Box>
  );
}

DateOverrideCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  index: PropTypes.number,
  data: PropTypes.object,
};

export { DateOverrideCard };
