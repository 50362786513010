/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-plusplus */
import { ForwardIcon, PreviousIcon } from '@atoms';
import { useAvailability } from '@hc/store';
import { formatDate } from '@hc/utils';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import isEqual from 'react-fast-compare';
import { dateRangePicker_style } from './style';

export default function CustomDateRangePicker(props) {
  const {
    className = '',
    rootStyle = {},
    selectedDate,
    setSelectedDate,
    ...rest
  } = props;
  const [year, setYear] = useState(new Date().getFullYear());

  const { setOverRideFromAndToDate } = useAvailability(
    (state) => ({
      setOverRideFromAndToDate: state.setOverRideFromAndToDate,
    }),
    (prev, curr) => {
      isEqual(prev, curr);
      return false;
    },
  );

  const handleDateClick = (date) => {
    const fromDate = date
      ? `${formatDate(date.toDateString())}${'T00:00:00+05:30'}`
      : '';

    const toDate = date
      ? `${formatDate(date.toDateString())}${'T23:59:59+05:30'}`
      : '';
    setOverRideFromAndToDate(fromDate, toDate);
    setSelectedDate(date);
  };

  const handleMonthClick = (month, currentYear) => {
    setYear(currentYear);
    setSelectedDate(null);
    const newDate = new Date(currentYear, month, 1);
    setSelectedDate(newDate);
  };

  const renderCalendar = () => {
    const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    const monthsOfYear = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const selectCurrentdate = new Date();
    const currentDate = selectedDate ? new Date(selectedDate) : new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    const monthDates = [];
    for (let i = 1; i <= daysInMonth; i++) {
      monthDates.push(new Date(currentYear, currentMonth, i));
    }

    const firstDayOfMonth = monthDates[0].getDay();
    const lastDayOfMonth = monthDates[monthDates.length - 1].getDay();

    const prevMonthDates = [];
    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      prevMonthDates.push(new Date(currentYear, currentMonth, 0 - i));
    }

    const nextMonthDates = [];
    for (let i = lastDayOfMonth + 1; i <= 6; i++) {
      nextMonthDates.push(
        new Date(currentYear, currentMonth + 1, i - lastDayOfMonth),
      );
    }

    const allDates = [...prevMonthDates, ...monthDates, ...nextMonthDates];

    return (
      <Box className="calendar">
        <Grid container px={2.5} pb={1} justifyContent="space-between">
          <Grid item>
            <Typography color="#4D4D4D" fontWeight={500}>
              {monthsOfYear[currentMonth]}{' '}
              {selectedDate ? new Date(selectedDate).getFullYear() : year}
            </Typography>
          </Grid>
          <Grid item display="flex">
            <Box
              pr={1}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleMonthClick(currentMonth - 1, currentYear)}
            >
              <PreviousIcon />
            </Box>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => handleMonthClick(currentMonth + 1, currentYear)}
            >
              <ForwardIcon />
            </Box>
          </Grid>
        </Grid>
        <Box p={2.5}>
          <Grid container columns={7} columnSpacing={5} pl={1} py={1.2}>
            {daysOfWeek.map((day, i) => (
              <Grid item key={i} xs={1}>
                <Box>
                  <Typography fontWeight={500} color="#808080">
                    {day}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid container columns={7} columnSpacing={4} pb={1.4}>
            {allDates.map((date, i) => (
              <Grid pb={1.2} sx={{ cursor: 'pointer' }} item key={i} xs={1}>
                <Box
                  sx={
                    selectedDate
                      ? date.toDateString() ===
                        new Date(selectedDate).toDateString()
                        ? dateRangePicker_style.selectedDateSx
                        : dateRangePicker_style.unSelectedDateSx
                      : dateRangePicker_style.unSelectedDateSx
                  }
                >
                  <Box
                    sx={
                      date.toDateString() === selectCurrentdate.toDateString()
                        ? dateRangePicker_style.currentDateSx
                        : dateRangePicker_style.unSelectedDateSx
                    }
                  >
                    <Box sx={{ display: 'flex', alignSelf: 'center' }}>
                      <Typography
                        id={date.getDate()}
                        fontWeight={500}
                        key={date.getTime()}
                        sx={
                          date.getMonth() === currentMonth
                            ? selectedDate
                              ? date.toDateString() ===
                                new Date(selectedDate).toDateString()
                                ? dateRangePicker_style.isSelectSx
                                : dateRangePicker_style.isCurrentMonthSx
                              : ''
                            : selectedDate
                            ? date.toDateString() ===
                              new Date(selectedDate).toDateString()
                              ? dateRangePicker_style.isSelectSx
                              : dateRangePicker_style.isNotCurrentMonthSx
                            : dateRangePicker_style.isNotCurrentMonthSx
                        }
                        className={
                          selectedDate &&
                          date.toDateString() ===
                            new Date(selectedDate).toDateString()
                            ? 'selected'
                            : ''
                        }
                        onClick={() => handleDateClick(date, i)}
                      >
                        {date.getDate()}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        ...dateRangePicker_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box>
        {renderCalendar()}
        {/* {selectedDate.toDateString()} */}
        {/* date data stored on {selectedDate.toDateString()} */}
      </Box>
    </Box>
  );
}

CustomDateRangePicker.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func,
};
