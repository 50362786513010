/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
import { Button, ShareReport } from '@atoms';
import { Box, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { modelPopup_style } from './style';

export function ModelPopup(props) {
  const {
    notifyBtnStyle = {},
    totalBoxStyle = {},
    notifyBtnsStyle = {},  
    subTitleStyle = {},
    icons,
    setOpen,
    loading,
    notifyBtnStyletwo = {},
    // handleClose = () => false,
    subTitle = '',
    open = false,
    icon = false,   
    className = '',  
    acceptButtonName = '',
    declineButtonName = '',
    onAccpet,
    onDecline,
    ...rest
  } = props;

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modelPopup_style.totalModalSx}>
        <Box className={`${className}`} {...rest}>
          <Box sx={{ ...modelPopup_style.totalBoxSx, ...totalBoxStyle }}>
            <Box pt={1}>{icons}</Box>
            {icon && (
              <ShareReport rootStyle={modelPopup_style.notifyIconSx} />
            )}
            <Typography
              sx={{ ...modelPopup_style.subTitleSx, ...subTitleStyle }}
            >
              {subTitle}
            </Typography>
            <Box sx={modelPopup_style.totalBtnSx}>
              <Button
                buttonStyle={{
                  ...modelPopup_style.notifyBtnSx,
                  backgroundColor: 'error.main',
                  ...notifyBtnStyletwo,
                }}
                onClick={onDecline}
              >
                {/* Decline */}
                {declineButtonName}
              </Button>
              <Button
                buttonStyle={{
                  ...modelPopup_style.notifyBtnSx,
                  backgroundColor: 'primary.main',
                  ...notifyBtnsStyle,
                }}
                onClick={onAccpet}
                // loading={loading}
              >
                {/* Accept */}
                {acceptButtonName}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
ModelPopup.propTypes = {
  notifyBtnStyle: PropTypes.object,
  open: PropTypes.bool,
  totalBoxStyle: PropTypes.object,
  onDecline: PropTypes.func,
  onAccpet: PropTypes.func,
  subTitle: PropTypes.string,
  className: PropTypes.string,
  acceptButtonName: PropTypes.string,
  declineButtonName: PropTypes.string,
  icon: PropTypes.bool,
  notifyBtnStyletwo: PropTypes.object,  
  notifyBtnsStyle : PropTypes.object,
  subTitleStyle : PropTypes.object,
  icons: PropTypes.any,
  setOpen: PropTypes.any,
  loading: PropTypes.any,
};
