/* eslint-disable no-restricted-syntax */
import { Button, DialogModal } from '@atoms';
import { useAvailability, useViewPort } from '@hc/store';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { AddDateOverrideDialog } from '../addDateOverrideDialog';
import { DateOverrideCard } from '../dateOverrideCard';
import { dateOverrideList_style } from './style';

function DateOverrideList(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  // General hooks

  const {
    setOverrideDatesInitialState,
    overrideDatesInitialState,
    setStartTime,
    setEndTime,
    createAvailability,
    setUnavailabilty,
    setOverrideDate,
    overrideDates,
  } = useAvailability(
    (state) => ({
      overrideDates: state.overrideDates,
      setOverrideDate: state.setOverrideDate,
      setUnavailabilty: state.setUnavailabilty,
      createAvailability: state.createAvailability,
      setOverrideDatesInitialState: state.setOverrideDatesInitialState,
      overrideDatesInitialState: state.overrideDatesInitialState,
      setStartTime: state.setStartTime,
      setEndTime: state.setEndTime,
    }),
    (prev, curr) => {
      isEqual(prev, curr);
      return false;
    },
  );

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [istimeValid, setIsTimeValid] = useState(true);
  const [isValid, setIsValid] = useState(true);

  const onCheckClick = () => {
    setUnavailabilty();
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
    const overRideobj = {
      startDate: '',
      endDate: '',
      isUnavailable: false,
      timeSlots: [
        {
          fromTime: '',
          toTime: '',
        },
      ],
    };
    setOverrideDatesInitialState(overRideobj);
  };

  const getFromTime = (startTime, childIndex) => {
    setIsValid(childIndex);
    if (childIndex === 0) {
      setIsTimeValid(true);
      setStartTime(startTime, childIndex);
    } else if (childIndex > 0) {
      if (
        overrideDatesInitialState?.timeSlots[childIndex - 1].toTime < startTime
      ) {
        setIsTimeValid(true);
        setStartTime(startTime, childIndex);
      } else {
        setIsTimeValid(false);
        toast.error("Enter the time greater than the previous slot 'to' time");
      }
    }
  };

  const getToTime = (endTime, childIndex) => {
    setIsValid(childIndex);
    if (overrideDatesInitialState?.timeSlots[childIndex].fromTime < endTime) {
      setIsTimeValid(true);
      setEndTime(endTime, childIndex);
    } else {
      setIsTimeValid(false);
      toast.error('Please enter the totime greaterthen from fromtime..!!! ');
    }
  };

  const onApply = () => {
    let isValid = true;
    let isNotSameDate = true;
    for (const data of overrideDatesInitialState?.timeSlots) {
      if (data?.fromTime === '' || data?.toTime === '') {
        isValid = false;
      }
    }

    for (const val of overrideDates) {
      if (val?.startDate === overrideDatesInitialState?.startDate) {
        for (const data of val?.timeSlots) {
          for (const value of overrideDatesInitialState?.timeSlots) {
            if (
              data?.fromTime > value?.fromTime ||
              (data?.toTime < value?.fromTime &&
                data?.toTime < value?.toTime) ||
              (data?.fromTime > value?.toTime &&
                value?.fromTime < value?.toTime)
            ) {
              isNotSameDate = true;
            } else {
              isNotSameDate = false;
            }
          }
        }
      }
    }

    if (
      overrideDatesInitialState?.startDate &&
      overrideDatesInitialState?.endDate
    ) {
      if (isValid || overrideDatesInitialState?.isUnavailable === true) {
        if (isNotSameDate) {
          setOverrideDate();
          setIsModalOpen(false);
        } else {
          toast.error(
            'you have already override this day,so you have dont select the already overrided between time..!!',
          );
        }
      } else {
        toast.error('Please Override the date or select the unavilable..!!');
      }
    } else {
      toast.error('Please select the date..!!');
    }
  };

  const onAdd = (childIndex) => {
    createAvailability(true, childIndex);
  };

  return (
    <Box
      sx={{
        ...dateOverrideList_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Typography mb={1} fontWeight={600}>
        Add Overrides
      </Typography>
      <Typography mb={2} fontSize={14} color="#4d4d4d">
        Add dates when your availability changes from your weekly hours.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          buttonStyle={dateOverrideList_style.buttonSx}
          onClick={handleOpen}
          variant="outlined"
        >
          Add a date override
        </Button>
      </Box>

      <DialogModal
        title={
          isMobilePort
            ? ' Add date override'
            : 'SELECT THE DATE(S) AND ASSIGN YOUR PREFERRED HOURS'
        }
        isBackRequired={isMobilePort ? true : false}
        titleStyle={dateOverrideList_style.titleSx}
        maxModalWidth="md"
        isDialogOpened={isModalOpen}
        handleCloseDialog={handleClose}
        contentRootStyle={dateOverrideList_style.contentSx}
        topDivider
        bottomDivider
        content={
          <AddDateOverrideDialog
            isAvailable={overrideDatesInitialState?.isUnavailable}
            selectedDate={selectedDate}
            istimeValid={istimeValid}
            isValid={isValid}
            getToTime={getToTime}
            getFromTime={getFromTime}
            onCheckClick={onCheckClick}
            setSelectedDate={setSelectedDate}
            overRideData={overrideDatesInitialState?.timeSlots}
            onAdd={onAdd}
          />
        }
        dialogRootStyle={dateOverrideList_style.dialogSx}
        closeStyle={{
          width: '16px',
          height: '16px',
          display: isMobilePort ? 'none' : 'block',
        }}
        DialogActionsboxShadow={{ boxShadow: '0px 0px 10px #efefef' }}
        actions={
          <Grid
            p={1}
            container
            justifyContent={isMobilePort ? 'center' : 'end'}
          >
            <Grid
              item
              xs={isMobilePort ? 12 : 2.5}
              md={2.5}
              sm={isMobilePort ? 12 : 2.5}
            >
              <Button onClick={onApply}>
                {isMobilePort ? 'ADD OVERRIDE' : 'Apply'}
              </Button>
            </Grid>
          </Grid>
        }
      />
      <Box mt={5}>
        {overrideDates?.length > 0 &&
          overrideDates?.map((val, index) => (
            <Box key={index} pb={3}>
              <DateOverrideCard data={val} index={index} />
            </Box>
          ))}
      </Box>
    </Box>
  );
}

export { DateOverrideList };

DateOverrideList.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};
