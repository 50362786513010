/* eslint-disable no-restricted-syntax */
import { useSummary } from '@hc/store';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { CustomTextArea } from '../../../atoms';
import {
  AddSummaryIcon,
  SummaryCloseIcon,
  SummaryEditIcon,
  SummaryTickIcon,
  SummaryTrashIcon,
} from '../../../atoms/icons/iconSet9';
import { summaryAdvice_style } from './style';

function SummaryAdvice(props) {
  const {
    summaryView = false,
    summaryModelView = false,
    summaryTitle = '',
    addTitle = '',
    className = '',
    rootStyle = {},
    ...rest
  } = props;

  // general Hooks
  const [inputShown, setInputShown] = useState(false);
  const [text, setText] = useState('');
  const [isEditIndex, setIsEditIndex] = useState(null);

  const { summaryState, addData, updateData, deleteData } = useSummary(
    (state) => ({
      summaryState: state.summaryState,
      addData: state.addData,
      updateData: state.updateData,
      deleteData: state.deleteData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { adviceData, pastSummaryData } = summaryState;
  const { advice } = pastSummaryData;

  // Input open Common Func
  const commonOpenFun = () => {
    setInputShown(!inputShown);
  };

  // Input close icon
  const cancelInput = () => {
    setInputShown(false);
    setIsEditIndex(null);
    // CLEAR TEXT STATE DATA
    setText('');
  };

  // Input Value change func
  const handleChange = (value) => {
    setText(value);
  };

  // ADD or UPDATE ADVICE FUNCTION
  const handleAdd = () => {
    let result;
    const wordLength = text.split(' ');
    for (const val of wordLength) {
      if (val.length > 25) {
        result = false;
      } else {
        result = true;
      }
    }
    if (result) {
      if (text && text?.length > 0) {
        if (isEditIndex !== null) {
          // UPDATE ADVICE
          updateData('adviceData', isEditIndex, text);
        } else {
          // ADD ADVICE
          addData('adviceData', text);
        }

        setInputShown(false);
        // CLEAR TEXT STATE DATA
        setText('');
        setIsEditIndex(null);
      } else {
        toast.error('Please enter advice!');
      }
    } else {
      toast.error('Please check space between the words on notes!');
    }
  };

  // Input Value Edit Func
  const onEdit = (index) => {
    setIsEditIndex(index);
    const findValue = adviceData?.filter((val, i) => i === index)?.[0];
    setText(findValue);
    setInputShown(true);
  };

  // DELETE ADIVE FUNCTION
  const onDelete = (i) => {
    // DELETE ADVICE
    deleteData('adviceData', i);
  };

  return (
    <Box
      sx={{
        ...summaryAdvice_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {/* Summary Title */}
      <Typography sx={summaryAdvice_style.addSummaryTitleSx}>
        {summaryTitle}
      </Typography>
      {!summaryModelView && !summaryView && (
        <>
          {/* Input Text Data Mapping */}
          {Array.isArray(adviceData) && adviceData?.length > 0
            ? adviceData?.map((val, index) => {
                return (
                  <Box key={index}>
                    <Box sx={summaryAdvice_style.inputValueSx}>
                      <Typography sx={summaryAdvice_style.inputTextSx}>
                        {val}
                      </Typography>
                      <Box sx={summaryAdvice_style.inputValueIconSx}>
                        <IconButton
                          disableRipple
                          onClick={() => onDelete(index)}
                        >
                          <SummaryTrashIcon />
                        </IconButton>
                        <IconButton disableRipple onClick={() => onEdit(index)}>
                          <SummaryEditIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider sx={summaryAdvice_style.dividerSx} />
                  </Box>
                );
              })
            : null}
          {/* Add Summary Icon With Text */}
          {!inputShown && (
            <Box
              sx={summaryAdvice_style.inputTextShowSx}
              onClick={() => commonOpenFun()}
            >
              <IconButton disableRipple>
                <AddSummaryIcon />
              </IconButton>
              <Typography sx={summaryAdvice_style.summaryTitlesx}>
                {addTitle}
              </Typography>
            </Box>
          )}
          {inputShown && (
            <Box sx={summaryAdvice_style.inputBoxSx}>
              <CustomTextArea
                fullWidth
                rows={1.8}
                rowsMax={5}
                textFieldStyle={{
                  width: '100%',
                  bgcolor: '#fff',
                }}
                sx={{
                  width: '100%',
                }}
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
                value={text}
              />
              <IconButton disableRipple onClick={() => cancelInput()}>
                <SummaryCloseIcon />
              </IconButton>
              <IconButton disableRipple onClick={() => handleAdd()}>
                <SummaryTickIcon />
              </IconButton>
            </Box>
          )}
        </>
      )}

      {summaryView && (
        <Box>
          {Array.isArray(advice) && advice?.length > 0 ? (
            <Box sx={summaryAdvice_style.boxCardSx}>
              {advice?.map((val, i) => {
                return (
                  <Typography sx={summaryAdvice_style.valTextSx} key={i}>
                    <RiCheckboxBlankCircleFill
                      style={{ fontSize: '4px', ml: 1, mr: 0.5 }}
                    />
                    &nbsp; {val ?? ''}
                  </Typography>
                );
              })}
            </Box>
          ) : (
            <Box sx={summaryAdvice_style.boxCardSx}>
              <Typography sx={summaryAdvice_style.valTextSx}>
                No advice found!
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {summaryModelView && (
        <Box>
          {Array.isArray(adviceData) && adviceData?.length > 0 ? (
            <Box sx={summaryAdvice_style.boxCardSx}>
              {adviceData?.map((val, i) => {
                return (
                  <Typography sx={summaryAdvice_style.valTextSx} key={i}>
                    <RiCheckboxBlankCircleFill
                      style={{ fontSize: '4px', ml: 1, mr: 0.5 }}
                    />
                    &nbsp; {val ?? ''}
                  </Typography>
                );
              })}
            </Box>
          ) : (
            <Box sx={summaryAdvice_style.boxCardSx}>
              <Typography sx={summaryAdvice_style.valTextSx}>
                No advice found!
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

SummaryAdvice.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  summaryTitle: PropTypes.string,
  addTitle: PropTypes.string,
  summaryView: PropTypes.bool,
  summaryModelView: PropTypes.bool,
};

export { SummaryAdvice };
