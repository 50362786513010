/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useDoctorReport } from '@hc/store';
import { Loader, ViewSummaryReportsEmptyState } from '@hc/ui/atoms';
import { ReportViewer, Screenlayout } from '@hc/ui/components';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { ViewReportPage_style } from './style';

export default function ViewReportPage(props) {
  const { className = '' } = props;

  const { loading, reportState } = useDoctorReport(
    (state) => ({
      reportState: state.reportState,
      getPatientReport: state.getPatientReport,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  return (
    <Screenlayout
      title="view Report"
      className={`${className}`}
      backRequired
      childrenStyle={{
        minHeight: '60vh',
        pt: {
          xs: 10,
        },
      }}
    >
      <>
        {loading && (
          <Box sx={ViewReportPage_style.rootSx}>
            <Box alignSelf="center">
              <Loader />
            </Box>
          </Box>
        )}
        <Box SX={loading ? { display: 'none' } : ''}>
          {reportState?.viewReportData?.length > 0 ? (
            <ReportViewer />
          ) : (
            <Box height="100vh" justifyContent="center" display="flex">
              <Box alignSelf="center">
                <Box pb={2} justifyContent="center" display="flex">
                  <ViewSummaryReportsEmptyState />
                </Box>
                <Typography fontSize={12} color="#888888">
                  Preview of PDF will appear here
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </>
    </Screenlayout>
  );
}
ViewReportPage.propTypes = {
  className: PropTypes.string,
};
