import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import {
  AppointmentHeaders,
  Button,
  PhoneIcon,
  ScheduleCalender,
  UserProfileIcon,
  UserSettingIcon,
} from '../../../atoms';
import { rxAuditRequsetCard_style } from './style';

function RxAuditRequsetCard(props) {
  const {
    className = '',
    rootStyle = {},
    data = {},
    onViewRequest = () => {},
    tabIndex,
    index,
    enableLoading,
    loading,
    btnId,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...rxAuditRequsetCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={rxAuditRequsetCard_style.cardSx}>
        <Grid container direction="row" justify="center" spacing={1}>
          <Grid
            sx={rxAuditRequsetCard_style.gridcenterSx}
            item
            sm={2.5}
            xs={12}
          >
            <AppointmentHeaders
              profileIcon={
                <UserSettingIcon
                  rootStyle={{ height: '16px', width: '16px' }}
                />
              }
              title={
                <span
                  style={{ marginLeft: '10px' }}
                >{`${data?.patient_gender} , ${data?.patient_age} Yrs`}</span>
              }
            />
          </Grid>
          <Grid
            sx={rxAuditRequsetCard_style.gridcenterSx}
            item
            sm={2.5}
            xs={12}
          >
            <AppointmentHeaders
              profileIcon={<PhoneIcon />}
              title={`${data?.patient_country_code} ${data?.patient_mobile_no}`}
            />
          </Grid>
          <Grid
            sx={rxAuditRequsetCard_style.gridcenterSx}
            item
            sm={2.5}
            xs={12}
          >
            <AppointmentHeaders
              profileIcon={<ScheduleCalender />}
              title={data?.appointment_type}
            />
          </Grid>
          <Grid
            sx={rxAuditRequsetCard_style.gridcenterSx}
            item
            sm={2.5}
            xs={12}
          >
            <AppointmentHeaders
              profileIcon={<UserProfileIcon />}
              title={`Dr .${data?.doctor_profile?.first_name ?? ''}`}
            />
          </Grid>

          <Grid sx={rxAuditRequsetCard_style.gridcenterSx} item sm={2}>
            <Button
             id={`${tabIndex === '1' ? 'View' : 'log'}-${btnId}`}
              type="submit"
              buttonStyle={rxAuditRequsetCard_style.buttonSx}
              onClick={() => onViewRequest(data, index)}
              variant="outlined"
              color="primary"
              fontSize="14px"
              loading={enableLoading === index ? loading : false}
            >
              {tabIndex === '1' ? 'View' : 'View log'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

RxAuditRequsetCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  data: PropTypes.object,
  onViewRequest: PropTypes.func,
  tabIndex: PropTypes.any,
  index: PropTypes.any,
  enableLoading: PropTypes.any,
  loading: PropTypes.any,
  btnId: PropTypes.string,
};

export { RxAuditRequsetCard };
