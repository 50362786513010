/* eslint-disable no-unused-vars */
import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import create from 'zustand';

export const useDoctorBySpeciality = create((set, get) => ({
  doctorBySpecialityData: [],
  loading: null,
  error: null,
  getDoctorBySpecialityData: async (specialityId) => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status, data, error, isFetching } = await queryClient.fetchQuery([
        '/doctor/get-doctor-by-speciality',
        'post',
        {
          speciality: specialityId,
        },
      ]);
      const doctorBySpecialityDataCopy = [];
      if (Array.isArray(data) && data?.length > 0) {
        data.map((val) => {
          doctorBySpecialityDataCopy.push({
            value: val?.id ?? '',
            label: val?.first_name ?? '',
            user_id: val?.user_id ?? '',
          });
        });
      }
      return set((state) => ({
        loading: false,
        doctorBySpecialityData: doctorBySpecialityDataCopy,
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  clearState: () => {
    set(() => ({
      doctorBySpecialityData: [],
    }));
  },
}));
