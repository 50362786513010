import { doctorRoutes } from '@hc/routes';
import { useViewPort } from '@hc/store';
import { useRxGroup } from '@hc/store/doctor/rxGroup';
import { Button } from '@hc/ui/atoms';
import {
  DoctorScreenLayout,
  DrugsAddDialog,
  DrugsGroupAddDialog,
  DrugsGroupList,
  DrugsImportDialog,
  DrugsMainContent,
  RxGroupEmptyState,
  Screenlayout,
} from '@hc/ui/components';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { rx_group_style } from './style';

export default function RxGroup(props) {
  const { className = '' } = props;
  const navigate = useNavigate();
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const keydata = parseJwt(authToken);
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrugAddModalOpen, setIsDrugAddModalOpen] = useState(false);
  const [isDrugImportModalOpen, setIsDrugImportModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const {
    rxGroupState,
    getRXGroup,
    updateRxGroupError,
    addRxGroup,
    updateRxGroup,
    handleRxGroupDataChange,
    getRxGroupDrugDataFnc,
    handleRxGroupEditData,
  } = useRxGroup((state) => ({
    rxGroupState: state.rxGroupState,
    getRXGroup: state.getRXGroup,
    updateRxGroupError: state.updateRxGroupError,
    addRxGroup: state.addRxGroup,
    updateRxGroup: state.updateRxGroup,
    handleRxGroupDataChange: state.handleRxGroupDataChange,
    getRxGroupDrugDataFnc: state.getRxGroupDrugDataFnc,
    handleRxGroupEditData: state.handleRxGroupEditData,
  }));
  const { rxGroupData, rxGroupId, rxGroupStaus } = rxGroupState;

  const handleClose = async () => {
    setIsModalOpen(false);
    setIsEdit(null);
  };

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const handleEditOpen = (data) => {
    handleRxGroupEditData(
      data?.rx_data?.rx_group_name,
      data?.rx_data?.id,
      data?.rx_data?.is_active
    );
    setIsEdit(data?.rx_data?.rx_group_name);
    setIsModalOpen(true);
  };
  const handleAddOpen = () => {
    // handleRxGroupEditData('', '', null);
    setIsModalOpen(true);
  };
  const drawerCloseFun = () => {
    // handleRxGroupEditData('', '', null);
    setOpenDrawer(false);
  };

  const drugAddFnc = async (type) => {
    await handleRxGroupDataChange('isAddDrug', type === 'add' ? true : null);
    navigate(doctorRoutes?.Prescription, {
      state: {
        fromPage: 'rxGroup',
      },
    });
  };
  const handleDrugAddModalClose = () => {
    setIsDrugAddModalOpen(false);
  };

  const handleDrugImportModalOpen = () => {
    setIsDrugImportModalOpen(true);
  };
  const handleDrugImportModalClose = () => {
    setIsDrugImportModalOpen(false);
  };

  const handleChange = (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    // HANDLE PROFILE DATA CAHNGE
    handleRxGroupDataChange(key, value);
  };

  const isIamValidToSave = () => {
    const rxGroupStateCopy = JSON.parse(JSON.stringify(rxGroupState));

    let isValid = true;
    const error = rxGroupStateCopy?.error;

    // Checking name
    if (!rxGroupStateCopy?.rxGroupName) {
      isValid = false;
      error.rxGroupName = 'Please enter RX Group name.';
    } else {
      error.rxGroupName = '';
    }
    // UPDATE RX-GROUP ERROR
    updateRxGroupError(error);
    return isValid;
  };

  const rxGroupSaveFnc = async (name) => {
    // const result = isIamValidToSave();
    // if (result) {
    const payload = {
      doctor_id: keydata?.user_id ?? '',
      rx_group_name: name ?? '',
    };

    const addRes = await addRxGroup(payload, true);
    if (addRes === 200) {
      handleRxGroupDataChange('isAddDrug', true);
      handleRxGroupDataChange('drugRxGroupData', []);

      navigate(doctorRoutes?.Prescription, {
        state: {
          fromPage: 'rxGroup',
        },
      });
    }
    // }
  };

  const rxGroupUpdateFnc = async (name) => {
    const result = isIamValidToSave();
    if (result) {
      const payload = {
        doctor_id: keydata?.user_id ?? '',
        rx_group_id: rxGroupId ?? '',
        rx_group_name: name ?? '',
        is_active: rxGroupStaus ?? false,
      };

      const addRes = await addRxGroup(payload, false);
      if (addRes === 200) {
        setIsModalOpen(false);
        setIsEdit(null);
        await getRXGroup(keydata?.user_id, '');
      }
    }
  };

  const onStatusChange = async (val, objValue) => {
    if (val?.target?.checked === false) {
      const payload = {
        doctor_id: keydata?.user_id ?? '',
        rx_group_id: objValue?.rx_data?.id ?? '',
        rx_group_name: objValue?.rx_data?.rx_group_name ?? '',
        is_active: val?.target?.checked ?? false,
      };

      const updateRes = await updateRxGroup(payload);
      if (updateRes === 200) {
        await getRXGroup(keydata?.user_id, '');
      }
    } else if (objValue?.drug_count > 0) {
      const payload = {
        doctor_id: keydata?.user_id ?? '',
        rx_group_id: objValue?.rx_data?.id ?? '',
        rx_group_name: objValue?.rx_data?.rx_group_name ?? '',
        is_active: val?.target?.checked ?? false,
      };

      const updateRes = await updateRxGroup(payload);
      if (updateRes === 200) {
        await getRXGroup(keydata?.user_id, '');
      }
    } else {
      toast.error('Add atleast one drug to enable');
    }
  };

  const handleBoxClickFnc = async (val) => {
    await getRxGroupDrugDataFnc(val?.rx_data?.id, val?.rx_data?.rx_group_name);
    if (isMobilePort)
      navigate(doctorRoutes?.viewDrug, {
        state: { drugName: val.rx_data.rx_group_name },
      });
  };

  const handleSearchClear = async () => {
    setSearch('');
    await getRXGroup(keydata?.user_id, '');
  };

  const setOnSearchFnc = async (val) => {
    setSearch(val);
    await getRXGroup(keydata?.user_id, val);
  };

  const initialCall = async () => {
    await getRXGroup(keydata?.user_id, '');
  };

  useEffect(() => {
    initialCall();
  }, []);

  return (
    <>
      {!isMobilePort && (
        <DoctorScreenLayout
          titleRequired
          title="RX Group"
          className={`class ${className}`}
          backRequired
          backRoute={doctorRoutes?.home}
          titleStyle={rx_group_style.titleSx}
          backIconStyle={rx_group_style.backIconSx}
          rightSideContainerRootStyle={{ px: 3, py: 2 }}
          leftSideContainerRootStyle={{
            ...(rxGroupData?.length === 0 && search?.length === 0
              ? { display: 'none' }
              : { display: '' }),
          }}
          leftChildren={
            Array.isArray(rxGroupData) && rxGroupData?.length > 0 ? (
              <DrugsGroupList
                data={rxGroupData ?? []}
                handleEditOpen={handleEditOpen}
                handleAddOpen={handleAddOpen}
                onChange={onStatusChange}
                handleBoxClickFnc={handleBoxClickFnc}
                setOnSearchFnc={setOnSearchFnc}
                search={search}
                handleSearchClear={handleSearchClear}
              />
            ) : Array.isArray(rxGroupData) &&
              rxGroupData?.length === 0 &&
              search?.length > 0 ? (
              <DrugsGroupList
                data={[]}
                handleEditOpen={handleEditOpen}
                handleAddOpen={handleAddOpen}
                onChange={onStatusChange}
                handleBoxClickFnc={handleBoxClickFnc}
                setOnSearchFnc={setOnSearchFnc}
                search={search}
                handleSearchClear={handleSearchClear}
              />
            ) : null
          }
          rightChildren={
            <Box>
              {Array.isArray(rxGroupData) && rxGroupData?.length > 0 ? (
                <DrugsMainContent
                  onAdd={drugAddFnc}
                  handleDrugImportModalOpen={handleDrugImportModalOpen}
                />
              ) : (
                <RxGroupEmptyState
                  content="Create a group of drugs to provide 
              prescription easily during consultation"
                  buttonName="Add Rx Group"
                  onAdd={() => setIsModalOpen(true)}
                />
              )}
            </Box>
          }
          rightSideContainer={
            Array.isArray(rxGroupData) &&
            rxGroupData?.length === 0 &&
            search?.length === 0
              ? 12
              : 8
          }
          leftSideContainer={
            Array.isArray(rxGroupData) &&
            rxGroupData?.length &&
            (search?.length === 0) === 0
              ? 0
              : 4
          }
        />
      )}

      {isMobilePort && (
        <Screenlayout
          isDoctorAppbar
          title="RX Group"
          titleStyle={{ color: '#0E1824' }}
          className={`${className}`}
          backRequired
          childrenStyle={{
            minHeight: '60vh',

            pt: {
              xs: 10,
            },
          }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={() => setOpenDrawer(true)}
                buttonStyle={rx_group_style.buttonSx}
              >
                ADD RX GROUP
              </Button>
            </Box>
          }
        >
          <Box p={0.5}>
            {Array.isArray(rxGroupData) && rxGroupData?.length > 0 ? (
              <DrugsGroupList
                cardResponse={isMobilePort}
                data={rxGroupData ?? []}
                handleEditOpen={handleEditOpen}
                handleAddOpen={handleAddOpen}
                onChange={onStatusChange}
                handleBoxClickFnc={handleBoxClickFnc}
                setOnSearchFnc={setOnSearchFnc}
                search={search}
                handleSearchClear={handleSearchClear}
              />
            ) : Array.isArray(rxGroupData) &&
              rxGroupData?.length === 0 &&
              search?.length > 0 ? (
              <DrugsGroupList
                data={[]}
                handleEditOpen={handleEditOpen}
                handleAddOpen={handleAddOpen}
                onChange={onStatusChange}
                handleBoxClickFnc={handleBoxClickFnc}
                setOnSearchFnc={setOnSearchFnc}
                search={search}
                handleSearchClear={handleSearchClear}
              />
            ) : (
              <RxGroupEmptyState
                content="Create a group of drugs to provide 
        prescription easily during consultation"
                buttonName="Add Rx Group"
                onAdd={() => setIsModalOpen(true)}
              />
            )}
          </Box>
        </Screenlayout>
      )}

      <DrugsGroupAddDialog
        isModalOpen={isModalOpen}
        handleClose={handleClose}
        isEdit={isEdit}
        handleChange={handleChange}
        rxGroupSaveFnc={rxGroupSaveFnc}
        rxGroupUpdateFnc={rxGroupUpdateFnc}
        openDrawer={openDrawer}
        drawerCloseFun={drawerCloseFun}
        dialogueTrue={openDrawer ? false : true}
      />

      <DrugsAddDialog
        isModalOpen={isDrugAddModalOpen}
        handleClose={handleDrugAddModalClose}
      />

      <DrugsImportDialog
        isModalOpen={isDrugImportModalOpen}
        handleClose={handleDrugImportModalClose}
      />
    </>
  );
}

RxGroup.propTypes = {
  className: PropTypes.string,
};
