import { Button, DialogModal } from '@atoms';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Input } from '../../../../atoms';
import { prescriptionAddNotes_style } from './style';

function PrescriptionAddNotes(props) {
  const {
    className = '',
    rootStyle = {},
    isModalOpen,
    handleClose,
    prescriptionNotesInstruction = '',
    handleNotesInstructionSave,
    ...rest
  } = props;

  const [value, setValue] = useState(prescriptionNotesInstruction);

  return (
    <Box
      sx={{
        ...prescriptionAddNotes_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <DialogModal
        title="ADD NOTES"
        titleStyle={prescriptionAddNotes_style.titleSx}
        maxModalWidth="xl"
        topDivider
        bottomDivider
        dialogRootStyle={prescriptionAddNotes_style.dialogSx}
        content={
          <Stack py={2}>
            <Typography sx={prescriptionAddNotes_style.description}>
              Notes / Instructions
            </Typography>
            <Box mt={1}>
              <Input
                multiline
                placeholder="Type the notes to be followed while taking drugs"
                value={value ?? ''}
                id="prescriptionNotesInstruction"
                onChange={(e) => setValue(e.target.value)}
              />
            </Box>
          </Stack>
        }
        actions={
          <Grid p={1} spacing={1} container display="flex" justifyContent="end">
            <Grid item>
              <Button onClick={() => handleNotesInstructionSave(value)}>
                SAVE
              </Button>
            </Grid>
          </Grid>
        }
        isDialogOpened={isModalOpen}
        handleCloseDialog={handleClose}
      />
    </Box>
  );
}

PrescriptionAddNotes.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isModalOpen: PropTypes.any,
  handleClose: PropTypes.any,
  prescriptionNotesInstruction: PropTypes.any,
  handleNotesInstructionSave: PropTypes.any,
};

export { PrescriptionAddNotes };
