import { getDateFormat } from '@hc/dayjs';
import { useLabReport, useSummary } from '@hc/store';
import { Box, Grid, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  CustomTextArea,
  Label,
  SearchIcon,
  SearchSelect
} from '../../../atoms';
import { QusAnsSelectionLisiting } from '../../qusAnsSelectionListing';
import CustomDateRangePicker from '../doctorAvailability/availabiltyDatePicker';
import { addLabTests_style } from './style';

function AddLabTests(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  const { labReportData, getLabReportData } = useLabReport((state) => ({
    labReportData: state.labReportData,
    getLabReportData: state.getLabReportData,
  }));

  const {
    addLabtest,
    labTestDetails,
    deleteLabData,
    clearPageData,
    updateLabTestDetails,
    // summaryState,
    // loading,
  } = useSummary((state) => ({
    addLabtest: state.addLabtest,
    // summaryState: state.summaryState,
    deleteLabData: state.deleteLabData,
    clearPageData: state.clearPageData,
    labTestDetails: state.labTestDetails,
    updateLabTestDetails: state.updateLabTestDetails,
    // loading: state.loading,
  }));
  const { labTestArray, labTestDate, note } = labTestDetails;

  const [searchInput, setSearchInput] = useState(null);
  const [addLabTest, setAddLabTest] = useState('');

  const filter = createFilterOptions();

  const onDateChange = (value) => {
    updateLabTestDetails('labTestDate', value);
  };

  // ADD LAB TEST FUNCTION
  const handleAdd = (e, option) => {
    // ADD LAB TEST
    addLabtest(e, option);
    // GET MEDICATION DATA
    getLabReportData();
    setSearchInput({ value: '', label: '', labelShow: '' });
    setAddLabTest('');
  };

  // DELETE FUNCTION
  const handleDelete = (i) => {
    // DELETE
    deleteLabData(i);
  };

  // CLEAR FUNCTION
  const handleClear = () => {
    // CLEAR
    clearPageData();
  };

  const handleChange = (e) => {
    updateLabTestDetails('note', e.target.value);
  };

  const initialData = async () => {
    await getLabReportData();
  };

  const getFilteredadata = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.title);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        value: inputValue,
        labelShow: inputValue,
        label: `ADD ${inputValue}`,
      });
    }
    return filtered;
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box
      sx={{
        ...addLabTests_style.rootSx,
        ...rootStyle,
        pt: 0,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box style={{ marginTop: '-20px' }}>
        <QusAnsSelectionLisiting
          QusAnsSelectionHeading=""
          QusAnsListingHeading="Medications"
          QusAnsListingAdded="Added"
          OnClearAll={handleClear}
          displaySelectionValues={labTestArray}
          OnDelete={handleDelete}
          sugesstionClick={handleAdd}
          qsOptionViewKey="label"
          QusAnsSelectionListing={
            <SearchSelect
              startAdornment={
                <SearchIcon sx={{ color: 'text.hint', fontSize: '16px' }} />
              }
              placeholder="Enter lab test name"
              onChange={handleAdd}
              onChangeText={(e) => setAddLabTest(e?.target?.value ?? '')}
              renderOption={(props, option) => {
                return (
                  <Box
                    sx={{ cursor: 'pointer' }}
                    height="40px"
                    onClick={(e) => handleAdd(e, option)}
                    px={2}
                    key={option.label}
                    display="flex"
                    {...props}
                  >
                    {option?.label?.includes('ADD') ? (
                      <Box display="flex" alignSelf="center">
                        <Typography
                          sx={{
                            color: '#EB6D13',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}
                        >
                          +ADD &nbsp;
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                          }}
                        >
                          {addLabTest}
                        </Typography>
                      </Box>
                    ) : (
                      <Box display="flex" alignSelf="center">
                        <Typography>{option.label}</Typography>
                      </Box>
                    )}
                  </Box>
                );
              }}
              filterOptions={(options, params) =>
                getFilteredadata(options, params)
              }
              optionViewKey="label"
              value={searchInput}
              options={
                Array.isArray(labReportData) && labReportData?.length > 0
                  ? labReportData
                  : []
              }
            />
          }
        />
      </Box>

      <Box mt={3}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: 'text.primary',
              mb: 1,
            }}
          >
            When to take test?
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: 'primary.main',
              mb: 1,
            }}
          >
            {labTestDate ? getDateFormat(labTestDate, 'YYYY-MM-DD') : ''}
          </Typography>
        </Box>
        <Grid container columnSpacing={2}>
          <CustomDateRangePicker
            selectedDate={labTestDate}
            setSelectedDate={onDateChange}
          />
        </Grid>
      </Box>

      <Box mt={4}>
        <Label
          htmlFor="Notes/Instructions"
          rootStyle={addLabTests_style.commonTextColorSx}
        >
          Notes / Instructions
        </Label>
        <CustomTextArea
          textFieldStyle={{
            borderRadius: '12px',
            width: '100%',
          }}
          fullWidth
          rows={2.5}
          rowsMax={5}
          onChange={handleChange}
          value={note}
        />
      </Box>
    </Box>
  );
}

AddLabTests.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { AddLabTests };

