/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import { AppBar, HealthCirclesLogo } from '@atoms';
import {
  FooterPatientIcon,
  ChatMessageIcon,
  FooterShareIcon,
  HealthCircleIcon,
} from '@atoms/icons';
import { useViewPort } from '@hc/store';
import { localStorageKeys } from '@hc/utils';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { doctorlayout_style } from './style';
import { useNavigate } from 'react-router-dom';
import { doctorRoutes } from '@hc/routes';

export function Layout(props) {
  const {
    bodyStyle,
    className = '',
    rootStyle,
    appBarStyle,
    children,
    isRxAudit,
    menuItems,
    isDoctorVerfied,
    appBarFirstChild,
    appBarFirstChildRootStyle,
    appBarSecChild,
    appBarSecChildRootStyle,
    handleHome,
    ...rest
  } = props;

  const { isMobilePort, setValue, homeTabIndex } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
    setValue: state.setValue,
    homeTabIndex: state.homeTabIndex,
  }));

  const navigate = useNavigate();

  const navItems = [
    {
      item: 'Appointments',
      icon: 'appointments',
    },
    {
      item: 'Referrals',
      icon: 'referrals',
    },
    {
      item: 'Patients',
      icon: 'patients',
    },
    {
      item: 'Chat',
      icon: 'userName',
    },
  ];

  const profileOpen = () => {
    navigate(doctorRoutes?.profileMobilePage);
  };

  const navIcons = {
    appointments: HealthCircleIcon,
    referrals: FooterShareIcon,
    patients: FooterPatientIcon,
    userName: ChatMessageIcon,
  };

  const onClick = (index) => {
    setValue('homeTabIndex', index.toString());
  };
  useEffect(() => {
    const homeTab = sessionStorage.getItem(localStorageKeys?.doctorResPage);
    setValue('homeTabIndex', homeTab === null ? '0' : homeTab);
  }, []);
  return (
    <Box
      sx={{ ...doctorlayout_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ ...doctorlayout_style.appBarSx, ...appBarStyle }}>
        <AppBar
          profileOpen={profileOpen}
          Organizationlogo={<HealthCirclesLogo />}
          handleHome={handleHome}
          organizationName="HealthCircles"
          OrganizationNotificationPopupTitle="Notification"
          menuItems={menuItems}
          anchorOriginStyle={doctorlayout_style.anchorStyleSx}
          transformOriginStyle={{
            ...(!isMobilePort && { vertical: 'top', horizontal: 'right' }),
          }}
          isRxAudit={isRxAudit}
          menuStyle={doctorlayout_style.menuSx}
          menuItemStyle={doctorlayout_style.menuItemSx}
          menuIconStyle={doctorlayout_style.drawerSx}
          version={
            isRxAudit && localStorage.getItem(localStorageKeys.rxAuditVersion)
              ? localStorage.getItem(localStorageKeys.rxAuditVersion)
              : localStorage.getItem(localStorageKeys.doctorVersion)
              ? localStorage.getItem(localStorageKeys.doctorVersion)
              : '0.0.0'
          }
          navStyle={doctorlayout_style.navSx}
        />
      </Box>
      {isRxAudit && isMobilePort && appBarFirstChild && (
        <Box
          sx={{
            ...doctorlayout_style.app1stRootSx,
            ...appBarFirstChildRootStyle,
          }}
        >
          {appBarFirstChild && <Box>{appBarFirstChild}</Box>}
        </Box>
      )}
      {isRxAudit && isMobilePort && appBarSecChild && (
        <Box
          sx={{
            ...doctorlayout_style.app2ndRootSx,
            ...appBarSecChildRootStyle,
          }}
        >
          {appBarSecChild && <Box>{appBarSecChild}</Box>}
        </Box>
      )}
      <Box
        sx={{
          ...(isMobilePort ? ' ' : doctorlayout_style.bodySx),
          ...bodyStyle,
        }}
      >
        {children}
      </Box>

      {!isRxAudit && (
        <Box>
          {isDoctorVerfied && (
            <Box>
              {isMobilePort && (
                <Box sx={{ ...doctorlayout_style.footerNavBarSx }}>
                  {navItems.map((navItem, i) => {
                    const Icon = navIcons[navItem.icon];
                    return (
                      <Box
                        key={i}
                        sx={doctorlayout_style.navItemSx}
                        onClick={() => {
                          onClick(i);
                        }}
                        id={navItem.item}
                      >
                        <Icon
                          rootStyle={{
                            color:
                              homeTabIndex == i ? '#007965' : 'text.secondary',
                          }}
                        />
                        <Typography
                          variant="body1"
                          color="initial"
                          sx={{
                            ...doctorlayout_style.navItemTextSx,
                            color:
                              homeTabIndex == i ? '#007965' : 'text.secondary',
                          }}
                        >
                          {navItem.item}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

Layout.propTypes = {
  menuItems: PropTypes.array,
};
