export const chatViewCard_style = {
  rootSx: { position: 'relative' },
  contentSx: {
    pt: 2,
    pb: 0,
    pl: 0,
    pr: 0,
  },
  titleSx: {
    fontSize: 16,
    fontWeight: 600,
    padding: '0px',
  },
  speedDialRootSx: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    bgcolor: '#007965',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  dialogSx: {
    width: '469px',
  },
  avatar: {
    background: '#F5F5F5',
  },
  subText: {
    textTransform: 'capitalize',
    color: '#0F0B117A',
    fontSize: '14px',
    fontWeight: 500,
  },

  totalCardLeftSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  overAllSx: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: '1px dashed',
    borderColor: '#808080',
    pt: 1,
    pb: 3,
  },
  referCardSx: {
    fontSize: '14px',
    backgroundColor: 'transparent',
    color: 'primary.main',
    textTransform: 'capitalize',
    border: '1px solid ',
    borderColor: 'primary.main',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'primary.main',
      boxShadow: 'none',
    },
  },
  totalCardRightSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  totalPatientCardSx: {
    // py: 3,
  },
  viewSx: {
    width: '100px',
  },
  documentSx: {
    color: 'orangeTints.400',
    width: '23px',
    height: '23px',
  },
  viewSummarySx: {
    '&: hover': {
      backgroundColor: '#fff5ef',
    },
  },
  nameSx: {
    width: '280px',
  },
  gridcenter: {
    justifyContent: 'center',
  },
  gridcenterTotal: {
    // border: '1px solid #000',
    mt: 2,
    pb: 3,
  },
  totalBoxSx: {
    mt: 2,
    mb: 3,
  },
  morelistSx: {
    '& .MuiPopover-paper': {
      boxShadow: ' 0px 25px 50px #0000001F',
      border: '1px solid #F5F5F5',
      borderRadius: '6px',
    },
    '& .MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list': {
      padding: '0px',
    },
  },
  borderSx: {
    fontSize: '12px',
    fontWeight: 600,
    margin: 0,
    color: 'error.main',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'common.white',
    },
  },
  cancelAppointment: {
    ml: 0.9,
  },
  subHeadSx: {
    fontSize: '16px',
    color: '#888888',
    // p: 5.5,
    fontWeight: '25px',
    textAlign: 'center',
  },
};
