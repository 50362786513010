import { Button, Drawer } from '@atoms';
import { Box, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { prescriptionDeleteModal_style } from './style';
import { DeleteCircleIcon } from '../../../../atoms';

function PrescriptionDeleteModal(props) {
  const {
    className = '',
    isModalOpen,
    handleClose,
    handleDelete,
    drugName,
    isMobileResponsive,
    rootStyle = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...prescriptionDeleteModal_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {!isMobileResponsive && (
        <Modal open={isModalOpen} onClose={handleClose}>
          <Box sx={prescriptionDeleteModal_style.totalModalSx}>
            <Box sx={prescriptionDeleteModal_style.totalBoxSx}>
              <Box mt={2}>
                <Typography sx={prescriptionDeleteModal_style.description}>
                  {' '}
                  Do you want to delete{' '}
                </Typography>
                <Typography sx={prescriptionDeleteModal_style.description}>
                  {drugName ?? ''}
                </Typography>

                <Box
                  mt={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'end',
                  }}
                >
                  <Button
                    sx={prescriptionDeleteModal_style.closebuttonSx}
                    onClick={handleClose}
                  >
                    CANCEL
                  </Button>
                  <Button
                    sx={prescriptionDeleteModal_style.DeletebuttonSx}
                    onClick={handleDelete}
                  >
                    DELETE
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}

      {isMobileResponsive && (
        <Drawer
          show={isModalOpen}
          onCloseDrawer={handleClose}
          rootStyle={prescriptionDeleteModal_style.drawerSx}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={handleClose}
                buttonStyle={prescriptionDeleteModal_style.cancelBtnSx}
              >
                CANCEL
              </Button>
              <Button
                onClick={handleDelete}
                buttonStyle={prescriptionDeleteModal_style.applyBtnSx}
              >
                DELETE
              </Button>
            </Box>
          }
        >
          <Box sx={{ height: '24vh' }}>
            <Box
              sx={{
                border: '0px solid',
                boxShadow: 24,
                backgrounColor: '#FFF',
                p: 3,
                ...(!isMobileResponsive &&
                  prescriptionDeleteModal_style.totalModalSx),
              }}
            >
              <Box>
                <Box sx={prescriptionDeleteModal_style.centerIconSx}>
                  <DeleteCircleIcon />
                </Box>
                <Typography sx={prescriptionDeleteModal_style.description}>
                  {' '}
                  Do you want to delete{' '}
                </Typography>
                <Typography sx={prescriptionDeleteModal_style.description}>
                  {drugName ?? ''}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

PrescriptionDeleteModal.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { PrescriptionDeleteModal };
