export const patientViewCard_style = {
  filterTotalSx: {},
  filterSx: {
    maxWidth: '75px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0',
      borderColor: 'transparent',
      borderRadius: 'none',
    },
    '&: hover': {
      borderColor: 'transparent',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      borderWidth: '0px',
    },
  },
  subHeadSx: {
    fontSize: '16px',
    color: '#888888',
    // p: 5.5,
    fontWeight: '25px',
    textAlign: 'center',
  },
};
