export const signIn_style = {
  rootSx: {
    bgcolor: '#F5F5F5',
  },
  CardcontentSx: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 8px 69px #0000001A',
    borderRadius: '12px',
    px: 4,
    py: 5,
    mt: 2.5,
    width: '435px',
  },
  CardcontentMobileSx: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 8px 69px #0000001A',
    borderRadius: '24px 24px 0px 0px',
    px: 2.5,
    py: 2.5,
    width: '386px',
    position: 'fixed',
    zIndex: 1,
    bottom: 0,
  },
  createPasswordSx: {
    fontSize: '20px',
    color: 'text.primary',
    textAlign: 'left',
    fontWeight: 600,
    py: 1.5,
  },
  hcLogoSx: {
    ml: -1.15,
  },
  forgotPasswordTextSx: {
    fontSize: '14px',
    color: 'primary.main',
    textAlign: 'right',
    fontWeight: 600,
    pt: 2,
    pb: 1.5,
    cursor: 'pointer',
  },
  inputGroupSx: {
    pt: 1.5,
    display: 'grid',
    gap: 0.5,
  },
  buttonStyleSx: {
    textTransform: 'capitalize',
  },
  eyeSx: {
    width: '20px',
    height: '20px',
  },
  totalSx: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    overflow: 'hidden',
  },
  leftSideSx: {
    height: '100vh',
    // display: { xs: 'none', sm: 'flex' },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  rightSideSx: {
    px: 2.5,
    pt: 4,
    pb: 5,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '24px',
  },

  signSx: {
    fontWeight: 600,
    fontSize: '14px',
    color: 'primary.main',
    opacity: 1,
    cursor: 'pointer',
    textDecoration: 'none',
  },

  googleStyle: {
    color: '#ACACAC',
    border: '1px solid',
    borderColor: 'grey.400',
    textTransform: 'none',
  },

  buttonsStyle: {
    textTransform: 'none',
  },

  titleSx: {
    color: 'text.primary',
    fontSize: '20px',
    fontWeight: 600,
  },

  forgotpasswordSx: {
    float: 'right',
    marginTop: '10px',
    fontWeight: 600,
    fontSize: '14px',
    cursor: 'pointer',
    color: 'primary.main',
    opacity: 1,
    textAlign: 'end',
    textDecoration: 'none',
  },
  rightImgSx: {
    height: '696px',
  },
  rightImgMobileSx: {
    height: '696px',
    marginBottom: '241px',
    opacity: '0.6',
  },

  imgSx: {
    width: '100%',
    maxWidth: '150px',
  },
  buttonBox: {
    display: 'grid',
    gap: 3,
    mt: 1,
  },
  labelStyle: {
    color: '#262C33',
    lineHeight: '1.4375em',
    fontWeight: 500,
  },
};
