export const doctorAppointmentCalender_style = {
  dateRootSx: {
    width: 36,
    height: 65,
    borderRadius: '24px',
    bgcolor: '#FAFAFA',
    // border:"1.5px solid #888888",
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
  },
  activeDateRootSx: {
    width: 36,
    height: 65,
    cursor: 'pointer',
    borderRadius: '24px',
    bgcolor: '#FEF6F0',
    border: '2px solid #EB6D13',
    display: 'flex',
    justifyContent: 'center',
  },
};
