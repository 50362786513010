export const referDialog_style = {
  titleSx: {
    color: 'common.black',
    fontSize: '16px',
    fontWeight: '600',
  },
  drawertitleSx: {
    color: '#0E1824',
    fontSize: '16px',
    fontWeight: '600',
  },
  firstSx: {
    fontSize: '16px',
    fontWeight: '500',
  },
  subSx: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#808080',
  },
  dialogSx: {
    width: '650px',
    // height: '550px',
  },
  customAboutSx: {
    '& .MuiOutlinedInput-input': {
      height: '76px!important',
      fontSize: '16px',
      fontWeight: 600,
      p: 0,
    },
  },
  referTotalSx: {
    pt: 3,
  },
  inputSx: {
    p: 3,
  },
  profileSx: {
    display: 'flex',
    alignItems: 'center',
  },
  proPicSX: {
    mr: 1.75,
  },
  addSx: {},
  listSx: {
    color: 'primary.main',
    fontSize: '14px',
    fontWeight: '600',
  },
  subListSx: { color: '#8f8f8f', fontSize: '12px', ml: 1.75 },
  buttonSx: {
    mr: 0.5,
    bgcolor: 'white',
    color: 'primary.main',
    borderRadius: '8px',
    '&:hover': {
      color: 'white',
    },
    border: '1px solid #007965',
  },
};
