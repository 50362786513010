export const prescriptionAddNotes_style = {
  titleSx: {
    fontSize: 16,
    fontWeight: 600,
  },
  description: {
    color: '#0E1824',
    fontSize: '16px',
    fontWeight: 600,
  },

  dialogSx: {
    width: { xs: '50vw', md: '40vw' },
  },
  buttonSx: {
    mr: 0.5,
    bgcolor: 'white',
    color: 'primary.main',
    borderRadius: '8px',
    '&:hover': {
      color: 'white',
    },
    border: '1px solid #007965',
    width: '100px',
  },

  toggleButtonActiveSx: {
    border: '1px solid',
    minWidth: '36px',
    minHeight: '40px',
    borderRadius: '10px',
  },
  toggleButtonInActiveSx: {
    border: '1px solid ',
    borderColor: '#E3E3E3',
    borderRadius: '10px',
    minWidth: '36px',
    minHeight: '40px',
  },
};
