export const doctor_availability_style = {
  buttonSx: {
    border: '2px solid #007965',
    '&:hover': {
      border: '2px solid #007965',
    },
  },
  boxRootSx: { p: 1 },
  backIconSx: {
    cursor: 'pointer',
    color: '#000',
  },
  titleSx: {},
  filterTabRootSx:{
    fontSize: '14px',
    fontWeight: 500,
    height: '20px',
    position: 'relative',
    zIndex: 1,
    color: '#3D3D3D',
    width: '49%',
    opacity: 1,
    borderRadius: '8px',
    '&.Mui-selected': {
      color: '#fff',
    },
  }
};
