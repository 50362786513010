import { getAppointmentTime, getDateFormat } from '@hc/dayjs';
import {
  Button,
  CalendarIcon,
  ClockIcon,
  ThreeDotsMenuIcon,
} from '@hc/ui/atoms';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { appointmentMobileCard_style } from './style';

function AppointmentMobileCard(props) {
  const {
    className = '',
    rootStyle = {},
    timeLapsed,
    resumeConsult,
    lastFiveMin,
    currentTime,
    timerStartTime,
    index,
    enableLoading,
    loading,
    viewSummaryClick,
    onClick,
    handleClick,
    appointmentCancelLoading,
    data,
    ...rest
  } = props;
  const [timer, setTimer] = useState();

  const selectedIndex = localStorage.getItem('index');

  useEffect(() => {
    const myIntervalll = setInterval(() => {
      if (
        getAppointmentTime(data?.appointment_time_slot, 'HH:mm:ss') <=
        currentTime
      ) {
        const countDownDate = new Date(data?.appointment_date_time).getTime();
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTimer(`${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => {
      clearInterval(myIntervalll);
    };
  }, [currentTime]);

  return (
    <Box
      sx={{
        ...appointmentMobileCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={appointmentMobileCard_style.cardRootSx}>
        <Grid p={2} container>
          <Grid xs={12} sm={12} md={12} item mb={2}>
            <Grid container>
              <Grid xs={2} sm={2} md={2} item>
                <Avatar
                  sx={{
                    height: '44px',
                    width: '44px',
                    borderRadius: '50%',
                    alignSelf: 'center',
                    bgcolor: '#888888',
                  }}
                >
                  {data?.name?.slice(0, 1) ?? 'A'}
                </Avatar>
              </Grid>
              <Grid xs={10} sm={10} md={10} item mt={0.2}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography fontSize={14} fontWeight={500}>
                          {data?.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {data?.master_appointment_status?.appointment_status ===
                          'canceled' ||
                        data?.master_appointment_status?.appointment_status ===
                          'timeLapsed' ||
                        data?.master_appointment_status?.appointment_status ===
                          'completed' ? (
                          <Box
                            sx={{
                              height: '21px',
                              bgcolor:
                                data?.master_appointment_status
                                  ?.appointment_status === 'completed'
                                  ? 'primary.lighter'
                                  : '#FFEBEC',
                              display: 'flex',
                              justifyContent: 'center',
                              px: 0.5,
                              borderRadius: '4px',
                            }}
                          >
                            <Typography
                              fontSize={12}
                              color={
                                data?.master_appointment_status
                                  ?.appointment_status === 'completed'
                                  ? 'primary.main'
                                  : '#F44F5A'
                              }
                              alignSelf="center"
                            >
                              {data?.master_appointment_status?.appointment_status
                                .charAt(0)
                                .toUpperCase() +
                                data?.master_appointment_status?.appointment_status.slice(
                                  1
                                )}
                            </Typography>
                          </Box>
                        ) : (
                          <ThreeDotsMenuIcon
                            onClick={(e) => handleClick(e, data)}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography fontSize={12} color="#888888">
                      {`${data?.gender} ${data?.age}Yrs`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} md={12} item>
            <Grid container>
              <Grid item xs={6} sm={6} md={6}>
                <Grid rowSpacing={0.5} container>
                  <Grid xs={12} sm={12} md={12} display="flex" item>
                    <Box>
                      <CalendarIcon
                        rootStyle={{ color: '#888888', height: 15, width: 14 }}
                      />
                    </Box>
                    <Box pl={0.5}>
                      <Typography color="#888888" fontSize={12}>
                        {data?.appointment_type_id === 1
                          ? 'Follow-Up'
                          : 'New Appointment'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} item display="flex">
                    <Box>
                      <ClockIcon rootStyle={{ color: '#888888' }} />
                    </Box>

                    <Box display="flex">
                      {getDateFormat(new Date(), 'YYYY-MM-DD') >=
                      data?.appointment_date ? (
                        data?.master_appointment_status?.appointment_status ===
                          'booked' ||
                        data?.master_appointment_status?.appointment_status ===
                          'InProgress' ? (
                          getAppointmentTime(
                            data?.appointment_time_slot,
                            'HH:mm:ss'
                          ) <= lastFiveMin ? (
                            <Typography pl={1} color="#25C460" fontSize={12}>
                              Live
                            </Typography>
                          ) : getAppointmentTime(
                              data?.appointment_time_slot,
                              'HH:mm:ss'
                            ) <= currentTime ? (
                            <Typography color="#25C460" pl={1} fontSize={12}>
                              {`in ${timer}`}
                            </Typography>
                          ) : (
                            <Typography
                              color="#888888"
                              pl={1}
                              fontSize={12}
                              px={0.5}
                            >
                              {getAppointmentTime(
                                data?.appointment_time_slot,
                                'HH:mm:ss'
                              )}
                            </Typography>
                          )
                        ) : (
                          <Typography
                            color="#888888"
                            pl={1}
                            fontSize={12}
                            px={0.5}
                          >
                            {getAppointmentTime(
                              data?.appointment_time_slot,
                              'HH:mm:ss'
                            )}
                          </Typography>
                        )
                      ) : (
                        <Typography
                          color="#888888"
                          pl={1}
                          fontSize={12}
                          px={0.5}
                        >
                          {getAppointmentTime(
                            data?.appointment_time_slot,
                            'HH:mm:ss'
                          )}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                {data?.master_appointment_status?.appointment_status ===
                'completed' ? (
                  <Button
                    type="submit"
                    buttonStyle={appointmentMobileCard_style.buttonSx}
                    onClick={() => viewSummaryClick(data, index)}
                    variant="outlined"
                    color="primary"
                    fontSize="14px"
                    loading={enableLoading === index ? loading : false}
                  >
                    View summary
                  </Button>
                ) : (
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => onClick(data, index)}
                  >
                    <Button
                      type="submit"
                      buttonStyle={appointmentMobileCard_style.buttonSx}
                      onClick={() => onClick(data, index)}
                      variant="outlined"
                      color="primary"
                      fontSize="14px"
                      loading={enableLoading === index ? loading : false}
                      disabled={
                        data?.master_appointment_status?.appointment_status ===
                          'booked' ||
                        data?.master_appointment_status?.appointment_status ===
                          'InProgress'
                          ? getAppointmentTime(
                              data?.appointment_time_slot,
                              'HH:mm:ss'
                            ) <= timeLapsed
                            ? false
                            : getAppointmentTime(
                                data?.appointment_time_slot,
                                'HH:mm:ss'
                              ) <= lastFiveMin
                            ? false
                            : getAppointmentTime(
                                data?.appointment_time_slot,
                                'HH:mm:ss'
                              ) <= currentTime
                            ? false
                            : true
                          : data?.master_appointment_status
                              ?.appointment_status === 'canceled'
                          ? true
                          : false
                      }
                    >
                      {data?.master_appointment_status?.appointment_status ===
                        'booked' ||
                      data?.master_appointment_status?.appointment_status ===
                        'InProgress'
                        ? getAppointmentTime(
                            data?.appointment_time_slot,
                            'HH:mm:ss'
                          ) <= timeLapsed
                          ? 'Add summary'
                          : data?.appointment_id === selectedIndex
                          ? 'continue'
                          : 'start consult'
                        : data?.master_appointment_status
                            ?.appointment_status === 'timeLapsed'
                        ? 'Add summary'
                        : data?.appointment_id === selectedIndex
                        ? 'continue'
                        : 'start consult'}
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {data?.appointment_type_id === 2 && (
          <Box
            height={27}
            sx={{ bgcolor: '#FFF6F0', borderRadius: '8px 0px 9px 9px' }}
            display="flex"
            justifyContent="center"
          >
            <Typography color="#EB6D13" fontSize={12} alignSelf="center">
              Referral
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

AppointmentMobileCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  viewSummaryClick: PropTypes.func,
  onClick: PropTypes.func,
  handleClick: PropTypes.func,
  appointmentCancelLoading: PropTypes.bool,
  data: PropTypes.object,
  timeLapsed: PropTypes.string,
  lastFiveMin: PropTypes.string,
  timerStartTime: PropTypes.string,
  currentTime: PropTypes.string,
  index: PropTypes.number,
  enableLoading: PropTypes.bool,
  loading: PropTypes.bool,
  resumeConsult: PropTypes.any,
};

export { AppointmentMobileCard };
