/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable func-names */
/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import { doctorRoutes } from '@hc/routes';
import {
  useDoctorAppointment,
  useDoctorBySpeciality,
  useSummary,
  useViewPort,
} from '@hc/store';
import {
  Button,
  DialogModal,
  DocumentColorIcon,
  MaximizeIcon,
  MinimizeIcon,
  SummaryCalendarIcon,
  VideoCallStartIcon,
} from '@hc/ui/atoms';

// import { getAppointmentTime } from '@hc/dayjs';
import { getDateFormat } from '@hc/dayjs';
import { useRxGroup } from '@hc/store/doctor/rxGroup';
import {
  FilterTab,
  JitsiMeetEmbed,
  PastReferNote,
  PatientSummary,
  Screenlayout,
  SummaryAdvice,
  SummaryFollowUp,
  SummaryLabTests,
  SummaryObservation,
  SummaryPrescription,
  SummaryRefer,
  ViewReferralNote,
  ViewSummaryModel,
} from '@hc/ui/components';
import { queryClient } from '@hc/utils';
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { summary_style } from './style';

export default function Summary(props) {
  const { className = '' } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [referralShown, setReferralShown] = useState(false);
  const [summaryView, setSummaryView] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkMulitiWindow, setCheckMulitiWindow] = useState(false);
  const [jitsiId, setJitsiId] = useState(0);
  const [startCall, setStartCall] = useState(false);
  const [minimizeMeetWindow, setMinimizeMeetWindow] = useState(false);
  const [showSummary, setShowSummary] = useState(true);
  const [resumeConsult, setResumeConsult] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const filterList = ['Patient Info', 'Summary'];

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const visitCloseSummaryModalClose = () => {
    setIsModalOpen(false);
  };
  const visitCloseSummaryModalOpen = () => {
    setIsModalOpen(true);
  };

  const onStartCall = () => {
    setStartCall(true);
    setShowSummary(false);
  };

  const meetMinimize = () => {
    setMinimizeMeetWindow(!minimizeMeetWindow);
    if (!minimizeMeetWindow) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  };

  const meetClose = () => {
    setStartCall(false);
    setShowSummary(true);
    setMinimizeMeetWindow(false);
    setJitsiId(jitsiId + 1);
  };

  const { clearState } = useDoctorBySpeciality(
    (state) => ({
      clearState: state.clearState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { clearRxGroupData, addSummaryDrugInStore } = useRxGroup((state) => ({
    clearRxGroupData: state.clearRxGroupData,
    addSummaryDrugInStore: state.addSummaryDrugInStore,
  }));

  const {
    summaryState,
    getReferralNoteData,
    getLocalStorage,
    getPastSummaryData,
    closeVisit,
    clearSummaryState,
    setUserHealthProblems,
    loading,
  } = useSummary(
    (state) => ({
      loading: state.loading,
      setUserHealthProblems: state.setUserHealthProblems,
      clearSummaryState: state.clearSummaryState,
      getLocalStorage: state.getLocalStorage,
      summaryState: state.summaryState,
      getReferralNoteData: state.getReferralNoteData,
      getPastSummaryData: state.getPastSummaryData,
      closeVisit: state.closeVisit,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const { getDoctorAppointmentData } = useDoctorAppointment(
    (state) => ({
      getDoctorAppointmentData: state.getDoctorAppointmentData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { appointmentData, prescriptionData } = summaryState;

  const referralClick = async (referralId) => {
    const referralResponse = await getReferralNoteData(referralId);
    if (referralResponse === 200) {
      clearState();
      setReferralShown(!referralShown);
    } else {
      toast.error('Something went wrong please try again!');
    }
  };
  const referralHandleClose = () => {
    setReferralShown(false);
  };

  const pastSummaryClick = async () => {
    const pastSummaryResponse = await getPastSummaryData();
    if (pastSummaryResponse === 200) {
      setSummaryView(true);
    } else {
      toast.error('Something went wrong please try again!');
    }
  };

  const backOnClick = () => {
    setSummaryView(false);
  };

  const visitCloseFnc = async () => {
    if (appointmentData?.doctorId === appointmentData?.bookedBy) {
      await setUserHealthProblems();
    }
    const closeVisitResponse = await closeVisit();

    if (closeVisitResponse === 200) {
      setResumeConsult(false);
      setIsModalOpen(false);
      clearSummaryState();
      navigate(doctorRoutes?.home);
      localStorage.removeItem('summaryState');
      localStorage.removeItem('index');
      localStorage.removeItem('startCall');
      queryClient.invalidateQueries({
        queryKey: ['doctorDashBoard'],
      });
      await getDoctorAppointmentData(true);
    } else {
      toast.error('Something went wrong please try again!');
    }
  };

  const addPrescription = async () => {
    clearRxGroupData();
    await addSummaryDrugInStore(prescriptionData);
    navigate(doctorRoutes?.Prescription, {
      state: {
        fromPage: 'summary',
      },
    });
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    getLocalStorage();
    const isContinue = localStorage.getItem('startCall');

    if (parseInt(isContinue) === 1) {
      setResumeConsult(true);
    } else {
      setResumeConsult(false);
    }

    if (location?.state?.isViewSummary) {
      setSummaryView(true);
    }
  }, []);

  useEffect(() => {
    if (!location?.state?.isViewSummary) {
      const bc = new BroadcastChannel('test_channel');
      bc.postMessage('This is a test message.');
      bc.onmessage = function (ev) {
        if (ev?.isTrusted === true) {
          setCheckMulitiWindow(true);
        } else {
          setCheckMulitiWindow(false);
        }
      };
    }
  }, []);

  return (
    <Screenlayout
      titleRequired
      title={
        isMobilePort ? appointmentData?.patientName : 'Consultation Summary'
      }
      className={`class ${className}`}
      backRequired
      fill="#E2E2E2"
      titleStyle={summary_style.titleSx}
      isDoctorAppbar
      notshowFooter={
        !isMobilePort ||
        tabIndex === 1 ||
        summaryState?.summaryType !== 'referral' ||
        summaryState?.summaryType !== 'followUp'
      }
      backIconStyle={summary_style.backIconSx}
      backRoute={doctorRoutes?.home}
      back={location?.state?.isViewSummary ? false : summaryView}
      backOnClick={backOnClick}
      visitClose={!summaryView}
      footer={
        <Box>
          {' '}
          {summaryState?.summaryType === 'referral' ? (
            <Button
              variant="outlined"
              buttonStyle={{
                border: '2px solid #007965',
                '&:hover': {
                  border: '2px solid #007965',
                },
              }}
              onClick={() => referralClick(appointmentData?.referralId)}
            >
              View Referral notes
            </Button>
          ) : summaryState?.summaryType === 'followUp' ? (
            <Button
              buttonStyle={{
                border: '2px solid #007965',
                '&:hover': {
                  border: '2px solid #007965',
                },
              }}
              variant="outlined"
              onClick={() =>
                pastSummaryClick(appointmentData?.follow_up_appointment)
              }
            >
              View Past Summary
            </Button>
          ) : null}
        </Box>
      }
      visitCloseFnc={visitCloseSummaryModalOpen}
    >
      <Box mt="-7px">
        {startCall && (
          <Box
            sx={
              minimizeMeetWindow
                ? summary_style.jitsiMeetMiniSx
                : summary_style.jitsiMeetMaxSx
            }
          >
            <Grid
              container
              display="flex"
              justifyContent="center"
              sx={
                minimizeMeetWindow
                  ? summary_style.jitsiGridContainerMiniSx
                  : summary_style.jitsiGridContainerMaxSx
              }
            >
              <Grid item pb={1.5}>
                <Button
                  startIcon={
                    !minimizeMeetWindow ? <MinimizeIcon /> : <MaximizeIcon />
                  }
                  onClick={meetMinimize}
                  size="small"
                  buttonStyle={
                    minimizeMeetWindow
                      ? summary_style.jitsiButtonMiniSx
                      : summary_style.jitsiButtonMaxSx
                  }
                >
                  {!minimizeMeetWindow ? 'Minimize' : 'Full screen'}
                </Button>
              </Grid>

              <Grid item sm={12} xs={12} sx={{ pl: '0px' }}>
                <JitsiMeetEmbed
                  onReadyToClose={meetClose}
                  minHeight="200px"
                  setResumeConsult={setResumeConsult}
                  doctor
                  apptId={appointmentData?.id}
                  maxHeight="85vh"
                  jitsiId={jitsiId}
                  roomId={appointmentData?.appoinmentId}
                  minimizeMeetWindow={minimizeMeetWindow}
                />
              </Grid>
            </Grid>
          </Box>
        )}

        {showSummary && (
          <Box sx={!showSummary ? { bgcolor: '#000' } : ''}>
            {!isMobilePort && (
              <Grid container display="flex">
                <Grid
                  item
                  sx={{ borderRight: '1px solid #E3E3E3' }}
                  xs={isMobilePort ? 12 : 4}
                  px={1.5}
                >
                  <PatientSummary
                    isDoctorAppoinment={
                      appointmentData?.doctorId === appointmentData?.bookedBy
                    }
                    summaryView={summaryView}
                  />
                </Grid>

                <Grid xs={isMobilePort ? 12 : 8}>
                  <Box
                    sx={{ overflowY: 'scroll', height: '100vh' }}
                    pb={minimizeMeetWindow ? 35 : ''}
                  >
                    {' '}
                    <Box px={3} pb={3}>
                      {summaryView && (
                        <Box sx={{ pt: 3 }}>
                          <PastReferNote referralShown />
                        </Box>
                      )}
                      <Box sx={summary_style.summaryHeaderSx}>
                        <Typography
                          sx={{
                            color: 'text.primary',
                            fontSize: '18px',
                            fontWeight: 600,
                          }}
                        >
                          Summary
                        </Typography>
                        {!summaryView && (
                          <Box>
                            {summaryState?.summaryType === 'referral' ? (
                              <Box sx={summary_style.summaryRootSx}>
                                <DocumentColorIcon />
                                <Typography
                                  onClick={() =>
                                    referralClick(appointmentData?.referralId)
                                  }
                                  sx={summary_style.viewSummaryTextSx}
                                >
                                  View Referral notes
                                </Typography>
                              </Box>
                            ) : summaryState?.summaryType === 'followUp' ? (
                              <Box sx={summary_style.summaryRootSx}>
                                <SummaryCalendarIcon />
                                <Typography
                                  onClick={() =>
                                    pastSummaryClick(
                                      appointmentData?.follow_up_appointment,
                                    )
                                  }
                                  sx={summary_style.viewSummaryTextSx}
                                >
                                  View Past Summary
                                </Typography>
                              </Box>
                            ) : null}
                          </Box>
                        )}
                      </Box>
                      <SummaryObservation
                        summaryView={summaryView}
                        addTitle="Add Observation"
                        summaryTitle="OBSERVATION"
                      />
                      <SummaryPrescription
                        summaryView={summaryView}
                        addTitle="Add Drugs / Rx Group"
                        summaryTitle="PRESCRIPTION"
                        addPrescription={addPrescription}
                      />
                      <SummaryLabTests
                        summaryView={summaryView}
                        addTitle="Add Lab Tests"
                        summaryTitle="LAB TESTS"
                      />
                      <SummaryAdvice
                        summaryView={summaryView}
                        addTitle="Add Advice"
                        summaryTitle="ADVICE"
                      />
                      <SummaryFollowUp
                        summaryView={summaryView}
                        addTitle="Add Follow-Up"
                        summaryTitle="FOLLOW-UP"
                      />
                      {!summaryView && (
                        <SummaryRefer
                          summaryView={summaryView}
                          addTitle="Refer to another doctor"
                          summaryTitle="REFERRED TO"
                        />
                      )}
                    </Box>
                    {summaryView && (
                      <Box px={2} py={2}>
                        <PastReferNote viewSummaryTitle="Refer" pastSummary />
                      </Box>
                    )}
                    <DialogModal
                      title="SUMMARY"
                      titleStyle={summary_style.dialogtitleSx}
                      maxModalWidth="md"
                      isDialogOpened={isModalOpen}
                      handleCloseDialog={visitCloseSummaryModalClose}
                      contentRootStyle={summary_style.contentSx}
                      topDivider
                      bottomDivider
                      content={<ViewSummaryModel isMobile={isMobilePort} />}
                      dialogRootStyle={summary_style.dialogSx}
                      closeStyle={{ width: '16px', height: '16px' }}
                      DialogActionsboxShadow={{
                        boxShadow: '0px 0px 10px #efefef',
                      }}
                      actions={
                        <Grid p={1} container justifyContent="end">
                          <Grid item md={2.5} lg={2.5} sm={2.5} xl={2.5}>
                            <Button
                              loading={loading}
                              onClick={() => visitCloseFnc()}
                            >
                              CONFIRM
                            </Button>
                          </Grid>
                        </Grid>
                      }
                    />
                    <Dialog
                      open={checkMulitiWindow}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      PaperProps={{
                        sx: { width: 650 },
                      }}
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Action cannot be done as the application still opens
                          in another tab. If you want to Continue refresh the
                          page to proceed.
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>
                  </Box>
                </Grid>
              </Grid>
            )}

            {isMobilePort && (
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 3 }}>
                  <Box p={1} width="100%">
                    <Box
                      sx={{
                        backgroundColor: '#EDEFF2',
                        borderRadius: '10px',
                        height: '40px',
                        width: '100%',
                      }}
                    >
                      <FilterTab
                        filterList={filterList}
                        handleChange={handleChange}
                        value={tabIndex}
                        filterTabRootStyle={summary_style.filterTabRootSx}
                        toggleStyle={{ height: '40px' }}
                      />
                    </Box>
                  </Box>
                </Box>
                {tabIndex === 0 && (
                  <Box px={3} pb={12}>
                    <PatientSummary
                      isDoctorAppoinment={
                        appointmentData?.doctorId === appointmentData?.bookedBy
                      }
                      summaryView={summaryView}
                    />
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box
                    sx={{ overflowY: 'scroll', height: '100vh' }}
                    pb={minimizeMeetWindow ? 35 : ''}
                  >
                    {' '}
                    <Box px={3} pb={3}>
                      {summaryView && (
                        <Box sx={{ pt: 3 }}>
                          <PastReferNote referralShown />
                        </Box>
                      )}
                      <Box sx={summary_style.summaryHeaderSx}>
                        <Typography
                          sx={{
                            color: 'text.primary',
                            fontSize: '18px',
                            fontWeight: 600,
                          }}
                        >
                          Summary
                        </Typography>
                      </Box>
                      <SummaryObservation
                        summaryView={summaryView}
                        addTitle="Add Observation"
                        summaryTitle="OBSERVATION"
                      />
                      <SummaryPrescription
                        summaryView={summaryView}
                        addTitle="Add Drugs / Rx Group"
                        summaryTitle="PRESCRIPTION"
                        addPrescription={addPrescription}
                      />
                      <SummaryLabTests
                        summaryView={summaryView}
                        addTitle="Add Lab Tests"
                        summaryTitle="LAB TESTS"
                      />
                      <SummaryAdvice
                        summaryView={summaryView}
                        addTitle="Add Advice"
                        summaryTitle="ADVICE"
                      />
                      <SummaryFollowUp
                        summaryView={summaryView}
                        addTitle="Add Follow-Up"
                        summaryTitle="FOLLOW-UP"
                      />
                      {!summaryView && (
                        <SummaryRefer
                          summaryView={summaryView}
                          addTitle="Refer to another doctor"
                          summaryTitle="REFERRED TO"
                        />
                      )}
                    </Box>
                    {summaryView && (
                      <Box px={2} py={2}>
                        <PastReferNote viewSummaryTitle="Refer" pastSummary />
                      </Box>
                    )}
                    <DialogModal
                      title="SUMMARY"
                      titleStyle={summary_style.dialogtitleSx}
                      maxModalWidth="md"
                      isDialogOpened={isModalOpen}
                      isBackRequired={isMobilePort ? true : false}
                      isCloseOut={isMobilePort ? false : true}
                      isMobileResponsive={isMobilePort}
                      handleCloseDialog={visitCloseSummaryModalClose}
                      contentRootStyle={summary_style.contentSx}
                      topDivider
                      bottomDivider
                      content={<ViewSummaryModel isMobile={isMobilePort} />}
                      dialogRootStyle={summary_style.dialogSx}
                      closeStyle={{ width: '16px', height: '16px' }}
                      DialogActionsboxShadow={{
                        boxShadow: '0px 0px 10px #efefef',
                      }}
                      actions={
                        <Grid
                          p={1}
                          container
                          columnSpacing={1.5}
                          justifyContent={isMobilePort ? 'space-around' : 'end'}
                        >
                          {isMobilePort && (
                            <Grid item xs={6}>
                              <Button
                                onClick={visitCloseSummaryModalClose}
                                variant="outlined"
                              >
                                Cancel
                              </Button>
                            </Grid>
                          )}
                          <Grid item xs={isMobilePort ? 6 : 2.5}>
                            <Button
                              loading={loading}
                              onClick={() => visitCloseFnc()}
                            >
                              CONFIRM
                            </Button>
                          </Grid>
                        </Grid>
                      }
                    />
                    <Dialog
                      open={checkMulitiWindow}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      PaperProps={{
                        sx: { width: 650 },
                      }}
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Action cannot be done as the application still opens
                          in another tab. If you want to Continue refresh the
                          page to proceed.
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>
                  </Box>
                )}
              </Box>
            )}

            {referralShown && (
              <Box>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <DialogModal
                      maxModalWidth="xl"
                      topDivider
                      bottomDivider
                      title="REFER NOTE"
                      isBackRequired={isMobilePort ? true : false}
                      isCloseOut={isMobilePort ? false : true}
                      isMobileResponsive={isMobilePort}
                      dialogRootStyle={{
                        width: '650px',
                      }}
                      titleStyle={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: 'text.primary',
                      }}
                      content={<ViewReferralNote />}
                      isDialogOpened={referralShown}
                      handleCloseDialog={referralHandleClose}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        )}
      </Box>

      {((!startCall &&
        getDateFormat(new Date(), 'YYYY-MM-DD') ===
          getDateFormat(appointmentData?.appointment_date_time, 'YYYY-MM-DD') &&
        appointmentData?.appointmentStatus === 'booked') ||
        (!startCall &&
          getDateFormat(new Date(), 'YYYY-MM-DD') ===
            getDateFormat(
              appointmentData?.appointment_date_time,
              'YYYY-MM-DD',
            ) &&
          appointmentData?.appointmentStatus === 'InProgress')) && (
        <Box
          sx={{
            position: 'fixed',
            inset: ' auto 40px 70px auto',
            zIndex: '2',
          }}
        >
          <Box
            sx={{
              position: 'fixed',
              zIndex: 2,
              bottom:
                (isMobilePort &&
                  tabIndex === 0 &&
                  summaryState?.summaryType === 'referral') ||
                summaryState?.summaryType === 'followUp'
                  ? 100
                  : 20,
              right: 20,
            }}
          >
            {isMobilePort ? (
              <Box
                sx={{
                  height: 50,
                  width: 50,
                  borderRadius: '50%',
                  bgcolor: '#007965',
                  display: 'flex',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={onStartCall}
              >
                <Box alignSelf="center">
                  <VideoCallStartIcon />
                </Box>
              </Box>
            ) : (
              <Button
                id="video"
                startIcon={<VideoCallStartIcon />}
                buttonStyle={{ width: 180 }}
                onClick={onStartCall}
              >
                {resumeConsult ? 'Continue call' : 'Start video call'}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Screenlayout>
  );
}

Summary.propTypes = {
  className: PropTypes.object,
};
