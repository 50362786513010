/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { doctorRoutes, rxAuditRoutes } from '@hc/routes';
import { useSummary } from '@hc/store';
import { Box, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  CougeIcon,
  Document,
  HeartIcon,
  HeartPulse,
  PainIcon,
  UserIcon,
} from '../../../atoms';
import { AppointmentChips } from '../../appointmentChips';
import { AppointmentDetails } from '../../appointmentDetails';
import { AppointmentList } from '../../appointmentList';
import { ReportDetails } from '../../reportDetails';
import { VitalDetails } from '../../vitalDetails';
import { SummaryChips } from '../summaryChips';
import { patientSummary_style } from './style';

function PatientSummary(props) {
  const {
    className = '',
    isDoctorAppoinment,
    summaryView,
    isRxAudit,
    isRxAuditData = {},
    rootStyle = {},
    ...rest
  } = props;
  const { summaryState, updatehealthData } = useSummary((state) => ({
    summaryState: state.summaryState,
    updatehealthData: state.updatehealthData,
  }));
  const navigate = useNavigate();
  const { appointmentData } = summaryState;
  const { vitals, patientId, patientType, id } = appointmentData;
  const [symptoms, setSymptoms] = useState([]);
  const [problems, setProblems] = useState([]);
  const [onSymptomsChangeData, setSymptomsOnChangeData] = useState('');
  const [onProblemsChangeData, setProblemsOnChangeData] = useState('');
  const [onHealthIssuesChangeData, setlthIssuesOnChangeData] = useState('');

  const setSymptomAndProblmData = () => {
    const symptomsData = isRxAudit
      ? isRxAuditData?.patient_symptoms
      : appointmentData?.user_symptoms;
    let arr = [];
    if (symptomsData) {
      for (const val of symptomsData) {
        if (!isDoctorAppoinment) {
          const obj = {
            label: val,
            value: val,
          };
          arr.push(obj);
        } else {
          arr.push(val);
        }
      }
    }

    for (const val of appointmentData?.symptom) {
      if (!isDoctorAppoinment) {
        const obj = {
          label: val?.label,
          value: val?.value,
        };
        arr.push(obj);
      } else {
        arr.push(val);
      }
    }
    setSymptoms(arr);

    const problemsData = isRxAudit
      ? isRxAuditData?.patient_problems
      : appointmentData?.user_health_problems;
    let array = [];
    if (problemsData) {
      for (const val of problemsData) {
        if (!isDoctorAppoinment) {
          const obj = {
            label: val,
            value: val,
          };
          array.push(obj);
        } else {
          array.push(val);
        }
      }
    }

    for (const val of appointmentData?.problem) {
      if (!isDoctorAppoinment) {
        const obj = {
          label: val?.label,
          value: val?.value,
        };
        array.push(obj);
      } else {
        array.push(val);
      }
    }
    setProblems(array);
  };

  const handleSymtomsChange = (value) => {
    setSymptomsOnChangeData(value);
  };

  const onSymtomsAdd = () => {
    if (onSymptomsChangeData?.length > 0) {
      const arr = appointmentData?.symptom;
      arr.push(onSymptomsChangeData);
      updatehealthData('symptom', arr);
      setSymptomsOnChangeData('');
    } else {
      toast.error('Please enter symptom!');
    }
  };

  const onSymtomsDelete = (val) => {
    let arr = [];
    for (const value of appointmentData?.symptom) {
      if (appointmentData?.symptom?.length > 1) {
        if (value !== val) {
          arr.push(value);
          updatehealthData('symptom', arr);
        }
      } else if (appointmentData?.symptom?.length === 1) {
        updatehealthData('symptom', []);
      }
    }
  };

  const handleProlemsChange = (value) => {
    setProblemsOnChangeData(value);
  };

  const onProblemsAdd = () => {
    if (onProblemsChangeData?.length > 0) {
      const arr = appointmentData?.problem;
      arr.push(onProblemsChangeData);
      updatehealthData('problem', arr);
      setProblemsOnChangeData('');
    } else {
      toast.error('Please enter problem!');
    }
  };

  const onProblemsDelete = (val) => {
    let arr = [];
    for (const value of appointmentData?.problem) {
      if (appointmentData?.problem?.length > 1) {
        if (value !== val) {
          arr.push(value);
          updatehealthData('problem', arr);
        }
      } else if (appointmentData?.problem?.length === 1) {
        updatehealthData('problem', []);
      }
    }
  };

  const handleHealthIssueChange = (value) => {
    setlthIssuesOnChangeData(value);
  };

  const onHealthIssuesAdd = () => {
    if (onHealthIssuesChangeData?.length > 0) {
      const arr = appointmentData?.healthIssue;
      arr.push(onHealthIssuesChangeData);
      updatehealthData('healthIssue', arr);
      setlthIssuesOnChangeData('');
    } else {
      toast.error('Please enter health issue!');
    }
  };

  const onHealthIssuesDelete = (val) => {
    let arr = [];
    for (const value of appointmentData?.healthIssue) {
      if (appointmentData?.healthIssue?.length > 1) {
        if (value !== val) {
          arr.push(value);
          updatehealthData('healthIssue', arr);
        }
      } else if (appointmentData?.healthIssue?.length === 1) {
        updatehealthData('healthIssue', []);
      }
    }
  };

  // CONSTRUCT VITALS DATA
  const constructVitalData = [
    {
      value: isRxAudit
        ? isRxAuditData?.patient_vitals?.height_in_cm
        : vitals?.height,
      unit: 'cm',
      name: '(Height)',
    },
    {
      value: isRxAudit
        ? isRxAuditData?.patient_vitals?.weight_in_cm
        : vitals?.weight,
      unit: 'Kg',
      name: '(Weight)',
    },
    {
      value: isRxAudit
        ? isRxAuditData?.patient_vitals?.temp_in_f
        : vitals?.temprature,
      unit: 'F',
      name: '(Temp)',
    },
    {
      value: isRxAudit
        ? isRxAuditData?.patient_vitals?.puls_bpm
        : vitals?.pulse,
      unit: '',
      name: '(Pulse)',
    },
    {
      value: isRxAudit
        ? `${isRxAuditData?.patient_vitals?.bp_sys}/${isRxAuditData?.patient_vitals?.bp_dia}`
        : vitals?.bloodPressure,
      unit: 'Mmhg',
      name: '(BP)',
    },
    {
      value: isRxAudit ? isRxAuditData?.patient_vitals?.o2_level : vitals?.o2,
      unit: '%',
      name: '(Spo2)',
    },
  ];

  const viewReportFnc = () => {
    if (isRxAudit) {
      navigate(rxAuditRoutes?.viewreports, {
        state: {
          appointmentId: isRxAuditData?.appointment?.id ?? '',
          patientId: patientId ?? '',
          patientType: patientType ?? '',
        },
      });
    } else {
      navigate(doctorRoutes?.reportviewsummary, {
        state: {
          appointmentId: id ?? '',
          patientId: patientId ?? '',
          patientType: patientType ?? '',
        },
      });
    }
  };

  useEffect(() => {
    setSymptomAndProblmData();
  }, [appointmentData]);

  return (
    <Box
      sx={{
        ...patientSummary_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {isRxAudit ? (
        <AppointmentDetails
          heading="patient details"
          icon={<UserIcon />}
          content={`${isRxAuditData?.patient_country_code}${isRxAuditData?.patient_mobile_no}`}
          label={`${isRxAuditData?.patient_gender} , ${isRxAuditData?.patient_age}`}
          editDownIconShown={false}
          expanded={false}
        />
      ) : (
        <AppointmentDetails
          isDoctor
          heading="patient details"
          icon={<UserIcon />}
          content={appointmentData?.patientName ?? ''}
          email=""
          label={
            appointmentData?.patientGender && appointmentData?.patientAge
              ? `${appointmentData?.patientGender}` +
                ' ' +
                `, ${appointmentData?.patientAge} Yrs`
              : appointmentData?.patientGender
              ? `${appointmentData?.patientGender}`
              : ''
          }
          number={`${appointmentData?.patientCountryCode} ${appointmentData?.patientMobileNumber} `}
          myself={false}
          editDownIconShown={false}
          expanded={false}
        />
      )}

      <Divider sx={patientSummary_style.dividerSx} />
      {!isDoctorAppoinment ? (
        <AppointmentList
          isDoctor
          icon={<HeartPulse />}
          heading="health issues"
          editDownIconShown={false}
          noDataFound="No health issues added."
          expanded={false}
          list={
            isRxAudit
              ? isRxAuditData?.patient_health_issues
              : appointmentData?.healthIssue
          }
        />
      ) : (
        <SummaryChips
          icon={<HeartPulse />}
          title="health issues"
          data={
            isRxAudit
              ? isRxAuditData?.patient_health_issues
              : appointmentData?.healthIssue
          }
          onAdd={onHealthIssuesAdd}
          onDelete={onHealthIssuesDelete}
          summaryView={summaryView}
          value={onHealthIssuesChangeData}
          onChange={handleHealthIssueChange}
          placeholder="Enter Health Issues"
          noDataFound="No health issues added."
        />
      )}
      <Divider sx={patientSummary_style.dividerSx} />
      {!isDoctorAppoinment ? (
        <AppointmentChips
          isDoctor
          label="symptoms"
          heading="symptoms"
          noDataFound="No symptoms added."
          icon={<CougeIcon />}
          editDownIconShown={false}
          list={symptoms}
          isEdit={false}
        />
      ) : (
        <SummaryChips
          icon={<CougeIcon />}
          title="symptoms"
          data={symptoms}
          onAdd={onSymtomsAdd}
          summaryView={summaryView}
          onDelete={onSymtomsDelete}
          value={onSymptomsChangeData}
          onChange={handleSymtomsChange}
          noDataFound="No symptoms added."
          placeholder="Enter Symptoms"
        />
      )}
      <Divider sx={patientSummary_style.dividerSx} />
      {!isDoctorAppoinment ? (
        <AppointmentChips
          isDoctor
          label="Problems"
          heading="Problems"
          noDataFound="No problems added."
          icon={<PainIcon />}
          editDownIconShown={false}
          list={problems}
          isEdit={false}
        />
      ) : (
        <SummaryChips
          title="Problems"
          placeholder="Enter Problems"
          data={problems}
          onAdd={onProblemsAdd}
          summaryView={summaryView}
          onDelete={onProblemsDelete}
          value={onProblemsChangeData}
          onChange={handleProlemsChange}
          noDataFound="No problems added."
          icon={<PainIcon />}
        />
      )}
      <Divider sx={patientSummary_style.dividerSx} />
      <ReportDetails
        heading="reports"
        editDownIconShown={false}
        icon={<Document />}
        noDataFound="No reports added."
        reportCount={
          isRxAudit
            ? isRxAuditData?.appointment?.appointment_uploads?.length
            : appointmentData?.reportCount
        }
        isEditIcon={false}
        isDoctor
        viewReportFnc={viewReportFnc}
      />
      <Divider sx={patientSummary_style.dividerSx} />
      <VitalDetails
        content={constructVitalData}
        heading="vitals"
        icon={<HeartIcon />}
        editDownIconShown={false}
        noDataFound="No vitals added."
        expanded={false}
      />
    </Box>
  );
}

PatientSummary.propTypes = {
  className: PropTypes.string,
  isDoctorAppoinment: PropTypes.any,
  summaryView: PropTypes.any,
  rootStyle: PropTypes.object,
  isRxAudit: PropTypes.bool,
  isRxAuditData: PropTypes.object,
};

export { PatientSummary };
