export const stepperForm_style = {
  rootSx: {
    display: 'flex',
    justifyContent: 'start',
  },
  totalStepperSx: {
    pt: 5.5,
  },
  rightSx: {
    px: 3,
    width: '80%',
    maxHeight: '100vh',
    overflow: 'auto',
  },
  rightTextSx: {
    color: '#0F0B11',
    fontSize: '20px',
    fontWeight: 600,
  },
  rightSubTextSx: {
    color: '#18574C7A',
    fontSize: '14px',
    fontWeight: 600,
  },
  nextSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%',
    position: 'sticky',
    top: 0,
    bgcolor: 'white',
    zIndex: 99,
    pb: 5,
  },
  iconNextSx: {
    fontSize: '16px',
    fontWeight: 600,
    pt: 0.5,
  },
  dataSx: {},
};
