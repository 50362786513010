/* eslint-disable react/jsx-key */
/* eslint-disable arrow-body-style */
import { getDateFormat } from '@hc/dayjs';
import { useReferrals, useViewPort, usePatientDetails } from '@hc/store';
import { NoRefferals, Dropdown } from '@hc/ui/atoms';
import {
  ClinicalEmptyState,
  DoctorModuleSkeleton,
  RefferalCard,
} from '@hc/ui/components';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import {
  localStorageKeys,
  parseJwt,
  // formatDate,
  // commonSocketData,
} from '@hc/utils';
import {
  AppointmentHeaders,
  Button,
  ConnectIcon,
  PhoneIcon,
  UserProfileIcon,
} from '../../../../atoms';
import { RefferalNoteModal } from '../refferalnotemodal';
import { refferals_style } from './style';

function Refferals(props) {
  const { className = '', rootStyle = {}, isMobileResponsive, ...rest } = props;
  const [doctorSelect, setDoctorSelect] = useState('');
  // const [isError, setIsError] = useState(false);

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const {
    referralsData,
    getReferralsData,
    referralsNotes,
    getReferralsNotes,
    loading,
  } = useReferrals(
    (state) => ({
      referralsNotes: state.referralsNotes,
      referralsData: state.referralsData,
      getReferralsData: state.getReferralsData,
      getReferralsNotes: state.getReferralsNotes,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { getVerifiedDoctors, verifiedDoctors } = usePatientDetails(
    (state) => ({
      getVerifiedDoctors: state.getVerifiedDoctors,
      verifiedDoctors: state.verifiedDoctors,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));
  const [displayReffModal, setDisplayReffModal] = useState(false);

  const onClickreffer = async (item) => {
    setDisplayReffModal(true);
    await getReferralsNotes(item);
  };

  const onDoctorSelect = async (option) => {
    setDoctorSelect(option);
    await getReferralsData('doctorReferral', option);
  };

  useEffect(() => {
    getVerifiedDoctors('list');
  }, []);

  return (
    <Box
      sx={{
        ...refferals_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {isMobilePort ? (
        <Box>
          {data?.role_id !== 2 ? (
            <Box sx={{ width: '100%', margin: '10px 0px 10px auto' }}>
              <Box sx={{ width: '188px' }}>
                {' '}
                <Typography
                  sx={{
                    color: 'common.black',
                    fontSize: '14px',
                    fontWeight: '600',
                    py: 1,
                  }}
                >
                  Select doctor
                </Typography>
              </Box>
              <Dropdown
                placeholder="select"
                selectOption={verifiedDoctors}
                optionListStyle={{ height: '350px' }}
                optionViewKey="label"
                value={doctorSelect}
                onChange={(event) => onDoctorSelect(event.target.value)}
                // isError={isError ? true : false}
                // helperText={isError ? 'Please select the slot' : ''}
                // onChangeText={handleOnchange}
              />
            </Box>
          ) : null}
        </Box>
      ) : (
        <Box>
          {data?.role_id !== 2 ? (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                maxWidth: '350px',
                alignItems: 'center',
                margin: '10px 0px 10px auto',
              }}
            >
              <Box sx={{ width: '188px' }}>
                {' '}
                <Typography
                  sx={{
                    color: 'common.black',
                    fontSize: '14px',
                    fontWeight: '600',
                    py: 1,
                  }}
                >
                  Select doctor
                </Typography>
              </Box>
              <Dropdown
                placeholder="select"
                selectOption={verifiedDoctors}
                optionListStyle={{ height: '350px' }}
                optionViewKey="label"
                value={doctorSelect}
                onChange={(event) => onDoctorSelect(event.target.value)}
                // isError={isError ? true : false}
                // helperText={isError ? 'Please select the slot' : ''}
                // onChangeText={handleOnchange}
              />
            </Box>
          ) : null}
        </Box>
      )}
      {loading &&
        Array(5)
          .fill(0)
          .map((i) => <DoctorModuleSkeleton key={i} />)}

      {referralsData?.length === 0 && (
        <Box sx={loading ? { display: 'none' } : ''}>
          <ClinicalEmptyState
            IconRequired={<NoRefferals />}
            emptyStateStyle={{ mt: 8, height: '50vh' }}
            subHead="Referrals will appear if any patient is referred by another doctor to you."
            subHeadStyle={refferals_style.subHeadSx}
          />
        </Box>
      )}

      <Box display={loading ? 'none' : ' '}>
        {!isMobileResponsive &&
          referralsData?.map((item, i) => {
            return (
              <Box sx={refferals_style.cardSx} key={i} id={`card${i + 1}`}>
                <Grid container direction="row" justify="center" spacing={3}>
                  <Grid sx={refferals_style.gridcenterSx} item sm={3} xs={12}>
                    <AppointmentHeaders
                      isNew={item.new}
                      title={
                        <span style={{ fontSize: '16px' }}>{item?.name}</span>
                      }
                      subText={item.gender}
                      age={item.age}
                      profileIcon={<UserProfileIcon />}
                    />
                  </Grid>

                  <Grid sx={refferals_style.gridcenterSx} item sm={3} xs={12}>
                    <AppointmentHeaders
                      profileIcon={<PhoneIcon />}
                      countyCode={item?.country_code}
                      title={item.mobile_no}
                    />
                  </Grid>
                  <Grid sx={refferals_style.gridcenterSx} item sm={3.5} xs={12}>
                    <AppointmentHeaders
                      profileIcon={<ConnectIcon />}
                      title={
                        <span style={{ fontSize: '14px' }}>
                          <span style={{ fontSize: '12px', color: '#007965' }}>
                            by
                          </span>{' '}
                          {item?.doctor_details?.first_name}
                        </span>
                      }
                      subText={item?.doctor_details?.doctor_specialities.map(
                        (val, i) => (
                          <span style={{ fontSize: '12px' }} key={i}>
                            {val?.master_speciality?.speciality}
                          </span>
                        ),
                      )}
                      date={item.date}
                    />
                  </Grid>

                  <Grid sx={refferals_style.gridcenterSx} item sm={2.5}>
                    <Button
                      sx={refferals_style.buttonSx}
                      onClick={() => onClickreffer(item?.referral_id)}
                      variant="outlined"
                      color="primary"
                      id={`viewNote${i + 1}`}
                    >
                      <Typography color="primary" fontSize="14px">
                        VIEW REFERRAL NOTE
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            );
          })}

        {isMobileResponsive &&
          referralsData?.map((item, i) => (
            <Box my={1.5} key={i}>
              <RefferalCard
                doctorName={item.name}
                gender={item.gender}
                age={item.age}
                referredBy={item?.doctor_details?.first_name}
                doctor_specialities={
                  item?.doctor_details?.doctor_specialities[0]
                    ?.master_speciality?.speciality ?? ''
                }
                date={getDateFormat(item.date, 'DD MMM YYYY')}
                referral_id={item?.referral_id}
                onClickreffer={onClickreffer}
              />
            </Box>
          ))}

        {displayReffModal && referralsNotes && (
          <RefferalNoteModal
            date={getDateFormat(referralsNotes?.referral_time, 'MMM DD yyyy')}
            doctorName={referralsNotes?.doctor_data?.first_name}
            patientName={referralsNotes?.patient_data?.name}
            data={referralsNotes?.referral_notes}
            setDisplayReffModal={setDisplayReffModal}
            time={getDateFormat(referralsNotes?.referral_time, 'hh:mm')}
            year={getDateFormat(referralsNotes?.referral_time, 'yyyy')}
            docMobileNo={referralsNotes?.doctor_data?.mobile_no}
            countryCode={referralsNotes?.doctor_data?.mobile_no_country_code}
            regNo={referralsNotes?.doctor_data?.register_no}
            spec={referralsNotes?.doctor_data?.doctor_specialities}
            age={referralsNotes?.patient_data?.age}
            gender={referralsNotes?.patient_data?.gender}
            hospitalName={referralsNotes?.doctor_data?.working_at}
            isMobileResponsive={isMobilePort}
          />
        )}
      </Box>
    </Box>
  );
}

Refferals.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isMobileResponsive: PropTypes.bool,
};

export { Refferals };
