export const rxAuditRoutes = {
  home: '/',
  welcome: '/welcome',
  onboarding: '/onboarding',
  createaccount: '/createaccount',
  signin: '/login',
  forgotpassword: '/forgotpassword',
  resetpassword: '/resetpassword',
  profile: '/profile',
  summary: '/summary',
  viewreports: '/viewreports',
  reportspage:'/reportspage'
};
