export const dateChanger_style = {
  rootSx: {
    display: 'flex',
    '.&MuiIconButton-root': {
      padding: '0px',
    },
  },
  dateRangeSx: {
    display: 'flex',
    justifyContent: 'center',
    ml: 1,
  },
};
