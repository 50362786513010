/* eslint-disable no-restricted-syntax */
import { Button, DialogModal } from '@atoms';
import {
  // useDoctorAppointment,
  usePatientDetails,
  useReferrals
} from '@hc/store';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { CustomTextArea, Dropdown, Input, MobileInput } from '../../../atoms';
import { referDialog_style } from './style';

function ReferDialog(props) {
  const {
    className = '',
    handleClose = () => false,
    isModalOpen,
    rootStyle = {},
    patientData = {},
    specialization = [],
    isMobileResponsive,
    ...rest
  } = props;

  const { getDocterList, setRefferalNotes, loading } = usePatientDetails(
    (state) => ({
      setRefferalNotes: state.setRefferalNotes,
      getDocterList: state.getDocterList,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { getReferralsData } = useReferrals(
    (state) => ({
      getReferralsData: state.getReferralsData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const [doctorOpen, setDoctorOpen] = useState(false);
  const [speciality, setSpeciality] = useState();
  const [doctorName, setDoctorName] = useState();
  const [doctorMobile, SetDoctorMobile] = useState();
  const [mobileErrorText, setMobileErrorText] = useState();
  const [doctor, setDoctor] = useState();
  const [refferalNote, setRefferals] = useState();
  const [isError, setIsError] = useState('');
  const [doctorListData, setDoctorListData] = useState([]);

  const addDoctor = () => {
    setDoctorOpen(true);
  };

  const AddRefferalnote = (value) => {
    setRefferals(value);
  };

  const onSpecialityChange = async (value) => {
    setSpeciality(value);
    setDoctor();
    setDoctorOpen(false);
    setDoctorName();
    setRefferals();
    SetDoctorMobile();

    const data = await getDocterList(value);
    const addNewDoctorData = {
      label: (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box onClick={addDoctor} sx={referDialog_style.addSx}>
            <Typography sx={referDialog_style.listSx}> + Add Doctor</Typography>
          </Box>
          <Box>
            <Typography sx={referDialog_style.subListSx}>
              Invite Doctor T0 the Health circle by providing the email
            </Typography>
          </Box>
        </Box>
      ),
      value: '',
    };
    const arr = JSON.parse(JSON.stringify(data));
    arr.push(addNewDoctorData);
    setDoctorListData(arr);
  };

  const onDoctorChange = async (value) => {
    setDoctor(value);
  };

  const onConfirm = async () => {
    if (speciality) {
      setIsError('');
      if (doctor) {
        setIsError('');
        if (refferalNote) {
          setIsError('');
          const obj = {
            patient_profile_id: patientData?.user_profile_id,
            speciality_id: speciality,
            referred_to: doctor,
            referral_notes: refferalNote,
          };

          await setRefferalNotes(obj);
          handleClose();
          await getReferralsData('doctorReferral');

          setSpeciality();
          setDoctor();
          setRefferals();
          setDoctorOpen(false);
        } else {
          setIsError('refferalNote');
        }
      } else if (doctorOpen) {
        setIsError('');
        if (doctorMobile) {
          setIsError('');
          if (doctorMobile?.mobile?.length === 10) {
            setIsError('');
            if (doctorName) {
              setIsError('');
              if (refferalNote) {
                setIsError('');
                const obj = {
                  patient_profile_id: patientData?.user_profile_id,
                  speciality_id: speciality,
                  referral_doctor_mobile_no: doctorMobile?.mobile,
                  referral_doctor_name: doctorName,
                  referral_doctor_country_code: doctorMobile?.mobileCode,
                  referral_notes: refferalNote,
                };
                handleClose();
                await setRefferalNotes(obj);
                setDoctorListData([]);
                await getReferralsData('doctorReferral');
                setSpeciality();
                setDoctor();
                setRefferals();
                setDoctorOpen(false);
              } else {
                setIsError('refferalNote');
              }
            } else {
              setIsError('doctorName');
            }
          } else {
            setIsError('doctorMobile');
            setMobileErrorText('Please enter the 10 digits of mobile number');
          }
        } else {
          setIsError('doctorMobile');
          setMobileErrorText('Please enter the mobile number');
        }
      } else {
        setIsError('doctor');
      }
    } else {
      setIsError('speciality');
    }
  };

  useEffect(() => {
    setDoctorListData([]);
  }, []);
  return (
    <Box
      sx={{
        ...referDialog_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <DialogModal
        title="REFER PATIENT"
        titleStyle={
          isMobileResponsive
            ? referDialog_style.drawertitleSx
            : referDialog_style.titleSx
        }
        isBackRequired={isMobileResponsive ? true : false}
        isCloseOut={isMobileResponsive ? false : true}
        isMobileResponsive={isMobileResponsive}
        maxModalWidth="xl"
        topDivider
        bottomDivider
        dialogRootStyle={referDialog_style.dialogSx}
        content={
          <Box>
            <Box sx={referDialog_style.profileSx}>
              <Box sx={referDialog_style.proPicSX}>
                {' '}
                <Avatar />{' '}
              </Box>
              <Box>
                <Typography sx={referDialog_style.firstSx}>
                  {patientData?.patient_profile?.name}
                </Typography>
                <Typography sx={referDialog_style.subSx}>
                  {patientData?.patient_profile?.gender}{' '}
                  {patientData?.patient_profile?.age} Years
                </Typography>
              </Box>
            </Box>

            <Box sx={referDialog_style.referTotalSx}>
              <Typography gutterBottom fontWeight={600} mb={1.25}>
                Doctor Specialization
              </Typography>
              <Dropdown
                datatestid="dropdown1"
                selectOption={specialization}
                onChange={(e) => onSpecialityChange(e.target.value)}
                isError={isError === 'speciality' ? true : false}
                helperText={
                  isError === 'speciality' ? 'Please select the speciality' : ''
                }
              />
            </Box>
            {!doctorOpen && (
              <Box sx={referDialog_style.referTotalSx}>
                <Typography gutterBottom fontWeight={600} mb={1.25}>
                  Select Doctor
                </Typography>

                <Dropdown
                  datatestid="dropdown2"
                  onChange={(e) => onDoctorChange(e.target.value)}
                  selectOption={doctorListData}
                  isError={isError === 'doctor' ? true : false}
                  helperText={
                    isError === 'doctor' ? 'Please select the doctor' : ''
                  }
                />
              </Box>
            )}
            {doctorOpen && (
              <>
                <Box sx={referDialog_style.referTotalSx}>
                  <Typography gutterBottom fontWeight={600} mb={1.25}>
                    Doctor&apos;s phoneNumber
                  </Typography>
                  <MobileInput
                    isError={isError === 'doctorMobile' ? true : false}
                    onChange={SetDoctorMobile}
                    value={doctorMobile}
                    helperText={
                      isError === 'doctorMobile' ? mobileErrorText : ''
                    }
                  />
                </Box>
                <Box sx={referDialog_style.referTotalSx}>
                  <Typography gutterBottom fontWeight={600} mb={1.25}>
                    Doctors Name
                  </Typography>
                  <Input
                    isError={isError === 'doctorName' ? true : false}
                    value={doctorName}
                    onChange={(e) => setDoctorName(e.target.value)}
                    placeholder="Doctor name"
                    helperText={
                      isError === 'doctorName'
                        ? 'Please enter the doctor name'
                        : ''
                    }
                  />
                </Box>
              </>
            )}
            <Box sx={referDialog_style.referTotalSx}>
              <Typography gutterBottom fontWeight={600} mb={1.25}>
                Notes
              </Typography>
              <CustomTextArea
                data_testId="addNotes"
                placeholder="Add some notes to the referred doctor"
                textFieldStyle={referDialog_style.customAboutSx}
                onChange={(e) => AddRefferalnote(e.target.value)}
                value={refferalNote}
                multiline
                rows={100}
                isError={isError === 'refferalNote' ? true : false}
                helperText={
                  isError === 'refferalNote' ? 'Please add the notes' : ''
                }
              />
            </Box>
          </Box>
        }
        actions={
          <Grid
            p={1}
            container
            display="flex"
            justifyContent={isMobileResponsive ? 'space-around' : 'end'}
            spacing={2}
          >
            {isMobileResponsive && (
              <Grid item xs={6}>
                <Button
                  loading={loading}
                  onClick={handleClose}
                  sx={referDialog_style.buttonSx}
                >
                  CANCEL
                </Button>
              </Grid>
            )}
            <Grid item xs={isMobileResponsive ? 6 : 4}>
              <Button loading={loading} onClick={onConfirm}>
                SEND REFERRAL
              </Button>
            </Grid>
          </Grid>
        }
        isDialogOpened={isModalOpen}
        handleCloseDialog={handleClose}
      />
    </Box>
  );
}

ReferDialog.propTypes = {
  className: PropTypes.string,
  handleClose: PropTypes.func,
  onConfirm: PropTypes.func,
  patientName: PropTypes.string,
  isModalOpen: PropTypes.func,
  Age: PropTypes.string,
  gender: PropTypes.string,
  rootStyle: PropTypes.object,
  patientData: PropTypes.object,
  specialization: PropTypes.array,
  isMobileResponsive: PropTypes.bool,
};

export { ReferDialog };

