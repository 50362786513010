export const drugsGroupAddDialog_style = {
  rootSx: {},

  titleSx: {
    fontSize: 16,
    fontWeight: 600,
  },
  dialogSx: {
    width: '650px',
  },
  buttonSx: {
    border: '2px solid #007965',
    '&:hover': {
      border: '2px solid #007965',
    },
  },
  cancelBtnSx: {
    textTransform: 'none',
    backgroundColor: '#Fff',
    border: '1px solid',
    borderColor: ' primary.main',
    color: 'primary.main',
  },
  applyBtnSx: {
    textTransform: 'none',
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',

      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2.5,
  },
};
