import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { BiChevronRight } from 'react-icons/bi';
import Bigpointarrow from '../../../../assets/Bigpointarrow.svg';
import BigpointarrowMiddle from '../../../../assets/BigpointarrowMiddle.svg';
import BigpointarrowRight from '../../../../assets/BigpointarrowRight.svg';
import naturalUserInterface from '../../../../assets/naturalUserInterface.svg';

import { instructionModal_style } from './style';

function InstructionModal(props) {
  const {
    className = '',
    rootStyle = {},
    InstructionModalOpen,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...instructionModal_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Modal open={InstructionModalOpen}>
        <Box sx={instructionModal_style.totalModalSx}>
          <Box sx={instructionModal_style.totalBoxSx}>
            <Grid
              container
              justifyContent="space-around"
              ml={3}
              sx={{ mt: { xs: 48, lg: 42 } }}
            >
              <Grid item sx={instructionModal_style.instructionTab}>
                <Typography sx={instructionModal_style.typoSx}>Dose</Typography>
                <Box>
                  <IconButton disableRipple sx={{ p: 0 }}>
                    <BiChevronRight color="#007965" />
                  </IconButton>
                </Box>
              </Grid>

              <Grid item sx={instructionModal_style.instructionTab}>
                <Box>
                  <Typography sx={instructionModal_style.typoSx}>
                    5 min, Daily, Before food
                  </Typography>
                </Box>
                <IconButton disableRipple sx={{ p: 0 }}>
                  <BiChevronRight color="#007965" />
                </IconButton>
              </Grid>

              <Grid item sx={instructionModal_style.instructionTab}>
                <Typography sx={instructionModal_style.typoSx}>
                  3 days,3 nos
                </Typography>
                <IconButton disableRipple sx={{ p: 0 }}>
                  <BiChevronRight color="#007965" />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container justifyContent="space-around" ml={3} mt={2}>
              <Grid
                item
                xs={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  rotate: '15deg',
                  ml: -2,
                }}
              >
                <Box>
                  <img src={naturalUserInterface} alt="naturalUserInterface" />
                </Box>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box>
                  <img src={naturalUserInterface} alt="naturalUserInterface" />
                </Box>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  rotate: '-15deg',
                }}
              >
                <Box>
                  <img src={naturalUserInterface} alt="naturalUserInterface" />
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              ml={5}
              position="relative"
            >
              <Grid
                position="absolute"
                ml={12}
                mt={1}
                item
                xs={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={Bigpointarrow} alt="Bigpointarrow" />
              </Grid>

              <Grid
                position="absolute"
                ml={31}
                item
                xs={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={BigpointarrowMiddle} alt="BigpointarrowMiddle" />
              </Grid>

              <Grid
                ml={46}
                mt={-1}
                item
                xs={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={BigpointarrowRight} alt="BigpointarrowRight" />
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#fff',
                  textAlign: 'center',
                }}
              >
                To update drug details
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#fff',
                  textAlign: 'center',
                }}
              >
                Click on the respective areas to edit the drug intake details
              </Typography>
            </Grid>
            <Typography
              mt={10}
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                color: '#fff',
                textAlign: 'center',
              }}
            >
              Tap anywhere to close
            </Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

InstructionModal.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  InstructionModalOpen: PropTypes.any,
};

export { InstructionModal };
