export const addPatientUi_style = {
  dialogSx: {
    width: '640px',
  },
  titleSx: {
    color: 'common.black',
    fontSize: '16px',
    fontWeight: '600',
  },
  toggleButtonActiveSx: {
    border: '1px solid',
    minWidth: 'max-content',
    minHeight: '40px',
    borderRadius: '10px',
  },
  toggleButtonInActiveSx: {
    border: '1px solid ',
    borderColor: '#E3E3E3',
    borderRadius: '10px',
    minWidth: 'max-content',
    minHeight: '40px',
  },
  buttonSx: {
    bgcolor: 'white',
    color: 'primary.main',
    borderRadius: '8px',
    '&:hover': {
      color: 'white',
    },
    border: '1px solid #007965',
  },
};
