import { useSummary } from '@hc/store';
import { Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { summaryReferNote_style } from './style';

function SummaryReferNote(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  const { summaryState } = useSummary((state) => ({
    summaryState: state.summaryState,
  }));

  const { referToDoctorDetails } = summaryState;
  const {
    doctorSpeciality,
    doctorName,
    doctorMobileNumber,
    doctorCountryCode,
    notes,
  } = referToDoctorDetails;

  return (
    <Box
      sx={{
        ...summaryReferNote_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={summaryReferNote_style.pastReferRootSx}>
        <Box sx={summaryReferNote_style.cardSx}>
          <Box display="flex" alignItems="center">
            <Typography sx={summaryReferNote_style.commonTextSx}>
              {`Dr. ${doctorName} `}&nbsp;
            </Typography>
            <Typography
              sx={{
                color: 'text.hint',
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
                fontWeight: 500,
              }}
            >
              {Array.isArray(doctorSpeciality) &&
                doctorSpeciality?.length > 0 &&
                doctorSpeciality.map((val, index) => (
                  <Typography
                    key={index}
                    sx={{
                      color: 'text.hint',
                      fontSize: '12px!important',
                      fontWeight: 'medium',
                    }}
                  >
                    • &nbsp;{val?.label ?? ''}&nbsp;
                  </Typography>
                ))}
            </Typography>
            <Typography sx={summaryReferNote_style.subCommonTextSx}>
              &nbsp; • {`(${doctorCountryCode}) ${doctorMobileNumber}`}
            </Typography>
          </Box>
          {/* <Box display="flex" alignItems="center">
            <CalenderIcon />
            &nbsp;
            <Typography sx={summaryReferNote_style.subCommonTextSx}>
              {getReferralDateFormat(
                referralNoteData?.referralNoteDate,
                'DD-MM-YYYY'
              )}
            </Typography>
          </Box> */}
        </Box>
        <Divider sx={summaryReferNote_style.divideDottedSx} />
        <Box>
          <Box
            display="flex"
            alignItems="center"
            sx={summaryReferNote_style.cardSxx}
          >
            <Typography sx={summaryReferNote_style.commonTextSx}>
              Notes
            </Typography>
            <Typography sx={summaryReferNote_style.issuesTextSx}>
              {notes}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

SummaryReferNote.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { SummaryReferNote };
