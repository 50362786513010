import { useDoctorOnboarding } from '@hc/store';
import DoctorOnboarding from '@hc/ui/assets/doctorOnboarding.png';
import { Button, HealthCirclesLogo, Input, Label } from '@hc/ui/atoms';
import { localStorageKeys } from '@hc/utils/constants';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { doctorRoutes } from '@hc/routes';
import { reset_password_style } from './style';

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    doctor,
    resetPassword,
    updateOnboarding,
    isPasswordValidation,
    loading,
  } = useDoctorOnboarding((state) => ({
    doctor: state.doctor,
    resetPassword: state.resetPassword,
    updateOnboarding: state.updateOnboarding,
    isPasswordValidation: state.isPasswordValidation,
    loading: state.loading,
  }));
  // General hooks
  const [showpassword, setPassword] = useState();
  const handleClickShowPassword = () => {
    setPassword(!showpassword);
  };
  const [showConfirmPassword, setConfirmPassword] = useState();
  const handleClickshowConfirmPassword = () => {
    setConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (val, key) => {
    updateOnboarding(key, val);
  };

  const resetPasswordLink = async () => {
    if (isPasswordValidation()) {
      const tokenData = localStorage.getItem(
        localStorageKeys.resetPasswordToken
      );
      // calling the signup api
      const res = await resetPassword(doctor?.password, tokenData);

      if (res === '200' || res === '401') {
        localStorage.clear();
        navigate(doctorRoutes?.signin);
      }
    }
  };

  useEffect(() => {
    if (searchParams.get('token')?.length > 0) {
      localStorage.setItem(
        localStorageKeys.resetPasswordToken,
        searchParams.get('token')
      );
      // const tokenData = parseJwt(searchParams.get('token'));
      // setData(tokenData);
      // updateOnboarding('email', tokenData?.email_id);
    }
  }, []);

  return (
    <Box sx={reset_password_style.rootSx}>
      <Grid container sx={reset_password_style.totalSx}>
        {/* Right Side */}
        <Grid item sm={5} md={6} sx={reset_password_style.leftSideSx}>
          <img
            src={DoctorOnboarding}
            alt=""
            style={reset_password_style.rightImgSx}
          />
        </Grid>
        {/* Left Side */}
        <Grid item xs={12} sm={7} md={6} sx={reset_password_style.rightSideSx}>
          <Stack p={5} spacing={2} sx={reset_password_style.CardcontentSx}>
            <Box>
              <HealthCirclesLogo />
            </Box>
            <Box sx={reset_password_style.formBoxSx}>
              <Typography sx={reset_password_style.createPasswordSx}>
                Reset Password
              </Typography>
              <Typography sx={reset_password_style.subTextSx}>
                Please provide a new password for your account
              </Typography>
            </Box>

            <Box sx={reset_password_style.inputGroupSx}>
              <Label htmlFor="password">New Password</Label>
              <Input
                id="password"
                type={showpassword ? 'text' : 'password'}
                onChange={(e) => handleChange(e.target.value, 'password')}
                isError={doctor?.error.password}
                errorMessage={doctor?.error.password}
                textFieldStyle={reset_password_style.textFieldSX}
                value={doctor?.password}
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword()}
                    edge="end"
                  >
                    {showpassword ? (
                      <MdVisibilityOff
                        htmlColor="#848484"
                        sx={reset_password_style.eyeSx}
                      />
                    ) : (
                      <MdVisibility
                        htmlColor="#848484"
                        sx={reset_password_style.eyeSx}
                      />
                    )}
                  </IconButton>
                }
              />
            </Box>
            <Box sx={reset_password_style.inputGroupSx}>
              <Label htmlFor="confirmPassword">Confirm New Password</Label>
              <Input
                id="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={(e) =>
                  handleChange(e.target.value, 'confirmPassword')
                }
                isError={doctor?.error.confirmPassword}
                errorMessage={doctor?.error.confirmPassword}
                value={doctor?.confirmPassword}
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickshowConfirmPassword()}
                    edge="end"
                  >
                    {showConfirmPassword ? (
                      <MdVisibilityOff
                        htmlColor="#848484"
                        sx={reset_password_style.eyeSx}
                      />
                    ) : (
                      <MdVisibility
                        htmlColor="#848484"
                        sx={reset_password_style.eyeSx}
                      />
                    )}
                  </IconButton>
                }
              />
            </Box>
            <Box sx={{ pt: 2, display: 'grid', gap: 3 }}>
              <Button
                fullWidth
                onClick={() => resetPasswordLink()}
                loading={loading}
                buttonStyle={reset_password_style.buttonStyleSx}
              >
                Submit
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
