/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { queryClient } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import { toast } from 'react-hot-toast';
import create from 'zustand';

export const useMasterDurationMode = create((set, get) => ({
  masterDurationModeData: [],
  loading: null,
  error: null,
  getMasterDurationModeData: async () => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['masterDurationMode'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/master/get-duration-mode`,
            {},
            true
          );
          return data;
        },
        staleTime: Infinity,
      });

      const masterDurationModeDataCopy = [];

      if (Array.isArray(data) && data?.length > 0) {
        data.map((val) => {
          masterDurationModeDataCopy.push({
            value: val?.id ?? '',
            name: val?.duration_mode ?? '',
          });
        });
      }
      return set((state) => ({
        loading: false,
        masterDurationModeData: masterDurationModeDataCopy,
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
