export const prescriptionDurationModal_style = {
  titleSx: {
    fontSize: 16,
    fontWeight: 600,
  },
  dialogSx: {
    width: 700,
  },
  TitleSx: {},
  buttonactivenumSX: {
    border: '1px solid',
    minWidth: '36px',
    minHeight: '40px',
    borderRadius: '10px',
  },
  dividerSx: {
    // px:1,
    border: '1.5px solid',
    borderColor: '#E3E3E3',
    backgroundColor: '#E3E3E3',
    height: '1px',
    mt: 1.8,
  },
  buttoninactivenumSX: {
    border: '1px solid ',
    borderColor: '#E3E3E3',
    borderRadius: '10px',
    minWidth: '36px',
    minHeight: '40px',
    '&:hover': {
      color: '#fff',
    },
  },
  borderRadius: '10px',
  buttonactivedaySX: {
    border: '1px solid',
    borderRadius: '10px',
    width: '75px',
  },
  buttoninactivedaySX: {
    borderRadius: '10px',
    border: '1px solid ',
    borderColor: '#E3E3E3',
    width: '75px',
  },

  buttonSx: {
    mr: 0.5,
    bgcolor: 'white',
    color: 'primary.main',
    borderRadius: '8px',
    '&:hover': {
      color: 'white',
    },
    border: '1px solid #007965',
    width: '100px',
  },

  countButtonSx: {
    height: '38px',
    display: 'flex',
    bgcolor: 'white',
    borderRadius: '8px',
    border: '2px solid #E3E3E3 ',
  },
  countButtonMideleSx: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60px',
    bgcolor: 'white',
    borderLeft: '2px solid #E3E3E3',
    borderRight: '2px solid #E3E3E3',
  },

  QtyTypoSx: {
    width: '120px',
    backgroundColor: '#E3E3E3',
    borderRadius: '10px',
    '& .MuiOutlinedInput-root': {
      width: '120px',
      borderRadius: '10px',
      '& fieldset': {
        borderRadius: '10px',
        borderColor: '#E3E3E3',
      },
      '&:hover fieldset': {
        borderColor: '#E3E3E3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E3E3E3',
      },
    },
  },
  QtyTypoendadoreSx: {},
  activeToggleTextSx: {
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: '12px',
  },
  inActiveToggleTextSx: {
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: '12px',
    color: '#0E1824',
  },
  drawerbuttonSx: {
    border: '2px solid #007965',
    '&:hover': {
      border: '2px solid #007965',
    },
  },
  cancelBtnSx: {
    border: '2px solid #007965',
    textTransform: 'none',
    backgroundColor: '#fff',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: '#Fff',
    },
  },
  applyBtnSx: {
    textTransform: 'none',
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2.5,
  },
};
