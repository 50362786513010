/* eslint-disable array-callback-return */
import { doctorRoutes } from '@hc/routes';
import { useSummary, useViewPort } from '@hc/store';
import { useDrugMaster } from '@hc/store/backoffice/drugMaster';
import { useMasterDrugCategory } from '@hc/store/doctor/masterDrugCategory';
import { useRxGroup } from '@hc/store/doctor/rxGroup';
import {
  Button,
  DocumentIcon,
  Dropdown,
  Label,
  SearchSelect
} from '@hc/ui/atoms';
import {
  InstructionModal,
  // ConfirmationPopup,
  PrescriptionAddNotes,
  PrescriptionButton,
  PrescriptionEmptystate,
  PrescriptionHeader,
  PrescriptionListCard,
  Screenlayout
} from '@hc/ui/components';
import { ConfirmationPopup } from '@hc/ui/components/doctor';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { BiSearch } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import { Prescription_style } from './style';

export default function Prescription() {
  const navigate = useNavigate();

  // General Hooks
  const location = useLocation();
  const filter = createFilterOptions();
  // GET LOCATION STATE DATA
  const { fromPage } = location?.state;

  const [value, setValue] = React.useState(null);
  const [type, setType] = React.useState('');

  const [drugAddModal, setDrugAddModal] = React.useState(false);
  const [searchValueState, setSearchValueState] = React.useState('');

  const [addnotes, setaddnotes] = React.useState(false);

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const keydata = parseJwt(authToken);

  const {
    rxGroupState,
    getDrugRxGroupList,
    addDrugInStore,
    addNewDrugInStore,
    saveRxGroupDrug,
    handleDrugFormChange,
    handleDrugClear,
    drug,
  } = useRxGroup(
    (state) => ({
      rxGroupState: state.rxGroupState,
      getDrugRxGroupList: state.getDrugRxGroupList,
      addDrugInStore: state.addDrugInStore,
      addNewDrugInStore: state.addNewDrugInStore,
      saveRxGroupDrug: state.saveRxGroupDrug,
      handleDrugFormChange: state.handleDrugFormChange,
      handleDrugClear: state.handleDrugClear,
      drug: state.drug,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const { category, error } = drug;
  // Store Data
  const { addDrugMaster } = useDrugMaster(
    (state) => ({
      addDrugMaster: state.addDrugMaster,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { isAddDrug, rxGroupName, drugRxGroupDropDownData, drugRxGroupData } =
    rxGroupState;

  const { summaryState, updateSummaryPrescriptionData, updateSummaryState } =
    useSummary((state) => ({
      summaryState: state.summaryState,
      updateSummaryPrescriptionData: state.updateSummaryPrescriptionData,
      updateSummaryState: state.updateSummaryState,
    }));

  const { masterDrugCategoryData, getMasterDrugCategoryData } =
    useMasterDrugCategory((state) => ({
      masterDrugCategoryData: state.masterDrugCategoryData,
      getMasterDrugCategoryData: state.getMasterDrugCategoryData,
    }));

  const { prescriptionNotesInstruction } = summaryState;

  const initialSearchCall = async () => {
    await getDrugRxGroupList(keydata?.id, value ?? '', type);
  };
  // GET DATA BASED ON SEARCH
  const onChangeText = async (searchValue) => {
    if (searchValue?.length > 2) {
      setSearchValueState(searchValue);
      setValue({ value: searchValue, label: searchValue });
      await getDrugRxGroupList(keydata?.id, searchValue, type);
    }
  };

  const onDecline = () => {
    handleDrugClear();
    setDrugAddModal(false);
  };

  const onAccpet = async () => {
    if (category > 0) {
      const payload = {
        drugName: searchValueState,
        frequency: 1,
        when: 2,
        time: 1,
        category,
      };
      const drugAddRes = await addDrugMaster(payload, 'add');
      if (drugAddRes?.status?.code === 200) {
        const drugData = {
          master_frequency: {
            id: 1,
            frequency: 'Daily',
          },
          master_when_type: {
            id: 2,
            when: 'After food',
          },
          master_time: {
            id: 1,
            time: 'Immediately',
          },
          master_drug_category: {
            id: category,
            drug_category_name:
              category === 1
                ? 'Medicine'
                : category === 2
                ? 'Syrup'
                : category === 3
                ? 'Ointment'
                : 'Medicine',
          },
        };
        addNewDrugInStore(drugData, searchValueState, drugAddRes?.data?.id);
        await getDrugRxGroupList(keydata?.id, '', type);
        setValue({ value: '', label: '' });
        setDrugAddModal(false);
        setSearchValueState('');
      } else {
        setDrugAddModal(false);
        setSearchValueState('');
        toast.error('Something went wrong. Please contact administrator!');
      }
    } else {
      toast.error('Please select category!');
    }
  };
  // ADD DRUG DATA
  const handleAdd = async (e, option) => {
    if (option?.type === 'add_new_drug') {
      if (searchValueState?.length > 0) {
        await getMasterDrugCategoryData();
        setDrugAddModal(true);
      } else {
        toast.error('Please enter drug name and proceed!');
      }
    } else {
      addDrugInStore(option);
      // GET DROPDOWN DATA
      if (e?.target?.value?.length === 0) {
        await getDrugRxGroupList(keydata?.id, '', type);
      }
      setValue({
        value: e?.target?.value ?? '',
        label: e?.target?.value ?? '',
      });
    }
  };

  const clearSearchState = async () => {
    setValue({ value: '', label: '' });
    setType(type);
    setSearchValueState('');
    // GET DROPDOWN DATA
    await getDrugRxGroupList(keydata?.id, '', type);
  };

  const handleChange = (key, value) => {
    // Handle drug category Function Change
    handleDrugFormChange(key, value);
  };

  const handleClickData = async (val) => {
    setValue({ value: '', label: '' });
    setType(val);
    // GET DROPDOWN DATA
    await getDrugRxGroupList(keydata?.id, '', val);
  };

  const checkDoseDurationValue = (data) => {
    const arr = [];
    if (Array.isArray(data) && data?.length > 0) {
      data.map((val) => {
        if (val?.isDeleted === false) {
          if (
            (val?.morningDose === 0 &&
              val?.afterNoonDose === 0 &&
              val?.nightDose === 0) ||
            val?.frequency?.label?.length === 0 ||
            val?.durationMode?.label?.length === 0 ||
            val?.duration === 0
          ) {
            arr.push(val);
          }
        }
      });
    }
    return arr?.length === 0 ? true : false;
  };

  const saveRxGroupDrugFnc = async () => {
    if (drugRxGroupData?.length > 0) {
      if (checkDoseDurationValue(drugRxGroupData)) {
        if (fromPage === 'summary') {
          const res = await updateSummaryPrescriptionData(
            drugRxGroupData?.filter((v) => !v?.isDeleted),
          );
          if (res) {
            navigate(doctorRoutes?.summary);
          }
        } else {
          const saveRes = await saveRxGroupDrug(keydata?.id ?? '');
          if (saveRes === 200) {
            navigate(doctorRoutes?.rxgroup);
          }
        }
      } else {
        toast.error('Please fill all the details to proceed');
      }
    } else {
      toast.error('Select atleast one drug to save');
    }
  };
  const AddnotesClosefunc = () => {
    setaddnotes(false);
  };

  const handleNotesInstructionSave = async (val) => {
    await updateSummaryState('prescriptionNotesInstruction', val);
    AddnotesClosefunc();
  };

  const AddnotesOpenfunc = () => {
    setaddnotes(true);
  };
  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const getFilteredadata = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some(
      (option) => inputValue?.toLowerCase() === option?.name?.toLowerCase(),
    );
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        value: inputValue,
        label: `+Add Drugs ${inputValue}`,
        type: 'add_new_drug',
      });
    }
    return filtered;
  };

  useEffect(() => {
    initialSearchCall();
  }, []);

  return (
    <>
      {!isMobilePort && (
        <Screenlayout
          titleRequired
          fill="#E2E2E2"
          title={
            fromPage === 'summary' ? 'Add Prescription' : `${rxGroupName}`
            //  ${isAddDrug ? `Add` : `Update`} Drug`
          }
          backRequired
          titleStyle={Prescription_style.titleSx}
          backIconStyle={Prescription_style.backIconSx}
          isDoctorAppbar
          notshowFooter
          backRoute={
            fromPage === 'rxGroup'
              ? doctorRoutes?.rxgroup
              : doctorRoutes?.summary
          }
          save
          saveOnClick={() => saveRxGroupDrugFnc()}
          saveStyle={Prescription_style.saveButtonSx}
        >
          <Box mt={3} px={4}>
            <Stack>
              <Box
                sx={{
                  ...Prescription_style.topFixedBar,
                  width: { xs: '700px', sm: '90vw', md: '92vw', lg: '950px' },
                }}
              >
                <Box width="100%" sx={{ overflow: 'hidden' }}>
                  <SearchSelect
                    TestId="drugField"
                    disableCloseOnSelect={
                      searchValueState?.length > 0 ? true : false
                    }
                    placeholder={
                      // type === 'drugs'
                      //   ? 'Search drugs'
                      //   : type === 'rxgroup'
                      //   ? 'Search Rx Group'
                      //   : 'Search drugs / Rx Group'
                      'Enter first 3 characters to search'
                    }
                    onChange={handleAdd}
                    renderOption={(props, option) => {
                      return (
                        <Box
                          sx={{ cursor: 'pointer' }}
                          height="40px"
                          onClick={(e) => handleAdd(e, option)}
                          px={2}
                          key={option.label}
                          display="flex"
                          {...props}
                          data-testId="option"
                        >
                          {option?.label?.includes('+Add Drugs') ? (
                            <Box display="flex" alignSelf="center">
                              <Typography
                                sx={{
                                  color: 'primary.main',
                                  fontSize: '14px',
                                  fontWeight: '600',
                                }}
                              >
                                +Add Drug &nbsp;
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                }}
                              >
                                {searchValueState}
                              </Typography>
                            </Box>
                          ) : (
                            <Box display="flex" alignSelf="center">
                              <Typography>{`${option.label}`}</Typography>
                            </Box>
                          )}
                        </Box>
                      );
                    }}
                    filterOptions={(options, params) =>
                      getFilteredadata(options, params)
                    }
                    fullWidth
                    autocompeleteStyle={Prescription_style.textFieldSx}
                    onChangeText={(e) => onChangeText(e?.target?.value)}
                    optionViewKey="label"
                    value={value}
                    endAdornment={
                      <PrescriptionButton
                        ActiveButton={type}
                        handleClickData={handleClickData}
                        closeIcon={searchValueState?.length > 0 ? true : false}
                        clearSearchState={clearSearchState}
                      />
                    }
                    options={
                      Array.isArray(drugRxGroupDropDownData) &&
                      drugRxGroupDropDownData?.length > 0
                        ? drugRxGroupDropDownData.filter(
                            (da) =>
                              !drugRxGroupData
                                ?.filter((v) => !v?.isDeleted)
                                .some(
                                  (eachDa) => eachDa?.drugName === da?.name,
                                ),
                          )
                        : []
                    }
                  />
                </Box>
                <Typography sx={Prescription_style.SelectedDrugs}>
                  {' '}
                  {`  Selected drugs ${
                    drugRxGroupData.filter((val) => val.isDeleted === false)
                      ?.length
                  }`}
                </Typography>
                <Box mt={0.9} pb={1}>
                  <PrescriptionHeader />
                </Box>
              </Box>

              {drugRxGroupData.filter((val) => val.isDeleted === false)
                .length === 0 && (
                <Box sx={Prescription_style.PrescriptionEmptystate}>
                  <PrescriptionEmptystate />
                </Box>
              )}

              {drugRxGroupData.filter((val) => val.isDeleted === false).length >
                0 && (
                <Box mt={16} sx={{ height: '60vh', overflow: 'scroll' }}>
                  <Box mt={2}>
                    <PrescriptionListCard />
                  </Box>
                </Box>
              )}
              {fromPage === 'summary' && (
                <Box
                  sx={{
                    width: {
                      lg: '80.5vw',
                    },
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      ...Prescription_style.FooterSx,
                      top: '90vh',
                      width: {
                        xs: '700px',
                        sm: '100vw',
                        md: '100vw',
                        lg: '1020px',
                      },
                      ml: -3.8,
                    }}
                  >
                    <Button
                      sx={Prescription_style.addnotesbuttonSx}
                      onClick={() => AddnotesOpenfunc()}
                    >
                      <DocumentIcon
                        ml="1"
                        rootStyle={{ color: '#007965', mr: 1 }}
                      />
                      add notes
                    </Button>
                  </Box>
                </Box>
              )}
            </Stack>

            <InstructionModal />
            <PrescriptionAddNotes
              isModalOpen={addnotes}
              handleClose={AddnotesClosefunc}
              prescriptionNotesInstruction={prescriptionNotesInstruction}
              handleNotesInstructionSave={handleNotesInstructionSave}
            />
          </Box>
          {/* Notification Popup */}
          <ConfirmationPopup
            open={drugAddModal}
            notifyBtnStyletwo={{
              bgcolor: 'primaryTints.200',
              color: 'primary.main',
              '&:hover': {
                bgcolor: 'primaryTints.200',
              },
            }}
            notifyBtnStyle={{
              bgcolor: 'primary.main',
              color: 'common.white',
            }}
            subTitle={
              <div>
                Are you sure, want to add{' '}
                <span style={{ fontWeight: 600 }}>{searchValueState}</span> drug
                in Drug master?
              </div>
            }
            field={
              <Box sx={{ display: 'grid', gap: 0.5 }}>
                <Label
                  rootStyle={{ color: 'text.label' }}
                  htmlFor="category"
                  isRequired
                >
                  Category
                </Label>
                <Dropdown
                  selectOption={
                    Array.isArray(masterDrugCategoryData) &&
                    masterDrugCategoryData?.length > 0
                      ? masterDrugCategoryData
                      : []
                  }
                  id="category"
                  value={category}
                  onChange={(value) => {
                    handleChange('category', value?.target?.value);
                  }}
                  isError={error?.category}
                  helperText={error?.category}
                />
              </Box>
            }
            acceptButtonName="Yes, Sure!"
            declineButtonName="No"
            onDecline={onDecline}
            onAccpet={onAccpet}
          />
        </Screenlayout>
      )}

      {isMobilePort && (
        <Screenlayout
          isDoctorAppbar
          title="RX Group"
          titleStyle={{ color: '#0E1824' }}
          backRequired
          childrenStyle={{
            minHeight: '60vh',
            pt: {
              xs: 10,
            },
          }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={saveRxGroupDrugFnc}
                buttonStyle={Prescription_style.buttonSx}
              >
                SAVE
              </Button>
            </Box>
          }
        >
          <Box p={1.5}>
            <Box sx={Prescription_style.mobileCardSx}>
              <PrescriptionButton
                isMobileResponsive={isMobilePort}
                ActiveButton={type}
                handleClickData={handleClickData}
                closeIcon={searchValueState?.length > 0 ? true : false}
                clearSearchState={clearSearchState}
              />
              <Box>
                <SearchSelect
                  disableCloseOnSelect={
                    searchValueState?.length > 0 ? true : false
                  }
                  placeholder={
                    // type === 'drugs'
                    //   ? 'Search drugs'
                    //   : type === 'rxgroup'
                    //   ? 'Search Rx Group'
                    //   : 'Search drugs / Rx Group'
                    'Enter first 3 characters to search'
                  }
                  onChange={handleAdd}
                  renderOption={(props, option) => {
                    return (
                      <Box
                        sx={{ cursor: 'pointer' }}
                        height="40px"
                        onClick={(e) => handleAdd(e, option)}
                        px={2}
                        key={option.label}
                        display="flex"
                        {...props}
                      >
                        {option?.label?.includes('+Add Drugs') ? (
                          <Box display="flex" alignSelf="center">
                            <Typography
                              sx={{
                                color: 'primary.main',
                                fontSize: '14px',
                                fontWeight: '600',
                              }}
                            >
                              +Add Drug &nbsp;
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                              }}
                            >
                              {searchValueState}
                            </Typography>
                          </Box>
                        ) : (
                          <Box display="flex" alignSelf="center">
                            <Typography>{`${option.label}`}</Typography>
                          </Box>
                        )}
                      </Box>
                    );
                  }}
                  filterOptions={(options, params) =>
                    getFilteredadata(options, params)
                  }
                  fullWidth
                  autocompeleteStyle={Prescription_style.responsiveTextFieldSx}
                  onChangeText={(e) => onChangeText(e?.target?.value)}
                  optionViewKey="label"
                  value={value}
                  startAdornment={
                    <IconButton
                      buttonSxdisableRipple
                      disableFocusRipple
                      disableRipple
                    >
                      <BiSearch />
                    </IconButton>
                  }
                  options={
                    Array.isArray(drugRxGroupDropDownData) &&
                    drugRxGroupDropDownData?.length > 0
                      ? drugRxGroupDropDownData.filter(
                          (da) =>
                            !drugRxGroupData
                              ?.filter((v) => !v?.isDeleted)
                              .some((eachDa) => eachDa?.drugName === da?.name),
                        )
                      : []
                  }
                />
              </Box>
            </Box>

            <Stack>
              {drugRxGroupData.filter((val) => val.isDeleted === false)
                .length === 0 && (
                <Box sx={Prescription_style.PrescriptionEmptystate}>
                  <PrescriptionEmptystate />
                </Box>
              )}

              {drugRxGroupData.filter((val) => val.isDeleted === false).length >
                0 && (
                <Box mt={2} sx={{ height: '60vh', overflow: 'scroll' }}>
                  <Box mt={2} pb={10}>
                    <PrescriptionListCard isMobileResponsive={isMobilePort} />
                  </Box>
                </Box>
              )}
            </Stack>
          </Box>
        </Screenlayout>
      )}
    </>
  );
}
