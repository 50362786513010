import { useAvailability } from '@hc/store';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { AddIcon, CheckBox } from '../../../../atoms';
import { AvailableTimeSelectionInput } from '../availableTimeSelectionInput';
import { dayAvailabilityList_style } from './style';

function DayAvailabilityCard(props) {
  const {
    className = '',
    rootStyle = {},
    data = {},
    parentIndex,
    ...rest
  } = props;

  const md = useMediaQuery('(max-width:864px)');

  const [istimeValid, setIsTimeValid] = useState(true);
  const [isValid, setIsValid] = useState(true);

  // General hooks

  const {
    createAvailability,
    setUnavailabilty,
    weeklyAvailabilities,
    setStartTime,
    setEndTime,
  } = useAvailability(
    (state) => ({
      weeklyAvailabilities: state.weeklyAvailabilities,
      setStartTime: state.setStartTime,
      setEndTime: state.setEndTime,
      createAvailability: state.createAvailability,
      setUnavailabilty: state.setUnavailabilty,
    }),
    (prev, curr) => {
      isEqual(prev, curr);
      return false;
    }
  );
  const getFromTime = (startTime, childIndex, parentIndex) => {
    // debugger
    setIsValid(childIndex);
    if (childIndex === 0) {
      setIsTimeValid(true);
      setStartTime(startTime, childIndex, parentIndex);
    } else if (childIndex > 0) {
      if (
        weeklyAvailabilities[parentIndex]?.slot[childIndex - 1].toTime <
        startTime
      ) {
        setIsTimeValid(true);
        setStartTime(startTime, childIndex, parentIndex);
      } else {
        setIsTimeValid(false);
        toast.error("Enter the time greater than the previous slot 'to' time");
      }
    }
  };

  const getToTime = (endTime, childIndex, parentIndex) => {
    // debugger
    setIsValid(childIndex);
    if (
      weeklyAvailabilities[parentIndex]?.slot[childIndex].fromTime < endTime
    ) {
      setIsTimeValid(true);
      setEndTime(endTime, childIndex, parentIndex);
    } else {
      setIsTimeValid(false);
      toast.error('Please enter the totime greaterthen from fromtime..!!! ');
    }
  };

  const onAddSlot = (parentIndex) => {
    createAvailability(false, parentIndex);
  };

  const setUnavailabiltyCheckClick = (parentIndex) => {
    setUnavailabilty(parentIndex);
  };

  // debugger;

  return (
    <Box
      sx={{
        ...dayAvailabilityList_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
      pb={!data?.is_unavailable ? 3 : 0}
      pt={!data?.is_unavailable ? 3 : 0}
    >
      <Grid container>
        <Grid item xs={12} md={2} sm={md ? 3 : null}>
          <Box display="flex" width={110} py={3} pr={5}>
            <Box mt="-2px" pr={1}>
              <CheckBox
                id={data?.day}
                checked={!data?.is_unavailable}
                checkStyle={{ borderRadius: '3px' }}
                checkSecondStyle={{ borderRadius: '3px' }}
                onChange={() => setUnavailabiltyCheckClick(parentIndex)}
              />
            </Box>
            <Typography fontWeight={500}>{data?.day}</Typography>
          </Box>
        </Grid>
        <Grid item xs={11} md={9} sm={md ? 7 : null}>
          {!data?.is_unavailable ? (
            <Box>
              {data?.slot?.length > 0 &&
                data?.slot?.map((val, childIndex) => (
                  <Box py={0.5} key={childIndex}>
                    <AvailableTimeSelectionInput
                      getFromTime={getFromTime}
                      getToTime={getToTime}
                      istimeValid={istimeValid}
                      isValid={isValid}
                      parentIndex={parentIndex}
                      childIndex={childIndex}
                      data={val}
                      day={data.day}
                    />
                  </Box>
                ))}
            </Box>
          ) : (
            <Box py={3}>
              <Typography color="#808080" fontWeight={500}>
                Unavailable
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={1}
          md={1}
          sm={md ? 2 : null}
          display="flex"
          justifyContent="start"
          alignItems={data?.slot?.length === 1 ? 'center' : 'end'}
          pb={data?.slot?.length === 1 ? 0 : 2.2}
        >
          {!data?.is_unavailable && (
            <Box
              id={`add${parentIndex}`}
              sx={{
                cursor: 'pointer',
                position: 'relative',
                left: data?.slot?.length === 1 ? '-100%' : null,
                // ml: isMobilePort ? '-4px' : null,
              }}
              onClick={() => onAddSlot(parentIndex)}
            >
              <AddIcon
                rootStyle={{ color: '#007965', height: '19', width: '20' }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

DayAvailabilityCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  parentIndex: PropTypes.number,
  data: PropTypes.object,
};

export { DayAvailabilityCard };
