export const professionalInfo_style = {
  rootSx: {
    // pr: 18.5,
    pr: {
      sm: 0,
      md: 0,
      lg: 2,
    },
    pl: {
      sm: 4,
      md: 4,
      lg: 2,
    },
    // pb:10
    // height: 'calc(100% - 56px)',
    // overflowY: 'scroll',
  },
  customtextSx: {
    '& .MuiOutlinedInput-input': {
      height: '51px!important',
      fontSize: '16px',
      fontWeight: 500,
      color: '#0E1824',
      lineHeight: '25px',
      p: 0,
    },
  },
  customAboutSx: {
    '& .MuiOutlinedInput-input': {
      height: '76px!important',
      fontSize: '16px',
      fontWeight: 500,
      p: 0,
    },
  },
  labelStyle: {
    color: '#262C33',
    lineHeight: '24px',
    fontWeight: 400,
    fontSize: '12px',
  },
  inputGroupSx: { display: 'grid', gap: 0.5 },
  dividerSx: {
    display: 'none',
  },
  qnsAnsSelectionHeadSx: {
    display: 'none',
  },
  listingSx: {
    pb: 1,
  },
  listSx: {
    pt: 1,
  },
  qnsAnsTotalSx: {
    pt: 0.5,
  },
  autocompeleteSx: {
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      paddingLeft: '14px !important',
      paddingRight: '14px !important',
      height: '51px!important',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
  },

  listingCardSx: {
    backgroundColor: '#F8F8F8',
    border: '1.5px solid #E3E3E3',
    borderColor: 'E3E3E3',
  },
  startadorentDrSx: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#0E1824',
    lineHeight: '25px',
    pb: 0.2,
  },
  worktypoSx: {
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      p: 0,
    },
  },
};
