import { Avatar, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Button, CalenderIcon } from '../../../atoms';
import { refferalCard_style } from './style';

function RefferalCard(props) {
  const {
    className = '',
    rootStyle = {},
    data,
    app_id,
    doctor_id,
    event_id,
    src = '',
    disabled = false,
    index,
    time,
    date,
    referredBy,
    doctorName = '',
    profilepic = {},
    appointmentCardStyle = {},
    doctorNameStyle = {},
    typeStyle = {},
    doctor_specialities,
    referral_id,
    onClickreffer,
    gender,
    age,
    ...rest
  } = props;


  return (
    <Box
      sx={{
        ...refferalCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box className={`${className}`} {...rest}>
        <Box sx={{ ...refferalCard_style.rootSx, ...rootStyle }}>
          {/* Doctor & his Profession */}
          <Box p={1.5}>
            <Box
              sx={{
                ...refferalCard_style.appointmentCardSx,
                ...appointmentCardStyle,
              }}
            >
              <Box
                sx={{ ...refferalCard_style.doctornameSx, ...doctorNameStyle }}
              >
                <Avatar
                  src={src}
                  sx={{ ...refferalCard_style.profilepicSx, ...profilepic }}
                />
                <Box sx={{ px: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: '600' }}>
                    {doctorName}
                  </Typography>
                  <Box sx={refferalCard_style.professionalContainSx}>
                    <Typography sx={refferalCard_style.professionalSx}>
                      {gender}&nbsp;
                    </Typography>
                    <Typography sx={refferalCard_style.professionalSx}>
                      {age} Yrs&nbsp;
                    </Typography>
                    {/* {Professional} */}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ ...refferalCard_style.typeSx, ...typeStyle }}>
                <Typography sx={refferalCard_style.statusSx}>New</Typography>
              </Box>
            </Box>
            {/* DateTime & Booking */}
            <Box sx={refferalCard_style.appointmentDocCardSx}>
              <Box sx={refferalCard_style.selfSx}>
                <CalenderIcon />
                <Box sx={{ px: 1 }}>
                  {date ? (
                    <Typography sx={refferalCard_style.selfSx}>
                      {date}
                      <span>{time}</span>
                    </Typography>
                  ) : (
                    <Typography sx={refferalCard_style.selfSx}>
                      Any time
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box>
                <Button
                  size="medium"
                  buttonStyle={{
                    ...refferalCard_style.btnSx,
                  }}
                  disabled={disabled}
                  onClick={() => onClickreffer(referral_id)}
                >
                  VIEW NOTE
                </Button>
              </Box>
            </Box>
          </Box>
          {referredBy && (
            <Box
              sx={{
                width: '100%',
                height: '30px',
                bgcolor: '#CCF3EC',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography textAlign="center" fontSize={12}>
                {`Referred By Dr.${referredBy}`}{' '}
                {doctor_specialities && `(${doctor_specialities})`}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

RefferalCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  data: PropTypes.any,
  app_id: PropTypes.any,
  doctor_id: PropTypes.any,
  event_id: PropTypes.any,
  src: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  index: PropTypes.any,
  time: PropTypes.any,
  date: PropTypes.any,
  referredBy: PropTypes.any,
  doctorName: PropTypes.string,
  profilepic: PropTypes.object,
  appointmentCardStyle: PropTypes.object,
  doctorNameStyle: PropTypes.object,
  typeStyle: PropTypes.object,
  doctor_specialities: PropTypes.any,
  referral_id: PropTypes.any,
  onClickreffer: PropTypes.any,
  gender: PropTypes.any,
  age: PropTypes.any,
};

export { RefferalCard };
