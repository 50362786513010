import { Button, DialogModal, Drawer } from '@atoms';
import { useMasterFrequency } from '@hc/store/doctor/masterFrequency';
import { useMasterTime } from '@hc/store/doctor/masterTime';
import { useMasterWhen } from '@hc/store/doctor/masterWhen';
import { Box, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ToggleButton } from '../../../../atoms';
import { prescriptionTimeFrequencyModal_style } from './style';

function PrescriptionTimeFrequencyModal(props) {
  const {
    className = '',
    isModalOpen,
    handleClose,
    timeFrequencyWhenData,
    timeFrequencyWhenChange,
    handleTimeFrequencyWhenSave,
    isMobileResponsive,
    rootStyle = {},
    ...rest
  } = props;

  const { getMasterTimeData, masterTimeData } = useMasterTime((state) => ({
    getMasterTimeData: state.getMasterTimeData,
    masterTimeData: state.masterTimeData,
  }));

  const { getMasterWhenData, masterWhenData } = useMasterWhen((state) => ({
    getMasterWhenData: state.getMasterWhenData,
    masterWhenData: state.masterWhenData,
  }));

  const { getMasterFrequencyData, masterFrequencyData } = useMasterFrequency(
    (state) => ({
      getMasterFrequencyData: state.getMasterFrequencyData,
      masterFrequencyData: state.masterFrequencyData,
    })
  );

  const initialMaterData = async () => {
    await getMasterTimeData();
    await getMasterWhenData();
    await getMasterFrequencyData();
  };

  useEffect(() => {
    initialMaterData();
  }, []);

  return (
    <Box
      sx={{
        ...prescriptionTimeFrequencyModal_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {!isMobileResponsive && (
        <DialogModal
          title="Time, Frequency & When"
          titleStyle={prescriptionTimeFrequencyModal_style.titleSx}
          maxModalWidth="xl"
          topDivider
          bottomDivider
          dialogRootStyle={prescriptionTimeFrequencyModal_style.dialogSx}
          content={
            <Grid container>
              <Grid item xs={12}>
                <Typography sx={prescriptionTimeFrequencyModal_style.titleSx}>
                  Time
                </Typography>
                <ToggleButton
                  dataTestId="time"
                  options={masterTimeData}
                  value={timeFrequencyWhenData?.time?.value}
                  data={timeFrequencyWhenData?.time}
                  buttonactiveStyle={
                    prescriptionTimeFrequencyModal_style.ToggleButtonActiveSx
                  }
                  buttoninactiveStyle={
                    prescriptionTimeFrequencyModal_style.ToggleButtonInActiveSx
                  }
                  nameActiveStyle={
                    prescriptionTimeFrequencyModal_style.activeToggleTimeSx
                  }
                  nameInactiveStyle={
                    prescriptionTimeFrequencyModal_style.inActiveToggleTimeSx
                  }
                  onChange={(e, name) => {
                    timeFrequencyWhenChange('time', e, name?.name);
                  }}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <Typography sx={prescriptionTimeFrequencyModal_style.titleSx}>
                  Frequency
                </Typography>
                <ToggleButton
                  dataTestId="frequency"
                  options={masterFrequencyData}
                  buttonactiveStyle={
                    prescriptionTimeFrequencyModal_style.ToggleButtonActiveSx
                  }
                  buttoninactiveStyle={
                    prescriptionTimeFrequencyModal_style.ToggleButtonInActiveSx
                  }
                  nameActiveStyle={
                    prescriptionTimeFrequencyModal_style.activeToggleTextSx
                  }
                  nameInactiveStyle={
                    prescriptionTimeFrequencyModal_style.inActiveToggleTextSx
                  }
                  value={timeFrequencyWhenData?.frequency?.value}
                  onChange={(e, name) => {
                    timeFrequencyWhenChange('frequency', e, name?.name);
                  }}
                />
              </Grid>
              <Divider />
              <Grid item xs={12} mt={2}>
                <Typography sx={prescriptionTimeFrequencyModal_style.titleSx}>
                  When
                </Typography>
                <ToggleButton
                  dataTestId="when"
                  options={masterWhenData}
                  buttonactiveStyle={
                    prescriptionTimeFrequencyModal_style.ToggleButtonActiveSx
                  }
                  buttoninactiveStyle={
                    prescriptionTimeFrequencyModal_style.ToggleButtonInActiveSx
                  }
                  nameActiveStyle={
                    prescriptionTimeFrequencyModal_style.activeToggleTextSx
                  }
                  nameInactiveStyle={
                    prescriptionTimeFrequencyModal_style.inActiveToggleTextSx
                  }
                  value={timeFrequencyWhenData?.when?.value}
                  onChange={(e, name) => {
                    timeFrequencyWhenChange('when', e, name?.name);
                  }}
                />
              </Grid>
            </Grid>
          }
          actions={
            <Grid
              p={1}
              spacing={1}
              container
              display="flex"
              justifyContent="end"
            >
              <Grid item>
                <Button
                  sx={prescriptionTimeFrequencyModal_style.buttonSx}
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid item>
                <Button
                  id="FrequencySave"
                  onClick={handleTimeFrequencyWhenSave}
                >
                  SAVE
                </Button>
              </Grid>
            </Grid>
          }
          isDialogOpened={isModalOpen}
          handleCloseDialog={handleClose}
        />
      )}

      {isMobileResponsive && (
        <Drawer
          show={isModalOpen}
          header="Time, Frequency & When"
          drawerRightClose
          onCloseDrawer={handleClose}
          headerStyle={{
            fontSize: '16px',
            fontWeight: 600,
            color: '#101010',
            textTransform: 'capitalize',
          }}
          rootStyle={prescriptionTimeFrequencyModal_style.drawerSx}
          closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={handleClose}
                buttonStyle={prescriptionTimeFrequencyModal_style.cancelBtnSx}
              >
                CANCEL
              </Button>
              <Button
                onClick={handleTimeFrequencyWhenSave}
                buttonStyle={prescriptionTimeFrequencyModal_style.applyBtnSx}
              >
                SAVE
              </Button>
            </Box>
          }
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={prescriptionTimeFrequencyModal_style.titleSx}>
                Time
              </Typography>
              <ToggleButton
                dataTestId="time"
                options={masterTimeData}
                value={timeFrequencyWhenData?.time?.value}
                data={timeFrequencyWhenData?.time}
                buttonactiveStyle={
                  prescriptionTimeFrequencyModal_style.ToggleButtonActiveSx
                }
                buttoninactiveStyle={
                  prescriptionTimeFrequencyModal_style.ToggleButtonInActiveSx
                }
                RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                onChange={(e, name) => {
                  timeFrequencyWhenChange('time', e, name?.name);
                }}
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography sx={prescriptionTimeFrequencyModal_style.titleSx}>
                Frequency
              </Typography>
              <ToggleButton
                dataTestId="frequency"
                options={masterFrequencyData}
                buttonactiveStyle={
                  prescriptionTimeFrequencyModal_style.ToggleButtonActiveSx
                }
                buttoninactiveStyle={
                  prescriptionTimeFrequencyModal_style.ToggleButtonInActiveSx
                }
                RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                value={timeFrequencyWhenData?.frequency?.value}
                onChange={(e, name) => {
                  timeFrequencyWhenChange('frequency', e, name?.name);
                }}
              />
            </Grid>
            <Divider />
            <Grid item xs={12} mt={2}>
              <Typography sx={prescriptionTimeFrequencyModal_style.titleSx}>
                When
              </Typography>
              <ToggleButton
                dataTestId="when"
                options={masterWhenData}
                buttonactiveStyle={
                  prescriptionTimeFrequencyModal_style.ToggleButtonActiveSx
                }
                buttoninactiveStyle={
                  prescriptionTimeFrequencyModal_style.ToggleButtonInActiveSx
                }
                nameActiveStyle={
                  prescriptionTimeFrequencyModal_style.activeToggleTextSx
                }
                nameInactiveStyle={
                  prescriptionTimeFrequencyModal_style.inActiveToggleTextSx
                }
                value={timeFrequencyWhenData?.when?.value}
                onChange={(e, name) => {
                  timeFrequencyWhenChange('when', e, name?.name);
                }}
              />
            </Grid>
          </Grid>
        </Drawer>
      )}
    </Box>
  );
}

PrescriptionTimeFrequencyModal.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { PrescriptionTimeFrequencyModal };
