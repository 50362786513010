export const refferalNoteModal_style = {
  rootSx: {
    p: 3,
  },
  logoSx: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '600px',
  },
  orgNameSx: {
    color: 'primary.main',
    fontWeight: 600,
    fontSize: '16px',
    mx: 1,
  },
  subHeadSx: {
    textAlign: 'end',
    fontSize: '12px',
    color: '#0F0B11',
    fontWeight: 600,
  },
  dateSx: {
    fontSize: '10px',
    color: '#808080',
  },
  mainHeadSx: {
    fontSize: '14px',
    color: '#0F0B11',
    fontWeight: 600,
    // pb: 2
  },
  dialogSx: {
    width: '640px',
  },
  buttonSx: {
    mr: 0.5,
    bgcolor: 'white',
    color: 'primary.main',
    borderRadius: '8px',
    '&:hover': {
      color: 'white',
    },
    border: '1px solid #007965',
  },
  titleSx: {
    fontSize: '16px',
    color: '#0E1824',
    fontWeight: 600,
  },
};
