/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { getTimeZoneFnc } from '@hc/dayjs';
import { log } from '@hc/logger';
import { doctorRoutes } from '@hc/routes';
import { queryClient, routeTo } from '@hc/utils';
import toast from 'react-hot-toast';
import { create } from 'zustand';
import { useRouting } from '../common/routing';

export const useAvailability = create((set, get) => ({
  overrideDates: [],
  weeklyAvailabilities: [],
  overrideDatesInitialState: {},
  overRideCopy: [],
  weeklyAvailabilitiesCopy: [],
  weeklyAvailInitialData: [
    {
      day: 'Sun',
      is_unavailable: false,
      slot: [
        {
          fromTime: '09:00',
          toTime: '22:00',
        },
      ],
    },
    {
      day: 'Mon',
      is_unavailable: false,
      slot: [
        {
          fromTime: '09:00',
          toTime: '22:00',
        },
      ],
    },
    {
      day: 'Tue',
      is_unavailable: false,
      slot: [
        {
          fromTime: '09:00',
          toTime: '22:00',
        },
      ],
    },
    {
      day: 'Wed',
      is_unavailable: false,
      slot: [
        {
          fromTime: '09:00',
          toTime: '22:00',
        },
      ],
    },
    {
      day: 'Thu',
      is_unavailable: false,
      slot: [
        {
          fromTime: '09:00',
          toTime: '22:00',
        },
      ],
    },
    {
      day: 'Fri',
      is_unavailable: false,
      slot: [
        {
          fromTime: '09:00',
          toTime: '22:00',
        },
      ],
    },
    {
      day: 'Sat',
      is_unavailable: false,
      slot: [
        {
          fromTime: '09:00',
          toTime: '22:00',
        },
      ],
    },
  ],
  timeZone: getTimeZoneFnc(),
  loading: null,
  error: null,

  getAvailability: async () => {
    try {
      const { timeZone } = get();
      const { weeklyAvailInitialData } = get();
      set({ loading: true });
      const { data } = await queryClient.fetchQuery([
        '/doctor/get/availability',
        'post',
        {
          timezone: timeZone,
        },
      ]);

      localStorage.setItem(
        'overRideDataCopy',
        JSON.stringify(data?.overrideDates)
      );

      const arr = [];
      for (const val of data?.weeklyAvailabilities) {
        if (val?.is_unavailable === true) {
          arr.push(val);
        }
      }

      set({
        loading: false,
        overrideDates: data?.overrideDates,
        weeklyAvailabilities:
          arr?.length === 7
            ? weeklyAvailInitialData
            : data?.weeklyAvailabilities,
        weeklyAvailabilitiesCopy: data?.weeklyAvailabilities,
      });
      return data?.overrideDates;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      if (error.message === 'Request failed with status code 401') {
        // toast('Redirecting to the login page, please continue', { icon: '⚠️' });
        localStorage.clear();
        return routeTo(useRouting, doctorRoutes.signin);
      }
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'something went worng please try aagin !'
      );
    }
  },

  setAvailability: async () => {
    const { weeklyAvailabilities, overrideDates, timeZone } = get();
    try {
      let arr = [];
      let weeklyAvailabilitiesCop = JSON.parse(
        JSON.stringify(weeklyAvailabilities)
      );
      let weeklyAvailabilityResult = [];

      for (const val of weeklyAvailabilitiesCop) {
        arr = [];
        for (const data of val?.slot) {
          const length = 5;
          const fromTime = data?.fromTime.substring(0, length);
          const toTime = data?.toTime.substring(0, length);
          const obj = {
            fromTime,
            toTime,
          };
          arr.push(obj);
        }
        const obj = {
          availability: val?.availability ?? '',
          createdAt: val?.createdAt ?? '',
          created_by: val?.created_by ?? '',
          day: val?.day ?? '',
          id: val?.id ?? '',
          is_active: val?.is_active ?? '',
          is_unavailable: val?.is_unavailable ?? '',
          updatedAt: val?.updatedAt ?? '',
          updated_by: val?.updated_by ?? '',
          user_id: val?.user_id ?? '',
          slot: arr,
          weekly_availibility_time_slots:
            val?.weekly_availibility_time_slots ?? [],
        };
        weeklyAvailabilityResult.push(obj);
      }

      let timeSlotArr = [];
      let overrideDatesCop = JSON.parse(JSON.stringify(overrideDates));

      let overrideDatesResult = [];
      for (const val of overrideDatesCop) {
        timeSlotArr = [];
        for (const data of val?.timeSlots) {
          const length = 5;
          const fromTime = data?.fromTime.substring(0, length);
          const toTime = data?.toTime.substring(0, length);
          const obj = {
            fromTime,
            toTime,
          };
          timeSlotArr.push(obj);
        }
        const obj = {
          endDate: val?.endDate ?? '',
          isUnavailable: val?.isUnavailable ?? '',
          startDate: val?.startDate ?? '',
          timeSlots: timeSlotArr,
        };
        overrideDatesResult.push(obj);
      }

      const data = {
        timezone: timeZone,
        weeklyAvailability: weeklyAvailabilityResult,
        overrideDates: overrideDatesResult,
      };
      localStorage.setItem(
        'overRideDataCopy',
        JSON.stringify(data?.overrideDates)
      );
      set({ loading: true });
      const response = await queryClient.fetchQuery([
        '/doctor/set/availability',
        'post',
        {
          ...data,
        },
      ]);
      set({ loading: false });
      if (response?.status?.code === '200') {
        toast.success(response?.status?.message);

        set({
          weeklyAvailabilitiesCopy: weeklyAvailabilities,
        });
      }
      // return overrideDates;
      return response?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.status?.message ??
          error?.message ??
          'something went worng please try aagin !'
      );
    }
  },

  createAvailability: (isOverRide, parentIndex) => {
    const { weeklyAvailabilities, overrideDatesInitialState } = get();
    let weeklyAvailabilitiesCopy = JSON.parse(
      JSON.stringify(weeklyAvailabilities)
    );
    let overrideDatesInitialStatetimeSlotsCopy;
    if (isOverRide) {
      overrideDatesInitialStatetimeSlotsCopy = JSON.parse(
        JSON.stringify(overrideDatesInitialState?.timeSlots)
      );
    }

    if (parentIndex !== undefined) {
      const initialObj = {
        fromTime: '',
        toTime: '',
      };
      if (!isOverRide) {
        weeklyAvailabilitiesCopy?.[parentIndex]?.slot.push(initialObj);
        set({
          weeklyAvailabilities: weeklyAvailabilitiesCopy,
        });
      } else {
        overrideDatesInitialStatetimeSlotsCopy.push(initialObj);
        set({
          overrideDatesInitialState: {
            ...overrideDatesInitialState,
            timeSlots: overrideDatesInitialStatetimeSlotsCopy,
          },
        });
      }
    }
  },

  deleteAvailability: (isOverRideList, isOverRide, parentIndex, childIndex) => {
    const { weeklyAvailabilities, overrideDatesInitialState, overrideDates } =
      get();
    let weeklyAvailabilitiesCopy = JSON.parse(
      JSON.stringify(weeklyAvailabilities)
    );

    let overrideDatesInitialStatetimeSlotsCopy;
    if (isOverRide) {
      overrideDatesInitialStatetimeSlotsCopy = JSON.parse(
        JSON.stringify(overrideDatesInitialState?.timeSlots)
      );
    }

    let overrideDatesCop;
    if (isOverRideList) {
      overrideDatesCop = JSON.parse(JSON.stringify(overrideDates));
    }

    if (!isOverRide) {
      if (parentIndex !== undefined && childIndex !== undefined) {
        const childDelete = weeklyAvailabilitiesCopy?.[
          parentIndex
        ]?.slot?.filter((val, index) => index !== childIndex);
        weeklyAvailabilitiesCopy.forEach((availability, index, array) => {
          if (index === parentIndex) {
            array[parentIndex] = {
              ...availability,
              slot: childDelete,
            };
          }
        });
        set({
          weeklyAvailabilities: weeklyAvailabilitiesCopy,
        });
      }
    }
    if (isOverRide) {
      if (
        (parentIndex !== undefined && childIndex === undefined) ||
        !childIndex
      ) {
        const childDelete = overrideDatesInitialStatetimeSlotsCopy?.filter(
          (val, index) => index !== parentIndex
        );
        set({
          overrideDatesInitialState: {
            ...overrideDatesInitialState,
            timeSlots: childDelete,
          },
        });
      }
    }

    if (isOverRideList) {
      if (
        (parentIndex !== undefined && childIndex === undefined) ||
        !childIndex
      ) {
        const childDelete = overrideDatesCop?.filter(
          (val, index) => index !== parentIndex
        );

        set({
          overrideDates: childDelete,
        });
      }
    }
  },

  setUnavailabilty: (parentIndex) => {
    const { weeklyAvailabilities, overrideDatesInitialState } = get();
    let weeklyAvailabilitiesCopy = JSON.parse(
      JSON.stringify(weeklyAvailabilities)
    );
    if (parentIndex !== undefined) {
      weeklyAvailabilitiesCopy.forEach((availability, index, array) => {
        if (index === parentIndex) {
          array[parentIndex] = {
            ...availability,
            is_unavailable:
              availability?.is_unavailable === false ? true : false,
          };
        }
      });

      if (weeklyAvailabilitiesCopy?.[parentIndex]?.slot?.length <= 0) {
        if (weeklyAvailabilitiesCopy?.[parentIndex]?.is_unavailable === false) {
          const initialObj = {
            fromTime: '',
            toTime: '',
          };
          weeklyAvailabilitiesCopy?.[parentIndex]?.slot.push(initialObj);
        }
      }
      set({
        weeklyAvailabilities: weeklyAvailabilitiesCopy,
      });
    }

    if (parentIndex === undefined) {
      set({
        overrideDatesInitialState: {
          ...overrideDatesInitialState,
          isUnavailable:
            overrideDatesInitialState?.isUnavailable === false ? true : false,
        },
      });
    }
  },

  setOverrideDatesInitialState: (value) => {
    set({
      overrideDatesInitialState: value,
    });
  },

  setOverrideDate: () => {
    const { overrideDatesInitialState, overrideDates } = get();
    let obj = overrideDatesInitialState;
    if (!obj?.timeSlots[0]?.fromTime && !obj?.timeSlots[0]?.toTime) {
      obj = {
        endDate: overrideDatesInitialState?.endDate,
        isUnavailable: overrideDatesInitialState?.isUnavailable,
        startDate: overrideDatesInitialState?.startDate,
        timeSlots: [],
      };
    }
    const array = overrideDates;
    array.push({
      ...obj,
    });
    set({
      overrideDates: array,
      overrideDatesInitialState: {},
    });
  },

  setOverRideFromAndToDate: (fromDate, toDate) => {
    const { overrideDatesInitialState } = get();
    set({
      overrideDatesInitialState: {
        ...overrideDatesInitialState,
        startDate: fromDate,
        endDate: toDate,
      },
    });
  },

  setStartTime: (startTime, childIndex, parentIndex) => {
    const { overrideDatesInitialState, weeklyAvailabilities } = get();
    if (parentIndex === undefined && childIndex !== undefined && startTime) {
      let overrideDatesInitialStatetimeSlotsCopy = JSON.parse(
        JSON.stringify(overrideDatesInitialState?.timeSlots)
      );

      overrideDatesInitialStatetimeSlotsCopy.forEach((slots, index, array) => {
        if (index === childIndex) {
          array[childIndex] = {
            ...slots,
            fromTime: startTime,
          };
        }
      });

      set({
        overrideDatesInitialState: {
          ...overrideDatesInitialState,
          timeSlots: overrideDatesInitialStatetimeSlotsCopy,
        },
      });
    }

    if (parentIndex !== undefined && childIndex !== undefined && startTime) {
      let weeklyAvailabilitiesCopy = JSON.parse(
        JSON.stringify(weeklyAvailabilities)
      );

      weeklyAvailabilitiesCopy.forEach((availability, index) => {
        if (index === parentIndex) {
          availability.slot.forEach((slot, slotIndex, slotArray) => {
            if (slotIndex === childIndex) {
              slotArray[childIndex] = {
                ...slot,
                fromTime: startTime,
              };
            }
          });
        }
      });

      set({
        weeklyAvailabilities: weeklyAvailabilitiesCopy,
      });
    }
  },

  setEndTime: (endTime, childIndex, parentIndex) => {
    const { overrideDatesInitialState, weeklyAvailabilities } = get();
    if (parentIndex === undefined && childIndex !== undefined && endTime) {
      let overrideDatesInitialStatetimeSlotsCopy = JSON.parse(
        JSON.stringify(overrideDatesInitialState?.timeSlots)
      );

      overrideDatesInitialStatetimeSlotsCopy.forEach((slots, index, array) => {
        if (index === childIndex) {
          array[childIndex] = {
            ...slots,
            toTime: endTime,
          };
        }
      });

      set({
        overrideDatesInitialState: {
          ...overrideDatesInitialState,
          timeSlots: overrideDatesInitialStatetimeSlotsCopy,
        },
      });
    }

    if (parentIndex !== undefined && childIndex !== undefined && endTime) {
      let weeklyAvailabilitiesCopy = JSON.parse(
        JSON.stringify(weeklyAvailabilities)
      );

      weeklyAvailabilitiesCopy.forEach((availability, index) => {
        if (index === parentIndex) {
          availability.slot.forEach((slot, slotIndex, slotArray) => {
            if (slotIndex === childIndex) {
              slotArray[childIndex] = {
                ...slot,
                toTime: endTime,
              };
            }
          });
        }
      });

      set({
        weeklyAvailabilities: weeklyAvailabilitiesCopy,
      });
    }
  },
}));
