export const appointmentHeaders_style = {
  rootSx: {
    boxShadow: 'none',
  },
  avatarSx: {
    background: '#F5F5F5',
    padding: '12px',
    borderRadius: '100%',
    display: 'grid',
    placeItem: 'center',
    position: 'relative',
    width: '41px',
    // mx: 0.8,
    ml: '11.4px',
    mr: '6.4px',
  },
  subTextSx: {
    textTransform: 'capitalize',
    color: '#808080',
    fontSize: '14px',
  },
  referchangingSx: {
    borderRadius: '4px',
    fontWeight: 600,
    color: '#007965',
    backgroundColor: '#E2F7F4',
    padding: '2px 6px',
  },
  followUpSx: {
    borderRadius: '4px',
    fontWeight: 600,
    color: 'primary.main',
    backgroundColor: 'primary.lighter',
    padding: 0,
    fontSize: '10px',
    top: '-14px !important',
    position: 'relative',
    '&.MuiChip-label': {
      p: 0,
    },
  },
  referralSx: {
    borderRadius: '4px',
    fontWeight: 600,
    color: 'primary.main',
    backgroundColor: 'primary.lighter',
    paddingLeft: '3px',
    fontSize: '10px',
    top: '-14px !important',
    position: 'relative',
    '&.MuiChip-label': {
      p: 0,
    },
  },
  isNew: {
    borderRadius: '4px',
    fontWeight: 600,
    color: '#F58634',
    backgroundColor: '#FFF3EB',
    border: '1px solid #FAE3D2',
    padding: '2px 6px',
    fontSize: '10px',
    position: 'absolute',
    mt: -1,
    mx: 1,
  },
};
