export const availableTimeSelectionInput_style = {
  timepickerSx: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid #DBEAE8',
        width: '140px',
      },
      '&:hover fieldset': {
        border: '1.5px solid #DBEAE8',
      },
      '&.Mui-focused fieldset': {
        border: '2px solid #007965',
      },
    },
    '& .MuiFormHelperText-root': {
      mx: 0,
    },
  },
};
