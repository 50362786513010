export const refferals_style = {
  cardSx: {
    boxShadow: 'none',
    '& .MuiCardHeader-root': {
      padding: '0px',
    },
    borderBottom: `1px dashed #E3E3E3`,
    padding: '24px 0px',
  },
  buttonSx: {
    boxShadow: 'none',
    border: '2px solid #007965',
    borderRadius: '8px',
    padding: '8px',
    fontSize: '14px',
    '&:hover': {
      border: '2px solid #007965',
    },
  },
  gridcenterSx: {
    alignItems: 'center',
    display: 'flex',
  },
  subHeadSx: {
    fontSize: '16px',
    color: '#888888',
    // p: 5.5,
    fontWeight: '25px',
    textAlign: 'center',
  },
  referchangingSx: {
    borderRadius: '4px',
    fontWeight: 600,
    color: 'primary.main',
    backgroundColor: 'primary.lighter',
    padding: '2px 6px',
  },
  borderSx: {
    fontSize: '12px',
    fontWeight: 600,
    margin: 0,
    color: 'error.main',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'common.white',
    },
  },
  cancelAppointment: {
    ml: 0.9,
  },
};
