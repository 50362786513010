import { getDateFormat } from '@hc/dayjs';
import { useViewPort } from '@hc/store';
import { useDoctorChatConsultation } from '@hc/store/doctor/chatConsultation';
import { Box, Modal } from '@mui/material';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import {
  Button,
  CalenderIcon,
  CancelAppointmentIcon,
  ClockIcon,
  ConfirmAppointmentIcon,
  VideoIcon,
} from '../../../atoms';
import {
  AppointmentCard,
  AppointmentShedule,
  BookingMinDetails,
  DoctorProfile,
} from '../../clinical';
import { doctorAcceptReject_style } from './style';

function DoctorAcceptReject(props) {
  const {
    confirmed,
    message = 'Chat Request',
    subMessage = '',
    className = '',
    isModalOpen,
    onRejectRequestFnc = () => {},
    onAcceptRequestFnc = () => {},
    handleDialogueClose = () => {},
    myself = false,
    rootStyle = {},
    ...rest
  } = props;

  const { doctorChatConsultationState } = useDoctorChatConsultation(
    (state) => ({
      doctorChatConsultationState: state.doctorChatConsultationState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const { chatStatusData } = doctorChatConsultationState;

  const {
    doctorProfileId = '',
    doctorUserId = '',
    doctorProfilePicture = '',
    doctorName = '',
    doctorMobileNo = '',
    doctorChatConsultationAmount = 0,
    chatRequestedDate = '',
    chatConsultationDuration = '',
    patientUserId = '',
    patientProfileId = '',
    patientProfilePicture = '',
    patientName = '',
    patientMobileNo = '',
    // type,
  } = chatStatusData;

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  return (
    <Box
      sx={{
        ...doctorAcceptReject_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={doctorAcceptReject_style.rootSx}>
        <Modal open={isModalOpen} onClose={handleDialogueClose}>
          <Box sx={doctorAcceptReject_style.totalModalSx}>
            <Box
              sx={
                isMobilePort
                  ? doctorAcceptReject_style.totalMobileBoxSx
                  : doctorAcceptReject_style.totalBoxSx
              }
            >
              <BookingMinDetails
                isIconRequired={false}
                isSubTextRequired
                isSmsTextRequired={false}
                subText=""
                iconType={
                  confirmed ? (
                    <ConfirmAppointmentIcon />
                  ) : (
                    <CancelAppointmentIcon />
                  )
                }
                appointmentType={message}
                updateStyle={doctorAcceptReject_style.smsTextSx}
                smsText={subMessage}
              >
                <AppointmentCard
                  appointmentCardStyle={doctorAcceptReject_style.rootCardSx}
                  mainStyle={doctorAcceptReject_style.mainSx}
                  header={
                    <DoctorProfile
                      src={patientProfilePicture ?? ''}
                      doctorName={patientName ?? ''}
                      aboutpatient={{
                        patientRole: patientMobileNo ?? '',
                        relationship: '',
                      }}
                      pricingRequired={doctorChatConsultationAmount}
                      apointmentCardstyle={doctorAcceptReject_style.cardSx}
                      profilepicStyle={doctorAcceptReject_style.profilepicSx}
                      tagStyle={doctorAcceptReject_style.priceTagSx}
                    />
                  }
                  footer={
                    <AppointmentShedule
                      icon={<CalenderIcon />}
                      dateText="Date"
                      // yearText="09 Apr,2003"
                      yearText={getDateFormat(
                        chatRequestedDate,
                        'DD MMM, YYYY',
                      )}
                      timeText="Time"
                      timeIcon={<ClockIcon />}
                      // timingClock="09:15 AM"
                      timingClock={chatConsultationDuration ?? '15 Mins'}
                      videoIcon={<VideoIcon />}
                      confirmed={confirmed}
                      rootStyle={doctorAcceptReject_style.appointmentRootSx}
                    />
                  }
                />
              </BookingMinDetails>

              <Box sx={{ display: 'flex', p: 2, gap: 2 }}>
                <Button
                  sx={doctorAcceptReject_style.DeletebuttonSx}
                  onClick={() => onRejectRequestFnc()}
                >
                  Reject
                </Button>
                <Button
                  sx={doctorAcceptReject_style.closebuttonSx}
                  onClick={() => onAcceptRequestFnc()}
                >
                  {' '}
                  Accept
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

DoctorAcceptReject.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { DoctorAcceptReject };

