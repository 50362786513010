import { SplitIcon, Switch } from '@atoms';
import { Box, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useViewPort } from '@hc/store';
import { drugsGroupCard_style } from './style';

function DrugsGroupCard(props) {
  const {
    className = '',
    rootStyle = {},
    groupName = '',
    isModalOpen,
    handleClose,
    drugsCount,
    isEdit,
    isActive = true,
    status = false,   
    onClick = () => {},
    handleEditOpen = () => {},
    onChange,
    data,
    index,
    length,
    data_testId,
    ...rest
  } = props;

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  return (
    <Box
      sx={{
        ...drugsGroupCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box
        id={data_testId}
        my="10px"
        px={1.5}
        py={1}
        sx={{
          ...(isActive
            ? drugsGroupCard_style.activeBoxSx
            : drugsGroupCard_style.boxSx),
          ...(isMobilePort && drugsGroupCard_style.responsiveCard),
        }}
      >
        <Grid
          id={data_testId}
          container
          justifyContent="space-between"
          display="flex"
          onClick={onClick}
        >
          <Grid item>
            <Typography
              color={isActive ? '#007965' : '#5C6266'}
              fontWeight={500}
              gutterBottom
            >
              {groupName}
            </Typography>
            <Typography fontSize={14} color="#808080">
              {drugsCount}&nbsp;{drugsCount === 1 ? 'Drug' : 'Drugs'}
            </Typography>
          </Grid>
          <Grid item display="flex" alignSelf="center">
            <Box
              mt="4px"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleEditOpen(data)}
            >
              <SplitIcon />
            </Box>
            <Box ml={1.6}>
              <Switch
                checked={status}
                onChange={onChange}
                rootStyle={drugsGroupCard_style.switchSx}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {!isMobilePort && length - 1 !== index && <Divider variant="middle" />}
    </Box>
  );
}

DrugsGroupCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  groupName: PropTypes.string,
  drugsCount: PropTypes.string,
  isActive: PropTypes.bool,
  status: PropTypes.bool,
  onClick: PropTypes.func,
  onRenameDialogOpen: PropTypes.func,
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleEditOpen: PropTypes.func,
  isEdit: PropTypes.bool,
  onChange: PropTypes.any,
  data: PropTypes.any,
  index: PropTypes.any,
  length: PropTypes.any,
  data_testId: PropTypes.any,
};

export { DrugsGroupCard };
