export const mobilePrescriptionCard_style = {
  rootSx: { border: '1px solid #E2E2E2', borderRadius: '8px', mt: 1 },
  buttonSx: {
    bgcolor: 'primaryTints.200',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  dividerSx: {
    height: '20px',
    border: '1px solid',
    borderColor: '#E2E2E2',
    backgroundColor: '#E2E2E2',
    mt: '-1px',
  },

  cardFooterSx: {
    fontSize: '12px',
    fontWeight: 600,
    color: '#007965',
  },
};
