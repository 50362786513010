/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { UserProfileIcon} from '@atoms';
import { doctorRoutes } from '@hc/routes';
import { useDoctorOnboarding, useDoctorProfile, useViewPort } from '@hc/store';
import Waves from '@hc/ui/assets/Wave.svg';
import { BasicInfo } from '@hc/ui/components/doctor/basicInfo';
import { getAuthData } from '@hc/utils/helperFunctions';
import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../atoms';
import { DoctorTitleCard } from '../doctorTitleCard';

import { Stepper } from '../stepper';
import { stepperForm_style } from './style';

export function StepperFormOthers(props) {
  const { logo } = props;
  const navigate = useNavigate();
  const data = getAuthData();

  const { activeIndex, handleBack, nextActive, doctor } =
    useDoctorOnboarding();

  const {
    // upsertProfileBasicInfo,
    upsertProfileBasicInfoOthers,
    isIamValidedForOthers,
  
    loading,
  } = useDoctorProfile((state) => ({
    profile: state.profile,
    upsertProfileBasicInfo: state.upsertProfileBasicInfo,
    upsertProfileBasicInfoOthers:state.upsertProfileBasicInfoOthers,
    isIamValidedForOthers: state.isIamValidedForOthers,
    isDocterValidToSave: state.isDocterValidToSave,
    loading: state.loading,
  }));

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const [stepsCompleted, setstepsCompleted] = useState([]);


  const onNext = async (tab) => { 
   if (tab === 0) {
      setstepsCompleted([...stepsCompleted, tab]);
      if (isIamValidedForOthers()) {
        const responseData = await upsertProfileBasicInfoOthers(
          data?.user_id,
          doctor,
        );
        if (responseData === 200) {
          navigate(doctorRoutes?.home);
        }
      }
    }
  };

  const onBack = () => {
    handleBack();
    setstepsCompleted([...stepsCompleted.splice(stepsCompleted.lenth - 1, 1)]);
  };

  // Function Render component
  function stepRenderer(index) {
    switch (index) {
      case 0:
        return (
          <Box
            sx={
              isMobilePort
                ? stepperForm_style.rightMobileSx
                : stepperForm_style.rightSx
            }
          >
            <DoctorTitleCard
              title="Update Basic Info"
              description={doctor?.email ?? ''}
              rootStyle={{
                mt: isMobilePort ? 13 : '',
                position: !isMobilePort ? 'sticky' : '',
                top: isMobilePort ? 85 : 0,
                zIndex: !isMobilePort ? 100 : '',
                backgroundColor: '#fff',
              }}
              nextStyle={isMobilePort ? { zIndex: '1' } : ''}
              onNext={onNext}
              onBack={onBack}
              activeIndex={activeIndex}
              activeBack={activeIndex > 0 ? true : false}
              activeNext={
                !isMobilePort
                  ? activeIndex === 0 && nextActive === false
                    ? true
                    : false
                  : false
              }
              others
            />

            <Box sx={stepperForm_style.dataSx}>
              <BasicInfo others   />
            </Box>
          </Box>
        );
      default:
        break;
    }
  }

  return (
    <Box sx={stepperForm_style.rootSx} container>
      {/* left Side */}
      {!isMobilePort && (
        <Box sx={stepperForm_style.leftSx}>
          <Box sx={stepperForm_style.TotalLogoSx}>
            <Box sx={stepperForm_style.logoSx}>{logo}</Box>
            <Box sx={{ display: 'grid', ml: 1 }}>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontWeight: 600,
                  fontSize: '16px',
                }}
              >
                Health Circles
              </Typography>
            </Box>
          </Box>
          <Stack sx={stepperForm_style.totalStepperSx}>
            <Box
              px={2}
              data-active={activeIndex === 2 ? true : false}
              id="stepper"
            >
              <Stepper
              others
                vertical
                stepsCompleted={stepsCompleted}
                activeIndex={activeIndex}
                StepperFunc={[
                  {
                    stepperIcon: (
                      <UserProfileIcon rootStyle={{ color: '#fff' }} />
                    ),
                    step: 'Step 1',
                    stepDescription: 'Basic Info',
                  },
                 
               
                ]}
              />
            </Box>
            <Box>
              <img src={Waves} alt="Waves" width="100%" />
            </Box>
          </Stack>
        </Box>
      )}

      {isMobilePort && (
        <Box display="flex" justifyContent="">
          <Box sx={{ ...stepperForm_style.stepperbarSx }}>
            <Typography color="#fff">
              {' '}
              <Stepper
              others

                horizontal
                stepsCompleted={stepsCompleted}
                activeIndex={activeIndex}
                StepperFunc={[
                  // {
                  //   stepperIcon: <LockPassword />,
                  //   step: 'step 1',
                  //   stepDescription: 'Set New Password',
                  // },
                  {
                    stepperIcon: (
                      <UserProfileIcon rootStyle={{ color: '#fff' }} />
                    ),
                    step: 'Step 1',
                    stepDescription: 'Basic Info',
                  },
                  
                ]}
              />
              {/* <CommonSteper step={1} icons={icons} stepText={steps} /> */}
            </Typography>
          </Box>
          <Box sx={{ ...stepperForm_style.footerNavBarSx }}>
           
           
            {activeIndex === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6}>
                  <Button variant="outlined" onClick={() => onBack()} fullwidth>
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Button
                    onClick={() => onNext(activeIndex)}
                    fullwidth
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      )}

      {/* Right Side */}
      {stepRenderer(activeIndex)}
    </Box>
  );
}
StepperFormOthers.propTypes = {};
