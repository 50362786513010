import { Button, DialogModal, Drawer } from '@atoms';
import { Box, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { ToggleButton } from '../../../../atoms';
import { prescriptionDoseModal_style } from './style';

function PrescriptionDoseModal(props) {
  const {
    className = '',
    isModalOpen,
    handleClose,
    rootStyle = {},
    doseData,
    doseChange,
    handleDoseSave,
    isMobileResponsive,
    ...rest
  } = props;

  const doseOptions = [
    {
      name: '0',
      value: 0,
    },
    {
      name: '1',
      value: 1,
    },
  ];

  return (
    <Box
      sx={{
        ...prescriptionDoseModal_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {!isMobileResponsive && (
        <DialogModal
          title="Dose"
          titleStyle={prescriptionDoseModal_style.titleSx}
          maxModalWidth="xl"
          topDivider
          dialogRootStyle={prescriptionDoseModal_style.dialogSx}
          content={
            <Grid container py={2}>
              <Grid item xs={4} display="flex" justifyContent="space-evenly">
                <Box>
                  <Typography sx={prescriptionDoseModal_style.titleSx}>
                    Morning
                  </Typography>
                  <ToggleButton
                    dataTestId="morning"
                    options={doseOptions}
                    value={doseData?.morningDose}
                    buttonactiveStyle={
                      prescriptionDoseModal_style.toggleButtonActiveSx
                    }
                    buttoninactiveStyle={
                      prescriptionDoseModal_style.toggleButtonInActiveSx
                    }
                    onChange={(e) => {
                      doseChange('morningDose', e);
                    }}
                  />
                </Box>
                <Divider flexItem orientation="vertical" />
              </Grid>

              <Grid item xs={4} display="flex" justifyContent="space-evenly">
                <Box>
                  <Typography sx={prescriptionDoseModal_style.titleSx}>
                    Afternoon
                  </Typography>

                  <ToggleButton
                    dataTestId="afternoon"
                    options={doseOptions}
                    buttonactiveStyle={
                      prescriptionDoseModal_style.toggleButtonActiveSx
                    }
                    buttoninactiveStyle={
                      prescriptionDoseModal_style.toggleButtonInActiveSx
                    }
                    value={doseData?.afterNoonDose}
                    onChange={(e) => {
                      doseChange('afterNoonDose', e);
                    }}
                  />
                </Box>
                <Divider flexItem orientation="vertical" />
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="space-evenly">
                <Box>
                  <Typography sx={prescriptionDoseModal_style.titleSx}>
                    Night
                  </Typography>
                  <ToggleButton
                    dataTestId="night"
                    options={doseOptions}
                    buttonactiveStyle={
                      prescriptionDoseModal_style.toggleButtonActiveSx
                    }
                    buttoninactiveStyle={
                      prescriptionDoseModal_style.toggleButtonInActiveSx
                    }
                    value={doseData?.nightDose}
                    onChange={(e) => {
                      doseChange('nightDose', e);
                    }}
                  />{' '}
                </Box>
              </Grid>
            </Grid>
          }
          actions={
            <Grid
              p={1}
              spacing={1}
              container
              display="flex"
              justifyContent="end"
            >
              <Grid item>
                <Button
                  sx={prescriptionDoseModal_style.buttonSx}
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid item>
                <Button id="doseSave" onClick={handleDoseSave}>
                  SAVE
                </Button>
              </Grid>
            </Grid>
          }
          isDialogOpened={isModalOpen}
          handleCloseDialog={handleClose}
        />
      )}

      {isMobileResponsive && (
        <Drawer
          show={isModalOpen}
          header="Dose"
          drawerRightClose
          onCloseDrawer={handleClose}
          headerStyle={{
            fontSize: '16px',
            fontWeight: 600,
            color: '#101010',
            textTransform: 'capitalize',
          }}
          rootStyle={prescriptionDoseModal_style.drawerSx}
          closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={handleClose}
                buttonStyle={prescriptionDoseModal_style.cancelBtnSx}
              >
                CANCEL
              </Button>
              <Button
                onClick={handleDoseSave}
                buttonStyle={prescriptionDoseModal_style.applyBtnSx}
              >
                SAVE
              </Button>
            </Box>
          }
        >
          <Grid container py={2}>
            <Grid item xs={4} display="flex" justifyContent="space-evenly">
              <Box>
                <Typography sx={prescriptionDoseModal_style.titleSx}>
                  Morning
                </Typography>
                <ToggleButton
                  dataTestId="morning"
                  options={doseOptions}
                  value={doseData?.morningDose}
                  buttonactiveStyle={
                    prescriptionDoseModal_style.toggleButtonActiveSx
                  }
                  buttoninactiveStyle={
                    prescriptionDoseModal_style.toggleButtonInActiveSx
                  }
                  onChange={(e) => {
                    doseChange('morningDose', e);
                  }}
                />
              </Box>
              <Divider flexItem orientation="vertical" />
            </Grid>

            <Grid item xs={4} display="flex" justifyContent="space-evenly">
              <Box>
                <Typography sx={prescriptionDoseModal_style.titleSx}>
                  Afternoon
                </Typography>

                <ToggleButton
                  dataTestId="afternoon"
                  options={doseOptions}
                  buttonactiveStyle={
                    prescriptionDoseModal_style.toggleButtonActiveSx
                  }
                  buttoninactiveStyle={
                    prescriptionDoseModal_style.toggleButtonInActiveSx
                  }
                  value={doseData?.afterNoonDose}
                  onChange={(e) => {
                    doseChange('afterNoonDose', e);
                  }}
                />
              </Box>
              <Divider flexItem orientation="vertical" />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="space-evenly">
              <Box>
                <Typography sx={prescriptionDoseModal_style.titleSx}>
                  Night
                </Typography>
                <ToggleButton
                  dataTestId="night"
                  options={doseOptions}
                  buttonactiveStyle={
                    prescriptionDoseModal_style.toggleButtonActiveSx
                  }
                  buttoninactiveStyle={
                    prescriptionDoseModal_style.toggleButtonInActiveSx
                  }
                  value={doseData?.nightDose}
                  onChange={(e) => {
                    doseChange('nightDose', e);
                  }}
                />{' '}
              </Box>
            </Grid>
          </Grid>
        </Drawer>
      )}
    </Box>
  );
}

PrescriptionDoseModal.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export { PrescriptionDoseModal };
