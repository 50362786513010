import { Button, DialogModal } from '@atoms';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { AddMedicineModal } from '../../addMedicineModal';
import { drugsAddDialog_style } from './style';

function DrugsAddDialog(props) {
  const {
    className = '',
    rootStyle = {},
    isModalOpen,
    handleClose = () => {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...drugsAddDialog_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <DialogModal
        title="DRUG ADDITION"
        titleStyle={drugsAddDialog_style.titleSx}
        maxModalWidth="xl"
        topDivider
        bottomDivider
        dialogRootStyle={drugsAddDialog_style.dialogSx}
        content={
          <Box>
            <AddMedicineModal />
          </Box>
        }
        actions={
          <Grid p={1} container display="flex" justifyContent="end">
            <Grid item md={2.5} lg={2.5} sm={2.5} xl={2.5}>
              <Button>ADD DRUG</Button>
            </Grid>
          </Grid>
        }
        isDialogOpened={isModalOpen}
        handleCloseDialog={handleClose}
      />
    </Box>
  );
}

DrugsAddDialog.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export { DrugsAddDialog };
