import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { mobilePrescriptionCard_style } from './style';
import { DeleteIcon } from '../../../atoms';

function MobilePrescriptionCard(props) {
  const {
    className = '',
    rootStyle = {},
    drugName,
    dose,
    time,
    duration,
    deleteDrug,
    id,
    quality,
    data,
    DoseModalOpenfunc,
    FrequencyModelOpenfunc,
    DurationModalOpenFunc,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...mobilePrescriptionCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box mb={1.2} mt={1} px={2} pt={1}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            gutterBottom
            fontSize="14px"
            fontWeight={500}
            color="#888888"
          >
            • {drugName}
          </Typography>
          <IconButton
            disableFocusRipple
            disableRipple
            disableTouchRipple
            color="secondary"
            onClick={() => deleteDrug(drugName, id)}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>

        <Grid container columnSpacing={2}>
          <Grid item>
            <Typography fontSize="14px" color="#888888">
              {dose}
            </Typography>
          </Grid>
          <Grid item>
            <Divider
              sx={mobilePrescriptionCard_style.dividerSx}
              orientation="vertical"
              variant="middle"
              flexItem
            />
          </Grid>
          <Grid item>
            <Typography fontSize="14px" color="#888888">
              {time}
            </Typography>
          </Grid>
          <Grid item>
            <Divider
              sx={mobilePrescriptionCard_style.dividerSx}
              orientation="vertical"
              variant="middle"
              flexItem
            />
          </Grid>
          <Grid item>
            <Typography fontSize="14px" color="#888888">
              {duration}
            </Typography>
          </Grid>
          <Grid item>
            <Divider
              sx={mobilePrescriptionCard_style.dividerSx}
              orientation="vertical"
              variant="middle"
              flexItem
            />
          </Grid>
          <Grid item>
            <Typography fontSize="14px" color="#888888">
              {quality} Qty
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          background: '#F8F8F8',
          borderTop: '1px solid #E2E2E2',
          px: 2,
          pt: 1,
          cursor: 'pointer',
        }}
      >
        <Typography
          sx={mobilePrescriptionCard_style.cardFooterSx}
          onClick={() => DoseModalOpenfunc(data, id)}
        >
          Dose
        </Typography>
        <Divider
          sx={mobilePrescriptionCard_style.dividerSx}
          orientation="vertical"
          variant="middle"
          flexItem
        />
        <Typography
          sx={mobilePrescriptionCard_style.cardFooterSx}
          onClick={() => FrequencyModelOpenfunc(data, id)}
        >
          Time & Frequency
        </Typography>
        <Divider
          sx={mobilePrescriptionCard_style.dividerSx}
          orientation="vertical"
          variant="middle"
          flexItem
        />
        <Typography
          sx={mobilePrescriptionCard_style.cardFooterSx}
          onClick={() => DurationModalOpenFunc(data, id)}
        >
          Duration & Qty
        </Typography>
      </Box>
    </Box>
  );
}

MobilePrescriptionCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { MobilePrescriptionCard };
