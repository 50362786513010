export const drugsImportDialog_style = {
  rootSx: {},
  titleSx: {
    fontSize: 16,
    fontWeight: 600,
  },
  dialogSx: {
    width: '650px',
    height: '550px',
  },
};
