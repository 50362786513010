import { Grid, Skeleton, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { rxGroupSkeleton_style } from './style';

function RxGroupSkeleton(props) {
  const { color } = props;

  return (
    <Grid container border="1px solid #DCF5F1" p={2} my={1}>
      <Grid item xs={6}>
        <Stack rowGap={0.5}>
          <Skeleton
            variant="rounded"
            width={100}
            height={20}
            animation="wave"
            sx={{
              ...rxGroupSkeleton_style.skeletonRoot,
              ...(color && { background: color }),
            }}
          />
          <Skeleton
            variant="rounded"
            width={100}
            height={20}
            animation="wave"
            sx={{
              ...rxGroupSkeleton_style.skeletonRoot,
              ...(color && { background: color }),
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack
          rowGap={0.5}
          columnGap={2}
          flexDirection="row"
          justifyContent="end"
          alignItems="center"
          height="100%"
        >
          <Skeleton
            variant="rounded"
            width={35}
            height={35}
            animation="wave"
            sx={{
              ...rxGroupSkeleton_style.skeletonRoot,
              ...(color && { background: color }),
            }}
          />
          <Skeleton
            variant="rounded"
            width={35}
            height={35}
            s
            animation="wave"
            sx={{
              ...rxGroupSkeleton_style.skeletonRoot,
              ...(color && { background: color }),
            }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}

RxGroupSkeleton.propTypes = {
  color: PropTypes.string,
};

export { RxGroupSkeleton };

