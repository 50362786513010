import PrescriptionEmptyState from '@hc/ui/assets/PrescriptionEmptyState.svg';
import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { prescriptionEmptystate_style } from './style';

function PrescriptionEmptystate(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  return (
    <Stack
      sx={{
        ...prescriptionEmptystate_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box>
        <img src={PrescriptionEmptyState} alt="Prescription Empty State" />
      </Box>
      <Typography
        mt={2}
        fontSize="20px"
        fontWeight={500}
        textAlign="center"
        color="#808080"
      >
        {' '}
        No drugs added yet
      </Typography>
      {/* sx={prescriptionEmptystate_style.description} */}
    </Stack>
  );
}

PrescriptionEmptystate.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { PrescriptionEmptystate };
