export const drugsGroupList_style = {
  searchFiledSx: {
    searchFiledSx: {
      '& .MuiOutlinedInput-root': {
        // py: 0.3,
        color: '#C5C5C5',
        '&.Mui-focused fieldset': {
          borderColor: '#DBEAE8',
          color: '#0E1824',
        },
      },
      '& .MuiOutlinedInput-input': {
        fontSize: '14px',
        color: '#0E1824',
      },
    },
  },
};
