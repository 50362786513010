import { Button } from '@hc/ui/atoms';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useRxGroup } from '@hc/store/doctor/rxGroup';
import { doctorRoutes } from '@hc/routes';
import PropTypes from 'prop-types';
// import { useBookAppointment } from '@hc/store';
// import isEqual from 'react-fast-compare';
import { useKeyPress } from 'ahooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewDrug_style } from './style';

export default function ViewDrug(props) {
  const { className = '', ...rest } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;

  const { handleRxGroupDataChange } = useRxGroup((state) => ({
    handleRxGroupDataChange: state.handleRxGroupDataChange,
  }));

  const { rxGroupState } = useRxGroup((state) => ({
    rxGroupState: state.rxGroupState,
    loading: state.loading,
  }));

  const drugAddFnc = async (type) => {
    await handleRxGroupDataChange('isAddDrug', type === 'add' ? true : null);
    navigate(doctorRoutes?.Prescription, {
      state: {
        fromPage: 'rxGroup',
      },
    });
  };
  const { drugRxGroupData } = rxGroupState;
  // While onEnterKeyPress Text  will Added
  useKeyPress(['Enter'], (event) => {
    event.preventDefault();
  });

  return (
    <Screenlayout
      isDoctorAppbar
      title={state.drugName}
      titleStyle={{ color: '#0E1824' }}
      className={`${className}`}
      backRequired
      childrenStyle={{
        minHeight: '60vh',
        pt: {
          xs: 10,
        },
      }}
      footer={
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button onClick={drugAddFnc}>ADD DRUGS</Button>
        </Box>
      }
      {...rest}
    >
      {/* Symptoms */}
      <Box>
        <Box sx={ViewDrug_style.boxRootSx}>
          {/* Qus and Ans Selection Lisiting */}
          {drugRxGroupData.map((val, i) => (
            <Box
              key={i}
              mb={1.2}
              mt={1}
              sx={{ border: '1px solid #E2E2E2', borderRadius: '8px', p: 2 }}
            >
              <Typography
                gutterBottom
                fontSize="14px"
                fontWeight={500}
                color="#888888"
              >
                • {val?.drugName ?? ''}
              </Typography>

              <Grid container columnSpacing={2}>
                <Grid item>
                  <Typography fontSize="14px" color="#888888">
                    {`${val?.morningDose} - ${val?.afterNoonDose} - ${val?.nightDose}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    sx={ViewDrug_style.dividerSx}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                </Grid>
                <Grid item>
                  <Typography fontSize="14px" color="#888888">
                    {`${val?.time?.label} - ${val?.when?.label}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    sx={ViewDrug_style.dividerSx}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                </Grid>
                <Grid item>
                  <Typography fontSize="14px" color="#888888">
                    {`${val?.frequency?.label} for ${val?.duration} ${val?.durationMode?.label}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    sx={ViewDrug_style.dividerSx}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                </Grid>
                <Grid item>
                  <Typography fontSize="14px" color="#888888">
                    {val?.quantity} Qty
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>
    </Screenlayout>
  );
}

ViewDrug.propTypes = {
  className: PropTypes.string,
};
