export const reportCard_style = {
  reportCardSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pb: 1,
  },
  selectSx: {
    border: ' 1px solid #007965',
    padding: 1,
    borderRadius: '8px',
    backgroundColor: '#E1EDEB',
  },
  selectdSx: {
    padding: 1,
  },
  reportTypeSx: {
    fontSize: '14px',
    color: '#0E1824',
    fontWeight: '500',
  },
  dateSx: {
    fontSize: '14px',
    color: '#4D4D4D',
  },
  dateUnselectSx: {
    fontSize: '14px',
    color: '#808080',
  },
  reportDescSx: {
    color: '#0E1824',
    fontSize: '16px',
    fontWeight: '500',
    // marginTop: "8px",
  },
  reportDescUnselectSx: {
    color: '#5C6266',
    fontSize: '16px',
    // marginTop: "8px",
  },
  reportTypeUnselectSx: {
    color: '#808080',
    fontSize: '14px',
  },
};
