import { Box, Divider, Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { BiSearch } from 'react-icons/bi';
import { Button, CloseIcon } from '../../../../atoms';
import { prescriptionButton_style } from './style';

function PrescriptionButton(props) {
  const {
    className = '',
    rootStyle = {},
    handleClickData,
    ActiveButton = '',
    closeIcon = false,
    clearSearchState,
    isMobileResponsive,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...prescriptionButton_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {!isMobileResponsive && (
        <>
          {' '}
          <Button
            buttonStyle={{
              ...prescriptionButton_style.buttonSx,
              ...(ActiveButton === '' && {
                bgcolor: '#007965',
                color: 'white',
              }),
            }}
            onClick={() => {
              handleClickData('');
            }}
          >
            All
          </Button>
          <Button
            buttonStyle={{
              ...prescriptionButton_style.buttonSx,
              ...(ActiveButton === 'drugs' && {
                bgcolor: '#007965',
                color: 'white',
              }),
            }}
            onClick={() => {
              handleClickData('drugs');
            }}
          >
            {' '}
            Drugs
          </Button>
          <Button
            buttonStyle={{
              ...prescriptionButton_style.buttonSx,
              ...(ActiveButton === 'rxgroup' && {
                bgcolor: '#007965',
                color: 'white',
              }),
            }}
            onClick={() => {
              handleClickData('rxgroup');
            }}
          >
            {' '}
            Rx Group
          </Button>{' '}
        </>
      )}

      {isMobileResponsive && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {' '}
            <Button
              buttonStyle={{
                ...prescriptionButton_style.buttonSx,
                ...(ActiveButton === '' && {
                  bgcolor: '#007965',
                  color: 'white',
                }),
              }}
              onClick={() => {
                handleClickData('');
              }}
            >
              All
            </Button>
          </Grid>
          <Grid item xs={4}>
            {' '}
            <Button
              buttonStyle={{
                ...prescriptionButton_style.buttonSx,
                ...(ActiveButton === 'drugs' && {
                  bgcolor: '#007965',
                  color: 'white',
                }),
              }}
              onClick={() => {
                handleClickData('drugs');
              }}
            >
              {' '}
              Drugs
            </Button>
          </Grid>
          <Grid item xs={4}>
            {' '}
            <Button
              buttonStyle={{
                ...prescriptionButton_style.buttonSx,
                ...(ActiveButton === 'rxgroup' && {
                  bgcolor: '#007965',
                  color: 'white',
                }),
              }}
              onClick={() => {
                handleClickData('rxgroup');
              }}
            >
              {' '}
              Rx Group
            </Button>
          </Grid>
        </Grid>
      )}
      {!isMobileResponsive && (
        <Divider
          width="1.5px"
          color="#dbeae8"
          sx={{ marginLeft: '10px', mr: '1' }}
        />
      )}
      {!isMobileResponsive && !closeIcon && (
        <IconButton
          buttonSxdisableRipple
          disableFocusRipple
          disableRipple
          id="search"
        >
          <BiSearch />
        </IconButton>
      )}
      {!isMobileResponsive && closeIcon && (
        <IconButton
          buttonSxdisableRipple
          disableFocusRipple
          disableRipple
          onClick={() => clearSearchState()}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
}

PrescriptionButton.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  closeIcon: PropTypes.bool,
  handleClickData: PropTypes.any,
  ActiveButton: PropTypes.any,
  clearSearchState: PropTypes.any,
  isMobileResponsive: PropTypes.any,
};

export { PrescriptionButton };
