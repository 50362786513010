export const appointmentMobileCard_style = {
  cardRootSx: {
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
  },
  buttonSx: {
    border: '2px solid #007965',
    '&:hover': {
      border: '2px solid #007965',
    },
  },
};
