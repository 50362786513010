export const refferalCard_style = {
  rootSx: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'grey.400',
    boxShadow: 'none',
    // p: 1.5,
  },
  labelSx: {
    color: '#868484',
    fontSize: '12px',
    // margin: '0px 2px',
    fontWeight: 600,
  },
  appointmentCardSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'inherit',
  },
  appointmentDocCardSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: 1.36,
  },
  doctornameSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selfSx: {
    fontSize: '12px!important',
    color: 'text.secondary',
    pt: 0.3,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
  btnSx: {
    borderRadius: '6px',
    textTransform: 'none',
    boxShadow: 'none',
    backgroundColor: '#Fff',
    border: '1px solid',
    borderColor: 'primary.main',
    color: 'primary.main',
    '&:hover': {
      color: 'common.white',
      backgroundColor: 'primary.main',
    },
  },
  referchangingSx: {
    borderRadius: '4px',
    fontWeight: 600,
    px: 0.4,
    mx: 0.75,
  },
  morelistSx: {
    '& .MuiPopover-paper': {
      boxShadow: '0px 0px 7px 1px #b7b7b7',
    },
    '& .MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list': {
      padding: '0px',
    },
  },
  borderSx: {
    fontSize: '12px',
    fontWeight: 600,
    margin: 0,
    color: 'error.main',
    borderRadius: '8px',
    p: 1.5,
    '&:hover': {
      backgroundColor: 'common.white',
    },
  },
  profilepicSx: {
    width: '44px',
    height: '44px',
  },
  cancelAppointment: {},
  professionalContainSx: {
    display: 'flex',
    flexWrap: 'wraps',
    width: '200px',
  },
  professionalSx: {
    color: 'text.hint',
    fontSize: '12px',
    pt: 0.25,
    whiteSpace: 'nowrap',
  },
  typeSx: {
    display: 'flex',
    alignItems: 'start',
    pt: 0.45,
  },
  patientAndDateSx: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px 0px',
  },
  statusSx: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#25C460',
    background: '#E8FCF0',
    borderRadius: '4px',
    px: 0.4,
  },
};
