import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { prescriptionHeader_style } from './style';

function PrescriptionHeader(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  return (
    <Box
      sx={{
        ...prescriptionHeader_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid container sx={prescriptionHeader_style.containerSx}>
        <Grid item xs={3}>
          {' '}
          <Typography sx={{ ...prescriptionHeader_style.typoSx, pl: 0.8 }}>
            Drug Name
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={prescriptionHeader_style.typoSx}>Dose</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={prescriptionHeader_style.typoSx}>
            Time, Frequency & When
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={prescriptionHeader_style.typoSx}>
            Duration & Quantity
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

PrescriptionHeader.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { PrescriptionHeader };

