import btnBG from '@assets/btnBG.png';
import doctorCalender from '@assets/doctorCalender.png';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Button, Loader } from '../../../atoms';
import { doctorBanner_style } from './style';

function DoctorBanner(props) {
  const {
    className = '',
    rootStyle = {},
    doctorName,
    apponitmentCount,
    followUPCount,
    refferals,
    loading,
    onAddPatient,
    ...rest
  } = props;
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  return (
    <Box
      sx={{
        ...doctorBanner_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {loading && (
        <Box justifyContent="center" display="flex">
          <Box alignSelf="center">
            <Loader />
          </Box>
        </Box>
      )}
      {!loading && doctorName.length > 0 && (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={10} md={10}>
            <Box sx={doctorBanner_style.cardSx}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item sm={2} xs={2}>
                  <Box sx={doctorBanner_style.imgcontentSx}>
                    <img src={doctorCalender} alt="calendar" />
                  </Box>
                </Grid>
                <Grid item sm={10} xs={10} sx={doctorBanner_style.gridMobile}>
                  <Box p={2}>
                    <Typography
                      color="text.primary"
                      sx={doctorBanner_style.bannerTextSx}
                    >
                      Welcome Dr. {doctorName}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      sx={doctorBanner_style.bannerSubTextSx}
                    >
                      {tokenData?.role_id === 2 ? "You have" : "Doctor have" }
                      
                      <span style={{ fontWeight: 600 }}>
                        {apponitmentCount}
                      </span>{' '}
                      new appointments,{' '}
                      <span style={{ fontWeight: 600 }}>{followUPCount}</span>{' '}
                      follow-ups &{' '}
                      <span style={{ fontWeight: 600 }}>{refferals}</span>{' '}
                      referral today.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <Button
              sx={{
                backgroundImage: `url(${btnBG})`,
                height: '88px',
                // marginTop: '16px',
                textTransform: 'capitalize',
                borderRadius: '8px',
                Py: 5,
                px: 1,
              }}
              onClick={onAddPatient}
            >
              Add New Patient
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

DoctorBanner.propTypes = {
  sx: PropTypes.string,
  rootStyle: PropTypes.object,
  className: PropTypes.string,
  doctorName: PropTypes.any,
  apponitmentCount: PropTypes.any,
  followUPCount: PropTypes.any,
  refferals: PropTypes.any,
  loading: PropTypes.bool,
  onAddPatient: PropTypes.any,
};

export { DoctorBanner };
