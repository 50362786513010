import { CheckMark } from '@atoms';
import { Badge, Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { stepper_style } from './style';

export function Stepper(props) {
  const {
    vertical = false,
    horizontal,
    StepperFunc = [],
    isRxAudit,
    // setActiveIndex,
    stepsCompleted,
    others=false,
    activeIndex,
    handleChange,
  } = props;

  // notes
  // we can control Stepper using [Vertical] or [Horizontal] via props

  // Stepper StepRender For Step 1,2,3....
  function stepRender(val, index) {
    if (stepsCompleted?.some((stepIndex) => stepIndex === index)) {
      return (
        <Box sx={{ ...stepper_style.stepperSwitchSx }}>
          <IconButton
            disableRipple
            sx={
              vertical
                ? {
                    ...stepper_style.stepperBgSX,
                    backgroundColor: 'primary.main',
                    borderColor: 'primary.main',
                  }
                : {
                    ...stepper_style.horizontalBgSx,
                    backgroundColor: 'primary.main',
                    borderColor: 'primary.main',
                  }
            }
          >
            <Badge
              sx={{ ...stepper_style.badgeSx }}
              badgeContent={<CheckMark rootStyle={stepper_style.subIconSx} />}
              color="primary"
            >
              {React.cloneElement(val.stepperIcon, {
                sx: { ...stepper_style.iconSx, color: 'common.white' },
              })}
            </Badge>
          </IconButton>
        </Box>
      );
    }
    if (activeIndex === index) {
      return (
        <Box
          sx={{
            ...stepper_style.stepperSwitchSx,
            border: '2px dashed #007965',
          }}
        >
          <IconButton
            disableRipple
            sx={
              vertical
                ? {
                    ...stepper_style.stepperBgSX,
                    backgroundColor: 'primary.main',
                    borderColor: 'primary.main',
                  }
                : {
                    ...stepper_style.horizontalBgSx,
                    backgroundColor: 'primary.main',
                    borderColor: 'primary.main',
                  }
            }
          >
            <Badge
              sx={{ ...stepper_style.badge2Sx }}
              badgeContent={<CheckMark rootStyle={stepper_style.subIconSx} />}
              color="primary"
            >
              {React.cloneElement(val.stepperIcon, {
                sx: { ...stepper_style.iconSx, color: 'common.white' },
              })}
            </Badge>
          </IconButton>
        </Box>
      );
    }
    return (
      <Box sx={{ ...stepper_style.stepperSwitchSx }}>
        <IconButton
          disableRipple
          sx={
            vertical
              ? {
                  ...stepper_style.stepperBgSX,
                  backgroundColor: 'common.white',
                  borderColor: 'grey.400',
                }
              : {
                  ...stepper_style.horizontalBgSx,
                  backgroundColor: 'common.white',
                  borderColor: 'grey.400',
                }
          }
        >
          <Badge
            sx={{ ...stepper_style.badge2Sx }}
            badgeContent={<CheckMark rootStyle={stepper_style.subIconSx} />}
            color="primary"
          >
            {React.cloneElement(val.stepperIcon, {
              sx: {
                ...stepper_style.iconSx,
                color: '#0F0B117A',
              },
            })}
          </Badge>
        </IconButton>
      </Box>
    );
  }

  // Stepper stepsForm For Step 1,2,3....
  function stepsForm(val, index) {
    if (stepsCompleted?.some((stepIndex) => stepIndex === index)) {
      return (
        <Box
          sx={{
            ...stepper_style.sideBoxSx,
            textAlign: vertical ? 'left' : 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={
              vertical
                ? { ...stepper_style.followSx }
                : { ...stepper_style.horiZontalFollowSx }
            }
          >
            {val.step}
          </Typography>
          <Typography
            variant="body1"
            sx={
              vertical
                ? { ...stepper_style.followSx, color: '#808080' }
                : { ...stepper_style.subTextSx, color: '#808080' }
            }
          >
            {val.stepDescription}
          </Typography>
        </Box>
      );
    }
    if (activeIndex === index) {
      return (
        <Box
          sx={{
            ...stepper_style.sideBoxSx,
            textAlign: vertical ? 'left' : 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={
              vertical
                ? { ...stepper_style.followSx, color: '#007965' }
                : { ...stepper_style.horiZontalFollowSx, color: '#007965' }
            }
          >
            {val.step}
          </Typography>
          <Typography
            variant="body1"
            sx={
              vertical
                ? { ...stepper_style.followSx, color: '#007965' }
                : { ...stepper_style.subTextSx, color: '#007965' }
            }
          >
            {val.stepDescription}
          </Typography>
        </Box>
      );
    }
    return (
      <Box
        sx={{
          ...stepper_style.sideBoxSx,
          textAlign: vertical ? 'left' : 'center',
        }}
      >
        <Typography
          variant="caption"
          sx={
            vertical
              ? { ...stepper_style.followSx }
              : { ...stepper_style.horiZontalFollowSx }
          }
        >
          {val.step}
        </Typography>
        <Typography
          variant="body1"
          sx={
            vertical
              ? { ...stepper_style.followSx, color: '#808080' }
              : { ...stepper_style.subTextSx, color: '#808080' }
          }
        >
          {val.stepDescription}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={
        horizontal
          ? stepper_style.horizontalRootSx
          : stepper_style.verticalRootSx
      }
    >
      {/* Stepper */}
      <Box>
        {/* Divider */}
        <Box
          display={horizontal ? 'flex' : ''}
          justifyContent={horizontal ? 'center' : ''}
        >
         {!others && <Box
            sx={{
              ...stepper_style.dividerSx,
              '&:before': {
                content: '""',
                position: 'absolute',
                borderLeft: vertical ? '2.5px dashed #007965' : '0px',
                borderBottom: horizontal ? '2.5px dashed #007965' : '0px',
                height: vertical ? 131 : 0,
                width: horizontal ? (isRxAudit ? 200 : 100) : 0,
                ml: vertical ? 3 : isRxAudit ? '-96px' : '25px',

                top: vertical ? '6px' : '18px',
              },
            }}
          >
            {' '}
          </Box>}
        </Box>

        {!isRxAudit && !others && (
          <Box
            sx={{
              ...stepper_style.dividerSx,
              '&:before': {
                content: '""',
                position: 'absolute',
                borderLeft: vertical ? '2.5px dashed #007965' : '0px',
                borderBottom: horizontal ? '2.5px dashed #007965' : '0px',
                height: vertical ? 80 : 0,
                width: horizontal ? 100 : 0,
                ml: vertical ? '23px' : '48px',
                top: vertical ? '145px' : '18px',
              },
            }}
          >
            {' '}
          </Box>
        )}

        {/* Stepper-Tabs */}
        <Box
          sx={vertical ? stepper_style.tabSX : stepper_style.horizontalTabSx}
        >
          {StepperFunc?.map((val, index) => (
            <Box
              sx={{ zIndex: 99 }}
              key={index}
              onClick={() => handleChange(index)}
            >
              {stepRender(val, index)}
            </Box>
          ))}
        </Box>
        {horizontal && (
          <Box
            sx={{
              display: vertical ? 'grid' : 'flex',
              justifyContent: 'space-around',
            }}
          >
            {StepperFunc?.map((val, index) => (
              <Box
                onClick={() => handleChange(index)}
                key={index}
                ml={
                  index === 1
                    ? isRxAudit
                      ? '76px'
                      : '30px'
                    : isRxAudit
                    ? '19px'
                    : ''
                }
                mr={index === 2 ? '-10px' : ''}
              >
                {stepsForm(val, index)}
              </Box>
            ))}
          </Box>
        )}
      </Box>

      {/* Steps for Stepper */}
      {vertical && (
        <Box
          sx={{
            ...stepper_style.SideTabSX,
            display: vertical ? 'grid' : 'flex',
          }}
        >
          {StepperFunc?.map((val, index) => (
            <Box onClick={() => handleChange(index)} key={index}>
              {stepsForm(val, index)}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
Stepper.propTypes = {
  StepperFunc: PropTypes.array,
  handleChange: PropTypes.func,
  vertical: PropTypes.bool,
  horizontal: PropTypes.bool,
  isRxAudit: PropTypes.bool,
  stepsCompleted: PropTypes.func,
  activeIndex: PropTypes.func,
};
