export const doctorlayout_style = {
  rootSx: {
    maxHeight: '100%',
  },
  appBarSx: {
    borderBottom: '1px solid #DBDBDB',
    backgroundColor: '#FAFAFA !important',
    boxShadow: 'none',
  },
  bodySx: {
    backgroundColor: '#FAFAFA',
    padding: '23px 24px 16px',
  },
  anchorStyleSx: {
    vertical: 'top',
    horizontal: 'center',
  },
  drawerSx: {
    display: 'none',
  },
  app1stRootSx: {
    pl: 2,
    pr: 2.5,
    bgcolor: '#fff',
    boxSizing: 'border-box',
    pt: '50px',
    borderBottom: '1px solid #E2E2E2',
    position: 'fixed',
    top: 0,
    zIndex: '4',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },
  app2ndRootSx: {
    pl: 2,
    pr: 2.5,
    bgcolor: '#fff',
    boxSizing: 'border-box',
    pt: '105px',
    paddingBottom: '8px',
    position: 'fixed',
    top: 0,
    zIndex: '3',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },
  navSx: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '60px',
    boxShadow: '0px 0px 8px #efefef',
    opacity: 1,
    maxWidth: {
      // sm: 425,
      // md:1024,
      lg: 1024,
      xl: 1024,
    },
    position: 'fixed',
    marginLeft: 'auto',
    marginRight: 'auto',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1000,
  },
  menuSx: {
    '& .MuiPaper-root': {
      padding: '8px 24px 8px 16px',
      boxShadow: '0px 0px 4px 2px #E3E3E3',
    },
  },
  menuItemSx: {
    px: 1,
    py: 1,
    fontSize: '14px',
    fontWeight: 500,
  },
  footerNavBarSx: {
    position: 'fixed',
    bottom: 0,
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
    display: 'flex',
    justifyContent: 'space-between',
    px: 2.5,
    py: 1.5,
    boxSizing: 'border-box',
    bgcolor: 'common.white',
    borderColor: 'border.main',
    boxShadow: '0px -2px 23px #00000014',
    borderRadius: '24px 24px 0px 0px',
  },
  navItemSx: {
    display: 'grid',
    placeItems: 'center',
    gap: 0.5,
    cursor: 'pointer',
  },
  navItemTextSx: {
    fontSize: '12px',
    fontWeight: 600,
  },
};
