/* eslint-disable react/jsx-key */
import { Box, Divider, Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { Button, DialogModal, HealthCirclesLogo } from '../../../../atoms';
import { RefferalNotesCard } from '../refferalnotescard';
import { refferalNoteModal_style } from './style';

function RefferalNoteModal(props) {
  const {
    className = '',
    rootStyle = {},
    isModalOpen,
    data,
    time = '',
    year = '',
    date = '',
    spec = [],
    gender = '',
    doctorName = '',
    hospitalName = [],
    regNo = '',
    docMobileNo = '',
    countryCode = '',
    patientName = '',
    setDisplayReffModal = () => {},
    isMobileResponsive,
    age = '',
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...refferalNoteModal_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <DialogModal
        isCloseOut={isMobileResponsive ? false : true}
        isMobileResponsive={isMobileResponsive}
        dialogRootStyle={refferalNoteModal_style.dialogSx}
        isBackRequired={isMobileResponsive ? true : false}
        topDivider
        bottomDivider
        closeStyle={{
          width: '16px',
          height: '16px',
          display: isMobileResponsive ? 'none' : 'block',
        }}
        DialogActionsboxShadow={{ boxShadow: '0px 0px 10px #efefef' }}
        title={
          <Box>
            <Box sx={refferalNoteModal_style.logoSx}>
              {!isMobileResponsive && (
                <>
                  <Box sx={{ display: 'flex' }}>
                    <HealthCirclesLogo />
                    <Typography sx={refferalNoteModal_style.orgNameSx}>
                      Health Circles
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={refferalNoteModal_style.subHeadSx}>
                      Referral Note
                    </Typography>
                    <Typography sx={refferalNoteModal_style.dateSx}>
                      {time}, {date}
                    </Typography>
                  </Box>{' '}
                </>
              )}
              {isMobileResponsive && (
                <Typography sx={refferalNoteModal_style.titleSx}>
                  {' '}
                  Referral note
                </Typography>
              )}
            </Box>
          </Box>
        }
        content={
          <Box>
            <Box sx={{ pt: 1, pb: 2 }}>
              {isMobileResponsive && (
                <Typography sx={refferalNoteModal_style.dateSx}>
                  {time}, {date}
                </Typography>
              )}
              <Typography sx={refferalNoteModal_style.mainHeadSx}>
                {doctorName}
              </Typography>
              <Box display="flex">
                {' '}
                {spec?.map((val, i) => (
                  <Typography
                    key={i}
                    sx={{ fontSize: '12px', color: '#808080' }}
                  >
                    {val?.master_speciality?.speciality}
                  </Typography>
                ))}
              </Box>
              <Typography sx={{ fontSize: '12px', color: '#808080' }}>
                • {countryCode} {docMobileNo} • Reg.{regNo}
              </Typography>

              {hospitalName?.map((val, i) => (
                <Typography key={i} sx={{ fontSize: '12px', color: '#808080' }}>
                  {val}
                </Typography>
              ))}
            </Box>
            <Divider />
            <Box sx={{ mb: 2, mt: 2 }}>
              <Typography sx={refferalNoteModal_style.mainHeadSx}>
                Patient: {patientName}, {age}, {gender}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ my: 2 }}>
              <Typography sx={refferalNoteModal_style.mainHeadSx}>
                Referral Notes
              </Typography>
            </Box>
            <RefferalNotesCard data={data} />
          </Box>
        }
        actions={
          isMobileResponsive ? (
            <Grid px={2} spacing={1} container justifyContent="center">
              <Grid item xs={12}>
                <Button
                  sx={refferalNoteModal_style.buttonSx}
                  onClick={() => setDisplayReffModal(false)}
                >
                  BACK
                </Button>
              </Grid>
            </Grid>
          ) : null
        }
        isDialogOpened={isModalOpen}
        handleCloseDialog={() => {
          setDisplayReffModal(false);
        }}
      />
    </Box>
  );
}

RefferalNoteModal.propTypes = {
  spec: PropTypes.array,
  date: PropTypes.string,
  year: PropTypes.string,
  time: PropTypes.string,
  age: PropTypes.string,
  patientName: PropTypes.string,
  gender: PropTypes.string,
  doctorName: PropTypes.string,
  regNo: PropTypes.string,
  docMobileNo: PropTypes.string,
  hospitalName: PropTypes.string,
  rootStyle: PropTypes.object,
  isModalOpen: PropTypes.bool,
  data: PropTypes.array,
  handleClose: PropTypes.func,
  className: PropTypes.string,
  countryCode: PropTypes.string,
  setDisplayReffModal: PropTypes.func,
  isMobileResponsive: PropTypes.bool,
};

export { RefferalNoteModal };
