import { DataTabs, Dropdown } from '@atoms';
import { doctorRoutes, rxAuditRoutes } from '@hc/routes';
import { useDoctorReport, useViewPort } from '@hc/store';
import reportEmptyState from '@hc/ui/assets/reportEmptyState.svg';
import { RxGroupSkeleton } from '@hc/ui/components';
import { Box, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { Input, SearchIcon } from '../../../atoms';
import { FilterTab } from '../../backoffice/filterTab';
import { ReportSummaryCard } from '../reportSummaryCard';
import { viewSummaryReport_style } from './style';

function ViewSummaryReport(props) {
  const {
    className = '',
    fileType,
    rootStyle = {},
    isRxAudit,
    isMobileResponsive,
    totalBoxStyle = {},
    ...rest
  } = props;

  // store Data
  const {
    reportState,
    updateReportStore,
    setSeachFilter,
    setViewReportData,
    onTabChange,
    // tabIndexVal,
    loading,
  } = useDoctorReport(
    (state) => ({
      // tabIndexVal: state.tabIndex,
      onTabChange: state.onTabChange,
      setViewReportData: state.setViewReportData,
      updateReportStore: state.updateReportStore,
      reportState: state.reportState,
      setSeachFilter: state.setSeachFilter,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const { caseSummaryData, uploadedData } = reportState;

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));
  // General Hooks
  const [tabIndex, setTabIndex] = useState('1');
  const [restabIndex, setRestabIndex] = useState(0);
  const [colorTab, setColorTab] = useState(0);
  const [onsearchValue, setOnsearchValue] = useState('');
  const [onSelectValue, setOnSelectValue] = useState('');
  const navigate = useNavigate();

  const breaks = [
    {
      value: '1',
      label: 'Uploaded',
    },
    {
      value: '2',
      label: 'case Summary',
    },
  ];

  const filterOption = [
    {
      value: '',
      label: 'All',
    },
    {
      value: 1,
      label: 'Lab',
    },
    {
      value: 2,
      label: 'Prescription',
    },
    {
      value: 3,
      label: 'Uncategorized',
    },
  ];
  const filterList = ['Uploaded', 'Case Summary'];

  // ReportType  Func
  const FilterPatientData = async (val) => {
    setOnSelectValue(val);
    await setSeachFilter(val, 'filter');
    setColorTab(0);
  };

  const searchreports = async (val) => {
    setOnsearchValue(val?.target?.value);

    await setSeachFilter(val?.target?.value, 'search');

    setColorTab(0);
  };

  const selectedReport = async (val, index) => {
    await setViewReportData(val);
    setColorTab(index);
    if (isMobileResponsive)
      navigate(
        isRxAudit ? rxAuditRoutes?.reportspage : doctorRoutes?.viewReportPage,
      );
  };

  const tabChangeReportCaseSummary = async (newValue, isTab) => {
    if (isTab) {
      setRestabIndex(newValue);
      setTabIndex((Number(newValue) + 1).toString());
      await updateReportStore('tabIndex', (Number(newValue) + 1).toString());
      await onTabChange((Number(newValue) + 1).toString());
    } else {
      setTabIndex(newValue);
      await updateReportStore('tabIndex', newValue);
      await onTabChange(newValue);
    }
  };

  return (
    <Box
      sx={{
        ...viewSummaryReport_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={viewSummaryReport_style.sectionOne}>
        {!isMobilePort && (
          <Typography sx={viewSummaryReport_style.reportTitle}>
            Reports
          </Typography>
        )}

        <Box sx={viewSummaryReport_style.toggleButton}>
          {!isMobilePort && (
            <DataTabs
              tabs={breaks}
              tabIndex={tabIndex}
              setTabIndex={tabChangeReportCaseSummary}
              tabStyle={viewSummaryReport_style.tabSx}
              totalStyle={viewSummaryReport_style.totalStyle}
              underTabSx={viewSummaryReport_style.underTab}
            />
          )}
          {isMobilePort && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                px: 3,
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#EDEFF2',
                  borderRadius: '10px',
                  height: '50px',
                  width: '340px',
                  ...totalBoxStyle,
                }}
              >
                <FilterTab
                  filterList={filterList}
                  handleChange={(event, newValue) =>
                    tabChangeReportCaseSummary(newValue, true)
                  }
                  // filterTabRootStyle={{ width: '170px', textWrap: 'noWrap' }}
                  value={restabIndex}
                  // toggleContainerSx={{
                  //   justifyContent: '',
                  //   width: '200px',
                  //   gap: 2,
                  //   '& .MuiButtonBase-root': {
                  //     width: '120px',
                  //   },
                  // }}

                  toggleStyle={{ height: '44px' }}
                  toggleContainerSx={{
                    justifyContent: '',
                    // width: '300px',
                  }}
                  filterTabRootStyle={viewSummaryReport_style.filterTabRootSx}
                />
              </Box>
            </Box>
          )}
        </Box>
        {/* search Field */}
        <Box sx={{ pt: 2 }}>
          <Input
            id="search"
            placeholder="Search"
            textFieldStyle={viewSummaryReport_style.searchFiledSx}
            onChange={searchreports}
            value={onsearchValue}
            startAdornment={!onsearchValue && <SearchIcon />}
          />
        </Box>
        <Box sx={viewSummaryReport_style.reportFilter}>
          <Typography
            fontSize={12}
            color="#5C6266"
            sx={tabIndex === '1' ? '' : { mt: '8px', mb: '8px' }}
          >
            {tabIndex === '1'
              ? `${uploadedData?.length} Reports`
              : `${caseSummaryData?.length} Case Summaries`}
          </Typography>
          <Box>
            {tabIndex === '1' && (
              <Dropdown
                datatestid="all"
                selectOption={filterOption}
                value={onSelectValue}
                rootStyle={viewSummaryReport_style.filterSx}
                onChange={(e) => FilterPatientData(e?.target?.value)}
              />
            )}
          </Box>
        </Box>
        <Box sx={viewSummaryReport_style.reportCardSx}>
          {!loading &&
            tabIndex === '1' &&
            uploadedData.length > 0 &&
            uploadedData?.map((val, index) => {
              return (
                <Box key={index}>
                  <ReportSummaryCard
                    index={index}
                    date={val?.updatedAt ? val?.updatedAt : ''}
                    selectedReport={() => selectedReport(val, index)}
                    reportType={val?.master_report_type?.report_type}
                    reportDescription={val?.report_name}
                    selectInd={colorTab === index}
                  />
                  <Divider sx={viewSummaryReport_style.divider} />
                </Box>
              );
            })}
          {!loading && tabIndex === '1' && uploadedData.length === 0 && (
            <Box>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item mt={9}>
                  <Box>
                    <img src={reportEmptyState} alt="" />
                  </Box>
                </Grid>
                <Grid item mt={1.6}>
                  <Typography fontSize={16} fontWeight={600}>
                    No Records Found
                  </Typography>
                </Grid>
                <Grid item mt={1}>
                  <Box width={250} textAlign="center">
                    <Typography fontSize={12} color="#888888">
                      Records will appear once you upload your or family members
                      documents
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          {loading &&
            tabIndex === '1' &&
            Array(5)
              .fill(0)
              .map((i) => <RxGroupSkeleton key={i} />)}
          {!loading &&
            tabIndex === '2' &&
            caseSummaryData.length > 0 &&
            caseSummaryData?.map((val, index) => {
              return (
                <Box key={index}>
                  <ReportSummaryCard
                    index={index}
                    date={val?.updatedAt ? val?.updatedAt : ''}
                    selectedReport={() => selectedReport(val, index)}
                    caseReport={`Dr. ${val?.doctor_profile?.first_name}`}
                    reportType={
                      val?.doctor_profile?.doctor_specialities?.[0]
                        ?.master_speciality?.speciality
                    }
                    selectInd={colorTab === index}
                    caseSummary
                  />
                  <Divider sx={viewSummaryReport_style.divider} />
                </Box>
              );
            })}
          {!loading && tabIndex === '2' && caseSummaryData.length === 0 && (
            <Box>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item mt={9}>
                  <Box>
                    <img src={reportEmptyState} alt="" />
                  </Box>
                </Grid>
                <Grid item mt={1.6}>
                  <Typography fontSize={16} fontWeight={600}>
                    No Records Found
                  </Typography>
                </Grid>
                <Grid item mt={1}>
                  <Box width={250} textAlign="center">
                    <Typography fontSize={12} color="#888888">
                      Records will appear once your doctor submits your case
                      summary
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          {loading &&
            tabIndex === '2' &&
            Array(5)
              .fill(0)
              .map((i) => <RxGroupSkeleton key={i} />)}
        </Box>
      </Box>
    </Box>
  );
}

ViewSummaryReport.propTypes = {
  fileType: PropTypes.string,
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isMobileResponsive: PropTypes.bool,
  isRxAudit: PropTypes.bool,
  totalBoxStyle: PropTypes.object,
};
export { ViewSummaryReport };

