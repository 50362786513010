import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { AddSolidIcon, Button, RxGroupEmptyStateImg } from '../../../../atoms';
import { rxGroupEmptyState_style } from './style';

function RxGroupEmptyState(props) {
  const {
    className = '',
    buttonName = '',
    content = '',
    onAdd = () => {},
    rootStyle = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...rxGroupEmptyState_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack alignSelf="center" pt={6}>
        <Box display="flex" justifyContent="center" pb={3.2}>
          <RxGroupEmptyStateImg />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box width="292px" pb={5.2}>
            <Typography textAlign="center" fontSize={14} color="#888888">
              {content ?? ''}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box width="204px">
            <Button
              id="addDrug"
              buttonStyle={{
                border: '2px solid #007965',
                '&:hover': { border: '2px solid #007965' },
              }}
              startIcon={<AddSolidIcon />}
              variant="outlined"
              onClick={() => onAdd()}
            >
              {buttonName ?? ''}
            </Button>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}

RxGroupEmptyState.propTypes = {
  className: PropTypes.string,
  buttonName: PropTypes.string,
  content: PropTypes.string,
  rootStyle: PropTypes.object,
  onAdd: PropTypes.func,
};

export { RxGroupEmptyState };
