export const Prescription_style = {
  saveButtonSx: {
    bgcolor: '#007965',
    color: 'white',
    '&:hover': {
      bgcolor: '#007965',
    },
  },
  backIconSx: {
    cursor: 'pointer',
    color: '#000',
    // backgroundColor:
  },
  buttonSx: {
    border: '2px solid #007965',
    '&:hover': {
      border: '2px solid #007965',
    },
  },

  titleSx: {
    color: '#000',
    fontWeight: 600,
    fontSize: '16px',
  },
  textFieldSx: {
    borderColor: 'border.main',
    '& .MuiOutlinedInput-input': {
      width: '100%',
      fontWeight: 600,
      height: '35px',
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '6px !important',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.hover',
      },
    },
  },
  SelectedDrugs: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#888888',
    mt: '12px',
    pl: 1,
  },
  PrescriptionEmptystate: {
    height: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addnotesbuttonSx: {
    maxWidth: '200px',
    // position: 'absolute',
    // top: '90%',
    // left: '50%',
    // translate: '-50% -50%',
    bgcolor: 'white',
    color: 'primary.main',
    border: '2px solid ',
    borderColor: 'primary.main',
    borderRadius: '8px',

    '&:hover': {
      color: 'primary.main',
      bgcolor: 'white',
    },
  },
  FooterSx: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    py: 2,
    boxShadow: '0px -3px 30px #00000014',
  },
  topFixedBar: {
    position: 'fixed',
    zIndex: 100,
    backgroundColor: '#fff',
  },
  mobileCardSx: {
    background: '#FFFFFF',
    border: '1px solid #E3E3E3',
    borderRadius: '8px',
    p: 1,
  },

  responsiveTextFieldSx: {
    '& .MuiOutlinedInput-input': {
      width: '100%',
      fontWeight: 600,
      height: '35px',
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '6px !important',
      '& fieldset': {
        border: '1.5px solid',
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  },
};
