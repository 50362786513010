export const dateOverrideList_style = {
  titleSx: {
    fontSize: 16,
    fontWeight: 600,
    padding: '0px',
  },
  dialogSx: {
    width: '469px',
  },
  contentSx: {
    pt: 2,
    pb: 0,
    pl: 0,
    pr: 0,
  },
  buttonSx: {
    border: '2px solid #007965',
    '&:hover': {
      border: '2px solid #007965',
    },
  },
};
