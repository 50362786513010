import { useSummary, useViewPort } from '@hc/store';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

import { AddSummaryIcon, Button, DialogModal } from '../../../atoms';
import {
  SummaryEditIcon,
  SummaryTrashIcon,
} from '../../../atoms/icons/iconSet9';
import { AddLabTests } from '../addLabTests';
import { summaryLabTests_style } from './style';

function SummaryLabTests(props) {
  const {
    summaryView = false,
    summaryModelView = false,
    addTitle = '',
    summaryTitle = '',
    className = '',
    rootStyle = {},
    ...rest
  } = props;

  const {
    summaryState,
    saveLabTest,
    clearLabTestDetailsState,
    labTestDetails,
    deleteLabTestData,
    updateLabTestFullDetails,
  } = useSummary((state) => ({
    summaryState: state.summaryState,
    saveLabTest: state.saveLabTest,
    labTestDetails: state.labTestDetails,
    clearLabTestDetailsState: state.clearLabTestDetailsState,
    updateLabTestFullDetails: state.updateLabTestFullDetails,
    deleteLabTestData: state.deleteLabTestData,
  }));

  const { pastSummaryData, labTest } = summaryState;
  const { labTestArray, labTestDate } = labTestDetails;

  // general Hooks
  const [inputShown, setInputShown] = useState(false);

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  // commonOpen input Func
  const commonOpenFun = () => {
    setInputShown(!inputShown);
  };

  // Close func
  const handleClose = () => {
    clearLabTestDetailsState();
    setInputShown(false);
  };

  const saveLabTestFnc = async () => {
    if (labTestArray?.length > 0) {
      if (labTestDate) {
        const res = await saveLabTest();
        if (res === 'Success') {
          clearLabTestDetailsState();
          setInputShown(false);
        }
      } else {
        toast.error('Please select date!');
      }
    } else {
      toast.error('Please add atleast one lab test!');
    }
  };

  // DELETE FUNCTION
  const onDelete = () => {
    // DELETE
    deleteLabTestData();
  };

  // Input Value Edit Func
  const onEdit = () => {
    const findValue =
      Array.isArray(labTest) && labTest?.length > 0 ? labTest?.[0] : {};
    updateLabTestFullDetails(findValue);
    setInputShown(true);
  };

  return (
    <Box
      sx={{
        ...summaryLabTests_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {/* Add Title */}
      <Typography sx={summaryLabTests_style.addSummaryTitleSx}>
        {summaryTitle}
      </Typography>

      {!summaryModelView && !summaryView && (
        <>
          {Array.isArray(labTest?.[0]?.labTestArray) &&
          labTest?.[0]?.labTestArray?.length > 0 ? (
            <Box sx={summaryLabTests_style.inputSx}>
              <Box sx={summaryLabTests_style.inputValueSx}>
                <Typography sx={summaryLabTests_style.inputTextSx}>
                  {Array.isArray(labTest?.[0]?.labTestArray) &&
                    labTest?.[0]?.labTestArray?.length > 0 &&
                    labTest?.[0]?.labTestArray.map((val_, index_) => (
                      <>
                        {index_ > 0 ? (
                          <span
                            key={index_}
                            style={{
                              // backgroundColor: 'PrimaryTints.A100',
                              padding: '4px 0px',
                              borderRadius: '4px',
                              color: '#868484',
                              fontSize: '12px',
                              // marginLeft: '6px',
                              fontWeight: 600,
                            }}
                          >
                            {', '}{' '}
                          </span>
                        ) : null}
                        <Typography
                          key={index_}
                          sx={{
                            color: '#4D4D4D',
                            fontSize: '12px!important',
                            fontWeight: 'medium',
                          }}
                        >
                          &nbsp;{val_?.label ?? ''}&nbsp;
                        </Typography>
                      </>
                    ))}
                </Typography>

                <Box sx={summaryLabTests_style.inputValueIconSx}>
                  <IconButton disableRipple onClick={() => onDelete()}>
                    <SummaryTrashIcon />
                  </IconButton>
                  <IconButton disableRipple onClick={() => onEdit()}>
                    <SummaryEditIcon />
                  </IconButton>
                </Box>
              </Box>
              <Divider sx={summaryLabTests_style.dividerSx} />
              <Box display="flex" px={1.2} pt={1}>
                <Typography fontWeight={500} fontSize={14}>
                  Note:
                </Typography>
                <Typography pl={1.2} fontSize={14} color="#888888">
                  {labTest?.[0]?.note}
                </Typography>
              </Box>
            </Box>
          ) : null}
          {/* Add Summary in labtests */}
          {Array.isArray(labTest) && labTest?.length === 0 ? (
            <Box
              sx={summaryLabTests_style.inputTextShowSx}
              onClick={() => commonOpenFun()}
            >
              <IconButton disableRipple>
                <AddSummaryIcon />
              </IconButton>

              <Typography sx={summaryLabTests_style.summaryTitlesx}>
                {addTitle}
              </Typography>
            </Box>
          ) : null}

          {/* Lab Test Dailog modal open */}
          {inputShown && (
            <Box>
              <DialogModal
                maxModalWidth="xl"
                topDivider
                bottomDivider
                title="LAB TESTS"
                isBackRequired={isMobilePort ? true : false}
                isCloseOut={isMobilePort ? false : true}
                isMobileResponsive={isMobilePort}
                dialogRootStyle={{
                  width: '490px',
                }}
                titleStyle={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: 'text.primary',
                }}
                content={<AddLabTests />}
                actions={
                  <Grid
                    p={1}
                    container
                    columnSpacing={1.5}
                    justifyContent={isMobilePort ? 'space-around' : 'end'}
                  >
                    {isMobilePort && (
                      <Grid item xs={6}>
                        <Button onClick={handleClose} variant="outlined">
                          Cancel
                        </Button>
                      </Grid>
                    )}
                    <Grid item xs={isMobilePort ? 6 : 6}>
                      <Button onClick={() => saveLabTestFnc()}>
                        ADD LAB TESTS
                      </Button>
                    </Grid>
                  </Grid>
                }
                isDialogOpened={inputShown}
                handleCloseDialog={handleClose}
              />
            </Box>
          )}
        </>
      )}

      {summaryView && (
        <Box>
          {Array.isArray(pastSummaryData?.labTest) &&
          pastSummaryData?.labTest?.length > 0 ? (
            pastSummaryData?.labTest?.map((val, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    bgcolor: '#F5F5F5',
                    borderRadius: '8px',
                    p: 2,
                    mb: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: 'text.primary',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    {val?.master_lab_test?.lab_test ?? ''}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <Box sx={summaryLabTests_style.boxCardSx}>
              <Typography sx={summaryLabTests_style.valTextSx}>
                No Labtest found!
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {summaryModelView && (
        <Box>
          {/* <Box> */}
          {Array.isArray(labTest?.[0]?.labTestArray) &&
          labTest?.[0]?.labTestArray?.length > 0 ? (
            <Box
              sx={{
                bgcolor: '#F5F5F5',
                borderRadius: '8px',
                py: 1.5,
                px: 2,
                mb: 1.5,
              }}
            >
              {labTest?.[0]?.labTestArray?.map((val, i) => (
                <Box key={i} pb={0.5}>
                  <Typography
                    sx={{
                      color: 'text.primary',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    {val?.label}
                  </Typography>
                </Box>
              ))}

              <Divider sx={summaryLabTests_style.dividerSx} />
              <Box display="flex" pt={1}>
                <Typography fontWeight={500} fontSize={14}>
                  Note :
                </Typography>
                <Typography pl={1.2} fontSize={14} color="#888888">
                  {labTest?.[0]?.note}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box sx={summaryLabTests_style.boxCardSx}>
              <Typography sx={summaryLabTests_style.valTextSx}>
                No Labtest found!
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

SummaryLabTests.propTypes = {
  className: PropTypes.string,
  addTitle: PropTypes.string,
  summaryTitle: PropTypes.string,
  viewSummaryTitle: PropTypes.string,
  isEdit: PropTypes.bool,
  rootStyle: PropTypes.object,
  summaryView: PropTypes.bool,
  summaryModelView: PropTypes.bool,
};

export { SummaryLabTests };
