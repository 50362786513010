export const stepper_style = {
  tabSX: {
    gap: 7,
    textAlign: 'center',
    display: 'grid',
  },
  horizontalTabSx: {
    gap: '60px',
    // textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    mb: 1,
  },
  SideTabSX: {
    gap: 5,
    pl: 1,
    textAlign: 'start',
    alignSelf: 'stretch',
  },
  horozontalSideTabSx: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  stepperBgSX: {
    border: '2.2px solid ',

    p: 1.5,
  },
  stepperSwitchSx: {
    backgroundColor: '#ebfaf7',
    zIndex: 3,
    borderRadius: 24,
    // height:28,
    // width:28,
    padding: 0.5,
  },
  horizontalBgSx: {
    border: '2.2px solid ',
    height: 28,
    width: 28,
    p: 1.5,
  },
  badgeSx: {
    '& .MuiBadge-badge': {
      top: -9,
      right: -8,
      p: 0,
      backgroundColor: '#25C460',
      border: '2.2px solid',
      borderColor: 'common.white',
    },
  },
  badge2Sx: {
    '& .MuiBadge-badge': {
      display: 'none',
    },
  },
  iconSx: {
    strokeWidth: '0.6',
    width: 16,
    height: 16,
    stroke: 'common.white',
    color: 'common.white',
  },
  subIconSx: {
    width: 9,
    height: 9,
    color: 'common.white',
  },
  dividerSx: {
    position: 'relative',
  },
  verticalRootSx: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  horizontalRootSx: {
    width: '100%',
    // display: 'flex',
    // justifyContent: 'center',
  },
  sideBoxSx: {
    // width: '169px',
    textAlign: 'left',
  },
  followSx: {
    color: '#007965',
    fontWeight: 600,
    fontSize: '16px',
    letterSpacing: '0px',
  },
  horiZontalFollowSx: {
    color: '#007965',
    fontWeight: 400,
    fontSize: '10px',
    letterSpacing: '0px',
    textAlign: 'center',
  },
  subTextSx: {
    color: '#007965',
    fontWeight: 600,
    fontSize: '12px',
    letterSpacing: '0px',
  },
};
