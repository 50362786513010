import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  Button,
  CertificateIcon,
  ShareIconDoctor,
  UserProfileIcon,
} from '../../../atoms';
import { referralSuccesPage_style } from './style';

function ReferralSuccessPage(props) {
  const {
    className = '',
    rootStyle = {},
    message = 'asdldasklsdkld',
    doctorName = 'Dalkjdsd',
    speciality,
    backOnClick,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...referralSuccesPage_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid container spacing={1}>
        {' '}
        <Grid item xs={12} sm={12} md={12} sx={referralSuccesPage_style.box}>
          <ShareIconDoctor />
          <Typography sx={referralSuccesPage_style.text}>{message}</Typography>
          <Box sx={referralSuccesPage_style.cardBox}>
            <Grid container py={2} ml={10}>
              <Grid item xs={2}>
                <Box sx={referralSuccesPage_style.avatarSx}>
                  <Box alignSelf="center">
                    <UserProfileIcon />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={8} display="flex">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      textAlign="start"
                      color="textPrimary"
                      sx={{
                        fontSize: '14px',
                        color: 'text.primary',
                        fontWeight: 500,
                      }}
                    >
                      Dr. {doctorName}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} display="flex">
                    <Box sx={referralSuccesPage_style.doctorSx}>
                      {Array.isArray(speciality) && speciality?.length > 0 && (
                        <CertificateIcon />
                      )}
                      {Array.isArray(speciality) &&
                        speciality?.length > 0 &&
                        speciality.map((val_, index_) => (
                          <>
                            {index_ > 0 ? (
                              <span
                                key={index_}
                                style={referralSuccesPage_style.labelSxx}
                              >
                                {', '}{' '}
                              </span>
                            ) : null}
                            <Typography
                              noWrap
                              key={index_}
                              sx={referralSuccesPage_style.assitantSx}
                            >
                              {val_?.label ?? ''}
                            </Typography>
                          </>
                        ))}
                      {/* {<span>{speciality ?? ''}</span>} */}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Button
            sx={{
              bgcolor: 'white',
              color: 'primary.main',
              borderRadius: '8px',
              '&:hover': {
                bgcolor: 'primaryTints.300',
              },

              border: '2px solid #007965',
              width: '180px',
              margin: 'auto',
            }}
            onClick={() => backOnClick()}
          >
            BACK TO SUMMARY
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

ReferralSuccessPage.propTypes = {
  rootStyle: PropTypes.object,
  className: PropTypes.string,
  message: PropTypes.string,
  doctorName: PropTypes.string,
  speciality: PropTypes.any,
  backOnClick: PropTypes.any,
};

export { ReferralSuccessPage };
