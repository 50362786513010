import { Box, Grid, Skeleton, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useViewPort } from '@hc/store';
import { doctorModuleSkeleton_style } from './style';

function DoctorModuleSkeleton(props) {
  const { isCardDetailsRequired = true, color } = props;

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  return (
    <Grid
      container
      p={1}
      py={1.5}
      mt={2}
      sx={{
        alignItems: 'center',
        border: '1px solid #DCF5F1',
        borderRadius: '10px',
        '&.MuiGrid-root>.MuiGrid-item': { paddingTop: '0px' },
        width: '100%',
        justifyContent: 'space-betweens',
      }}
    >
      <Grid item xs={6}>
        <Grid container>
          <Grid
            pt={0}
            item
            xs={6}
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            {!isMobilePort && (
              <Skeleton
                variant="circular"
                width={50}
                height={50}
                animation="wave"
                sx={{
                  ...doctorModuleSkeleton_style.skeletonRoot,
                  ...(color && { background: color }),
                }}
              />
            )}
            <Box pl={isMobilePort ? 3 : null}>
              <Skeleton
                width={80}
                height={20}
                animation="wave"
                sx={{
                  ...doctorModuleSkeleton_style.skeletonRoot,
                  ...(color && { background: color }),
                }}
              />
              <Skeleton
                width={100}
                height={20}
                animation="wave"
                sx={{
                  ...doctorModuleSkeleton_style.skeletonRoot,
                  ...(color && { background: color }),
                }}
              />
            </Box>
          </Grid>

          {!isMobilePort && (
            <Grid
              pt={0}
              item
              xs={6}
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <Skeleton
                variant="circular"
                width={50}
                height={50}
                animation="wave"
                sx={{
                  ...doctorModuleSkeleton_style.skeletonRoot,
                  ...(color && { background: color }),
                }}
              />
              <Box>
                <Skeleton
                  width={80}
                  height={20}
                  animation="wave"
                  sx={{
                    ...doctorModuleSkeleton_style.skeletonRoot,
                    ...(color && { background: color }),
                  }}
                />
                <Skeleton
                  width={100}
                  height={20}
                  animation="wave"
                  sx={{
                    ...doctorModuleSkeleton_style.skeletonRoot,
                    ...(color && { background: color }),
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={6} mt={isCardDetailsRequired ? 1.5 : null}>
        <Grid container>
          <Grid
            item
            xs={6}
            pl={2}
            sx={{ display: 'flex', justifyContent: 'end' }}
          >
            <Stack flexDirection="row" alignItems="center" gap={4}>
              <Skeleton
                width={30}
                height={30}
                animation="wave"
                variant="rounded"
                sx={{
                  ...doctorModuleSkeleton_style.skeletonRoot,
                  ...(color && { background: color }),
                }}
              />
              <Skeleton
                width={30}
                height={30}
                animation="wave"
                variant="rounded"
                sx={{
                  ...doctorModuleSkeleton_style.skeletonRoot,
                  ...(color && { background: color }),
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
            <Skeleton
              width="80%"
              height="100%"
              animation="wave"
              variant="rounded"
              sx={{
                ...doctorModuleSkeleton_style.skeletonRoot,
                ...(color && { background: color }),
              }}
            />
          </Grid>{' '}
        </Grid>
      </Grid>
    </Grid>
  );
}

DoctorModuleSkeleton.propTypes = {
  isCardDetailsRequired: PropTypes.bool,
  color: PropTypes.string,
};

export { DoctorModuleSkeleton };
