/* eslint-disable no-unused-vars */
import { log } from '@hc/logger';
import { doctorRoutes } from '@hc/routes';
import { queryClient, routeTo } from '@hc/utils';
import { toast } from 'react-hot-toast';
import create from 'zustand';
import { useRouting } from '../common/routing';

export const useDoctorSpeciality = create((set, get) => ({
  specialityData: [],
  loading: null,
  error: null,
  getSpecialityData: async () => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status, data, error, isFetching } = await queryClient.fetchQuery([
        '/speciality/getAll',
        'get',
      ]);
      const specialityDataCopy = [];
      if (Array.isArray(data) && data?.length > 0) {
        data.map((val) => {
          specialityDataCopy.push({
            value: val?.id ?? '',
            label: val?.speciality ?? '',
          });
        });
      }
      return set((state) => ({
        loading: false,
        specialityData: specialityDataCopy,
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      if (error?.message === 'Request failed with status code 401') {
        // toast('Redirecting to the login page, please continue', { icon: '⚠️' });
        localStorage.clear();
        return routeTo(useRouting, doctorRoutes.signin);
      }
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
