import { DoctorWelcome } from '@hc/ui/atoms/illustration';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { doctorVerification_style } from './style';


export function DoctorVerification(props) {
  const { doctorName, isMobilePort } = props;
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography
        sx={{
          ...doctorVerification_style.doctorSx,
          mt: isMobilePort ? '125px' : '8px',
        }}
      >
        {`Welcome Dr. ${doctorName}`}
      </Typography>
      <Typography sx={doctorVerification_style.descriptionSx}>
        We&apos;ve received your account details.
      </Typography>
      <DoctorWelcome
        fillColor="#addcd4"
        rootStyle={
          isMobilePort
            ? { ...doctorVerification_style.illustartionMobileSx }
            : { ...doctorVerification_style.illustartionSx }
        }
      />
      <Typography sx={doctorVerification_style.descriptionSx}>
        We are verifying your documents, will notify you shortly after
        <br />
        the verification process is completed.
      </Typography>
    </Box>
  );
}

DoctorVerification.propTypes = {
  doctorName: PropTypes.string,
  isMobilePort: PropTypes.bool,
};
