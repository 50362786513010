import { ChatStatusCard, Screenlayout } from '@hc/ui/components';
import { Box } from '@mui/material';
import { doctor_status_style } from './style';

export default function DoctorChatStatusPage(props) {
  const { className = '' } = props;

  const chatStatusDetails = {
    id: 'b2393cfc-5bb4-4601-97d8-c52b04268966',
    appointment_date: '2024-01-09',
    appointment_time_slot: '13:15:00',
    reason_for_consultation: 'Health Issues',
    appointment_date_time: '2024-01-09T07:45:00.000Z',
    patientDetails: {
      id: '3b510761-8751-46f3-98d0-3ab230e55a0a',
      user_id: '5602bd1e-653c-4c42-9307-eeda4220787a',
      name: 'Jeyachandran',
      gender: 'Male',
      age: 22,
      mobile: '+91 9629334396',
      email_id: 'chandruvikki0007@gmail.com',
      profile_pic: '',
      relationship: null,
    },
    doctorDetails: {
      id: '01b69712-6e93-440d-9458-89bc5be1fb65',
      doctor_name: 'Jeyachandran S',
      education: 'MBB',
      mobile: '+91 9629334396',
      email_id: 'jeyachandran30112000@gmail.com',
      profile_pic:
        'https://healthcircles-staging.objectstore.e2enetworks.net/1697177972410.jpeg',
      consulation_charge: null,
    },
  };

  return (
    <Box sx={doctor_status_style.rootSx}>
      <Screenlayout
        title="Jeyachandran"
        className={`class ${className}`}
        backRequired
        backRoute={-1}
        notshowFooter
      >
        <Box px={1.5}>
          <ChatStatusCard
            confirmed
            message="Chat Request Sent!"
            subMessage="Waiting for the doctor's"
            chatStatusDetails={chatStatusDetails}
          />
        </Box>
      </Screenlayout>
    </Box>
  );
}
