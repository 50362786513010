import { Input, Label } from '@atoms';
import { useDoctorProfile, useViewPort } from '@hc/store';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { professionalInfo_style } from './style';
import { CheckBox } from '../../../atoms';

export function ProfessionalInfo(props) {
  const { className = '', ...rest } = props;
  // Store Data
  const { profile, handleProfileDataChange, setAuthValue } = useDoctorProfile(
    (state) => ({
      profile: state.profile,
      handleProfileDataChange: state.handleProfileDataChange,
      setAuthValue: state.setAuthValue,
    }),
    (prev, curr) => {
      isEqual(prev, curr);
      return false;
    },
  );
  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const changeState = (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    // HANDLE Basic DATA CAHNGE
    handleProfileDataChange(key, value);
  };

  // useEffect(() => setAuthValue(), []);

  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={!isMobilePort ? professionalInfo_style.rootSx : ''}>
        <Box sx={{ display: 'grid', gap: 3 }}>
          {/* Name */}
          <Box sx={professionalInfo_style.inputGroupSx}>
            <Label rootStyle={professionalInfo_style.labelStyle} isRequired>
              Registration Number
            </Label>
            <Input
              id="registrationNumber"
              errorMessage={profile?.validation?.register_no}
              isError={profile?.validation?.register_no?.length > 0}
              value={profile?.register_no}
              onChange={(e) => changeState('register_no', e.target.value)}
              textFieldStyle={professionalInfo_style.customtextSx}
            />
          </Box>
          {/* Qualification */}
          <Box sx={professionalInfo_style.inputGroupSx}>
            <Label rootStyle={professionalInfo_style.labelStyle} isRequired>
              Year Of Registration
            </Label>
            <Input
              id="registrationYear"
              errorMessage={profile?.validation?.year_of_registration}
              isError={profile?.validation?.year_of_registration?.length > 0}
              value={profile?.year_of_registration}
              onChange={(e) =>
                changeState('year_of_registration', e.target.value)
              }
              textFieldStyle={professionalInfo_style.customtextSx}
            />
          </Box>
          {/* Registration Number */}
          <Box sx={professionalInfo_style.inputGroupSx}>
            <Label rootStyle={professionalInfo_style.labelStyle} isRequired>
              State Medical Council
            </Label>
            <Input
              id="medicalCouncil"
              errorMessage={profile?.validation?.state_medical_council_name}
              isError={
                profile?.validation?.state_medical_council_name?.length > 0
              }
              value={profile?.state_medical_council_name}
              onChange={(e) =>
                changeState('state_medical_council_name', e.target.value)
              }
              textFieldStyle={professionalInfo_style.customtextSx}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'start' }}>
            <CheckBox
              id="verification"
              checked={profile.verification}
              onChange={() =>
                changeState('verification', !profile.verification)
              }
            />
            <Typography
              sx={{
                color: '#808080',
                lineHeight: '1.7',
                wordSpacing: '2px',
                letterSpacing: '1px',
                fontSize: '14px',
                fontWeight: 400,
                pl: 1,
              }}
            >
              {`Please review the information above, as it's crucial for your
              verification process.`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

ProfessionalInfo.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  className: PropTypes.node,
  sx: PropTypes.object,
};
