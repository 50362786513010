export const onboarding_content_card_style = {
  rootSx: {
    bgcolor: '#F5F5F5',
  },

  leftSideSx: {
    height: '100vh',
    // display: { xs: 'none', sm: 'flex' },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },

  titleSx: {
    fontSize: '20px',
    color: 'text.primary',
    fontWeight: 600,
  },
  rightImgSx: {
    height: '696px',
  },
  rightImgMobileSx: {
    height: '696px',
    marginBottom: '241px',
    opacity: '0.8',
  },

  totalSx: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    overflow: 'hidden',
  },

  rightSideSx: {
    px: 2.5,
    pt: 4,
    pb: 5,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '24px',
  },

  createPasswordSx: {
    fontSize: '20px',
    color: 'text.primary',
    textAlign: 'left',
    fontWeight: 600,
    py: 1.5,
  },
  CardcontentSx: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 8px 69px #0000001A',
    borderRadius: '12px',
    px: 4,
    py: 5,
    mt: 2.5,
    width: '435px',
  },
  formBoxSx: {
    fontSize: '20px',
    color: 'text.primary',
    textAlign: 'left',
    fontWeight: 600,
    py: 1.5,
  },
  CardcontentMobileSx: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 8px 69px #0000001A',
    borderRadius: '24px 24px 0px 0px',
    px: 2.5,
    py: 2.5,
    width: '386px',
    position: 'fixed',
    zIndex: 1,
    bottom: 0,
  },
  hcLogoSx: {
    ml: -1.15,
  },
  inputGroupSx: {
    pt: 1,
  },
  buttonStyleSx: {
    textTransform: 'capitalize',
  },
};
