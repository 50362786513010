export const drugsGroupCard_style = {
  switchSx: {
    '& .MuiSwitch-track': {
      backgroundColor: 'text.hint',
      opacity: 1,
      border: 0,
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#007965',
      opacity: 1,
    },
  },
  activeBoxSx: {
    backgroundColor: '#E1EDEB',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  boxSx: { cursor: 'pointer' },
  responsiveCard: {
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    p: 2,
    mb: 1.5,
  },
};
