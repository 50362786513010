export const doctorScreenLayout_style = {
  rootSx: {
    position: 'relative',
    width: '100%',
    minHeight: '100vh',
  },
  buttonSx: {
    backgroundColor: 'transparent',
  },

  appbarRightChildSx: {
    minWidth: '150px',
    display: 'flex',
    justifyContent: 'end',
  },
  doctorAppbarSx: {
    pl: 2.5,
    pr: 2.5,
    py: 2,
    boxSizing: 'border-box',
    bgcolor: '#fff',
    borderBottom: '1px solid #E3E3E3',
    position: 'sticky',
    top: 0,
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      lg: 1024,
    },
  },
  doctorAppbarContainerSx: {
    // pl: 2.5,
    // pr: 2.5,
    // py: 2,
    boxSizing: 'border-box',
    // bgcolor: '#fff',
    // borderBottom: '1px solid #E3E3E3',
    position: 'sticky',
    top: 0,
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      lg: 1024,
    },
  },
  totalScreenSx: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  backIconSx: {
    cursor: 'pointer',
    color: '#000 ',
  },
  headerSx: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  titleSx: {
    fontWeight: 600,
  },
  completedSx: {
    fontWeight: 600,
    color: '#25C460',
    textTransform: 'capitalize',
    opacity: 1,
  },
  leftSideContainerSx: {
    position: 'relative',
    minHeight: '100vh',
    overflowY: 'scroll',
    p: 1,
    borderRight: '1px solid #E3E3E3',
  },
  rightSideContainerSx: {
    p: 3,
    minHeight: '100vh',
  },
};
