import { getDateFormat } from '@hc/dayjs';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { reportCard_style } from './style';

function ReportSummaryCard(props) {
  const {
    className = '',
    selectedReport,
    reportType = '',
    date = '',
    reportDescription = '',
    caseReport = '',
    caseSummary = false,
    selectInd,
    index,
    rootStyle = {},
    ...rest
  } = props;

  const currentdate = getDateFormat(date, 'DD/MM/YYYY');

  return (
    <Box
      sx={{
        cursor: 'pointer',
        ...reportCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box
        id={`card${index + 1}`}
        onClick={() => selectedReport()}
        sx={selectInd ? reportCard_style.selectSx : reportCard_style.selectdSx}
      >
        <Box sx={reportCard_style.reportCardSx}>
          <Typography
            sx={
              selectInd
                ? reportCard_style.reportTypeSx
                : reportCard_style.reportTypeUnselectSx
            }
          >
            {reportType}
          </Typography>
          <Typography
            sx={
              selectInd
                ? reportCard_style.dateSx
                : reportCard_style.dateUnselectSx
            }
          >
            {/* {formatDate(date.toDateString())} */}
            {currentdate}
          </Typography>
        </Box>

        {caseSummary && (
          <Typography
            sx={
              selectInd
                ? reportCard_style.reportDescSx
                : reportCard_style.reportDescUnselectSx
            }
          >
            {caseReport}
          </Typography>
        )}
        <Typography
          sx={
            selectInd
              ? reportCard_style.reportDescSx
              : reportCard_style.reportDescUnselectSx
          }
        >
          {reportDescription}
        </Typography>
      </Box>
    </Box>
  );
}

ReportSummaryCard.propTypes = {
  reportType: PropTypes.string,
  caseSummary: PropTypes.bool,
  caseReport: PropTypes.string,
  date: PropTypes.string,
  reportDescription: PropTypes.string,
  selectInd: PropTypes.func,
  selectedReport: PropTypes.func,
  index: PropTypes.func,
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ReportSummaryCard };
