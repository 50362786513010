/* eslint-disable react/jsx-key */
import { useDoctorReport } from '@hc/store';
import { getFileType } from '@hc/utils';
import { Box } from '@mui/material';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { reportViewer_style } from './style';
import { PdfViewer } from '../pdfviewer';

function ReportViewer(props) {
  const { className = '', rootStyle = {}, adStyle = {}, ...rest } = props;

  const { reportState } = useDoctorReport(
    (state) => ({
      reportState: state.reportState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const { viewReportData, tabIndex } = reportState;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const fileTypeValues =
    tabIndex === '2'
      ? Array.isArray(viewReportData) && viewReportData?.length > 0
        ? viewReportData.map((data) => getFileType(data?.visit_report_url))
        : []
      : Array.isArray(viewReportData) && viewReportData?.length > 0
      ? viewReportData.map((data) => getFileType(data?.downloadUrl))
      : [];

  const fileType = fileTypeValues?.[0]?.toString() ?? '';

  return (
    <Box
      sx={{
        ...reportViewer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {fileType === 'pdf' && (
        <Box>
          {Array.isArray(viewReportData) && viewReportData?.length > 0
            ? viewReportData?.map((data) => (
                <div style={{ zoom: '50%' }}>
                  <PdfViewer
                    url={
                      tabIndex === '2'
                        ? data?.visit_report_url
                        : data?.downloadUrl
                    }
                  />
                </div>
              ))
            : null}
        </Box>
      )}

      {fileType !== 'pdf' && (
        <Box sx={{ ...reportViewer_style.adSx, ...adStyle }}>
          <Slider {...settings}>
            {Array.isArray(viewReportData) && viewReportData?.length > 0
              ? viewReportData?.map((data, i) => (
                  <img
                    key={i}
                    style={{ objectFit: 'fill' }}
                    src={
                      tabIndex === '2'
                        ? data?.visit_report_url
                        : data?.downloadUrl
                    }
                    alt=""
                  />
                ))
              : null}
          </Slider>
        </Box>
      )}
    </Box>
  );
}

ReportViewer.propTypes = {
  fileUpdate: PropTypes.bool,
  fileData: PropTypes.array,
  adStyle: PropTypes.object,
  src: PropTypes.string,
  fileType: PropTypes.string,
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ReportViewer };
