export const bookappoinmentDialog_style = {
  titleSx: {
    color: 'common.black',
    fontSize: '16px',
    fontWeight: '600',
    
  },
  profileSx: {
    display: 'flex',
    alignItems: 'center',
  },
  proPicSX: {
    mr: 1.75,
  },
  firstSx: {
    fontSize: '16px',
    fontWeight: '500',
  },
  subSx: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#808080',
  },
  dialogSx: {
    width: '650px',
    height: '550px',
  },

  inputSx: {
    mt: 3,
  },
  continueBtnSx: {
    display: 'flex',
    pt: 1.25,
  },
  saveBtnSx: {
    // mr: 1.25,
    width: '108px',
  },
  noBtnSx: {
    textTransform: 'none',
    backgroundColor: 'primaryTints.300',
    color: 'primary.main',
    boxShadow: 'none',
    width: '108px',
    '&:hover': {
      backgroundColor: 'primaryTints.300',
      boxShadow: 'none',
      width: '108px',
    },
  },
  buttonSx: {
    bgcolor: 'white',
    color: 'primary.main',
    borderRadius: '8px',
    '&:hover': {
      color: 'white',
    },
    border: '1px solid #007965',
  },

  availableTextSx: {
    fontSize: '14px',
    fontWeight: 'medium',
    color: 'text.primary',
    mt: 2,
    ml: 1.6,
  },
  totalSx: {
    backgroundColor: '#fff',
    width: ' 100%',
    margin: '0px auto',
    '& .MuiTabs-root': {
      minHeight: 0,
      borderRadius: '8px',
    },
  },
  tabSx: {
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: 'orangeTints.600',
      borderRadius: '8px',
      fontSize: '14px',
      p: '6px 0px',
      fontWeight: 600,
    },
  },
  underTabCalenderStyle: {
    backgroundColor: 'orangeTints.50',
    '& .MuiTabs-indicator': {
      backgroundColor: 'none',
      height: '0',
      px: 3,
    },
    '& .MuiTabs-flexContainer': {
      display: 'grid',
      gridTemplateColumns: 'repeat(4,1fr)',
      gap: '4px',
      boxSizing: 'border-box',
      m: 0.5,
      overflow: 'overlay',
      '::-webkit-scrollbar-thumb': {
        display: 'none',
      },
    },
  },

  timeSelectionSx: {
    pr: 1,
    py: 0.8,
    textAlign: 'center',
    borderRadius: '8px',
  },

  selectTimeButton: {
    backgroundColor: 'orangeTints.50',
    color: 'orangeTints.600',
    border: '1px solid',
  },

  pastTimeButton: {
    backgroundColor: 'primaryGreyishTints.100',
    fontSize: '8px',
    fontWeight: 'medium',
    opacity: 0.5,
  },
  unSelectTimeButton: {
    backgroundColor: '#fff',
    fontSize: '14px',
    fontWeight: 'medium',
    border: '1px solid',
    borderColor: '#007965',
  },

  iconWithTimeSelectionSx: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    cursor: 'pointer',
    px: 0.1,
  },

  SelectIconChange: {
    color: 'orangeTints.600',
    fontSize: '14px',
    fontWeight: 'medium',
  },

  unSelectIconChange: {
    color: 'divider',
    fontSize: '14px',
    fontWeight: 'medium',
  },

  timeTextSx: {
    fontSize: '14px',
    fontWeight: 500,
    cursor: 'pointer',
  },

  CalendarSx: {
    fontSize: '14px',
    color: 'text.primary',
    fontWeight: 'medium',
  },
  dateSelectionWrapperSx: {
    overflowY: 'scroll',
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: 1.5,
    mt: 1,
  },

  rootDateSx: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'minmax(auto, 2%) minmax(auto, 96%) minmax(auto, 2%)',
  },
  stickySx: {
    // position: '-webkit-sticky',
    position: 'sticky',
    top: '-18px',
    backgroundColor: '#fff',
    zIndex: 3,
  },
  dateSelectionSx: {
    px: 0.8,
    py: 1.8,
    textAlign: 'center',
  },

  pastButton: {
    backgroundColor: 'primaryGreyishTints.100',
    fontSize: '14px',
    fontWeight: 'medium',
    borderRadius: '24px',
    opacity: '0.5',
  },
  todayButton: {
    backgroundColor: 'orangeTints.50',
    fontSize: '14px',
    fontWeight: 'medium',
    borderRadius: '24px',
    border: '2px solid #EB6D13',
  },

  unSelectButton: {
    backgroundColor: 'primaryGreyishTints.100',
    fontSize: '14px',
    fontWeight: 'medium',
    borderRadius: '24px',
  },
};
