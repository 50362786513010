import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Drawer,
  FooterProfileIcon,
  LogoutConfirmIcon,
  LogoutIcon,
  RxIcon,
  SetAvailabilityIcon
} from '../../../atoms';

import { Layout } from '../layout';
import { profileMobilePage_style } from './style';

function ProfileMobilePage(props) {
  const { className = '', rootStyle = {}, ...rest } = props;
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const data = [
    {
      name: 'View Profile',
      icon: <FooterProfileIcon rootStyle={{ height: '16px', color: '#000' }} />,
      path: '/profile',
    },
    {
      name: 'Set Availability',
      icon: (
        <SetAvailabilityIcon
          rootStyle={{ height: '16px', px: '2px', color: '#000' }}
        />
      ),
      path: '/availability',
    },
    {
      name: 'Rx Group',
      icon: <RxIcon rootStyle={{ height: '16px', color: '#000' }} />,
      path: '/rxgroup',
    },
    // {
    //   name: 'Transactions',
    //   icon: (
    //     <TransectionsIcon
    //       rootStyle={{ height: '16px', px: '2px', color: '#000' }}
    //     />
    //   ),
    //   path: '/transactions',
    // },
    {
      name: 'Logout',
      icon: <LogoutIcon rootStyle={{ height: '16px' }} />,
      path: '/login',
    },
  ];

  const logout = () => {
    toast.success('Signed Out Successfully');
    setDrawerOpen(false);
    localStorage.clear();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const onCardClick = (path) => {
    if (path !== '/login') {
      navigate(path);
    } else {
      setDrawerOpen(true);
    }
  };

  const handleHome = () => {
    navigate('/');
  };

  return (
    <Box>
      <Layout isDoctorVerfied={data?.is_verified} handleHome={handleHome}>
        <Box
          sx={{
            ...profileMobilePage_style.rootSx,
            ...rootStyle,
            p: 2,
            mt: 7,
          }}
        >
          {data?.map((val, i) => (
            <Box
              key={i}
              sx={profileMobilePage_style?.cardRootSx}
              onClick={() => onCardClick(val?.path, i)}
            >
              <Box alignSelf="center">
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  color={val?.name === 'Logout' ? '#F44F5A' : '#000 '}
                >
                  {val?.name}
                </Typography>
              </Box>
              <Box
                alignSelf="center"
                width={20}
                display="flex"
                justifyContent="center"
              >
                {val?.icon}
              </Box>
            </Box>
          ))}

          {drawerOpen && (
            <Drawer
              show={drawerOpen}
              onCloseDrawer={() => setDrawerOpen(false)}
              rootStyle={profileMobilePage_style.drawerRootSx}
              footer={
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button
                    onClick={() => setDrawerOpen(false)}
                    buttonStyle={profileMobilePage_style.buttonSx}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={logout}
                    buttonStyle={profileMobilePage_style.callButtonSx}
                  >
                    Logout
                  </Button>
                </Box>
              }
            >
              <Box sx={profileMobilePage_style.logoutConfirmSx}>
                <Box>
                  <LogoutConfirmIcon />
                  <Typography
                    variant="body2"
                    sx={{ color: '#0F0B11', fontWeight: 500 }}
                  >
                    Are you sure you want to Logout?
                  </Typography>
                </Box>
              </Box>
            </Drawer>
          )}
        </Box>
      </Layout>
    </Box>
  );
}

ProfileMobilePage.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ProfileMobilePage };

