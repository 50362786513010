export const prescriptionEmptystate_style = {
  rootSx: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    fontSize: '20px',
    fontWeight: 500,
  },
};
